import React, { useState,useEffect, useMemo } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import styles from '../../rates.module.css';
import { useDispatch,useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import BulkDefaultChange from '../BulkDefaultChanges/BullkDefaultChange';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReactTooltip from 'react-tooltip';
import {LoadSettings} from '../../../../../../general_redux/general settings/actions';
import Loading from "../../../../../../components/loading";
import BulkChannelAdjustment from "../ChannelAdjustment/BulkChannelAdjustment";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import { customSort } from '../../../../../../common/functions/utils';


function RatesList() {
  const dispatch=useDispatch();

  const rateList = useSelector(({ rates }) => rates && rates.rateSettings);
  const actionForBulkDefault = useSelector(({rates})=>rates && rates.actionForBulkDefault);
  const rentals =useSelector(({rentals})=>rentals && rentals.rentals);
  const setting = useSelector(({generalSetting})=> generalSetting && generalSetting.setting);
  const rateListStatus = useSelector(({rates})=> rates && rates.actionForRateList);
  const currency = useSelector(({ user }) => user && user.user && user.user.currency);
  const channelAdjustmentRate = useSelector(({rates})=> rates && rates.channelAdjustmentRate);
  
  const [isEdit, setIsEdit] = useState(null);
  const [bulkDefaultModal, setBulkDefaultModal] = useState(false);

  useEffect(()=>{
    if(actionForBulkDefault.success){
      dispatch(LoadSettings());
    }
  },[actionForBulkDefault])

  const defaultRateslist =  useMemo(()=>{
    return [{
      rentalId: 'default',
      dailyRate: setting.dailyRate ?? 0, 
      maximumStayRequirement: setting.maximumStayRequirement ?? 0,
      minimumStayRequirement: setting.minimumStayRequirement ?? 1,
      blockDateBefore: setting.blockDateBefore ?? 0,
      blockDateAfter: setting.blockDateAfter ?? 0,
    }, ...rateList]
  }, [rateList, setting])

  const rateListOrdered = useMemo(() => {

    const rateListName = [];

    if(rentals && rentals.length!==0 && rateList && rateList.length!==0) {
        rateList.map((rate) => {
            const rental = rentals.find(rental => rental.id === rate.rentalId);
            if (rental) {
              rateListName.push({
                ...rate,
                rentalName: rental.name,
                parentRentalId: rental.parentRentalId,
              });
            }
        })
    };

    return [
      {
        rentalId: 'default',
        dailyRate: setting.dailyRate ?? 0, 
        maximumStayRequirement: setting.maximumStayRequirement ?? 0,
        minimumStayRequirement: setting.minimumStayRequirement ?? 1,
        blockDateBefore: setting.blockDateBefore ?? 0,
        blockDateAfter: setting.blockDateAfter ?? 0,
      },
      ...customSort(rateListName, 'asc', 'rentalName', 'rentalId'),
    ];

  }, [rateList, setting])

  const rateName=(id)=>{
    const tmp=rentals.filter((rental)=> {
      return rental.id === Number(id)
    })
    if(tmp.length!==0){
      return tmp[0].name;
    }else{
      return "";
    }
  };

  const findRentalById = (id) => {
    return rentals.find((rental) => Number(rental.id) === Number(id));
  };

  const [modalShow, setModalShow] = React.useState(false);

  const handleEdit = (data) => {
    const initValue = {
      multiRentals: [{
        value: data.rentalId,
        label: data.rentalId==='default' ? 'New Rentals' : rateName(data.rentalId)
      }],
      dailyRate: data.dailyRate,
      maximumStayRequirement: data.maximumStayRequirement,
      minimumStayRequirement: data.minimumStayRequirement,
      blockDateBefore: data.blockDateBefore,
      blockDateAfter: data.blockDateAfter,
    };
    setIsEdit(initValue);
    setBulkDefaultModal(true);
  }
  
  //cleanup function
  useEffect(() => {
    return () => {
      setBulkDefaultModal(false);
      setIsEdit(null);
    };
  },[])

  return (
    <div>
      <Loading loadingStatus={rateListStatus.loading || actionForBulkDefault.loading}/>
      <BulkDefaultChange
          show={bulkDefaultModal}
          onHide={() => {setBulkDefaultModal(false); setIsEdit(null);}}
          initValue = {isEdit}
      />
      <BulkChannelAdjustment
          show={modalShow}
          onHide={() => setModalShow(false)}
       />
      <div className={`my-2 col-sm-8 col-lg-6 col-xl-4 col-xs-12 p-0`}>
        <div className={`my-3 ${styles.btnContainer}`}>
          <button className={`${styles.ratelistBtn}`} onClick={() => setBulkDefaultModal(true)}>
            Set Defaults
          </button>
          <button className={`${styles.ratelistBtn}`}  onClick={() => setModalShow(true)}>
             Set Channel Adjustment 
          </button>
        </div>
        <div className="d-flex flex-direction-column">
        <label>Channel rates adjusted by: {channelAdjustmentRate}%</label>
          <div className={styles.toolTipIconContainer}>
              <InfoOutlinedIcon
                fontSize="small"
                color="inherit"
                data-tip
                data-for="SetChannelAdjustment"
                className={styles.toolTipIcon}
              />
              <ReactTooltip place="bottom" type="dark" id="SetChannelAdjustment" effect="solid" className={styles.toolTip}>
                <span>
                  {`The rates displayed in the rates calendar can be
                  marked up by a percentage. The rates with a
                  percentage adjustment will be the ones sent to channels (any OTA you have synchronized to Beehaz
                  with an API connection). Example: if your rate for a particular rental for a particular night is 100$ and you
                  want a 15% adjustment, the rate sent to channels is 115$.`}
                </span>
              </ReactTooltip>
            </div>
          </div>
      </div>
      <div className={`col-sm-8 col-lg-6 col-xl-4 col-xs-12 p-0`}>
        {rentals.length && rateListOrdered?.length!==0 ? (
            rateListOrdered.map((data, index) => {
            const currencyByRental = data.rentalId === "default" ? currency : findRentalById(data.rentalId)?.currency;
            return (
              <Table responsive hover className={styles.ratelistTable} key={index}>
                <thead>
                  <tr>
                    <th colSpan={2}>
                      <div  className={styles.rentalTitle}>
                          <div className="d-flex">
                            {data.parentRentalId && 
                              <SubdirectoryArrowRightIcon
                                  fontSize="small"
                                  style={{ color: "#9e9e9e" }}
                              />
                            }
                            <div className={styles.rentalName}>
                                {data.rentalId === 'default' ? 'New Rentals' : rateName(data.rentalId)}
                            </div>
                            {data.rentalId === 'default' &&
                              <div className={styles.toolTipIconContainer}>
                                <InfoOutlinedIcon
                                  fontSize="small"
                                  color="inherit"
                                  data-tip
                                  data-for="newRentals"
                                  className={styles.toolTipIcon}
                                />
                                <ReactTooltip place="bottom" type="dark" id="newRentals" effect="solid" className={styles.toolTip}>
                                  <span>
                                    Defaults are used when there is no rate set for particular date. When you create a new rental, 
                                    we will use the default rate and minimum stay requirement you set here. You can later change the 
                                    default for that (or any) rental.
                                  </span>
                                  <br />
                                  <br />
                                  <span>
                                    Defaults are used for your own peace of mind: you are always certain to have a rate set at all times.
                                  </span>
                                </ReactTooltip>
                              </div>
                            }
                          </div>
                          <div
                            className={styles.icon}
                            onClick={() => handleEdit(data)}
                          >
                            <i className={`${styles.editIcon} fa fa-pencil-square-o`}/>
                          </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={styles.tableWidth65}>Default nightly rate:</td>
                    <td className={styles.tableWidth35}>
                      {`${Number(data.dailyRate).toFixed(2)} ${currencyByRental ? getSymbolFromCurrency(currencyByRental) : getSymbolFromCurrency(currency)}`} 
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.tableWidth65}>Minimum stay:</td>
                    <td className={styles.tableWidth35}>{data.minimumStayRequirement} nights</td>
                  </tr>
                  <tr>
                    <td className={styles.tableWidth65}>Maximum stay:</td>
                    <td className={styles.tableWidth35}>{data.maximumStayRequirement} nights</td>
                  </tr>
                  <tr>
                    <td className={styles.tableWidth65}>Block nights before:</td>
                    <td className={styles.tableWidth35}>{data.blockDateBefore} nights</td>
                  </tr>
                  <tr>
                    <td className={styles.tableWidth65}>Block nights after:</td>
                    <td className={styles.tableWidth35}>{data.blockDateAfter} nights</td>
                  </tr>
                </tbody>
              </Table>
            );
          })
        ) : (
          <h5>No data to display</h5>
        )}
      </div>
    </div>
  );
};

export default RatesList;
