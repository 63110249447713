import React,{useState,useEffect} from 'react';
import {withStyles,makeStyles ,lighten,MuiThemeProvider,createTheme} from '@material-ui/core/styles';
import clsx from 'clsx';
import moment from 'moment';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableFooter,
    TableRow ,
    TableSortLabel,
    Paper,
    Checkbox,
    Typography,
    Toolbar,
    Tooltip,
    IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FilterListIcon from '@material-ui/icons/FilterList';
import styles from './table.module.css';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#F0F0F0',
        color: '#555',
        fontWeight: 550,
        lineHeight: '1.5rem',
    },
    root: {
        fontFamily:`'Montserrat','roboto'`,
        padding: '1em 0.3em'
    },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.primary.main,
                backgroundColor: lighten(theme.palette.primary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.dark,
            },
    title: {
        flex: '1 1 100%',
        fontWeight:'600'
    },
    subtitle:{
        flex: '1 1 100%',
    },
    palette:{
        primary: { // works
            main: '#439a86',
            contrastText: '#fff',
        },
    }
}));
const myTheme=createTheme({
    palette: {
        primary: { // works
            main: '#439a86',
            contrastText: '#fff',
        },
    },
});

function CustomizedTable (props){
    const classes = useStyles();
    const handleDelete=props.handleDelete;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(props.startKey);
    const [selected, setSelected] = useState([]);
    const [currentSelected, setCurrentSelected] = useState(false);
    const [page, setPage] = useState(0);
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const rowsPerPage = 25;
    const queryResult = props.rows;
    const totalPage = Math.ceil(queryResult.length / rowsPerPage);
    const showNavigateBeforeIcon = page > 0;
    const showNavigateNextIcon = !(page >= ((queryResult.length / rowsPerPage) - 1));
    const [zrows, setZrows] = useState(queryResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    
    useEffect(() => {
        setZrows(queryResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    }, [page, queryResult]);
    
    useEffect(() => {

        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const currentIDsList = zrows.map((item) => item.id);

    useEffect(()=>{
       setSelected([]);
    }, [props.clearCheckBoxes])

    const navigateBeforeFunc = () => {
        if (showNavigateBeforeIcon) {
            setPage(page-1);
        };
    };
      
    const navigateNextFunc = () => {
        if (showNavigateNextIcon) {
            setPage(page+1);
        };
    };

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    const handleRequestSort = ( property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = [...selected, ...currentIDsList];
            setSelected(newSelected);
        } else {
            const clearCheckBoxesOfCurrentTab = selected.filter(item => !currentIDsList.includes(item));
            setSelected(clearCheckBoxesOfCurrentTab);
        };
    };
    
    useEffect(() => {
        const checkCurrentSelected = currentIDsList.filter(item => selected.includes(item));
        setCurrentSelected(checkCurrentSelected);
     }, [zrows, selected]);

    const EnhancedTableToolbar = (props) => {
        const classes = useToolbarStyles();
        const { numSelected } = props;

        return (
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                {numSelected > 0 ? (
                    <Typography className={classes.subtitle} color="inherit" variant='inherit' component="div">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography className={classes.title} variant='inherit' id="tableTitle" component="div">
                        {props.heading}
                    </Typography>
                )}

                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="delete">
                            <DeleteIcon onClick={()=>handleDelete(selected)}/>
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Filter list">
                        <IconButton aria-label="filter list">
                            <FilterListIcon style={{display:'none'}}/>
                        </IconButton>
                    </Tooltip>
                )}
            </Toolbar>
        );
    };

    return(
        <MuiThemeProvider theme={myTheme}>
            <Paper className={classes.paper}>
                {(windowSize.width < 986 ? props.heading : selected.length) ? (
                    <EnhancedTableToolbar numSelected={selected.length} heading={props.heading} />
                ) : <></>}
                <TableContainer component={Paper}>
                    <Table aria-label="caption table">
                        <TableHead>
                            <TableRow>
                                {(props.heading) && (
                                    <StyledTableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={currentSelected.length > 0 && currentSelected.length < zrows.length}
                                            checked={(zrows.length) > 0 && currentSelected.length === zrows.length}
                                            onChange={handleSelectAllClick}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'select all desserts' }}
                                            style={myTheme.palette.primary}
                                        />
                                    </StyledTableCell>
                                )}
                                {props.cols.map((col,index)=>(
                                    <StyledTableCell
                                        align="left"
                                        key={index}
                                        sortDirection={orderBy === col.key? order : false}
                                    >
                                        {col.key!=="" ? (
                                            <span className={styles.wrapFieldTh}>
                                        <TableSortLabel
                                            active={orderBy === col.key}
                                            direction={orderBy === col.key ? order : 'asc'}
                                            onClick={()=>handleRequestSort(col.key)}
                                        >
                                            {col.lable}
                                            {orderBy === col.key ? (
                                                <span className={classes.visuallyHidden}>
                                                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                     </span>
                                        ):(
                                            <span className={styles.wrapFieldTh}>
                                            {col.lable}
                                        </span>
                                        )}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {zrows.length!==0 && stableSort(zrows, getComparator(order, orderBy))
                                .map((row) => {
                                    const isItemSelected = isSelected(row.id);
                                    return(
                                        <TableRow
                                            key={row.id}
                                            onClick={(event) => handleClick(event, row.id)}
                                            role="checkbox"
                                            color="primary"
                                            style={myTheme.palette.primary}
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                        >
                                            {(props.heading) && (
                                                <StyledTableCell>
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        color="primary"
                                                        inputProps={{'aria-labelledby': row.id}}
                                                        size={"small"}
                                                    />
                                                </StyledTableCell>
                                            )}
                                            {props.cols.map((field) => (
                                                <>
                                                    <StyledTableCell align="left" key={field.key}>
                                      <span className={styles.wrapField}>
                                        {field.lable === "Actions" ? (
                                            <>
                                                {props.onEdit && (
                                                    <span className={styles.icon}>
                                                    <i className="fa fa-pencil-square-o"
                                                       onClick={() => props.onEdit(row)}/>
                                                  </span>
                                                )}
                                                <span className={styles.icon}>
                                                <i className="fa fa-eye"/>
                                              </span>
                                                {props.onDelete && (
                                                    <span className={styles.icon}>
                                                      <i className="fa fa-trash" onClick={() => props.onDelete(row)}/>
                                                    </span>
                                                )}

                                            </>
                                        ) : (
                                            <span>{(field.isDate) ? `${moment(row[field.key]).format(props.dateFormat)}`
                                                : `${row[field.key]}`}</span>
                                        )}
                                      </span>
                                                    </StyledTableCell>
                                                </>
                                            ))}
                                        </TableRow>
                                    )})}
                        </TableBody>
                        {zrows.length===0 && (
                            <caption>No data found.</caption>
                        )}
                    </Table>
                </TableContainer>
            </Paper>
            {(totalPage > 1) && <TableFooter className="d-flex justify-content-center">
                <div className={styles.navigateContainer}>
                <NavigateBeforeIcon
                    style={{
                    color: showNavigateBeforeIcon ? "grey" : "transparent",
                    cursor: showNavigateBeforeIcon ? "pointer" : "default",
                    }}
                    onClick={navigateBeforeFunc}
                />
                <div>Page <span>{page + 1}</span> of <span>{totalPage}</span></div>
                <NavigateNextIcon
                    style={{
                    color: showNavigateNextIcon ? "grey" : "transparent",
                    cursor: showNavigateNextIcon ? "pointer" : "default",
                    }}
                    onClick={navigateNextFunc}
                />
                </div>
            </TableFooter>}
        </MuiThemeProvider>
    )
}

export default CustomizedTable;
