import React, { useState } from 'react';
import * as yup from 'yup';
import moment from "moment";
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import CreateMode from '../../Calendar/components/EditBooking/components/CreateMode/CreateMode';
import styles from '../../Calendar/components/EditBooking/editBooking.module.css';
import { getDateObjFromString } from '../../../../../common/functions/utils';

function EditInvoice(props){
    const { selectedInvoice, handleSubmit, bookingId, handleReset } = props;
    
    const bookingData = useSelector(({calendar}) => calendar && calendar.allBooking.filter((row) => row.id === bookingId)[0]);
    const settings = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);

    const initialValueForInvoiceItem = {
        id: 1,
        title: (bookingData) ? `${bookingData.rentalName}, ${moment(bookingData.arrive).format('Mo MMM YYYY')} - ${moment(bookingData.depart).format('Mo MMM YYYY')}, ${bookingData.noOfAdults + bookingData.noOfChildren} Guest(s)`: `Booking`,
        fee: Number(bookingData?.price ? Number(bookingData.price) : 0),
    };

    const [invoiceItems, setInvoiceItems] = useState(selectedInvoice?.invoiceItem || [initialValueForInvoiceItem]);
    const [feeItems, setFeeItems] = useState(selectedInvoice?.feeItems || []);
    const [taxItems, setTaxItems] = useState(selectedInvoice?.taxItems || []);

    const updateValidationSchema = (invoiceItems, feeItems, taxItems) => {
        setInvoiceItems(invoiceItems);
        setFeeItems(feeItems);
        setTaxItems(taxItems);
    };

    const initialValues = {
        issueDate: selectedInvoice?.invoiceDate ? getDateObjFromString(selectedInvoice?.invoiceDate) : new Date(),
        dueDate: selectedInvoice?.dueDate ? getDateObjFromString(selectedInvoice?.dueDate) : new Date(),
        status: selectedInvoice?.paymentStatus || "Not Paid",
        r_name: selectedInvoice?.recipientDetail.name || "",
        r_comp: selectedInvoice?.recipientDetail.company || "",
        r_address1: selectedInvoice?.recipientDetail.address1 || "",
        r_address2: selectedInvoice?.recipientDetail.address2 || "",
        r_address3: selectedInvoice?.recipientDetail.address3 || "",
        s_name: selectedInvoice?.senderDetail.name || "",
        s_address1: selectedInvoice?.senderDetail.address1 || `${settings?.address1}`,
        s_address2: selectedInvoice?.senderDetail.address2 || `${settings?.address2}`,
        s_address3: selectedInvoice?.senderDetail.address3 || `${settings?.address3}`,
        s_country: selectedInvoice?.senderDetail.country || `${settings?.country}`,
        invoiceText: selectedInvoice?.invoiceText || "",
        invoiceFooter: selectedInvoice?.invoiceFooter || "",
        total: selectedInvoice?.total ? Number(selectedInvoice?.total) : 0,
        invoiceNr: selectedInvoice?.invoiceNumber ? Number(selectedInvoice?.invoiceNumber) : 0,
        bookingNumber: selectedInvoice?.bookingNumber ? Number(selectedInvoice?.bookingNumber) : 0,
        stripeButton: selectedInvoice?.stripeButton || false,
        markBookingAsPaid: selectedInvoice?.markBookingAsPaid || false,
        whenPaidMarkBookingAsPaid: selectedInvoice?.whenPaidMarkBookingAsPaid || false,
        tableData: selectedInvoice?.invoiceItem || [initialValueForInvoiceItem],
        tableData2: selectedInvoice?.feeItems || [],
        tableData3: selectedInvoice?.taxItems || [],
        ...(selectedInvoice?.invoiceItem || [initialValueForInvoiceItem]).reduce((acc, curr, index) => {
            acc[`invoice_table-1_description_${index + 1}`] = curr.title;
            acc[`invoice_table-1_amount_${index + 1}`] = Number(curr.fee);
            return acc;
        }, {}),
        ...(selectedInvoice?.feeItems || []).reduce((acc, curr, index) => {
            acc[`invoice_table-2_description_${index + 1}`] = curr.title;
            acc[`invoice_table-2_amount_${index + 1}`] = Number(curr.fee);
            return acc;
        }, {}),
        ...(selectedInvoice?.taxItems || []).reduce((acc, curr, index) => {
            acc[`invoice_table-3_description_${index + 1}`] = curr.title;
            acc[`invoice_table-3_amount_${index + 1}`] = Number(curr.tax);
            return acc;
        }, {}),
    };

    const invoiceSchema =  yup.object().shape({
        issueDate: yup.date().required('Issue Date is a required field').typeError('Must be a value of type date'),
        dueDate: yup.date().required('Due date is a required field').typeError('Must be a value of type date'),
        status: yup.string().required('Status of payment is a required field'),
        r_name: yup.string().required("Recipient's name is a required field"),
        r_comp: yup.string(),
        r_address1: yup.string(),
        r_address2: yup.string(),
        r_address3: yup.string(),
        s_name: yup.string().required("Sender name is a required field"),
        s_address1: yup.string(),
        s_address2: yup.string(),
        s_address3: yup.string(),
        s_country: yup.string(),
        invoiceText: yup.string(),
        invoiceFooter: yup.string(),
        total: yup.number().typeError('Must be a number greater than 0'),
        invoiceNr: yup.number().required('Invoice Nr is a required field').typeError('Must be a number greater than 0'),
        bookingNumber: yup.number().typeError('Must be a number greater than 0'),
        stripeButton: yup.bool().typeError('Must be a boolean value'),
        markBookingAsPaid: yup.bool().typeError('Must be a boolean value'),
        whenPaidMarkBookingAsPaid: yup.bool().typeError('Must be a boolean value'),
        ...invoiceItems.reduce((acc, curr, index) => {
            acc[`invoice_table-1_description_${index + 1}`] = yup.string().required('Description is a required field').max(150, 'Must be 150 characters or less');
            acc[`invoice_table-1_amount_${index + 1}`] = yup.number().required('Amount is a required field').typeError('Must be a value of type number');
            return acc;
        }, {}),
        ...feeItems.reduce((acc, curr, index) => {
            acc[`invoice_table-2_description_${index + 1}`] = yup.string().required('Description is a required field').max(150, 'Must be 150 characters or less');
            acc[`invoice_table-2_amount_${index + 1}`] = yup.number().required('Amount is a required field').positive('Must be a number greater than 0').typeError('Must be a value of type number');
            return acc;
        }, {}),
        ...taxItems.reduce((acc, curr, index) => {
            acc[`invoice_table-3_description_${index + 1}`] = yup.string().required('Description is a required field').max(150, 'Must be 150 characters or less');
            acc[`invoice_table-3_amount_${index + 1}`] = yup.number().required('Amount is a required field').positive('Must be a number greater than 0').typeError('Must be a value of type number');
            return acc;
        }, {}),
    });

    return(
        <>
        <Modal
          onHide={props.onHide}
          show={props.show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`px-sm-2 p-0 ${styles.modal_body}`}>
                <div className="p-3">
                    <CreateMode
                        validationSchema={invoiceSchema}
                        onSubmit={handleSubmit}
                        onReset={handleReset}
                        initialValues={initialValues}
                        bookingId={bookingId}
                        updateValidationSchema={updateValidationSchema}
                    />
                </div>
            </Modal.Body>    
        </Modal>
        </>
    )
};

export default EditInvoice;