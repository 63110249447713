import React from 'react';
import { Box, Popover } from "@material-ui/core";
import { Formik } from 'formik';

import styles from './DeleteConfirm.module.css';


function DeleteConfirm (props) {

    return (
        <div>
            <Popover
                className={styles.modalContainer}
                id={props.id}
                open={props.open}
                onClose={props.onClose}
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        display: "flex",
                        boxShadow: "none",
                        borderRadius: 0
                    }
                }}
            >
            
            <Formik>
                <section className={styles.modalBody}>
                    <div className={styles.containerText}>
                        Are you sure you want to delete this entry?
                    </div>
                    <div className={styles.containerButton}>
                        <button
                            type="submit"
                            onClick={props.onConfirmAct}
                            className={`mr-3 ${styles.deleteBtn}`}
                        >
                            Delete
                        </button>
                        <button
                            onClick={props.onClose}
                            className={`${styles.cancelBtn}`}
                        >
                            Cancel
                        </button>
                    </div>
                </section>
            </Formik>

            <Box className={styles.rightArrow}/>

            </Popover>
        </div>
    );
};

export default DeleteConfirm;