import React, {useEffect} from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import comma from '../../icons/comma.svg';
import styles from './aboutUs.module.css';
import laptop from '../../icons/laptop.jpg';
import AboutForm from './AboutForm';

function AboutUS(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Header/>
            <div className={styles.mainLayout}>
                <h1 className={styles.mainHeader}>About us</h1>
                {/*<div className={styles.section1}>*/}
                {/*    <img className={styles.leftimg} src={comma}/>*/}
                {/*        <span className={styles.section1Text}>Our mission is to make rental management affordable through an intuitive software interface that offers the main functionalities to small entrepreneurs</span>*/}
                {/*        <img className={styles.rightimg} src={comma}/>*/}
                {/*</div>*/}
                <div className={styles.section1}>
                    <label className={styles.section1Text}>
                        <img src={comma} className={styles.leftSvgComma}/>
                        Our mission is to make rental management affordable by providing an intuitive software interface that offers the main functionalities business owners need.
                        <img src={comma} className={styles.rightSvgComma}/>
                    </label>
                </div>
                <div className={styles.section2}>
                    <span className={styles.section2Text}>One team, one mission</span>
                    <span className={styles.section2Text1}>BeeHaz was created by property managers for property managers.</span>
                    <span className={styles.section2Text1}>We were after simplicity and affordability in our search for a vacation management software, and found none that met our needs. Most solutions in the market are skewed towards catering to big hotels chains or mature rental management companies. Short trial periods, high prices, complicated rate management systems, and impractical interfaces were everywhere to be found. We embarked on a mission to solve this problem.</span>
                    <span className={styles.section2Text1}>The entrepreneur and the small and medium company managers deserve a software that fits their needs and suits their pockets in order to be able to grow and focus on their business</span>
                </div>
                <div className={styles.section3}>
                    <h1 className={styles.section3header}>The rental management software that grows with you</h1>
                    <span className={styles.section2Text}>Get inspired!</span>
                    <div className={styles.section3Image}>
                        <img src={laptop}/>
                    </div>
                    <span className={styles.section3Text}>Insights, inspiration, and rental management tips can be found in our blog. Recommendations from professional Airbnb hosts, guides for those starting out, and much more!</span>
                    <a href={"http://www.beehaz-resources.com/blog"} target={"_blank"} rel={"noopener noreferrer"}>
                        <button className={styles.section3btn}>Check out our blog!</button>
                    </a>
                </div>
                <div className={styles.section2}>
                    <span className={styles.section2Text}>Growing with you</span>
                    <span className={styles.section2Text1}>Beehaz entered the market with both basic and plus plans in 2021. Our development efforts will continue, as we believe there is still much more we can offer.​</span>
                    <span className={styles.section2Text1}>Help us built the best rental software for the best price in the market! Share your thoughts and feedbacks with us.</span>
                </div>
                <div className={styles.section4}>
                    <span className={styles.section4Text}>What features do you believe we should offer?</span>
                    <AboutForm/>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default AboutUS;