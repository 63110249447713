// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ConfirmDelete_modalTitle__3TF5S {\n    font-size: 12px;\n    padding: 4px 6px 8px 6px;\n}\n\n.ConfirmDelete_modalContainer__1-Flw {\n    background: #000 !important;\n    color: #fff !important;\n    padding: 8px 10px;\n    border-radius: 8px;\n    max-width: 180px;\n}\n\n.ConfirmDelete_rightArrow__2M_3- {\n    position: relative;\n    margin-right: 10px;\n}\n\n.ConfirmDelete_rightArrow__2M_3-::before{\n    background-color: #000;\n    content: '';\n    display: block;\n    position: absolute;\n    width: 12px;\n    height: 12px;\n    right: -6px;\n    top: calc(50% - 6px);\n    transform: rotate(45deg);\n}\n\n.ConfirmDelete_cancelBtn__3cxyN, .ConfirmDelete_deleteBtn__131wo {\n    color: white;\n    padding: 0.2em 0.5em;\n    border: 0px;\n    font-size: 12px;\n    border-radius: 5px;\n}\n\n.ConfirmDelete_cancelBtn__3cxyN {\n    background-color: #177e66;\n}\n\n.ConfirmDelete_cancelBtn__3cxyN:disabled {\n    background-color:#61aa99;\n}\n\n.ConfirmDelete_deleteBtn__131wo {\n    background-color: #dc3545;\n    margin-right: 1em;\n}\n\n.ConfirmDelete_deleteBtn__131wo:hover {\n    background-color: #cd0027 !important;\n}\n\n@media screen and (max-width: 480px) {\n    .ConfirmDelete_modalContainer__1-Flw {\n        max-width: 170px;\n        padding: 6px 10px;\n    }\n\n    .ConfirmDelete_modalTitle__3TF5S {\n        padding: 4px 6px 8px 6px;\n    }\n\n    .ConfirmDelete_cancelBtn__3cxyN, .ConfirmDelete_deleteBtn__131wo {\n        font-size: 11px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"modalTitle": "ConfirmDelete_modalTitle__3TF5S",
	"modalContainer": "ConfirmDelete_modalContainer__1-Flw",
	"rightArrow": "ConfirmDelete_rightArrow__2M_3-",
	"cancelBtn": "ConfirmDelete_cancelBtn__3cxyN",
	"deleteBtn": "ConfirmDelete_deleteBtn__131wo"
};
module.exports = exports;
