import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Popover } from "@material-ui/core";
import { Formik, Field } from 'formik';

import { editBookingColor } from "../../../../../../../general_redux/calendar/actions";
import styles from './ColorsBox.module.css';


function ColorsBox (props) {
    const dispatch = useDispatch();
    
    const { booking } = props;
    const [color, setColor] = useState('');

    const colorsMap = useSelector(({ generalSetting }) => {
        const colorsSetting = generalSetting && generalSetting.setting.colors;
        const map = [];
        for (const key in colorsSetting) {
            if (key.startsWith('color')) {
                map.push(colorsSetting[key]);
            }
        };
        return map;
    });

    useEffect(() => {
        setColor(booking?.color);
    }, [booking]);

    const handleHideColorsModal = () => {
        props.onClose();
        setColor(booking?.color)
    };

    const handleSubmit = () => {
        const payload = {
            id: booking?.id,
            color: color
        };
        dispatch(editBookingColor(payload));
        // console.log(payload);

        handleHideColorsModal();
    };

    return (
        <Popover
            id={props.id}
            open={props.open}
            onClose={props.onClose}
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: 0,
                    display: "flex",
                    marginTop: -6,
                }
            }}
        >
        <Formik>
            <section className={styles.modalBody}>
                <Box
                    className={styles.leftArrow}
                />
                <div className={styles.containerColor}>
                    {colorsMap.map((item, index)=>(
                        <div
                            key={index}
                            style={{background: `${item}`}}
                            className={`${styles.containerBox} ${(color === item) ? ` active1` : ``}`}
                            onClick={() => setColor(item)}
                        />
                    ))}
                    <Field
                        type={'color'}
                        name={'color'}
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                        className={`${styles.colorPicker} ${styles.containerBox}`}
                    />
                </div>
                <button onClick={handleSubmit} className={styles.submitButton}>
                    Save
                </button>
            </section>
        </Formik>
        </Popover>
    );
};

export default ColorsBox;