import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import styles from './loading.module.css';
import Loader from "react-loader-spinner";
import { useResizeDetector } from 'react-resize-detector';

function Loading(props){

  const { width, height, ref } = useResizeDetector();
  const { loadingStatus, className, zindex } = props;
  return (
    <div ref={ref}>
      <Modal
        show={loadingStatus}
        centered
        size="lg"
        contentClassName={styles.modalContent}
      >
          <div className={styles.loader} >
            <Loader
                type={"Oval"}
                color="#f5b60e"
                ariaLabel='loading'
                height={40}
                width={40}
            />
          </div>
      </Modal>
    </div>
  )
};

export default Loading;
