import {
    ADD_ICAL_CHANNEL,
    EDIT_ICAL_CHANNEL,
    GET_ICAL_CHANNEL_LIST,
    DELETE_ICAL_CHANNEL,
    CHANGE_ICAL_CHANNEL_NAME
} from './types';

import { BACKEND_API } from "../../config/middleware";
import API from "../../config/api-url";


export const addChannel = data => ({
    type: BACKEND_API,
        payload: Object.assign(
        {
            type: ADD_ICAL_CHANNEL,
            method: 'post',
            url: API.ICAL_CHANNEL,
            data: data
        },
    )
});

export const editChannel = data => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type: EDIT_ICAL_CHANNEL,
            method: 'put',
            url: API.ICAL_CHANNEL,
            data: data
        },
    )
});

export const getChannelList = () => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type: GET_ICAL_CHANNEL_LIST,
            method: 'get',
            url: API.ICAL_CHANNEL
        },
    )
});

export const deleteChannel = id => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type: DELETE_ICAL_CHANNEL,
            method: 'delete',
            url: `${API.ICAL_CHANNEL}${id}`,
        },
    )
});

export const changeChannelName = data => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type: CHANGE_ICAL_CHANNEL_NAME,
            method: 'put',
            url: `${API.ICAL_CHANNEL}${data.id}`,
            data: data
        },
    )
});