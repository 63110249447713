// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".contactus_form_wrapper__3XltS {\n  padding: 5px 0px;\n}\n\n.contactus_form_title__2paQb {\n  display: block;\n  background-color: white !important;\n  margin-top: 8px;\n}\n\n.contactus_form_entry___Tbfn {\n  border-radius: 5px !important;\n  border: 1px solid #d1ceda;\n  width: 100% !important;\n  padding-left: 5px;\n}\n\n.contactus_form_entry_err__7nPfm {\n  border-radius: 5px !important;\n  border: 1px solid red;\n  width: 100% !important;\n}\n\n@keyframes contactus_fadeIn__QYhiI {\n  0% {\n    color: rgba(255, 0, 0, 0);\n  }\n  100% {\n    color: rgba(255, 0, 0, 1);\n  }\n}\n\n.contactus_form_error__1dcow {\n  font-size: smaller;\n  color: red;\n  animation-name: contactus_fadeIn__QYhiI;\n  animation-duration: 1s;\n}\n\n.contactus_form_error_hidden__1Cg7n {\n  display: none;\n}\n\n.contactus_form_submit__35w5I {\n  background-color: #439a86;\n  color: white;\n  padding: 7px;\n  width: 200px;\n  border: 0px;\n  font-size: 16px;\n  border-radius: 5px;\n  margin: 30px 10px;\n  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,\n    box-shadow 0.15s ease-in-out;\n}\n.contactus_form_submit__35w5I:hover {\n  background-color: #177e66;\n}\n\n#contactus_message__1jpKm {\n  height: 4rem !important;\n}\n", ""]);
// Exports
exports.locals = {
	"form_wrapper": "contactus_form_wrapper__3XltS",
	"form_title": "contactus_form_title__2paQb",
	"form_entry": "contactus_form_entry___Tbfn",
	"form_entry_err": "contactus_form_entry_err__7nPfm",
	"form_error": "contactus_form_error__1dcow",
	"fadeIn": "contactus_fadeIn__QYhiI",
	"form_error_hidden": "contactus_form_error_hidden__1Cg7n",
	"form_submit": "contactus_form_submit__35w5I",
	"message": "contactus_message__1jpKm"
};
module.exports = exports;
