// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ConfirmDelete_modalTitle__2fmSH {\n    font-size: 1.4em !important;\n}\n\n.ConfirmDelete_modalBody__1fvFE {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    font-size: 1em;\n}\n\n.ConfirmDelete_deleteIcon__FzSTV {\n    font-size: 13px !important;\n    margin-left: 7px;\n}\n  \n.ConfirmDelete_cancelIcon__1_wc3 {\n    font-size: 11px !important;;\n    margin-left: 7px;\n}\n\n.ConfirmDelete_allBtn__3-zsf {\n    height: 30px;\n    width: 99%;\n    color: white !important;\n    border: 0 !important;\n    border-radius: 5px !important;\n    margin: 2px !important;\n}\n\n.ConfirmDelete_cancelBtn__1Ksrd {\n    background-color: #177e66;\n}\n\n.ConfirmDelete_cancelBtn__1Ksrd:hover {\n    background-color:#61aa99;\n}\n\n.ConfirmDelete_deleteBtn__onbcf {\n    background-color: #dc3545 !important;\n}\n\n.ConfirmDelete_deleteBtn__onbcf:hover {\n    background-color: #cd0027 !important;\n}", ""]);
// Exports
exports.locals = {
	"modalTitle": "ConfirmDelete_modalTitle__2fmSH",
	"modalBody": "ConfirmDelete_modalBody__1fvFE",
	"deleteIcon": "ConfirmDelete_deleteIcon__FzSTV",
	"cancelIcon": "ConfirmDelete_cancelIcon__1_wc3",
	"allBtn": "ConfirmDelete_allBtn__3-zsf",
	"cancelBtn": "ConfirmDelete_cancelBtn__1Ksrd",
	"deleteBtn": "ConfirmDelete_deleteBtn__onbcf"
};
module.exports = exports;
