import React,{useEffect} from 'react';
import styles from'./iCalExplanation.module.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function Terms(props){

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    return(
        <>
            <Header/>
            <div className={styles.mainLayout}>
                <div className={`${styles.container}`}>
                    <h1 className={`${styles.H1} ${styles.headerTitle}`}>
                        iCal connection to OTAs
                    </h1>
                    <br/>
                    <div className={styles.container_question}>
                        <h3 className={styles.generalH3}>What does 'iCal connection' mean?</h3>
                    </div>

                    <div className={styles.container_answer}>
                        <p className={styles.generalP}>
                            iCal is the industrial standard of how one calendar is linked to another. It communicates availability through a text file. If you link your Beehaz calendar to other online travel agencies (OTAs), whenever you receive a booking through one of the channels, those days will be blocked in the Beehaz calendar. 
                            Beehaz will then 'inform' the other OTAs that those dates for that particular unit are blocked. 
                            This is true for any two-way iCal connection (meaning links to channels that accept both sending and receiving information, such as Airbnb and VRBO).
                            This way, you can avoid double bookings.
                        </p>
                        <p className={styles.generalP}>
                            All major OTAs accept iCal connections: VRBO, Rentals.com, Wimdu, Airbnb, Booking.com, Agoda, among others. 
                            Major calendar providers can also accept iCal links, such as Google Calendar, Yahoo, and Outlook.  
                        </p>
                    </div>

                    <div className={styles.container_question}>
                        <h3 className={styles.generalH3}>What type of information is synchronized?</h3>
                    </div>
                    <div className={styles.container_answer}>
                        <p className={styles.generalP}>
                            The amount of information Beehaz receives, depends on the OTA sending the information. It usually contains the check-in/out dates and number of guests. It may contain information such as the guest's name and phone number.
                        </p>
                        <p className={styles.generalP}>
                            Please note you still have to manage your rates in the OTAs themselves, as rates are not shared via iCal links.  
                        </p>
                    </div>

                    <div className={styles.container_question}>
                        <h3 className={styles.generalH3}>How easy is the connection process?</h3>
                    </div>
                    <div className={styles.container_answer}>
                        <p className={styles.generalP}>
                            It is very easy to connect your calendars to OTAs. You will be provided with a link per property, which you can then copy to the OTA in question. That OTA should also provide you with a link, which you then copy and paste to Beehaz. This will enable a 2-way information flow.
                        </p>
                    </div>

                    <div className={styles.container_question}>
                        <h3 className={styles.generalH3}>Can Beehaz help me avoid double bookings??</h3>
                    </div>
                    <div className={styles.container_answer}>
                        <p className={styles.generalP}>
                            Yes, Beehaz can help you manage your rental units and help avoid double bookings.
                            As soon as Beehaz receives a message from a linked chanel that you have received a reservation, you will receive a notification. This makes managing multiple calendars much easier.
                        </p>
                        <p className={styles.generalP}>
                            Beehaz sends booking information right away. You should keep in mind, however, that iCal imports can take several minutes, since the synchronization time depends on how fast other calendars provide us with information. Beehaz cannot control how fast the information is provided to us and therefore we recommend disabling instant bookings. 
                        </p>
                    </div>
                </div>
            </div>
            <br/>
            <Footer/>
        </>
    )
}

export default Terms;