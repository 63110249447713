/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import {Field, Form, Formik} from "formik";
import Toggle from 'react-toggle';
import {Row} from "react-bootstrap";
import { useSelector,useDispatch} from "react-redux";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { Checkbox } from "@material-ui/core";
import '../../../../../UserSetting/toggle-css.css';
import iconPlus from "../../../../../../../../icons/icons8-plus.svg";
import {makeCustomerPayment} from '../../../../../../../../general_redux/user/actions';
import {fetchDiscountByBooking, resetCharges, fetchFeesTaxesById, clearFessTaxes} from '../../../../../../../../general_redux/calendar/actions';
import CustomDate from '../../../../../../../../components/CustomDate';
import { zeroPad } from "../../../../../../../../common/functions/utils";
import styles from "../../editBooking.module.css";
import styles2 from "../invoiceDetail.module.css";
import Loading from "../../../../../../../../components/loading"; 

const myTheme=createTheme({
    palette: {
        primary: { // works
            main: '#439a86',
            contrastText: '#fff',
        },
    },
});

function CreateMode({bookingId, initialValues, validationSchema, updateValidationSchema, onSubmit, onReset}){
    const dispatch=useDispatch();
    const [useBookingId, setBookingId] = useState(bookingId);
    const [showBookingNr,setShowBookingNr]=useState(initialValues.bookingNumber !== "" ? true : false);
    const bookingData= useSelector(({calendar}) => calendar &&
        calendar.allBooking.filter((row)=>row.id===bookingId)[0]);
    const bookingDiscount=useSelector(({calendar})=>calendar && calendar.bookingDiscount);
    const stripeID=useSelector(({generalSetting})=>generalSetting && generalSetting.setting && generalSetting.setting.stripeId);
    const paymentCustomerAction =  useSelector(({user})=>user && user.paymentCustomerAction);
    const [paypalStatus,setPaypalStatus]=useState(false);
    const rentalDetail = useSelector(({rentals})=>rentals
        && rentals.rentals.filter((row)=> {
            if(bookingData){
                return row.id === bookingData.rentalId
            }
        })[0]);
    const paymentOpt = ['Not Paid', 'Partially Paid', 'Paid','Cancelled'];
    const [totalCost,setTotalCost]=useState(initialValues.total);
    const [tableData,setTableData]=useState((initialValues.tableData && initialValues.tableData.length > 0)
        ?initialValues.tableData:[
        {
            id:1,
            title:(bookingData)?`${bookingData.rentalName}, ${moment(bookingData.arrive).format('Mo MMM YYYY')} - ${moment(bookingData.depart).format('Mo MMM YYYY')}, ${bookingData.noOfAdults + bookingData.noOfChildren} Guest(s)`:`Booking`,
            fee:Number(`${initialValues.total}`),
        }
    ]);
    const [tableData2, setTableData2] = useState((initialValues.tableData2 && initialValues.tableData2.length > 0)
        ? initialValues.tableData2 : []);
    const [tableData3, setTableData3] = useState((initialValues.tableData3 && initialValues.tableData3.length > 0)
        ? initialValues.tableData3 : []);
    const [viewFee,setViewFee]=useState(tableData2.length > 0);
    const [viewTaxes,setViewTaxes]=useState(tableData3.length > 0);
    const user=useSelector(({user})=>user && user.user);
    const feesTaxes = useSelector(({ calendar }) => calendar && calendar.bookingFeesTaxes);
    const [issueDate,setIssueDate] = useState(initialValues.issueDate? initialValues.issueDate:"");
    const [dueDate,setDueDate] = useState(initialValues.dueDate ? initialValues.dueDate : "");

    useEffect(()=>{
        return () => {
            dispatch(resetCharges());
            dispatch(clearFessTaxes());
        }
    },[]);

    useEffect(() => {
        if(!initialValues.tableData || initialValues.tableData.length === 0){
            dispatch(fetchDiscountByBooking(useBookingId));
            dispatch(fetchFeesTaxesById(useBookingId));
        }
    },[bookingId]);

    useEffect(() => {
        let overridePrices = bookingData && bookingDiscount && bookingData.price!==bookingDiscount.totalPrice;
        if(!overridePrices){
            if (feesTaxes.fee && feesTaxes.fee.length) {
                setTableData2(feesTaxes.fee);
                setViewFee(feesTaxes.fee.length > 0);
            }
            if(feesTaxes.tax && feesTaxes.tax.length) {
                setTableData3(feesTaxes.tax);
                setViewTaxes(feesTaxes.tax.length > 0);
            }
        }
    }, [feesTaxes]);

    useEffect(() => {
        if(bookingDiscount?.bookingId && (!initialValues.tableData || initialValues.tableData.length === 0)){
            let newInvoiceItem = [...tableData];
            const overridePrice = bookingData && bookingDiscount && bookingData.price!==bookingDiscount.totalPrice;
            if(newInvoiceItem.length){
                newInvoiceItem[0] = {...newInvoiceItem[0], fee: overridePrice ? bookingData.price : bookingDiscount.baseRate + bookingDiscount.extraGuestFee}
            }
            if(!overridePrice) {
                if(bookingDiscount.discountAmount !== 0){
                    newInvoiceItem.push({id: (newInvoiceItem[newInvoiceItem.length - 1]?.id || 0) + 1, title: bookingDiscount.discountName , fee: -Math.abs(bookingDiscount.discountAmount)})
                }
                if(bookingDiscount.chargeAmount !== 0){
                    newInvoiceItem.push({id: (newInvoiceItem[newInvoiceItem.length - 1]?.id || 0) + 1, title: bookingDiscount.chargeName , fee: bookingDiscount.chargeAmount})
                }
            }
            setTableData(newInvoiceItem);
        }
    },[bookingDiscount]);

    useEffect(()=>{
        let tmp=0;
        tableData.forEach((row)=>{
            tmp+=Number(row.fee);
        })
        viewFee && (tableData2.forEach((row) => {
            tmp += Number(row.fee);
        }))
        viewTaxes && (tableData3.forEach((row) => {
            tmp += Number(row.tax);
        }))
        setTotalCost(Number(tmp.toFixed(2)));

        updateValidationSchema(tableData, tableData2, tableData3);

    }, [tableData, tableData2, tableData3, viewTaxes, viewFee]);

    const setInputs=(val,index,field, tableName)=>{

        const myArr= tableName === "tableData" ? [...tableData] : tableName === "tableData2" ? [...tableData2] : [...tableData3];
        if(field){
            myArr[index].title=val;
        } else {
            if (tableName === "tableData3") { myArr[index].tax = val }
            else { myArr[index].fee = val }
        }
        if(tableName === "tableData2") {
            setTableData2(myArr)
        } else if(tableName === "tableData3") {
            setTableData3(myArr)
        } else {
            setTableData(myArr);
        }
    }

    const setData=(tableName)=>{
        let amountField = tableName === "tableData3" ? {tax: ""} : {fee: ""}
        let addField = {
            title:"",
            ...amountField
        }
        if(tableName === "tableData"){
            setTableData((prevState)=>([...prevState,{...addField, id: (tableData[tableData.length - 1]?.id || 0) + 1}]))
        } else if(tableName === "tableData2") {
            setTableData2((prevState)=>([...prevState,{...addField, id: (tableData2[tableData2.length - 1]?.id || 0) + 1}]))
        } else if(tableName === "tableData3") {
            setTableData3((prevState)=>([...prevState,{...addField, id: (tableData3[tableData3.length - 1]?.id || 0) + 1}]))
        }
    }

    const handleDelData=(id, tableName)=>{
        if(tableName === "tableData"){
            setTableData(tableData.filter((row)=>(row.id!==Number(id))));
        } else if(tableName === "tableData2"){
            setTableData2(tableData2.filter((row)=>(row.id!==Number(id))));
        } else if(tableName === "tableData3"){
            setTableData3(tableData3.filter((row)=>(row.id!==Number(id))));
        }
    }

    const convertToSmallUnit = (total)=>{
        const tmp = parseFloat(total).toFixed(2);
        return Math.round(tmp*100);
    }

    const handleStripeConnect=(e)=>{
        e.preventDefault()
        //connect stripe
        if(user && rentalDetail){
            const payload={
                customer_id:user.id,
                name:user.name,
                amount: convertToSmallUnit(totalCost),
                currency:rentalDetail.currency,
            }
            dispatch(makeCustomerPayment(payload));
        }
    }

    return(
        <MuiThemeProvider theme={myTheme}>
        <Loading 
            loadingStatus={paymentCustomerAction.loading} 
        />
        <div className={'p-0'}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(data)=>{
                    onSubmit({ ...data, bookingNumber: showBookingNr ? data.bookingNumber || `${new Date().getUTCFullYear()}${zeroPad(bookingData.id, 4)}` : '' }, totalCost, tableData, viewFee ? tableData2:[], viewTaxes ? tableData3 : [])
                }}
                onReset={()=>onReset()}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleReset,
                    setFieldValue
                  })=>(
                    <Form>
                        <div className={styles.mainContainer}>
                            <div className={`col-lg-6 col-md-12 px-xs-1 p-0`}>
                                <div className={`${styles.form_wrapper} d-flex`} key={'invoiceNr'}>
                                    <div className={styles2.form_title}>{'Invoice Nr: '}</div>
                                    <div>
                                        <Field
                                            type={'number'}
                                            id={"invoiceNr"}
                                            name={'invoiceNr'}
                                            onChange={handleChange}
                                            className={`${errors.invoiceNr ? styles2.form_entry_err : styles2.form_entry} w-50`}
                                            min={0}
                                        >
                                        </Field>
                                        <div className={`${styles2.errorMessage}`} >{errors.invoiceNr}</div>
                                    </div>
                                </div>
                                <div className={`${styles.form_wrapper} d-flex`} key={'bookingEnable'}>
                                    <div className={styles2.form_title}>{'Booking Nr: '}
                                        <InfoOutlinedIcon
                                            fontSize="small"
                                            color="inherit"
                                            data-tip
                                            data-for="bookingNumberTooltip"
                                            className={styles.toolTipIcon}
                                        />
                                        <ReactTooltip place="bottom" type="dark" id="bookingNumberTooltip" effect="solid" className={styles.toolTip}>
                                            <span>
                                                You can choose whether to include the booking number on the invoice.
                                            </span>
                                        </ReactTooltip>
                                    </div>
                                    <div className={`border-0 d-flex justify-content-center flex-column ${styles2.form_entry_half} mr-3`}>
                                        <Toggle
                                            defaultChecked={Boolean(showBookingNr)}
                                            icons={false}
                                            id={"bookingNrToggle"}
                                            value={String(values.bookingNumber) || (bookingData ? `${new Date().getUTCFullYear()}${zeroPad(bookingData.id, 4)}` : "")}
                                            onChange={(e) => setShowBookingNr(!showBookingNr)}
                                        />
                                    </div>
                                    {showBookingNr && <div>
                                        <Field
                                            type={'number'}
                                            name={'bookingNumber'}
                                            id={"bookingNumber"}
                                            disabled={true}
                                            value={values.bookingNumber || (bookingData ? `${new Date().getUTCFullYear()}${zeroPad(bookingData.id, 4)}` : 0)}
                                            className={`${errors.bookingNumber ? styles2.form_entry_err : styles2.form_entry} w-50`}
                                            min={0}
                                        >
                                        </Field>
                                    </div>}
                                </div>
                                <div className={`${styles.form_wrapper} d-flex`} key={'issueDate'}>
                                    <div className={styles2.form_title}>{'Date: '}</div>
                                    <div>
                                        <CustomDate
                                            id={"issueDate"}
                                            name={'issueDate'}
                                            handleChange={(date)=>{
                                                setIssueDate(date);
                                                setFieldValue('issueDate',date);
                                            }}
                                            hasError={errors.issueDate}
                                            value={issueDate}
                                        >
                                        </CustomDate>
                                        <div className={errors.issueDate ? styles.form_error : styles.form_error_hidden}>{errors.issueDate}</div>
                                    </div>
                                </div>
                                <div className={`${styles.form_wrapper} d-flex`} key={'dueDate'}>
                                    <div className={styles2.form_title}>{'Due date: '}</div>
                                    <div>
                                        <CustomDate
                                            id={"dueDate"}
                                            name={'dueDate'}
                                            handleChange={(date)=>{
                                                setDueDate(date);
                                                setFieldValue('dueDate',date);
                                            }}
                                            hasError={errors.dueDate}
                                            value={dueDate}
                                        >
                                        </CustomDate>
                                        <div className={`${errors.dueDate ? styles.form_error : styles.form_error_hidden}`}>{errors.dueDate}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`col-lg-6 col-md-12 p-0`}>
                                <div className={`${styles.form_wrapper} d-flex`} key={'paymentStatus'}>
                                    <div className={`${styles2.labelInvoiceStatus} ${styles2.form_title}`}>{'Invoice status: '}</div>
                                    <div className={`${styles2.toggleInvoiceStatus}`}>
                                        <Field
                                            as={'select'}
                                            name={'status'}
                                            className={
                                                errors.status
                                                    ? styles2.form_entry_err
                                                    : `${styles2.form_entry_half} w-100`
                                            }
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFieldValue('stripeButton', false);
                                                setFieldValue('markBookingAsPaid', false);
                                                setFieldValue('whenPaidMarkBookingAsPaid', false);
                                            }}
                                            value={values.status}
                                            id={"invoiceStatus"}
                                        >
                                            {paymentOpt.map((opt) => (
                                                <option value={opt} key={opt}>
                                                    {opt}
                                                </option>
                                            ))}
                                        </Field>
                                        <div className={errors.status ? styles.form_error : styles.form_error_hidden}>{errors.status}</div>
                                    </div>
                                </div>
                                { (stripeID && ['Not Paid', 'Partially Paid'].includes(values.status)) && 
                                    <div className={`mt-1 ${styles.form_wrapper} d-flex`}>
                                        <div className={`${styles2.labelStripeButton}`}>Stripe button: </div>
                                        <div className={`${styles2.toggleStripeButton} border-0 d-flex justify-content-center flex-column`}>
                                            <Toggle
                                                icons={false}
                                                id={"stripeButton"}
                                                value={values.stripeButton}
                                                checked={values.stripeButton}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    if (values.stripeButton) {
                                                        setFieldValue('whenPaidMarkBookingAsPaid', false);
                                                    }
                                                }}
                                                disabled={!stripeID}
                                            />
                                        </div>
                                    </div>
                                }
                                { stripeID && (values.stripeButton && ['Not Paid', 'Partially Paid'].includes(values.status)) && 
                                    <div className={`mt-1 ${styles.form_wrapper} d-flex`}>
                                        <div className={`${styles2.labelWhenPaidMarkBookingAsPaid}`}>{`When paid, mark booking as paid: `}
                                            <InfoOutlinedIcon
                                                fontSize="small"
                                                color="inherit"
                                                data-tip
                                                data-for="labelWhenPaidMarkBookingAsPaid"
                                                className={styles.toolTipIcon}
                                            />
                                            <ReactTooltip place="bottom" type="dark" id="labelWhenPaidMarkBookingAsPaid" effect="solid" className={styles.toolTip}>
                                                <span>
                                                    The invoice payment status is not linked to the payment status of the reservation.
                                                <br />
                                                <br />
                                                    If you have invoiced your guest for the full amount due for the reservation, you can select this option to mark the
                                                    reservation as paid once the guest pays through the Stripe secure payment gateway.
                                                <br />
                                                <br />
                                                    If the payment of this invoice does not represent the full amount due for the reservation, do not select this option.
                                                </span>
                                            </ReactTooltip>
                                        </div>
                                        <div className={`${styles2.toggleWhenPaidMarkBookingAsPaid} border-0 d-flex justify-content-center flex-column`}>
                                            <Toggle
                                                icons={false}
                                                id={"whenPaidMarkBookingAsPaid"}
                                                value={values.whenPaidMarkBookingAsPaid}
                                                checked={values.whenPaidMarkBookingAsPaid}
                                                onChange={handleChange}
                                                disabled={!values.stripeButton}
                                            />
                                        </div>
                                    </div>
                                }
                                { values.status === "Paid" && 
                                    <div className={`mt-1 ${styles.form_wrapper} d-flex`}>
                                        <div className={`${styles2.labelMarkBookingAsPaid}`}>{'Mark booking as paid: '}
                                            <InfoOutlinedIcon
                                                fontSize="small"
                                                color="inherit"
                                                data-tip
                                                data-for="labelMarkBookingAsPaid"
                                                className={styles.toolTipIcon}
                                            />
                                            <ReactTooltip place="bottom" type="dark" id="labelMarkBookingAsPaid" effect="solid" className={styles.toolTip}>
                                                <span>
                                                    The invoice payment status is not linked to the payment status of the reservation.
                                                <br />
                                                <br />
                                                    If you have invoiced your guest for the full amount due for the reservation, you can mark the reservation as paid.
                                                <br />
                                                <br />
                                                    If the payment of this invoice represents a partial payment of the reservation, you must manually mark the reservation
                                                    as 'partially paid' on the calendar.
                                                </span>
                                            </ReactTooltip>
                                        </div>
                                        <div className={`${styles2.toggleMarkBookingAsPaid} border-0 d-flex justify-content-center flex-column`}>
                                            <Toggle
                                                icons={false}
                                                id={"markBookingAsPaid"}
                                                value={values.markBookingAsPaid}
                                                checked={values.markBookingAsPaid}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                }
                                { stripeID && ((values.stripeButton || paypalStatus) && ['Not Paid', 'Partially Paid'].includes(values.status)) && 
                                    <div className={`${styles.form_wrapper} justify-content-lg-center d-flex flex-row`}>
                                        <div className={`${styles2.form_title} m-0`}>
                                            <button className={`${styles.settings_save} m-0`} onClick={handleStripeConnect}>
                                                <i className="far fa-credit-card"/>
                                                <span className={`pl-1`}>
                                                    Pay with card
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <br/>
                        <div className={styles2.tableWrapper}>
                            <table className={`table ${styles2.tableClass}`}>
                                <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>{``}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tableData && tableData.length!==0 && tableData.map((row,index)=>(
                                    <tr key={index}>
                                        <td className={styles2.firstCol}>{index+1}</td>
                                        <td>
                                            <Field
                                                type={"text"}
                                                value={values[`invoice_table-1_description_${index+1}`]}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setInputs(e.target.value, index, 1, "tableData");
                                                }}
                                                className={`${errors[`invoice_table-1_description_${index+1}`] ? `${styles2.table_input_err} ${styles2.tableInput}` : styles2.tableInput}`}
                                                name={`invoice_table-1_description_${index+1}`}
                                            />
                                            <div className={`${styles2.errorMessage}`} >{errors[`invoice_table-1_description_${index+1}`]}</div>
                                        </td>
                                        <td>
                                            <Field
                                                type={"text"}
                                                value={values[`invoice_table-1_amount_${index+1}`]}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    setInputs(e.target.value, index, 0, "tableData");
                                                }}
                                                className={`${errors[`invoice_table-1_amount_${index+1}`] ? styles2.form_entry_err : styles2.tableInput} ${styles2.widthInput}`}
                                                name={`invoice_table-1_amount_${index+1}`}
                                            />
                                            <div className={`${styles2.errorMessage}`} >{errors[`invoice_table-1_amount_${index+1}`]}</div>
                                        </td>
                                        <td>
                                            {(index!==0) && (
                                                <span className={styles2.icon} >
                                                  <i className="fa fa-trash" onClick={()=>handleDelData(row.id, "tableData")}/>
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <img
                                src={iconPlus}
                                alt={"addIcon"}
                                className={styles2.add_btn}
                                onClick={()=>{setData("tableData")}}
                            />
                        </div>
                        <div className="pt-2">
                            <div>
                                <div className={`${styles.includeRow} ${styles.form_wrapper} d-flex `} key={'invoiceNr'}>
                                    <Checkbox
                                        name={'includeFee'}
                                        id={'includeFee'}
                                        checked={viewFee}
                                        onChange={(e)=>setViewFee(!viewFee)}
                                        color="primary"
                                        style={myTheme.palette.primary}
                                        className={styles.feeCheckBox}
                                    />
                                    <div className={styles.feeBox}>{'Include fees '}
                                        <InfoOutlinedIcon
                                            fontSize="small"
                                            color="inherit"
                                            data-tip
                                            data-for="includeFees"
                                            className={styles.toolTipIcon}
                                        />
                                        <div>
                                            <ReactTooltip place="bottom" type="dark" id="includeFees" effect="solid" className={styles.toolTip}>
                                                <span>
                                                    The total amount of the invoice is the sum of all values. Not including fees will result in a different total invoice amount.
                                                    This is also the case if a single fee is deleted, or if its amount is modified.
                                                <br />
                                                <br />
                                                    The price of the booking will not be altered if you make changes to the invoice.
                                                </span>
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {viewFee ? (
                                <>
                                    <div className={styles2.tableWrapper}>
                                        <table className={`table ${styles2.tableClass}`}>
                                        <thead>
                                        <tr>
                                            <th>Item</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <th>{``}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tableData2 && tableData2.length!==0 && tableData2.map((row,index)=>(
                                            <tr key={index}>
                                                <td className={styles2.firstCol}>{index+1}</td>
                                                <td>
                                                    <Field
                                                        type={"text"}
                                                        value={values[`invoice_table-2_description_${index+1}`]}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setInputs(e.target.value, index, 1, "tableData2");
                                                        }}
                                                        className={`${errors[`invoice_table-2_description_${index+1}`] ? `${styles2.table_input_err} ${styles2.tableInput}` : styles2.tableInput}`}
                                                        name={`invoice_table-2_description_${index+1}`}
                                                    />
                                                    <div className={`${styles2.errorMessage}`} >{errors[`invoice_table-2_description_${index+1}`]}</div>
                                                </td>
                                                <td>
                                                    <Field
                                                        type={"text"}
                                                        value={values[`invoice_table-2_amount_${index+1}`]}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setInputs(e.target.value, index, 0, "tableData2");
                                                        }}
                                                        className={`${errors[`invoice_table-2_amount_${index+1}`] ? styles2.form_entry_err : styles2.tableInput} ${styles2.widthInput}`}
                                                        name={`invoice_table-2_amount_${index+1}`}
                                                    />
                                                    <div className={`${styles2.errorMessage}`} >{errors[`invoice_table-2_amount_${index+1}`]}</div>
                                                </td>
                                                <td>
                                                    <span className={styles2.icon} >
                                                        <i className="fa fa-trash" onClick={()=>handleDelData(row.id, "tableData2")}/>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    </div>
                                    <div>
                                        <img
                                            src={iconPlus}
                                            alt={"addIcon"}
                                            className={styles2.add_btn}
                                            onClick={()=>{setData("tableData2")}}
                                        />
                                    </div>
                                </>
                            ) : null}
                        </div>

                        <div className="pt-2">
                            <div>
                                <div className={`${styles.includeRow} ${styles.form_wrapper} d-flex`} key={'invoiceNr'}>
                                    <Checkbox
                                        name={'includeTaxes'}
                                        id={'includeTaxes'}
                                        checked={viewTaxes}
                                        onChange={(e)=>setViewTaxes(!viewTaxes)}
                                        color="primary"
                                        style={myTheme.palette.primary}
                                        className={styles.feeCheckBox}
                                    />
                                    <div className={styles.feeBox}>{'Include taxes '}
                                        <InfoOutlinedIcon
                                            fontSize="small"
                                            color="inherit"
                                            data-tip
                                            data-for="includeTaxes"
                                            className={styles.toolTipIcon}
                                        />
                                        <div>
                                            <ReactTooltip place="bottom" type="dark" id="includeTaxes" effect="solid" className={styles.toolTip}>
                                                <span>
                                                    The total amount of the invoice is the sum of all values. Not including taxes will result in a different total invoice amount.
                                                    This is also the case if a single tax is deleted, or if its amount is modified.
                                                <br />
                                                <br />
                                                    The price of the booking will not be altered if you make changes to the invoice.
                                                </span>
                                            </ReactTooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {viewTaxes ? (
                                <>
                                    <div className={styles2.tableWrapper}>
                                        <table className={`table ${styles2.tableClass}`}>
                                            <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th>Description</th>
                                                <th>Amount</th>
                                                <th>{``}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {tableData3 && tableData3.length!==0 && tableData3.map((row,index)=>(
                                                <tr key={index}>
                                                    <td className={styles2.firstCol}>{index+1}</td>
                                                    <td>
                                                        <Field
                                                            type={"text"}
                                                            value={values[`invoice_table-3_description_${index+1}`]}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                setInputs(e.target.value, index, 1, "tableData3");
                                                            }}
                                                            className={`${errors[`invoice_table-3_description_${index+1}`] ? `${styles2.table_input_err} ${styles2.tableInput}` : styles2.tableInput}`}
                                                            name={`invoice_table-3_description_${index+1}`}
                                                        />
                                                        <div className={`${styles2.errorMessage}`} >{errors[`invoice_table-3_description_${index+1}`]}</div>
                                                    </td>
                                                    <td>
                                                        <Field
                                                            type={"text"}
                                                            value={values[`invoice_table-3_amount_${index+1}`]}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                setInputs(e.target.value, index, 0, "tableData3");
                                                            }}
                                                            className={`${errors[`invoice_table-3_amount_${index+1}`] ? styles2.form_entry_err : styles2.tableInput} ${styles2.widthInput}`}
                                                            name={`invoice_table-3_amount_${index+1}`}
                                                        />
                                                        <div className={`${styles2.errorMessage}`} >{errors[`invoice_table-3_amount_${index+1}`]}</div>
                                                    </td>
                                                    <td>
                                                        <span className={styles2.icon} >
                                                            <i className="fa fa-trash" onClick={()=>handleDelData(row.id, "tableData3")}/>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <img
                                            src={iconPlus}
                                            alt={"addIcon"}
                                            className={styles2.add_btn}
                                            onClick={()=>{setData("tableData3")}}
                                        />
                                    </div>
                                </>
                            ) : null}
                        </div>

                        <div className={`${styles.subHeader} my-2`}>{`Total: ${Number(totalCost).toFixed(2)} ${rentalDetail?getSymbolFromCurrency(rentalDetail.currency):""}`}</div>
                        <hr className="my-1" />
                        <Row className={'p-3'}>
                            <div className={`col-lg-6 col-md-12 px-xs-1 p-0`}>
                                <div className={styles.subHeader}>Recipient</div>
                                <div className={`${styles.form_wrapper} d-flex`} key={'r_name'}>
                                    <div className={styles2.form_title}>{'Name*: '}</div>
                                    <div>
                                        <div>
                                            <Field
                                                type={'text'}
                                                id={"r_name"}
                                                name={'r_name'}
                                                onChange={handleChange}
                                                className={`${errors.r_name ? styles2.form_entry_err : styles2.form_entry} w-100`}
                                            >
                                            </Field>
                                            <div className={errors.r_name ? styles.form_error : styles.form_error_hidden}>{errors.r_name}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.form_wrapper} d-flex`} key={'r_comp'}>
                                    <div className={styles2.form_title}>{'Company: '}</div>
                                    <div>
                                        <div>
                                            <Field
                                                type={'text'}
                                                name={'r_comp'}
                                                id={'r_comp'}
                                                onChange={handleChange}
                                                className={`w-100 ${errors.r_comp ? styles2.form_entry_err : styles2.form_entry}`}
                                            >
                                            </Field>
                                            <div className={errors.r_comp ? styles.form_error : styles.form_error_hidden}>{errors.r_comp}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.form_wrapper} d-flex`} key={'r_address1'}>
                                    <div className={styles2.form_title}>{'Address 1: '}</div>
                                    <div>
                                        <Field
                                            type={'text'}
                                            name={'r_address1'}
                                            id={'r_address1'}
                                            onChange={handleChange}
                                            className={`w-100 ${
                                                errors
                                                    .r_address1 ? styles2.form_entry_err : styles2.form_entry
                                            }`}
                                        >
                                        </Field>
                                        <div className={errors.r_address1 ? styles.form_error : styles.form_error_hidden}>{errors.r_address1}</div>
                                    </div>
                                </div>
                                <div className={`${styles.form_wrapper} d-flex`} key={'r_address2'}>
                                    <div className={styles2.form_title}>{'Address 2: '}</div>
                                    <div>
                                        <Field
                                            type={'text'}
                                            name={'r_address2'}
                                            id={'r_address2'}
                                            onChange={handleChange}
                                            className={`w-100 ${
                                                errors
                                                    .r_address2 ? styles2.form_entry_err : styles2.form_entry
                                            }`}
                                        >
                                        </Field>
                                        <div className={errors.r_address2 ? styles.form_error : styles.form_error_hidden}>{errors.r_address2}</div>
                                    </div>
                                </div>
                                <div className={`${styles.form_wrapper} d-flex`} key={'r_address3'}>
                                    <div className={styles2.form_title}>{'Address 3: '}</div>
                                    <div>
                                        <Field
                                            type={'text'}
                                            name={'r_address3'}
                                            id={'r_address3'}
                                            onChange={handleChange}
                                            className={`w-100 ${
                                                errors
                                                    .r_address3 ? styles2.form_entry_err : styles2.form_entry
                                            }`}
                                        >
                                        </Field>
                                        <div className={errors.r_address3 ? styles.form_error : styles.form_error_hidden}>{errors.r_address3}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`col-lg-6 col-md-12 px-xs-1 p-0`}>
                                <div className={styles.subHeader}>Sender</div>
                                <div className={`${styles.form_wrapper} d-flex`} key={'s_name'}>
                                    <div className={styles2.form_title}>{'Name*: '}</div>
                                    <div>
                                        <Field
                                            type={'text'}
                                            name={'s_name'}
                                            id={'s_name'}
                                            onChange={handleChange}
                                            className={`w-100 ${errors.s_name ? styles2.form_entry_err : styles2.form_entry}`}
                                        >
                                        </Field>
                                        <div className={errors.s_name ? styles.form_error : styles.form_error_hidden}>{errors.s_name}</div>
                                    </div>
                                </div>
                                <div className={`${styles.form_wrapper} d-flex`} key={'s_address1'}>
                                    <div className={styles2.form_title}>{'Address 1: '}</div>
                                    <div>
                                        <Field
                                            type={'text'}
                                            name={'s_address1'}
                                            id={'s_address1'}
                                            onChange={handleChange}
                                            className={`w-100 ${
                                                errors
                                                    .s_address1 ? styles2.form_entry_err : styles2.form_entry
                                            }`}
                                        >
                                        </Field>
                                        <div className={errors.s_address1 ? styles.form_error : styles.form_error_hidden}>{errors.s_address1}</div>
                                    </div>
                                </div>
                                <div className={`${styles.form_wrapper} d-flex`} key={'s_address2'}>
                                    <div className={styles2.form_title}>{'Address 2: '}</div>
                                    <div>
                                        <Field
                                            type={'text'}
                                            name={'s_address2'}
                                            id={'s_address2'}
                                            onChange={handleChange}
                                            className={`w-100 ${
                                                errors
                                                    .s_address2 ? styles2.form_entry_err : styles2.form_entry
                                            }`}
                                        >
                                        </Field>
                                        <div className={errors.s_address2 ? styles.form_error : styles.form_error_hidden}>{errors.s_address2}</div>
                                    </div>
                                </div>
                                <div className={`${styles.form_wrapper} d-flex`} key={'s_address3'}>
                                    <div className={styles2.form_title}>{'Address 3: '}</div>
                                    <div>
                                        <Field
                                            type={'text'}
                                            name={'s_address3'}
                                            id={'s_address3'}
                                            onChange={handleChange}
                                            className={`w-100 ${
                                                errors
                                                    .s_address3 ? styles2.form_entry_err : styles2.form_entry
                                            }`}
                                        >
                                        </Field>
                                        <div className={errors.s_address3 ? styles.form_error : styles.form_error_hidden}>{errors.s_address3}</div>
                                    </div>
                                </div>
                                <div className={`${styles.form_wrapper} d-flex`} key={'s_country'}>
                                    <div className={styles2.form_title}>{'Country: '}</div>
                                    <div>
                                        <Field
                                            type={'text'}
                                            name={'s_country'}
                                            id={'s_country'}
                                            onChange={handleChange}
                                            className={`w-100 ${
                                                errors
                                                    .s_country ? styles2.form_entry_err : styles2.form_entry
                                            }`}
                                        >
                                        </Field>
                                        <div className={errors.s_country ? styles.form_error : styles.form_error_hidden}>{errors.s_country}</div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <hr className="my-1" />
                        <div >
                            <div className={styles.subHeader}>Invoice Text</div>
                            <div className="col-sm-12 col-lg-8 col-xs-10 pt-1 pb-2 px-2">
                                <Field
                                    as={"textarea"}
                                    className={`w-100 ${styles2.textArea}`}
                                    onChange={handleChange}
                                    rows={4}
                                    name="invoiceText"
                                    id="invoiceText"
                                />
                            </div>
                            <div className={styles.subHeader}>Footer Text</div>
                            <div className="col-sm-12 col-lg-8 pt-1 pb-2 col-xs-10 px-2">
                                <Field
                                    as={"textarea"}
                                    className={`w-100 ${styles2.textArea}`}
                                    onChange={handleChange}
                                    rows={4}
                                    name="invoiceFooter"
                                    id="invoiceFooter"
                                />
                            </div>
                        </div>
                        <div className={`d-flex justify-content-center ${styles.button_group}`}>
                            <div>
                                <button className={styles.settings_save} style={{backgroundColor:"#dc3545"}} onClick={handleReset}>
                                    Discard changes
                                </button>
                                <button type="submit" className={styles.settings_save} >
                                    Save changes
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
        </MuiThemeProvider>
    );
}

export default CreateMode;