// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_joinBtn__2v82z {\n    background-color: #439a86;\n    color: white;\n    padding: 0.3em;\n    border: 0px;\n    border-radius: 5px;\n    min-width: 10rem;\n    margin-right: 1em;\n}\n", ""]);
// Exports
exports.locals = {
	"joinBtn": "container_joinBtn__2v82z"
};
module.exports = exports;
