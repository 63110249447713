import React,{useState,useEffect} from 'react';
import * as yup from 'yup';
import moment from 'moment';
import {useSelector,useDispatch} from "react-redux";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReactTooltip from "react-tooltip";
import { Row,Col } from 'react-bootstrap';
import {
  Popover,
  Box,
} from '@material-ui/core';
import Table from '../../../../../../../components/Table';
import Loading from '../../../../../../../components/loading';
import CreateMode from './CreateMode/CreateMode';
import MainFile from '../../../../../../../components/InvoiceSend/MainFile';
import { zeroPad, getDateObjFromString } from "../../../../../../../common/functions/utils";
import styles from '../editBooking.module.css';
import styles2 from './invoiceDetail.module.css';

import {addInvoice, getInvoiceByBooking, deleteInvoice, editInvoiceByBooking} from '../../../../../../../general_redux/invoice/actions';

const Invoice = (props) => {
  
  const dispatch=useDispatch();
  
    const bookingData = useSelector(({calendar}) => calendar && calendar.allBooking.filter((row) => row.id === props.bookingId)[0]);
    const rentalDetail = useSelector(({rentals})=> rentals && rentals.rentals.filter((row) => row.id === bookingData.rentalId)[0]);
    const guestDetails = useSelector(({guests})=>guests && guests?.guestByBooking?.[0]);
    const settings= useSelector(({generalSetting})=>generalSetting && generalSetting.setting);
    const invoiceByBooking=useSelector(({invoice})=>invoice && invoice.invoiceByBooking);
    const invoiceList=useSelector(({invoice})=>invoice && invoice.invoiceList);
    const dateFormat = useSelector(({generalSetting})=>generalSetting && generalSetting.setting.dateDisplay);
    const actionForAddInvoice=useSelector(({invoice})=>invoice && invoice.actionForAddInvoice);
    const actionForEditInvoice = useSelector(({invoice}) => invoice && invoice.actionForEditInvoice);
    const actionForDeleteInvoice = useSelector(({invoice}) => invoice && invoice.actionForDeleteInvoice);
    const stripeID = useSelector(({generalSetting})=>generalSetting && generalSetting.setting && generalSetting.setting.stripeId);

    const initialValueForInvoiceItem = {
      id: 1,
      title: (bookingData) ? `${bookingData.rentalName}, ${moment(bookingData.arrive).format('Mo MMM YYYY')} - ${moment(bookingData.depart).format('Mo MMM YYYY')}, ${bookingData.noOfAdults + bookingData.noOfChildren} Guest(s)`: `Booking`,
      fee: Number(bookingData?.price ? Number(bookingData.price) : 0),
    };

    const [createMode,setCreateMode]=useState(false);
    const [viewSendInvoice,setViewSendInvoice]=useState(false);
    const [tableView,setTableView]=useState(true);
    const [selectedInvoice,setSelectedInvoice]=useState(null);
    const [invoiceBySend, setInvoiceBySend]=useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentDelete,setCurrentDelete]=useState(null);
    const [invoiceItems, setInvoiceItems] = useState(selectedInvoice?.invoiceItem || [initialValueForInvoiceItem]);
    const [feeItems, setFeeItems] = useState(selectedInvoice?.feeItems || []);
    const [taxItems, setTaxItems] = useState(selectedInvoice?.taxItems || []);

    useEffect(()=>{
        dispatch(getInvoiceByBooking(props.bookingId));
    },[invoiceList]);

    const handleDiscardChanges = () => {
      setSelectedInvoice(null);
      setCreateMode(false);
      setTableView(true);
    }
    
    const updateValidationSchema = (invoiceItems, feeItems, taxItems) => {
      setInvoiceItems(invoiceItems);
      setFeeItems(feeItems);
      setTaxItems(taxItems);
    };

    useEffect(() => {
        if(actionForAddInvoice.success || actionForEditInvoice.success){
          handleDiscardChanges();
        }
    }, [actionForAddInvoice.success, actionForEditInvoice.success]);

    const cols = [
        {lable:'Invoice Nr', key:'invoiceNumber'},
        {lable: 'Created', key: 'invoiceDate',isDate:true},
        {lable: 'Invoice Due', key: 'dueDate',isDate:true},
        {lable: 'Total', key: 'total'},
        {lable: 'Invoice Status', key: 'paymentStatus'},
        {lable: 'Mark as Sent', key: 'markSent'},
        {lable: 'Actions', key: ''},
    ];

  const handleSubmit = (data, totalCost, tableData, tableData2, tableData3)=>{
          const payload = {
            id: selectedInvoice?.id || undefined,
            guestId: guestDetails.id,
            bookingId: props.bookingId,
            dueDate: moment(data.dueDate).utc(false).format('YYYY-MM-DD'),
            dueDateUtc: moment(data.dueDate).utc(true).format('YYYY-MM-DD HH:mm:ss'),
            invoiceDate: moment(data.issueDate).utc(false).format('YYYY-MM-DD'),
            invoiceDateUtc: moment(data.issueDate).utc(true).format('YYYY-MM-DD HH:mm:ss'),
            invoiceNumber: Number(data.invoiceNr || selectedInvoice.invoiceNumber),
            invoiceItem: tableData.map((item) => ({title: item.title, fee: Number(item.fee)})),
            feeItems: tableData2.map((item) => ({title: item.title, fee: Number(item.fee)})),
            taxItems: tableData3.map((item) => ({title: item.title, tax: Number(item.tax)})),
            currency: selectedInvoice?.currency || settings.currency.value,
            total: Number(totalCost),
            markSent: selectedInvoice?.markSent || false,
            downloadLink: selectedInvoice?.downloadLink || "",
            recipientDetail: {
                name: data.r_name,
                company: data.r_comp,
                address1: data.r_address1,
                address2: data.r_address2,
                address3: data.r_address3,
                email: guestDetails.emailId,
            },
            senderDetail: {
                name: data.s_name,
                address1: data.s_address1,
                address2: data.s_address2,
                address3: data.s_address3,
                country: data.s_country
            },
            invoiceText: data.invoiceText,
            invoiceFooter: data.invoiceFooter,
            paymentStatus: data.status,
            bookingNumber: Number(data.bookingNumber) || 0,
            stripeButton: data.stripeButton,
            markBookingAsPaid: data.markBookingAsPaid,
            whenPaidMarkBookingAsPaid: data.whenPaidMarkBookingAsPaid,
        }
        // console.log(payload);
        if(!selectedInvoice){
            dispatch(addInvoice(payload));
        }else{
            dispatch(editInvoiceByBooking(payload))
        }
    }

    const handleEdit=(data)=>{
        setSelectedInvoice(data);
        setCreateMode(true);
        setTableView(false);
    }

    const handleSendInvoiceEdit = (data) => {
        const findInvoice = invoiceByBooking.filter(item => item.id === data.id);
        if(findInvoice.length) {
            setViewSendInvoice(false);
            handleEdit(findInvoice[0]);
        }
    }

    const handleSendInvoice = (data) => {
      setInvoiceBySend(data);
      setViewSendInvoice(true);
      setTableView(false);
    }

    const handleDelete=()=>{
        if(currentDelete && currentDelete.id){
            dispatch(deleteInvoice(currentDelete.id))
        }
        setAnchorEl(null);
        setCurrentDelete(null);
    }

    const handleDownload=(invoiceD)=>{
        window.open(invoiceD.downloadLink,"_parent")
    }

    const initialValuesEdit = {
        issueDate: selectedInvoice?.invoiceDate ? getDateObjFromString(selectedInvoice?.invoiceDate) : new Date(),
        dueDate: selectedInvoice?.dueDate ? getDateObjFromString(selectedInvoice?.dueDate) : new Date(),
        status: selectedInvoice?.paymentStatus || "Not Paid",
        r_name: selectedInvoice?.recipientDetail.name || "",
        r_comp: selectedInvoice?.recipientDetail.company || "",
        r_address1: selectedInvoice?.recipientDetail.address1 || "",
        r_address2: selectedInvoice?.recipientDetail.address2 || "",
        r_address3: selectedInvoice?.recipientDetail.address3 || "",
        s_name: selectedInvoice?.senderDetail.name || "",
        s_address1: selectedInvoice?.senderDetail.address1 || `${settings?.address1}`,
        s_address2: selectedInvoice?.senderDetail.address2 || `${settings?.address2}`,
        s_address3: selectedInvoice?.senderDetail.address3 || `${settings?.address3}`,
        s_country: selectedInvoice?.senderDetail.country || `${settings?.country}`,
        invoiceText: selectedInvoice?.invoiceText || "",
        invoiceFooter: selectedInvoice?.invoiceFooter || "",
        total: selectedInvoice?.total ? Number(selectedInvoice?.total) : 0,
        invoiceNr: selectedInvoice?.invoiceNumber ? Number(selectedInvoice?.invoiceNumber) : 0,
        bookingNumber: selectedInvoice?.bookingNumber ? Number(selectedInvoice?.bookingNumber) : 0,
        stripeButton: selectedInvoice?.stripeButton || false,
        markBookingAsPaid: selectedInvoice?.markBookingAsPaid || false,
        whenPaidMarkBookingAsPaid: selectedInvoice?.whenPaidMarkBookingAsPaid || false,
        tableData: selectedInvoice?.invoiceItem || [initialValueForInvoiceItem],
        tableData2: selectedInvoice?.feeItems || [],
        tableData3: selectedInvoice?.taxItems || [],
        ...(selectedInvoice?.invoiceItem || [initialValueForInvoiceItem]).reduce((acc, curr, index) => {
            acc[`invoice_table-1_description_${index + 1}`] = curr.title;
            acc[`invoice_table-1_amount_${index + 1}`] = Number(curr.fee);
            return acc;
        }, {}),
        ...(selectedInvoice?.feeItems || []).reduce((acc, curr, index) => {
            acc[`invoice_table-2_description_${index + 1}`] = curr.title;
            acc[`invoice_table-2_amount_${index + 1}`] = Number(curr.fee);
            return acc;
        }, {}),
        ...(selectedInvoice?.taxItems || []).reduce((acc, curr, index) => {
            acc[`invoice_table-3_description_${index + 1}`] = curr.title;
            acc[`invoice_table-3_amount_${index + 1}`] = Number(curr.tax);
            return acc;
        }, {}),
    };

    const validationSchema =  yup.object().shape({
        issueDate: yup.date().required('Issue Date is a required field').typeError('Must be a value of type date'),
        dueDate: yup.date().required('Due date is a required field').typeError('Must be a value of type date'),
        status: yup.string().required('Status of payment is a required field'),
        r_name: yup.string().required("Recipient's name is a required field"),
        r_comp: yup.string(),
        r_address1: yup.string(),
        r_address2: yup.string(),
        r_address3: yup.string(),
        s_name: yup.string().required("Sender name is a required field"),
        s_address1: yup.string(),
        s_address2: yup.string(),
        s_address3: yup.string(),
        s_country: yup.string(),
        invoiceText: yup.string(),
        invoiceFooter: yup.string(),
        total: yup.number().typeError('Must be a number greater than 0'),
        invoiceNr: yup.number().required('Invoice Nr is a required field').typeError('Must be a number greater than 0'),
        bookingNumber: yup.number().typeError('Must be a number greater than 0'),
        stripeButton: yup.bool().typeError('Must be a boolean value'),
        markBookingAsPaid: yup.bool().typeError('Must be a boolean value'),
        whenPaidMarkBookingAsPaid: yup.bool().typeError('Must be a boolean value'),
        ...invoiceItems.reduce((acc, curr, index) => {
            acc[`invoice_table-1_description_${index + 1}`] = yup.string().required('Description is a required field').max(150, 'Must be 150 characters or less');
            acc[`invoice_table-1_amount_${index + 1}`] = yup.number().required('Amount is a required field').typeError('Must be a value of type number');
            return acc;
        }, {}),
        ...feeItems.reduce((acc, curr, index) => {
            acc[`invoice_table-2_description_${index + 1}`] = yup.string().required('Description is a required field').max(150, 'Must be 150 characters or less');
            acc[`invoice_table-2_amount_${index + 1}`] = yup.number().required('Amount is a required field').positive('Must be a number greater than 0').typeError('Must be a value of type number');
            return acc;
        }, {}),
        ...taxItems.reduce((acc, curr, index) => {
            acc[`invoice_table-3_description_${index + 1}`] = yup.string().required('Description is a required field').max(150, 'Must be 150 characters or less');
            acc[`invoice_table-3_amount_${index + 1}`] = yup.number().required('Amount is a required field').positive('Must be a number greater than 0').typeError('Must be a value of type number');
            return acc;
        }, {}),
    });

    const initialValues={
        issueDate:new Date(),
        dueDate: new Date(`${moment(new Date()).add(settings.invoiceDueDate || 0, 'days')}`),
        status:'Not Paid',
        r_name:guestDetails?.name || "",
        r_comp:guestDetails?.company || "",
        r_address1:guestDetails?.street || "",
        r_address2: `${guestDetails?.postal_code || ""} ${guestDetails?.state || ""}`,
        r_address3:guestDetails?.country?`${guestDetails?.country}`:"",
        s_name:settings.name?`${settings.name}`:"",
        s_address1:settings.address1?`${settings.address1}`:"",
        s_address2:settings.address2?`${settings.address2}`:"",
        s_address3: settings.address3 ? `${settings.address3}` : "",
        s_country:settings.country?`${settings.country}`:"",
        invoiceText:settings.invoiceText?`${settings.invoiceText}`:"",
        invoiceFooter:settings.invoiceFooter?`${settings.invoiceFooter}`:"",
        total: bookingData?.price ? Number(bookingData.price) : 0,
        invoiceNr: Number(`${1000+(invoiceList?.length ? invoiceList.length : 0)}`),
        bookingNumber: bookingData?.id ? Number(`${new Date().getUTCFullYear()}${zeroPad(bookingData.id, 4)}`) : 0,
        stripeButton: false,
        markBookingAsPaid: false,
        whenPaidMarkBookingAsPaid: false,
        tableData: [initialValueForInvoiceItem],
        tableData2: [],
        tableData3: [],
        ...[initialValueForInvoiceItem].reduce((acc, curr, index) => {
            acc[`invoice_table-1_description_${index + 1}`] = curr.title;
            acc[`invoice_table-1_amount_${index + 1}`] = Number(curr.fee);
            return acc;
        }, {}),
    }

    const handleConfirmDialog = (e,row) => {
      setCurrentDelete(row);
      setAnchorEl( anchorEl ? null : e.currentTarget);
    }
  const invoiceDeleteDialog = () => {
    return (
      <Popover
        id={"popover-1"}
        open={Boolean(anchorEl)}
        onClose={handleConfirmDialog}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
            display: "flex",
          },
        }}
      >
        <div className={styles2.contentWrap}>
          <div className={styles2.deleteTitle}>
            Are you sure you want to delete this invoice?
          </div>
          <div className="text-center m-1">
            <button
              onClick={() => handleDelete()}
              className={styles2.delConfirmBtn}
            >
              Delete
            </button>
            <button
              onClick={handleConfirmDialog}
              className={styles2.cancelBtn}
            >
              Cancel
            </button>
          </div>
        </div>
        <Box
            className={styles2.rightArrow}
        />
      </Popover>
    );
  };

  return (
    <div className="p-3">
        <Loading loadingStatus={actionForAddInvoice.loading || actionForEditInvoice.loading || actionForDeleteInvoice.loading} />
        <Row >
          <div className="pb-2 pl-3 d-flex w-100 justify-content-between">
            <div className="d-flex">
              <div className={styles.subHeader}> Invoice </div>
              <InfoOutlinedIcon
                fontSize="small"
                color="inherit"
                data-tip
                data-for="invoiceEdit"
                className={styles.toolTipIcon}
              />
            </div>
            <div>
              <ReactTooltip place="bottom" type="dark" id="invoiceEdit" effect="solid" className={styles.toolTip}>
                  {
                      createMode || viewSendInvoice ? (
                          <span>
                            Standard invoice details such as sender, invoice text, and footer information can be edited by clicking on ‘Settings’ in the
                            top menu under ‘Invoice Settings’.
                            <br/>
                            <br/>
                            There, you can also change your preferences for date display and currency. If you want to set a different currency for this
                            rental than your default currency in the settings, you can change the currency under ‘Rentals’ in the top menu.
                          </span>
                      ) : (
                          <span>
                            You will see here invoices made for this reservation. To see all invoices linked to a particular guest, click ‘Guests’ on the left menu and search for the desired guest. To see all invoices, click ‘Invoices’ on the left menu.
                            <br/>
                            <br/>
                            Note that changing the invoice payment status will not affect the payment status of the booking. Changing the status of the total due can be done under the ‘Booking Details’ or ‘Payment’ tabs.
                        </span>
                      )
                  }

              </ReactTooltip>
            </div>
            <button
              className={styles2.sideButton}
              onClick={() => {
                if (createMode) {
                  setSelectedInvoice(null);
                  setCreateMode(false);
                  setTableView(true);
                } else if (viewSendInvoice) {
                  setSelectedInvoice(null);
                  setViewSendInvoice(false);
                  setTableView(true);
                } else {
                  setCreateMode(true);
                  setTableView(false);
                }
              }}
            >
              {createMode || viewSendInvoice ? "View invoice" : "Create invoice"}
            </button>
          </div>
        </Row>
        {tableView && (
          <>
            <div className="p-0">
              <Col xs={12} md={12} lg={12} className="p-0">
                <Table
                  rows={invoiceByBooking}
                  cols={cols}
                  onEdit={(data) => handleEdit(data)}
                  onDelete={(val) => handleDelete(val)}
                  startKey={cols[0].key}
                  dateFormat={dateFormat.toUpperCase()}
                  onDownload={handleDownload}
                  onSendGuest={(data) => handleSendInvoice(data)}
                  confirmDeleteDialog={invoiceDeleteDialog}
                  handleConfirmDialog={handleConfirmDialog}
                  tableProps={
                    {
                      style:{
                       width: "750px", 
                       tableLayout: "fixed"
                      }
                    }
                  }
                  columnStyle={styles2.customWrapField}
                  actionColumnStyle = {styles2.actionField}
                />
              </Col>
            </div>
          </>
        )}
        {viewSendInvoice && (
          <>
            <MainFile
                invoiceData={invoiceBySend}
                bookingId={props.bookingId}
                onEdit={(data) => handleSendInvoiceEdit(data)}
            />
          </>
        )}
        {createMode && (
          <>
             <CreateMode
                initialValues={(selectedInvoice && selectedInvoice.length !== 0) ? initialValuesEdit : initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                onReset={handleDiscardChanges}
                bookingId={props.bookingId}
                updateValidationSchema={updateValidationSchema}
              />
          </>
        )}

    </div>
  );
};

export default Invoice;
