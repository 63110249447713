// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EmptyNightPopover_popoverBody__1M79S {\n    background-color: #000;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    border-radius: 6px;\n    width: 120px;\n}\n\n.EmptyNightPopover_tester__3MPFA * {\n    overflow: visible !important;\n}\n\n.EmptyNightPopover_popoverContainer__2otKy {\n    display: flex;\n    flex-direction: column;\n    margin: 1rem;\n    grid-gap: 1rem;\n    gap: 1rem;\n}\n\n.EmptyNightPopover_allBtn__1AyFX {\n    background-color: #439a86;\n    color: white;\n    padding: 0.4em;\n    border: 0px;\n    font-size: 0.9em;\n    border-radius: 5px;\n    min-width: 6rem;\n}\n\n.EmptyNightPopover_greyBtn__1iwoS {\n    background-color: #6c757d;\n}\n\n.EmptyNightPopover_leftArrow__vbeUa {\n    position: absolute;\n    left: 0;\n    width: 0;\n    height: 0;\n    border-left: 25px solid transparent;\n    border-bottom: 25px solid #000;\n}", ""]);
// Exports
exports.locals = {
	"popoverBody": "EmptyNightPopover_popoverBody__1M79S",
	"tester": "EmptyNightPopover_tester__3MPFA",
	"popoverContainer": "EmptyNightPopover_popoverContainer__2otKy",
	"allBtn": "EmptyNightPopover_allBtn__1AyFX",
	"greyBtn": "EmptyNightPopover_greyBtn__1iwoS",
	"leftArrow": "EmptyNightPopover_leftArrow__vbeUa"
};
module.exports = exports;
