// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ConfirmMessage_modalHeader__2z7sk {\n  font-size: 1.4em !important;\n}\n\n.ConfirmMessage_modalBody__3Cg-H {\n  font-size: 1em;\n}\n\n.ConfirmMessage_modalFooter__1YP_7 {\n  margin: 0.7em 0em;\n  align-items: center;\n  text-align: center;\n  padding: 0.5em;\n}\n\n.ConfirmMessage_deleteIcon__2uYaU {\n  font-size: 13px !important;\n  margin-left: 7px;\n}\n\n.ConfirmMessage_cancelIcon__36fW6 {\n  font-size: 12px !important;;\n  margin-left: 7px;\n}\n\n.ConfirmMessage_allBtn__1wMrD {\n  color: white;\n  padding: 0.5em 1.5em;\n  border: 0px;\n  font-size: 1em;\n  margin-right: 1em;\n  border-radius: 5px;\n}\n\n.ConfirmMessage_cancelBtn__AdEX6 {\n  background-color: #177e66;\n}\n\n.ConfirmMessage_cancelBtn__AdEX6:hover {\n  background-color:#61aa99;\n}\n\n.ConfirmMessage_cancelConfirmBtn__Jyy8X {\n  padding: 0.5em 0.8em;\n}\n\n.ConfirmMessage_deleteBtn__g8qt3 {\n  background-color: #dc3545 ;\n}\n\n.ConfirmMessage_deleteBtn__g8qt3:hover {\n  background-color: #cd0027;\n}\n\n.ConfirmMessage_deleteConfirmBtn__3eQ4p {\n  padding: 0.5em 0.8em;\n}", ""]);
// Exports
exports.locals = {
	"modalHeader": "ConfirmMessage_modalHeader__2z7sk",
	"modalBody": "ConfirmMessage_modalBody__3Cg-H",
	"modalFooter": "ConfirmMessage_modalFooter__1YP_7",
	"deleteIcon": "ConfirmMessage_deleteIcon__2uYaU",
	"cancelIcon": "ConfirmMessage_cancelIcon__36fW6",
	"allBtn": "ConfirmMessage_allBtn__1wMrD",
	"cancelBtn": "ConfirmMessage_cancelBtn__AdEX6",
	"cancelConfirmBtn": "ConfirmMessage_cancelConfirmBtn__Jyy8X",
	"deleteBtn": "ConfirmMessage_deleteBtn__g8qt3",
	"deleteConfirmBtn": "ConfirmMessage_deleteConfirmBtn__3eQ4p"
};
module.exports = exports;
