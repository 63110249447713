import React, {useEffect, useState, useMemo} from 'react';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Row, Col } from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import * as yup from "yup";
import ReactTooltip from "react-tooltip";
import { saveSpecialBookingData } from "../../../../../../general_redux/calendar/actions"
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import iconPlus from "../../../../../../icons/icons8-plus.svg";
import styles from "./addspecialbooking.module.css";
import CustomDate from '../../../../../../components/CustomDate';
import { chargeCalculation, resetCharges, clearSpecialBookingData } from '../../../../../../general_redux/calendar/actions';
import { addMonth, getDateObjFromString, checkDateLimitForReservations } from '../../../../../../common/functions/utils';
import { toast } from 'react-toastify';


const myTheme=createTheme({
    palette: {
        primary: { // works
            main: '#439a86',
            contrastText: '#fff',
        },
    },
});

function SpecialBookingForm(props){
    const dispatch = useDispatch();

    const { rentals, guestDetail, handleGuestError, handleSave, onHide, addSpecialBookingFromEmptyNight } = props;
    const paymentOpt = ["Payment Pending","Partially Paid","Paid"];
    const currentCharges=useSelector(({calendar})=>calendar && calendar.currentCharges); 
    const colorDictionary = useSelector(({ generalSetting }) => {
        const colorsSetting = generalSetting && generalSetting.setting.colors;
        const map = [];
        for (const key in colorsSetting) {
            if (key.startsWith('color')) {
                map.push(colorsSetting[key]);
            }
        };
        return map;
    });
    const {numberOf, allowBookingFor} = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);
    const rentalList = useSelector(({rentals}) => rentals && rentals.rentals);
    const tempSpecialBookingData = useSelector(({calendar}) => calendar && calendar.tempSpecialBookingData);
    
    const [price, setPrice] = useState(0);
    const [bookingType, setBookingType] = useState('weekly');
    const [rentalId, setRentalId] = useState(addSpecialBookingFromEmptyNight?.rentalId || 0);
    const [rentalDetail, setRentalDetail] = useState(0);
    const [adult,setAdult] = useState(1);
    const [children,setChildren] = useState(0);
    const [babies,setBabies] = useState(0);
    const [totalGuests, setTotalGuests] = useState(adult+children);
    const [specialBookingData, setSpecialBookingData] = useState(null);
    const [bookingDate, setBookingDate] = useState([(addSpecialBookingFromEmptyNight?.arrive || '')]);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const currency = useMemo(()=>{
        return rentalDetail ? rentalDetail.currency : "USD";
    },[rentalId]);

    const validationSchema = yup.object().shape({
        bookingType: yup.string().required('Select type is required'),
        rental: yup.number().required('Rental selection is required').min(1, 'Rental selection is required'),
        totalGuests: yup.number().required('At least 1 guest is required').min(1, 'Must be a number greater than or equal to 1').integer('Must be an integer').typeError('Must be a value of type number')
            .test('totalGuests', `Maximum ${rentalDetail.maxOccup} guests are allowed`, (value) => {
                return (value > rentalDetail.maxOccup) ? false : true;
        }),
        adult: yup.number().required('At least 1 adult is required').min(1, 'At least 1 adult is required').integer('Must be an integer').typeError('Must be a value of type number'),
        children: yup.number().min(0, 'Must be a positive number').integer('Must be an integer').typeError('Must be a value of type number')
            .test('children', `Maximum ${rentalDetail.maxChild} children are allowed`, (value) => {
                return (value > rentalDetail.maxChild) ? false : true;
        }),
        babies: yup.number().min(0, 'Must be a positive number').integer('Must be an integer').typeError('Must be a value of type number'),
        ...bookingDate.reduce((acc, curr, index) => {
            acc[`bookingDate-${index + 1}`] = yup.date().required('Start date is required field').typeError('Must be a value of type date')
            .test(`bookingDate-${index + 1}`, 'Must be a date within the permitted limit', (value) => {
                return checkDateLimitForReservations(allowBookingFor, numberOf, bookingType, value) ? false : true;
            });
            return acc;
        }, {}),
        price: yup.number().min(0, 'Must be a positive number').typeError('Must be a value of type number'),
    });

    const initVal = () => {
        return {
            bookingType: bookingType,
            rental: (addSpecialBookingFromEmptyNight?.rentalId || ''),
            totalGuests:1,
            adult: 1,
            children: 0,
            babies: 0,
            price: 0,
            paymentStatus: 'Payment Pending',
            bookingDateList: [(addSpecialBookingFromEmptyNight?.arrive || '')],
            ...[(addSpecialBookingFromEmptyNight?.arrive || '')].reduce((acc, curr, index) => {
                acc[`bookingDate-${index + 1}`] = curr;
                return acc;
            }, {}),
            color: '#D3D3D3'
        };
    };

    useEffect(() => {
        if(currentCharges){
            setPrice(currentCharges.total)
        }

        setDisableSubmit(false);

    }, [currentCharges]);

    useEffect(() => {
        
        // check the form validation before calculating charges
        validationSchema
            .isValid(specialBookingData)
            .then((isValid) => {
                if (isValid) {
                    let departDate = moment(bookingDate[0],"YYYY-MM-DD").add(bookingType==="weekly" ? 7 : 1, bookingType==="weekly" ? 'days' : 'M').format('YYYY-MM-DD')
                    const chargePayload = {
                        arrive: moment(bookingDate[0]).format('YYYY-MM-DD'),
                        depart: departDate,
                        noOfAdults: Number(adult),
                        noOfChildren: Number(children),
                        rentalId: Number(rentalId),
                        extraCharge: 0,
                        discount: 0,
                        bookingType: bookingType,
                    };
                    dispatch(chargeCalculation(chargePayload));

                    setDisableSubmit(true);
                }
            })

    },[specialBookingData?.totalGuests, specialBookingData?.children, specialBookingData?.bookingDateList, specialBookingData?.rental, specialBookingData?.bookingType])
    
    useEffect(() => {
        dispatch(saveSpecialBookingData(specialBookingData));
    }, [specialBookingData])

    useEffect(() => {
        //cleanup function
        return () => {
            dispatch(resetCharges());
            dispatch(clearSpecialBookingData());
        };
    },[])
    
    const findRentalDetail = (rentalId) => {
        const findRental = rentals.find((rental) => Number(rental.id) === Number(rentalId));
        return findRental || { id: '', maxOccup: 1, maxChild: 0, checkinTime: '', checkoutTime: '' , currency: 'USD'};
    };

    const getMinDate = (index, bookingDates, bookingType) => {
        return index > 0 ? new Date(`${moment(bookingDates[index-1]).add(bookingType === "weekly" ? 7 : 1, bookingType === "weekly" ? 'd' : 'M').format('YYYY-MM-DD')}`) : new Date("")
    }

    const getMaxDate = (index, bookingDates, bookingType) => {
        return (bookingDates?.[index+1]) ?
            new Date(`${moment(bookingDates?.[index+1]).subtract(bookingType === "weekly" ? 7 : 1,bookingType === "weekly" ? 'd' : 'M').format('YYYY-MM-DD')}`) :
            new Date(`${moment().add(numberOf,allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)
    }

    const blockedDate = (bookingType, date) => {
        if (checkDateLimitForReservations(allowBookingFor, numberOf, bookingType, date)) {
            toast.warn('This date is blocked. Future dates are blocked according to the calendar windows settings.')
            return true;
        };
    };

    const handleSubmit = (values) => {
        let guestData={};
        const rentalData = rentalList.find((rental) => rental.id === Number(values.rental));
 
        const payload=[];
        bookingDate.map((item) => {
            let departDate=moment(item,"YYYY-MM-DD").add(values.bookingType==="weekly" ? 7 : 1, values.bookingType==="weekly" ? 'days' : 'M').format('YYYY-MM-DD')
            payload.push({
                rentalId: Number(values.rental),
                noOfAdults: Number(values.adult),
                noOfChildren: Number(values.children),
                noOfBabies: Number(values.babies),
                noOfGuests: Number(values.totalGuests),
                arrive:moment(item).utc(true).format('YYYY-MM-DD'),
                depart:moment(departDate).utc(true).format('YYYY-MM-DD'),
                arriveUTC : moment(item).utc(true).format('YYYY-MM-DD HH:mm:ss'),
                departUTC : moment(departDate).utc(true).format('YYYY-MM-DD HH:mm:ss'),
                source: "Beehaz",
                checkInTime: rentalData?.checkinTime ?? "",
                checkOutTime: rentalData?.checkoutTime ?? "",
                bookingType: values.bookingType,
                status:"Booked",
                paymentStatus: values.paymentStatus,
                title : `${guestDetail.label} | ${values.paymentStatus} | Direct Booking`,
                nights:moment(departDate,"YYYY-MM-DD").diff(moment(item,"YYYY-MM-DD"),'day'),
                color: values.color,
                discountName: "Discount",
                discountAmount:0,
                chargeName:"Extra charge",
                chargeAmount:0,
                baseRate: currentCharges.daily_rate,
                fees: currentCharges.fees,
                tax: currentCharges.taxes,
                extraGuestFee: 0,
                price: Number(price),
                miniNotes: "",
                notes: ""
            })
        })
        guestData={
            guestId:guestDetail.value
        }
        payload.map((payload) => {
            handleSave(payload,guestData,false);
        });
    };

    const validateGuest = () => {
        if(!guestDetail || guestDetail === ""){
            handleGuestError("Guest selection is required")
            return false;
        }
    };

    return (
        <MuiThemeProvider theme={myTheme}>
            <Formik
                initialValues={tempSpecialBookingData || initVal()}
                validationSchema={validationSchema}
                onSubmit={(data) => {
                    if(guestDetail && guestDetail !== ""){
                        handleSubmit(data)
                        onHide();
                    }
                }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={(values) => validateGuest(values)}
            >
                {({ initialValues, values, errors, setFieldValue, setValues, handleChange, validateForm }) => (
                    <Form>
                        <Row>
                            <Col>
                                <div className={`d-flex ${styles.selectTypeRow}`}>
                                    <div className={`${styles.form_title} ${styles.typeTitle}`}>Select type:</div>
                                    <div className={styles.customWidth}>
                                        <div className="d-flex" >
                                            <div className="d-flex mx-3">
                                                <Checkbox
                                                    name="bookingType"
                                                    color="primary"
                                                    style={myTheme.palette.primary}
                                                    size={"small"}
                                                    className={styles.term_select}
                                                    onChange={() => {
                                                        setFieldValue("bookingType", "monthly");
                                                        setBookingType("monthly");
                                                        setBookingDate(initialValues.bookingDate);
                                                        setPrice(0);
                                                    }}
                                                    value="monthly"
                                                    checked={values.bookingType === "monthly"}
                                                />
                                                <div>Monthly</div>
                                            </div>
                                            <div className="d-flex ml-1">
                                                <Checkbox
                                                    name="bookingType"
                                                    color="primary"
                                                    style={myTheme.palette.primary}
                                                    size={"small"}
                                                    className={styles.term_select}
                                                    onChange={() => {
                                                        setFieldValue("bookingType", "weekly");
                                                        setBookingType("weekly");
                                                        setBookingDate(initialValues.bookingDate);
                                                        setPrice(0);
                                                    }}
                                                    value="weekly"
                                                    checked={values.bookingType === "weekly"}
                                                />
                                                <div className={styles.form_date}>Weekly</div>
                                            </div>
                                        </div>
                                        {errors.bookingType && (
                                            <div className={`ml-3 ${styles.form_error}`}>
                                                {errors.bookingType}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="pl-0 pl-sm-3 mt-2">
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Select rental*:</div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                as={"select"}
                                                name={"rental"}
                                                className = {(errors.rental)?styles.form_entry_err:styles.form_entry}
                                                value={values.rental}
                                                onChange={(e)=> {
                                                    const selectedRental = findRentalDetail(e.target.value);
                                                    setFieldValue('rental', selectedRental.id);
                                                    setRentalId(selectedRental.id);
                                                    setRentalDetail(selectedRental);
                                                }}
                                            >
                                                <option value={""} label={"---Select Rental---"}/>
                                                {rentals.map((opt)=>(
                                                    <option key={opt.id} value={opt.id}>{opt.name}</option>
                                                ))}
                                            </Field>
                                            {errors.rental && (
                                                <div className={styles.form_error}>
                                                    {errors.rental}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Number of guests:</div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                type={"text"}
                                                name={"totalGuests"}
                                                className = {(errors.totalGuests)?styles.form_entry_err:styles.form_entry}
                                                value={totalGuests}
                                                disabled={true}
                                            />
                                            {errors.totalGuests && (
                                                <div className={styles.form_error}>
                                                    {errors.totalGuests}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Adults:</div>
                                    <div className={styles.customWidth}>
                                        <Field
                                            type={"text"}
                                            name={"adult"}
                                            value={adult}
                                            onChange={(e) => {
                                                const adultValue = e.target.value;
                                                setAdult(adultValue);
                                                setTotalGuests(Number(adultValue)+Number(children));
                                                setFieldValue('adult', adultValue);
                                                setFieldValue('totalGuests', Number(adultValue)+Number(children));
                                            }}
                                            className={errors.adult ? styles.form_entry_err : styles.form_entry}
                                        />
                                        {errors.adult && (
                                            <div className={styles.form_error}>
                                                {errors.adult}
                                            </div>
                                        )}
                                    </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Children:</div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                type={"text"}
                                                name={"children"}
                                                value={children}
                                                onChange={(e) => {
                                                    const childrenValue = e.target.value;
                                                    setChildren(childrenValue);
                                                    setTotalGuests(Number(childrenValue)+Number(adult));
                                                    setFieldValue('children', childrenValue);
                                                    setFieldValue('totalGuests', Number(childrenValue)+Number(adult));
                                                }}
                                                className={errors.children ? styles.form_entry_err : styles.form_entry}
                                            />
                                            {errors.children && (
                                                <div className={styles.form_error}>
                                                    {errors.children}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Babies:
                                            <InfoOutlinedIcon
                                                fontSize="small"
                                                color="inherit"
                                                data-tip
                                                data-for="babies"
                                                className={styles.toolTipIcon}
                                            />
                                            <ReactTooltip place="bottom" type="dark" id="babies" effect="solid" className={styles.toolTip}>
                                                <span>
                                                    Babies are not counted in the number of total guests (which consists of the total number of adults + the total number of children). This means they do not have any effect on the price calculation (while the number of adults and children influence the price when you have per person fees in your pricing).
                                                </span>
                                            </ReactTooltip>
                                        </div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                type={"text"}
                                                name={"babies"}
                                                value={babies}
                                                onChange={(e)=>{
                                                    setBabies(e.target.value);
                                                    setFieldValue("babies", e.target.value);
                                                }}
                                                className={styles.form_entry}
                                            />
                                            {errors.babies && (
                                                <div className={styles.form_error}>
                                                    {errors.babies}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Price per period:
                                            <InfoOutlinedIcon
                                                fontSize="small"
                                                color="inherit"
                                                data-tip
                                                data-for="price"
                                                className={styles.toolTipIcon}
                                            />
                                            <ReactTooltip place="bottom" type="dark" id="price" effect="solid" className={styles.toolTip}>
                                                <span>
                                                    The price is calculated according to the input under the ‘Fixed Rates’ tab inside the ‘Rates’ menu. If you choose to book multiple months/weeks, the price will be calculated for each period alone (per month or per week respectively).
                                                </span>
                                            </ReactTooltip>
                                        </div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                type={"text"}
                                                name={"price"}
                                                value={`${price} ${currency}`}
                                                disabled={true}
                                                className={styles.form_entry}
                                            />
                                        </div>
                                    </div>
                                    <div className={`d-flex ${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Payment status:</div>
                                        <div className={styles.customWidth}>
                                            <Field
                                                as={"select"}
                                                name={"paymentStatus"}
                                                className={styles.form_entry}
                                            >
                                                <option key={""} value={""} disabled={true} label={"--Select Status--"}/>
                                                {paymentOpt.map((opt)=>(
                                                    <option value={opt} key={opt}>{opt}</option>
                                                ))}
                                            </Field>
                                        </div>
                                    </div>
                                    <FieldArray name='bookingDateList'>
                                        {({push, remove}) => (
                                            <>
                                            {bookingDate.map((item, index) => (
                                                <div className={`d-flex ${styles.form_wrapper} ${styles.date_main_wrapper}`} key={`long-${index}`}>
                                                    <div className={styles.dateWrap}>
                                                        <div className={styles.form_title}>Start date*:</div>
                                                        <div className={`${styles.customWidth} ${bookingDate.length > 1 && styles.dateWidth}`}>
                                                            <CustomDate
                                                                id={"bookingDate"}
                                                                name={`bookingDate-${index + 1}`}
                                                                hasError={errors[`bookingDate-${index + 1}`]}
                                                                min={getMinDate(index, bookingDate, values.bookingType)}
                                                                max={getMaxDate(index, bookingDate, values.bookingType)}
                                                                value={values[`bookingDate-${index + 1}`] ?? ""}
                                                                handleChange={(date)=>{
                                                                    const newBookingDate = [...bookingDate];
                                                                    newBookingDate[index] = (date ? date : "");
                                                                    setBookingDate(newBookingDate);
                                                                    setFieldValue(`bookingDate-${index + 1}`, (date ? date : ""));
                                                                    setFieldValue('bookingDateList', newBookingDate);
                                                                    blockedDate(values.bookingType, date);
                                                                }}
                                                            />
                                                            <div className={styles.form_error}>
                                                                {errors[`bookingDate-${index + 1}`]}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {bookingDate.length > 1 && (
                                                        <span className={styles.delDateIcon}>
                                                            <i className={`fa fa-trash ${index === 0 && 'd-none'}`} onClick={() => {
                                                                remove(index);
                                                                const updateBookingDateList = bookingDate.filter((date, idx) => idx!==index);
                                                                setBookingDate(updateBookingDateList);
                                                                const updateValues = {
                                                                    ...values,
                                                                    ...updateBookingDateList.reduce((acc, curr, _index) => {
                                                                        acc[`bookingDate-${_index + 1}`] = curr;
                                                                        return acc;
                                                                    }, {})
                                                                };
                                                                delete updateValues[`bookingDate-${updateBookingDateList.length + 1}`];
                                                                setValues(updateValues, true);
                                                                setFieldValue('bookingDateList', updateBookingDateList);
                                                            }}/>
                                                        </span>
                                                    )}
                                                </div>
                                            ))}
                                            <div className={`d-flex ${styles.addDateCol}`}>
                                                <img
                                                    src={iconPlus}
                                                    alt={"addStartDate"}
                                                    width={"22px"}
                                                    height={"22px"}
                                                    className={`mr-2 ${styles.addDateIcon} ${bookingDate.length >= 12 && styles.addDateDisabled}`}
                                                    onClick={() => {
                                                        let nextDate;
                                                        const lastDate = bookingDate.slice(-1)[0];
                                                        if (lastDate) {
                                                            const cloneLastDate = new Date(lastDate);
                                                            if (values.bookingType === "weekly") {
                                                                nextDate = new Date(cloneLastDate.setDate(cloneLastDate.getDate() + 7));
                                                            } else {
                                                                nextDate = getDateObjFromString(addMonth(cloneLastDate.toISOString().slice(0, 10)));
                                                            };
                                                            if (blockedDate(values.bookingType, nextDate)) {
                                                                return;
                                                            };
                                                            if (cloneLastDate && bookingDate.length < 12) {
                                                                push(nextDate);
                                                                setBookingDate((prevArray) => [...prevArray, nextDate]);
                                                                setFieldValue(`bookingDate-${bookingDate.length + 1}`, nextDate);
                                                                setFieldValue('bookingDateList', [...values.bookingDateList, nextDate]);
                                                            };
                                                        } else {
                                                            if (bookingDate.length < 12) {
                                                                push("");
                                                                setBookingDate((prevArray) => [...prevArray, ""]);
                                                                setFieldValue(`bookingDate-${bookingDate.length + 1}`, "");
                                                                setFieldValue('bookingDateList', [...values.bookingDateList, ""]);
                                                            };
                                                        };
                                                        validateForm(values);
                                                    }}
                                                />
                                                <div className={`${styles.addDateTitle} ${bookingDate.length >= 12 && styles.addDateDisabled}`}>add start date
                                                    <InfoOutlinedIcon
                                                        fontSize="small"
                                                        color="inherit"
                                                        data-tip
                                                        data-for="addDate"
                                                        className={styles.toolTipIcon}
                                                    />
                                                    <ReactTooltip place="bottom" type="dark" id="addDate" effect="solid" className={styles.toolTip}>
                                                        <span>
                                                            You can add up to 12 start dates. These fixed-term period will be treated as separate bookings on the calendar.
                                                        </span>
                                                    </ReactTooltip>
                                                </div>
                                            </div>
                                            </>
                                        )}
                                    </FieldArray>

                                    <div className={`${styles.form_wrapper}`}>
                                        <div className={styles.form_title}>Choose colour:</div>
                                        <div className={styles.containerColor}>
                                            {colorDictionary.map((item, index)=>(
                                                <div
                                                    className={`${styles.containerBox}`}
                                                    style={{background:`${item}`}}
                                                    onClick={()=>setFieldValue("color", item)}
                                                    key={index}
                                                />
                                            ))}
                                            <Field
                                                type={'color'}
                                                name={'color'}
                                                value={values.color}
                                                onChange={handleChange}
                                                className={styles.form_entry_color}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-center">
                                    <button
                                        type="submit"
                                        className={styles.settings_save}
                                        disabled={disableSubmit}
                                    >
                                        {'Save changes'}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                        {setSpecialBookingData(values)}
                    </Form>
                )}
            </Formik>
        </MuiThemeProvider>
    )
};

export default SpecialBookingForm;