import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import styles from './features.module.css';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import vector from '../../../icons/VectorCheck.png';
import {onLoad} from "../../../general_redux/actions";

function Features(props){
    const dispatch=useDispatch();
    useEffect(() => {
        dispatch(onLoad());
        window.scrollTo(0, 0);
    }, []);

    return(
        <>
            <Header/>
            <div className={styles.featuresMain}>
                <h1 className={`mb-5`}>The features you need in a rental management software</h1>
                <div className={styles.featuresMainlists}>
                    <div className={styles.featuresMainlistsheader}>
                        <div className={styles.col1}>
                            <span>Front desk system</span>
                        </div>
                        <div className={styles.col2}>
                            <span>Basic Plan</span>
                        </div>
                        <div className={styles.col2}>
                            <span>Plus Plan</span>
                        </div>
                    </div>
                    <div className={styles.featuresMainlistsbody}>
                    <div className={styles.featuresMainlistsbodyinner}>
                        <div className={styles.col1}>
                            <span>Add reservations and block dates</span>
                        </div>
                        <div className={styles.col2}>
                            <img src= {vector}/>
                        </div>
                        <div className={styles.col2}>
                            <img src= {vector}/>
                        </div>
                    </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Add multiple weekly/monthly bookings at once</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Color coded payment status</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Make notes of special requests</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Color-code reservations</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Infinite scroll for ease of viewing</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Guest information at a glance</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Whatsapp or email your guest with a click</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Channel manager (iCal)</span>
                            </div>
                            <div className={styles.col2}>

                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={styles.featuresMainlists}>
                    <div className={styles.featuresMainlistsheader}>
                        <div className={styles.col1}>
                            <span>Guest management (CRM)</span>
                        </div>
                        <div className={styles.col2}>
                            <span>Basic Plan</span>
                        </div>
                        <div className={styles.col2}>
                            <span>Plus Plan</span>
                        </div>
                    </div>
                    <div className={styles.featuresMainlistsbody}>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Save guest details</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Check past bookings and invoices</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Download guest list as Excel file</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.featuresMainlists}>
                    <div className={styles.featuresMainlistsheader}>
                        <div className={styles.col1}>
                            <span>Rate and rental management</span>
                        </div>
                        <div className={styles.col2}>
                            <span>Basic Plan</span>
                        </div>
                        <div className={styles.col2}>
                            <span>Plus Plan</span>
                        </div>
                    </div>
                    <div className={styles.featuresMainlistsbody}>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Set different currencies for each rental</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Rates calendar showing availabilty</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Set daily/weekly/monthly rates</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={styles.featuresMainlists}>
                    <div className={styles.featuresMainlistsheader}>
                        <div className={styles.col1}>
                            <span>Invoicing and direct payments</span>
                        </div>
                        <div className={styles.col2}>
                            <span>Basic Plan</span>
                        </div>
                        <div className={styles.col2}>
                            <span>Plus Plan</span>
                        </div>
                    </div>
                    <div className={styles.featuresMainlistsbody}>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Flexible invoicing</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>See and download invoice history</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Scheduled invoice sending</span>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                            <div className={styles.col2}>
                                <img src= {vector}/>
                            </div>
                        </div>
                        <div className={styles.featuresMainlistsbodyinner}>
                            <div className={styles.col1}>
                                <span>Receiving direct payments (Stripe)</span>
                            </div>
                            <div className={styles.col2}>

                            </div>
                            <div className={styles.col2}>
                                <img
                                    src= {vector}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                
                <br/><br/>
                {/* <div className={styles.section5}>
                    <h1 className={styles.textCenter}>Coming up soon</h1>
                    <h2 className={`${styles.col1} justify-content-center w-100`}>What you can expect in 2022</h2>
                    <br/>
                    <div className={styles.leftClass}>
                        <p>The Beehaz development team is currently working on the following features:</p>
                        <div>
                            <ul>
                                <li>Paypal integration</li>
                                <li>Booking engine</li>
                                <li>Website builder</li>
                            </ul>
                        </div>
                        <p>We are committed to offering the most important features to enable our users to grow their businesses. Continuous development and improvement to our services are only possible with your feedback, and we are happy to hear from you! Reach out via live chat or contact form and share your thoughts on how we can enhance our software offering.</p>
                    </div>

                    <button className={`${styles.mainBut} mt-4`} onClick={(e)=>e.preventDefault()}>
                        Share your thoughts!
                    </button>
                </div> */}

                <div className={`${styles.feturesimg} text-center `}>
                    <div>
                        <img src='https://static.wixstatic.com/media/844e46_c333dc81e5954226bc935b50d4b0bc4e~mv2.png/v1/crop/x_0,y_0,w_1747,h_1165/fill/w_979,h_653,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/Beehaz_09_more%20for%20less.png' />
                    </div>
                    <Link className={`${styles.mainBut} mt-4 d-inline-block`} to={'/register'}>Get started!</Link>
                </div>
               
            </div>
            <Footer/>
        </>
    )
}

export default Features;