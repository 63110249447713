import React, {useEffect} from 'react';
import styles from "./payment.module.css";

function PaymentReAuth(props){
    useEffect(()=>{
        props.history.replace('/view/calendar');
    },[])
    return(
        <>
            <div className={`container`}>
                <div className={`copy-container ${styles.center}`}>
                    <p>Authentication Failed returning to main page...</p>
                </div>
            </div>
        </>
    )
}

export default PaymentReAuth;