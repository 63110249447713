// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".viewBooking_mainContainer__X4EhC{\n    padding:0.4em;\n}\n.viewBooking_sideText__2uaww{\n    color:grey;\n    padding-bottom: 0.5em;\n}\n.viewBooking_mainText__1VAZm{\n    display: flex;\n    justify-content: left;\n    font-weight: 400;\n    padding-bottom: 0.5em;\n}\n.viewBooking_mininote__3I4_W{\n    display: flex;\n    font-size: 0.9em;\n    font-style: italic;\n    font-weight: 400;\n    padding-bottom:0.5em ;\n}\n.viewBooking_special__XJhZL{\n    font-style: normal;\n    color: #439a86;\n}\n.viewBooking_modalFooter__SCPgT {\n    background-color: white;\n    min-width: 23rem;\n}\n.viewBooking_specialBooking__3d4UP{\n    font-size: 1.1em;\n    font-style: normal;\n    font-weight: 400;\n}\n.viewBooking_icons__1zw_P {\n    font-size: 14px !important;\n    margin-left: 3px !important;\n}\n.viewBooking_containerBox__3Ut6z{\n    float: left;\n    cursor: pointer;\n    width: 15px;\n    height: 15px;\n    border: 1px solid #000;\n    margin-left: 10px;\n    padding: 5px 5px 5px 10px;\n}", ""]);
// Exports
exports.locals = {
	"mainContainer": "viewBooking_mainContainer__X4EhC",
	"sideText": "viewBooking_sideText__2uaww",
	"mainText": "viewBooking_mainText__1VAZm",
	"mininote": "viewBooking_mininote__3I4_W",
	"special": "viewBooking_special__XJhZL",
	"modalFooter": "viewBooking_modalFooter__SCPgT",
	"specialBooking": "viewBooking_specialBooking__3d4UP",
	"icons": "viewBooking_icons__1zw_P",
	"containerBox": "viewBooking_containerBox__3Ut6z"
};
module.exports = exports;
