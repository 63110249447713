import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {Button,Form} from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import styles from '../../guest.module.css';
import 'react-phone-input-2/lib/style.css';
import CustomDate from "../../../../../../components/CustomDate";

import Select from 'react-select';
import countryList from "react-select-country-list";
import {findCountry} from '../../../../../../common/functions/utils';

function AddGuestForm({
        values,
        errors,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        customFieldClass,
        customLabelClass,
    }){

        const [dialCode, setDialCode] = useState(1);
        const [primaryPhone, setPrimaryPhone] = useState(values.phoneNo);
        const [secondaryPhone, setSecondaryPhone] = useState(values.secondaryPhoneNo);
        const user = useSelector(({user}) => user && user.user);
        const guest = useSelector(({guests}) => guests && (guests.guestList?.find(guest => guest.id === values?.id) || null));
        const code = guest?.country ? findCountry(guest?.country) : findCountry(user.country);
        const countries = countryList().getData();

        return (
            <Form onSubmit={handleSubmit}>
                <Form.Row className="pb-2">
                    <Form.Label className={`${customLabelClass ? customLabelClass : "col-md-3"} col-form-label`}>Guest name*: </Form.Label>
                    <div className={customFieldClass ? customFieldClass : "col-md-3"}>
                        <Form.Control
                            type="text"
                            name="name"
                            onChange={handleChange}
                            isInvalid={!!errors.name }
                            value={values.name}
                            style={{fontSize:'inherit'}}
                        />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </div>
                </Form.Row>
                <Form.Row className="pb-2">
                    <Form.Label className={`${customLabelClass ? customLabelClass : "col-md-3"} col-form-label`}>Company: </Form.Label>
                    <div className={customFieldClass ? customFieldClass : "col-md-3"}>
                        <Form.Control
                            type="text"
                            name="company"
                            onChange={handleChange}
                            isInvalid={!!errors.company }
                            value={values.company}
                            style={{fontSize:'inherit'}}
                        />
                        <Form.Control.Feedback type="invalid">{errors.company}</Form.Control.Feedback>
                    </div>
                </Form.Row>
                <Form.Row className="pb-2">
                    <Form.Label className={`${customLabelClass ? customLabelClass : "col-md-3"} col-form-label`}>Primary email*: </Form.Label>
                    <div className={customFieldClass ? customFieldClass : "col-md-3"}>
                        <Form.Control
                            type="text"
                            name="emailId"
                            onChange={handleChange}
                            isInvalid={!!errors.emailId }
                            value={values.emailId}
                            style={{fontSize:'inherit'}}
                        />
                        <Form.Control.Feedback type="invalid">{errors.emailId}</Form.Control.Feedback>
                    </div>
                </Form.Row>
                <Form.Row className="pb-2">
                    <Form.Label className={`d-flex justify-content-between ${customLabelClass ? customLabelClass : "col-md-3"} col-form-label`}>
                        <div>Secondary email:</div>
                        <button
                            type="button"
                            onClick={() => {
                                if (values.secondaryEmailId) {
                                    const emailId = values.emailId;
                                    setFieldValue("emailId", values.secondaryEmailId);
                                    setFieldValue("secondaryEmailId", emailId);
                                };
                            }}
                            className={`${customLabelClass ? "d-flex" : "d-md-none"} ${styles.makePrimary}`}
                        >
                            make primary
                        </button>
                    </Form.Label>
                    <div className={customFieldClass ? customFieldClass : "col-md-3"}>
                        <Form.Control
                            type="text"
                            name="secondaryEmailId"
                            onChange={handleChange}
                            isInvalid={!!errors.secondaryEmailId }
                            value={values.secondaryEmailId}
                            style={{fontSize:'inherit'}}
                        />
                        <Form.Control.Feedback type="invalid">{errors.secondaryEmailId}</Form.Control.Feedback>
                    </div>
                    <button
                        type="button"
                        onClick={() => {
                            if (values.secondaryEmailId) {
                                const emailId = values.emailId;
                                setFieldValue("emailId", values.secondaryEmailId);
                                setFieldValue("secondaryEmailId", emailId);
                            };
                        }}
                        className={`${styles.makePrimary} ${customLabelClass ? "d-none" : "d-none ml-2 d-md-flex"} justify-content-center align-items-center`}
                    >
                        make primary
                    </button>
                </Form.Row>
                <Form.Row className="pb-2">
                    <Form.Label className={`${customLabelClass ? customLabelClass : "col-md-3"} col-form-label`}>Phone number: </Form.Label>
                    <div className={customFieldClass ? customFieldClass : "col-md-3"}>
                        <PhoneInput
                            country={code}
                            value={primaryPhone}
                            onChange={(value, data) => {
                                const rawVal = value.slice(data.dialCode.length);
                                const phone = rawVal ? `+${data.dialCode} ${rawVal}` : "";
                                setFieldValue("phoneNo", phone);
                                setPrimaryPhone(phone || `+${dialCode}`);
                            }}
                            inputStyle={{width: '100%'}}
                            countryCodeEditable={false}
                            enableSearch={true}
                            disableSearchIcon={true}
                         />
                        <Form.Control.Feedback type="invalid">{errors.phoneNo}</Form.Control.Feedback>
                    </div>
                </Form.Row>
                <Form.Row className="pb-2">
                    <Form.Label className={`d-flex justify-content-between ${customLabelClass ? customLabelClass : "col-md-3"} col-form-label`}>
                        <div>Secondary phone:</div>
                        <button
                            type="button"
                            onClick={() => {
                                const rawVal = values.secondaryPhoneNo.slice(`+${dialCode} `.length);
                                if (rawVal) {
                                    const phone = values.phoneNo || `+${dialCode}`;
                                    setFieldValue("phoneNo", values.secondaryPhoneNo);
                                    setFieldValue("secondaryPhoneNo", phone);
                                    setPrimaryPhone(values.secondaryPhoneNo);
                                    setSecondaryPhone(phone);
                                };
                            }}
                            className={`${customLabelClass ? "d-flex" : "d-md-none"} ${styles.makePrimary}`}
                        >
                            make primary
                        </button>
                    </Form.Label>
                    <div className={customFieldClass ? customFieldClass : "col-md-3"}>
                        <PhoneInput
                            country={code}
                            value={secondaryPhone}
                            onChange={(value, data) => {
                                const rawVal = value.slice(data.dialCode.length);
                                const phone = rawVal ? `+${data.dialCode} ${rawVal}` : "";
                                setFieldValue("secondaryPhoneNo", phone);
                                setSecondaryPhone(phone || `+${dialCode}`);
                                setDialCode(data.dialCode);
                            }}
                            inputStyle={{width: '100%'}}
                            countryCodeEditable={false}
                            enableSearch={true}
                            disableSearchIcon={true}
                         />
                        <Form.Control.Feedback type="invalid">{errors.secondaryPhoneNo}</Form.Control.Feedback>
                    </div>
                    <button
                        type="button"
                        onClick={() => {
                            const rawVal = values.secondaryPhoneNo.slice(`+${dialCode} `.length);
                            if (rawVal) {
                                const phone = values.phoneNo || `+${dialCode}`;
                                setFieldValue("phoneNo", values.secondaryPhoneNo);
                                setFieldValue("secondaryPhoneNo", phone);
                                setPrimaryPhone(values.secondaryPhoneNo);
                                setSecondaryPhone(phone);
                            };
                        }}
                        className={`${styles.makePrimary} ${customLabelClass ? "d-none" : "d-none ml-2 d-md-flex"} justify-content-center align-items-center`}
                    >
                        make primary
                    </button>
                </Form.Row>
                <Form.Row className="pb-2">
                    <Form.Label className={`${customLabelClass ? customLabelClass : "col-md-3"} col-form-label`}>Select country: </Form.Label>
                    <div className={customFieldClass ? customFieldClass : "col-md-3"}>
                        <Select
                            id="countrySelect"
                            options={countries}
                            isClearable={true}
                            value={values.country}
                            name={"country"}
                            className={styles.form_entry_select}
                            onChange={(val) => setFieldValue("country", val)}
                        />
                    </div>
                </Form.Row>
                <Form.Row className="pb-2">
                    <Form.Label className={`${customLabelClass ? customLabelClass : "col-md-3"} col-form-label`}>Street and nr:</Form.Label>
                    <div className={customFieldClass ? customFieldClass : "col-md-3"}>
                        <Form.Control
                            type="text"
                            name="street"
                            onChange={handleChange}
                            isInvalid={!!errors.street }
                            value={values.street}
                            style={{fontSize:'inherit'}}
                        />
                        <Form.Control.Feedback type="invalid">{errors.street}</Form.Control.Feedback>
                    </div>
                </Form.Row>
                <Form.Row className="pb-2">
                    <Form.Label className={`${customLabelClass ? customLabelClass : "col-md-3"} col-form-label`}>State/Province: </Form.Label>
                    <div className={customFieldClass ? customFieldClass : "col-md-3"}>
                        <Form.Control
                            type="text"
                            name="state"
                            onChange={handleChange}
                            isInvalid={!!errors.state }
                            value={values.state}
                            style={{fontSize:'inherit'}}
                        />
                        <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                    </div>
                </Form.Row>
                <Form.Row className="pb-2">
                    <Form.Label className={`${customLabelClass ? customLabelClass : "col-md-3"} col-form-label`}>Postal code: </Form.Label>
                    <div className={customFieldClass ? customFieldClass : "col-md-3"}>
                        <Form.Control
                            type="text"
                            name="postalCode"
                            onChange={handleChange}
                            isInvalid={errors.postalCode}
                            value={values.postalCode}
                            style={{fontSize:'inherit'}}
                        />
                        <Form.Control.Feedback type="invalid">{errors.postalCode}</Form.Control.Feedback>
                    </div>
                </Form.Row>
                <Form.Row className="pb-2">
                    <Form.Label className={`${customLabelClass ? customLabelClass : "col-md-3"} col-form-label`}>Address details: </Form.Label>
                    <div className={customFieldClass ? customFieldClass : "col-md-3"}>
                        <Form.Control
                            type="text"
                            name="address"
                            onChange={handleChange}
                            isInvalid={!!errors.address }
                            value={values.address}
                            style={{fontSize:'inherit'}}
                        />
                        <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                    </div>
                </Form.Row>
                <Form.Row className="pb-2">
                    <Form.Label className={`${customLabelClass ? customLabelClass : "col-md-3"} col-form-label`}>Nationality: </Form.Label>
                    <div className={customFieldClass ? customFieldClass : "col-md-3"}>
                        <Form.Control
                            type="text"
                            name="nationality"
                            onChange={handleChange}
                            isInvalid={!!errors.nationality }
                            value={values.nationality}
                            style={{fontSize:'inherit'}}
                        />
                        <Form.Control.Feedback type="invalid">{errors.nationality}</Form.Control.Feedback>
                    </div>
                </Form.Row>
                <Form.Row className="pb-2">
                    <Form.Label className={`${customLabelClass ? customLabelClass : "col-md-3"} col-form-label`}>Language: </Form.Label>
                    <div className={customFieldClass ? customFieldClass : "col-md-3"}>
                        <Form.Control
                            type="text"
                            name="language"
                            onChange={handleChange}
                            isInvalid={!!errors.language }
                            value={values.language}
                            style={{fontSize:'inherit'}}
                        />
                        <Form.Control.Feedback type="invalid">{errors.language}</Form.Control.Feedback>
                    </div>
                </Form.Row>
                <Form.Row className="pb-2">
                    <Form.Label className={`${customLabelClass ? customLabelClass : "col-md-3"} col-form-label`}>Birthday:</Form.Label>
                    <div className={customFieldClass ? customFieldClass : "col-md-3"}>
                        <CustomDate
                            customClass={errors.dob ? styles.form_entry_err : styles.form_entry}
                            name="dob"
                            handleChange={(date)=>{
                                setFieldValue("dob",date);
                            }}
                            value={values.dob}
                            max={new Date()}
                            min={new Date(`${(new Date().getUTCFullYear())-100}-01-01`)}
                        />
                        <Form.Control.Feedback type="invalid">{errors.dob}</Form.Control.Feedback>
                    </div>
                </Form.Row >
                <Form.Row className="pb-2">
                    <Form.Label className={`${customLabelClass ? customLabelClass : "col-md-3"} col-form-label`}>More info:</Form.Label>
                    <div className={customFieldClass ? customFieldClass : "col-md-3"}>
                        <textarea
                            className="form-control"
                            rows="4"
                            name="notes"
                            onChange={handleChange}
                            value={values.notes}
                            style={{fontSize:'inherit'}}
                        />
                        <Form.Control.Feedback type="invalid">{errors.notes}</Form.Control.Feedback>
                    </div>
                </Form.Row>
                <br />
                <div style={{textAlign: customFieldClass ?'center': ''}}>
                    <Button
                        disabled={isSubmitting}
                        type="submit"
                        className={styles.savBtn}
                    >Save changes</Button>
                </div>
            </Form>
        );

}

export default AddGuestForm;
