// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".blockdate_form_title__FBJ2Y {\n  width: 160px;\n  background-color: white !important;\n  border: none !important;\n  font-size: 13px !important;\n}\n\n.blockdate_form_entry__2XbNJ {\n  border-radius: 5px !important;\n  border: 1px solid #d1ceda;\n  width: 100% !important;\n  padding-left: 5px;\n  height: 30px;\n  font-size: 13px !important;\n}\n\n.blockdate_settings_save__3akEO {\n  background-color: #439a86;\n  color: white;\n  padding: 7px;\n  width: 200px;\n  border: 0px;\n  font-size: inherit !important;\n  border-radius: 5px;\n  margin: 30px 10px;\n  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,\n    box-shadow 0.15s ease-in-out;\n}\n.blockdate_settings_save__3akEO:hover {\n  background-color: #177e66;\n}\n\n.blockdate_form_wrapper__2LBBC {\n  padding: 5px 0px;\n}\n\n.blockdate_form_entry_err__30Hck {\n  border-radius: 5px !important;\n  border: 1px solid #ff0000;\n  width: 100% !important;\n  padding-left: 5px;\n  height: 30px;\n}\n\n.blockdate_form_error__1Z4q- {\n  font-size: smaller;\n  color: red;\n  animation-name: blockdate_fadeIn__JHQD7;\n  animation-duration: 1s;\n}\n\n.blockdate_modalTitle__1odpT {\n  font-size: 18px !important;\n}\n\n@keyframes blockdate_fadeIn__JHQD7 {\n  0% {\n    color: rgba(255, 0, 0, 0);\n  }\n  100% {\n    color: rgba(255, 0, 0, 1);\n  }\n}\n\n.blockdate_form_error_hidden___OIe6 {\n  display: none;\n}\n", ""]);
// Exports
exports.locals = {
	"form_title": "blockdate_form_title__FBJ2Y",
	"form_entry": "blockdate_form_entry__2XbNJ",
	"settings_save": "blockdate_settings_save__3akEO",
	"form_wrapper": "blockdate_form_wrapper__2LBBC",
	"form_entry_err": "blockdate_form_entry_err__30Hck",
	"form_error": "blockdate_form_error__1Z4q-",
	"fadeIn": "blockdate_fadeIn__JHQD7",
	"modalTitle": "blockdate_modalTitle__1odpT",
	"form_error_hidden": "blockdate_form_error_hidden___OIe6"
};
module.exports = exports;
