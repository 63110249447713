import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
    List,
    ListItemText,
    Checkbox,
    MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles({
    checkbox: {
        color: '#ccc',
        '&$checked': {
            color: '#4caf50',
        },
        '&$checked:hover': {
            backgroundColor: 'rgba(76, 175, 80, 0.1)',
        },
        '&:hover': {
            backgroundColor: 'rgba(76, 175, 80, 0.1)',
        }
    },
    checked: {},
});


function CheckboxList(props) {

    const {
        selectedItems,
        setSelectedItems,
        items
    } = props;

    const handleToggle = (value) => () => {
        const currentIndex = selectedItems.indexOf(value);
        const newChecked = [...selectedItems];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedItems(newChecked);
    };
    
    const classes = useStyles();

    return (
        <List
            sx={props.style}
            className={props.className}
        >
            {items.map((item) => {
                const labelId = `checkbox-list-label-${item.value}`;

                return (
                    <MenuItem
                        key={item.value}
                        onClick={handleToggle(item.value)}
                    >
                        <Checkbox
                            checked={selectedItems.includes(item.value)}
                            classes={{
                                root: classes.checkbox,
                                checked: classes.checked,
                            }}
                            tabIndex={-1}
                        />
                        <ListItemText
                            primary={item.label}
                            style={{
                                fontSize: "0.8rem",
                                fontFamily: "Montserrat",
                            }}
                            disableTypography={true}
                        />
                    </MenuItem>
                );
            })}
        </List>
    );
};

export default CheckboxList;