// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".table_wrapField__HfmMV {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  width: 115px;\n  display: block;\n  font-size: 12px;\n}\n.table_wrapFieldTh__38btB {\n  width: 115px;\n  display: block;\n  font-size: 14px;\n}\n.table_iconBox__1XO6m {\n  cursor: pointer;\n  margin: 0 !important;\n  padding: 0 !important;\n}\n.table_icon__3u-R0{\n  margin: 0 !important;\n  padding: 0 !important;\n  font-size: 17px !important;\n  margin-left: 10px !important;\n}\n.table_iconDownload__20HEE{\n  font-size: 15px !important;\n}\n.table_iconSend___Jfdd{\n  font-size: 15px !important;\n  margin-left: 12px !important;\n}\n.table_editIcon__6Fvmx {\n  font-size: 17px !important;\n  margin-top: 2px !important;\n}\n.table_iconContainer__RLHsY {\n  display: flex;\n  align-items: center;\n}\n\ncaption {\n  font-family: 'Montserrat', 'Roboto' !important;\n  text-align: center !important;\n}\n\n.table_color_box__2UWYY {\n  width: 20px;\n  height: 20px;\n}\n.table_popperBox__2LD-N{\n  z-index: 10000;\n}\n\n.table_navigateContainer__2Zxyf {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 10px;\n}\n\n.table_navigateContainer__2Zxyf div span {\n  font-weight: bold;\n  color: #495057;\n}\n\n@media screen and (max-width: 767px) {\n  .table_wrapField__HfmMV {\n    font-size: 11px !important;\n  }\n  .table_wrapFieldTh__38btB {\n    font-size: 13px !important;\n  }\n  caption {\n    font-size: inherit !important;\n  }\n}", ""]);
// Exports
exports.locals = {
	"wrapField": "table_wrapField__HfmMV",
	"wrapFieldTh": "table_wrapFieldTh__38btB",
	"iconBox": "table_iconBox__1XO6m",
	"icon": "table_icon__3u-R0",
	"iconDownload": "table_iconDownload__20HEE",
	"iconSend": "table_iconSend___Jfdd",
	"editIcon": "table_editIcon__6Fvmx",
	"iconContainer": "table_iconContainer__RLHsY",
	"color_box": "table_color_box__2UWYY",
	"popperBox": "table_popperBox__2LD-N",
	"navigateContainer": "table_navigateContainer__2Zxyf"
};
module.exports = exports;
