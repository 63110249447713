import React from 'react';
import { Box, Popover } from "@material-ui/core";
import { Formik } from 'formik';

import styles from './EmptyNightPopover.module.css';


function EmptyNightPopover (props) {

    const handleClosePopover = () => {
        props.onClose();
    };

    const handleSubmit = (bookingType) => {
        props.addBookingFromPopover(bookingType);
        handleClosePopover();
    };

    const rightPosition = props.distanceToScreenMargin.right < (props.width > 280 ? 150 : 135);
    const bottomPosition = props.distanceToScreenMargin.bottom < 155;

    const leftArrowStyles = {
        top: (bottomPosition ? 'none' : '20px'),
        bottom: (bottomPosition ? '20px' : 'none'),
        marginLeft: (rightPosition ? '-10px' : '5px'),
        transform: (rightPosition ? 'rotate(-45deg)' : 'rotate(135deg)'),
    };
    
    const popoverBodyStyles = {
        marginLeft: (rightPosition ? '-115px' : '15px'),
    };

    return (
        <Popover
            className={styles.tester}
            id={props.id}
            open={props.open}
            onClose={props.onClose}
            anchorEl={props.anchorEl}
            transformOrigin={{
                vertical: (bottomPosition ? 'center' : 'top'),
                horizontal: (rightPosition ? 'right' : 'left'),
            }}
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: 0,
                    display: "flex",
                    marginTop: (bottomPosition ? -25 : -20),
                }
            }}
        >
        <Formik>
            <main style={popoverBodyStyles} className={styles.popoverBody}>
                <div className={styles.popoverContainer}>
                    <button
                        className={`${styles.allBtn} w-25`}
                        onClick={() => handleSubmit('booking')}
                        >
                        Add Booking
                    </button>
                    <button
                        className={`${styles.allBtn} ${styles.greyBtn} w-25`}
                        onClick={() => handleSubmit('weekly')}
                        >
                        Add Fixed
                    </button>
                    <button
                        className={`${styles.allBtn} ${styles.greyBtn} w-25`}
                        onClick={() => handleSubmit('blockdates')}
                        >
                        Block Dates
                    </button>
                </div>
                <Box style={leftArrowStyles} className={styles.leftArrow} />
            </main>
        </Formik>
        </Popover>
    );
};

export default EmptyNightPopover;