import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import styles from '../../rates.module.css';
import { useSelector } from 'react-redux';


function ChannelAdjustment({ handleSubmit, onHide }) {

  const channelAdjustmentRate = useSelector(({ rates })=> rates && rates.channelAdjustmentRate);

  const schema = yup.object({
    percentage: yup.number('Must be an integer')
      .required('Adjust channel rates is a required field')
      .min(-99, 'Must be a number greater than or equal to -99')
      .max(100, 'Must be a number less than or equal to 100')
      .typeError('Must be a value of type number')
      .integer('Must be an integer'),
  });

  const initialValues = {
    percentage: channelAdjustmentRate,
  };

  const resetForm = (handleReset) => {
    handleReset();
    onHide();
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, values, handleChange, handleSubmit, handleReset }) => (
          <Form onSubmit={handleSubmit}>
            <div className={`mb-4 ${styles.formGroup}`}>
              <p>
                This will be used as a rate markup. If you want to offer different rates in channels 
                than you use in direct bookings to cover the extra commission costs. Put a value greater than
                0 to increase the rates in the rates calendar that are sent to channels.
              </p>
            </div>
            <div className="d-flex align-items-center">
              <label htmlFor="percentage" className="mr-2">Adjust channel rates by:</label>
              <Col>
                <div className="d-flex align-items-center">
                  <Form.Control
                    type="text"
                    name="percentage"
                    value={values.percentage}
                    onChange={handleChange}
                    isInvalid={!!errors.percentage}
                    style={{ maxWidth: '110px', borderRadius: '5px', fontSize:'inherit' }}
                  />
                  <div className="pl-2 d-flex align-items-center" style={{color: 'gray'}}>
                    %
                  </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.percentage}</div>
              </Col>
            </div>
            <div className='p-2 mt-3 d-flex justify-content-center'>
                <div className='col-md-8 d-flex justify-content-around'>
                    <button  type="submit" className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`} >Save</button>
                    <button type="reset" className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`} style={{backgroundColor:"#6c757d"}} onClick={()=>resetForm(handleReset)} >Discard</button>
                </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ChannelAdjustment;
