import React, { useEffect, useState } from 'react';
import styles from './rentals.module.css';
import AddEditRental from './components/AddEditRental/AddEditRental';
import EditMultipleRentals from './components/AddEditRental/EditMultipleRentals';
import AddGroup from './components/AddEditGroup/AddGroup';
import AddProperty from './components/AddEditProperty/AddProperty';
import EditMultipleProperties from './components/AddEditProperty/EditPropertyMultiple'
import NoPropertyModal from './components/AddEditProperty/NoPropertyModal';
import ViewGroups from './components/view-groups';
import ViewRentals from './components/view-rentals';
import ViewProperty from './components/view-property';
import { useSelector, useDispatch } from 'react-redux';
import styles2 from "../Guest/guest.module.css";
import { toast } from 'react-toastify';
import {
  addGroup,
  addRental,
  updateGroup,
  updateRental,
  loadRental,
  loadGroup,
  loadProperty,
  clearRental,
  addProperty,
  editProperty,
  updateRentalDataInherited,
} from '../../../../general_redux/rentals/actions';

function Rentals() {
  const [addEditRentalModal, setAddEditRentalModal] = useState(false);
  const [addPropertyModal, setAddPropertyModal] = useState(false);
  const [editMultipleRentalsModal, setEditMultipleRentalsModal] = useState(false);
  const [editMultiplePropertiesModal, setEditMultiplePropertiesModal] = useState(false);
  const [addGroupModal, setAddGroupModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [noPropertyModal, setNoPropertyModal] = useState(false);
  
  const dispatch = useDispatch();

  const rentals = useSelector(({rentals})=>rentals && rentals.rentals);
  const properties = useSelector(({ rentals }) => rentals && rentals.properties);
  const rentalStatus = useSelector(({ rentals }) => rentals && rentals.actionAddRental);
  const rentalID = useSelector(({ rentals }) => rentals && rentals.lastAddedRentalID);
  const setting = useSelector(({generalSetting})=>generalSetting && generalSetting.setting);
  const [currentTab, setCurrentTab] = useState("Rentals");

  const tabs = [
    "Rentals",
    "Properties",
    "Groups",
  ];

  const handleTab = (tab) => {
    setCurrentTab(tab);
  };
 
  const closeModal = (type, data, isNew) => {
    setEditMultipleRentalsModal(false);
    setAddEditRentalModal(false);
    setAddGroupModal(false);
    setAddPropertyModal(false);
    setEditMultiplePropertiesModal(false);
    setModalData({ data, type, isNew });
  };

  useEffect(() => {
      dispatch(loadGroup());
      dispatch(loadRental());
      dispatch(loadProperty());
  }, [dispatch]);

  useEffect(() => {
    if (rentalStatus.success) {
      if(rentals && rentals.length===4 && setting.accountType){
        toast.warn(` Please don’t forget to include your payment information before the end of the trial period! If you do not sign up to the Plus Plan, you may lose all information associated with all rentals above the third!`
            ,{
              style:{
                color:'#484848'
              }
            });
      }
      if (rentalID) {
        dispatch(clearRental());
      }
    }
  }, [dispatch, rentalID]);
 
  useEffect(() => {
    if (modalData && modalData.data) {
      if (modalData.isNew) {
        const payload = modalData.data;
        if (modalData.type === 'rentals') {
          dispatch(addRental(payload));
        } else if (modalData.type === 'properties') {
          dispatch(addProperty(payload));
        } else {
          dispatch(addGroup(payload));
        }
      } else {
        const payload = modalData.data;
        if (modalData.type === 'rentals') {
          dispatch(updateRental(payload));
        } else if (modalData.type === 'properties') {
          dispatch(editProperty(payload)).then(() => {
            dispatch(updateRentalDataInherited(payload));
          });
        } else {
          dispatch(updateGroup(payload));
        }
      }
      setModalData(null); 
    }
  }, [dispatch, modalData]);
 
 
  const editGroupData = (value) => {
    setAddGroupModal({ show: true, value });
  };

  const editRentalData = (values) => {
    setAddEditRentalModal({ show: true, value: values, modalType: 'edit'});
  };
 
  const editPropertyData = (value) => {
    setAddPropertyModal({ show: true, value });
  };
 
  
 
  return (
    <>
      <AddEditRental
        show={addEditRentalModal.show}
        value={addEditRentalModal.value}
        modalType={addEditRentalModal.modalType}
        onHide={(data) => closeModal('rentals', data, true)}
        onEdit={(data) => closeModal('rentals', data, false)}
      />
      <EditMultipleRentals
        show={editMultipleRentalsModal.show}
        value={editMultipleRentalsModal.value}
        modalType={editMultipleRentalsModal.modalType}
        onHide={(data) => closeModal('rentals', data, true)}
        onEdit={(data) => closeModal('rentals', data, false)}
      />
      <AddGroup
        show={addGroupModal.show}
        value={addGroupModal.value}
        onHide={(data) => closeModal('groups', data, true)}
        onEdit={(data) => closeModal('groups', data, false)}
      /> 
      <AddProperty
        show={addPropertyModal.show}
        value={addPropertyModal.value}
        onHide={(data) => closeModal('properties', data, true)}
        onEdit={(data) => closeModal('properties', data, false)}
      />
     <EditMultipleProperties
        show={editMultiplePropertiesModal.show}
        value={editMultiplePropertiesModal.value}
        onHide={(data) => closeModal('properties', data, true)}
        onEdit={(data) => closeModal('properties', data, false)}
      />
      <NoPropertyModal
        show={noPropertyModal}
        onHide={() => setNoPropertyModal(false)}
        openAddPropertyModal={() => {
          setAddPropertyModal({ show: true, data: null });
        }}
      />

      <div style={{ margin: '0em 1em' }}>
        <div className={styles2.upperRow}>
          {tabs.length !== 0 && tabs.map((tab, index) => (
            <a
              className={currentTab === tab ? `${styles2.tabBox} ${styles2.active}` : `${styles2.tabBox}`}
              key={index}
              onClick={() => handleTab(tab)}
            >{tab}</a>
          ))}
        </div>
 
        <div className={styles.flexEnd}>
        {currentTab === 'Rentals' && (
          <>
            <button
              className={`${styles.allBtn}`}
              style={{ height: '25px', marginTop: '7px' }} 
              onClick={() => {
                if (properties.length) {
                  setAddEditRentalModal({ show: true, data: null, modalType: 'add'});
                } else {
                  setNoPropertyModal(true);
                }
              }}
            >Add Rental</button>
            <button
              className={`${styles.allBtn}`}
              style={{ height: '25px', marginTop: '7px' }} 
              onClick={() => {
                if (properties.length) {
                  setEditMultipleRentalsModal({ show: true, data: null, modalType: 'editMultiple' });
                } else {
                  setNoPropertyModal(true);
                }
              }}
            >Edit Multiple</button>
          </>
        )}
        {currentTab === 'Properties' && (
          <>
            <button
              className={`${styles.allBtn}`}
              style={{ height: '25px', marginTop: '5px' }} 
              onClick={() => setAddPropertyModal({ show: true, data: null })}
            >Add Property</button>
            <button
              className={`${styles.allBtn}`}
              style={{ height: '25px', marginTop: '5px' }} 
              onClick={() => setEditMultiplePropertiesModal({ show: true, data: null })}
            >Edit Multiple</button>
          </>
        )}
        {currentTab === 'Groups' && (
            <button
              className={`${styles.allBtn}`}
              style={{ height: '25px', marginTop: '5px' }} 
              onClick={() => setAddGroupModal({ show: true, data: null })}
            >Add Group</button>
          )}
        </div>
 
        <div>
          {currentTab === "Rentals" && (
            <ViewRentals editRental={editRentalData} />
          )}
          {currentTab === "Properties" && (
            <ViewProperty editProperty={editPropertyData} />
          )}
          {currentTab === "Groups" && (
            <ViewGroups editGroup={editGroupData} />
          )}
        </div>
      </div>
    </>
  );
}
export default Rentals;
