import {
    SU_ADD_PROPERTY,
    SU_DELETE_PROPERTY,
    CHANNEL_MAPPING,
} from './types';

import { BACKEND_API } from "../../config/middleware";
import API from "../../config/api-url";


export const addChannelsMock = data => ({
    type: "ADD_CHANNELS_MOCK_SUCCESS",
    payload: data,
});

export const channelMapping = data => ({
    type: BACKEND_API,
        payload: Object.assign(
        {
            type: CHANNEL_MAPPING,
            method: 'post',
            url: API.CHANNEL_MAPPING,
            data: data,
        },
    )
});

export const addProperty = data => ({
    type: BACKEND_API,
        payload: Object.assign(
        {
            type: SU_ADD_PROPERTY,
            method: 'post',
            url: API.CONNECT_PROPERTY_TO_SU,
            data: data,
        },
    )
});

export const deleteProperty = data => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type: SU_DELETE_PROPERTY,
            method: 'delete',
            url: API.CONNECT_PROPERTY_TO_SU,
            data: data,
        },
    )
});