// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n    --stdYellow: #f5b60e;\n    --stdGreen: #439a86;\n    --stdRed: #dc3545;\n    --lightGreyLv1: #e8e6e6;\n    --midGreyLv1: #757575;\n    --darkGrey: #343436;\n    --formEntryBorder: #d1ceda;\n    --formErrorRed: red;\n}\nbody{\n    font-family: 'Montserrat', 'roboto' !important;\n    font-size: 13px !important;\n}\nhtml {\n    scroll-behavior: smooth;\n}\n.main_headerDiv__2q60I{\n    font-size: 2em;\n    text-align: center;\n    line-height: 1.5;\n    padding: 1rem;\n    letter-spacing: 1.2;\n    font-weight: 570;\n    /*color:#439A86*/\n}\n.main_cardStyle__2nslq{\n    width: 20rem;\n    height: 25rem;\n    padding: 1rem;\n    background-color: var(--lightGreyLv1);\n    border: 1.5px solid var(--stdGreen);\n    box-shadow: 7px 5px 7px 0px var(--midGreyLv1);\n}\n.main_cardBody__1G325{\n    text-align: center;\n    color: var(--darkGrey);\n}\n.main_cardText__1J-kC{\n    font-size: 0.9em;\n    margin-bottom: 1em;\n}\n/* .cardImg{\n    width: 1.5em;\n    height: 1.5em;\n    padding-right: 2px;\n    padding-bottom: 2px;\n} */\n.main_join__1VN3I {\n    background-color: var(--stdGreen);\n    color: var(--lightGreyLv1);\n    padding: 10px;\n    border: 0px;\n    font-size: 1em;\n    border-radius: 3px;\n    margin-bottom: 0.2em;\n    margin-top: 1.3em;\n}\n.main_join__1VN3I:disabled{\n    background-color: var(--stdGreen);\n    cursor: not-allowed;\n    color: var(--lightGreyLv1);\n}\n.main_cardSubtitle__2O5QR{\n    color: var(--darkGrey);\n}\n.main_login_image__3eN0m {\n    box-sizing: border-box;\n    width: 100%;\n    max-width: 120px;\n    height: auto;\n    margin: 10px;\n    align-self: flex-start;\n}\n.main_containerLogo__1_d1G{\n    max-width:1200px;\n    margin: auto;\n    padding: 0 15px;\n}\n.main_modal-title__J_LWv h4{\n    font-size: 1rem !important;\n}\n@media (max-width:767px ) {\n    body{\n        font-size: 12px !important;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"headerDiv": "main_headerDiv__2q60I",
	"cardStyle": "main_cardStyle__2nslq",
	"cardBody": "main_cardBody__1G325",
	"cardText": "main_cardText__1J-kC",
	"join": "main_join__1VN3I",
	"cardSubtitle": "main_cardSubtitle__2O5QR",
	"login_image": "main_login_image__3eN0m",
	"containerLogo": "main_containerLogo__1_d1G",
	"modal-title": "main_modal-title__J_LWv"
};
module.exports = exports;
