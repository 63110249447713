import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from "react-redux";
import * as yup from 'yup';
import moment from 'moment';
import { withFormik } from 'formik';
import AddGuestForm from './AddGuestForm';
import GuestBookingList from '../List/GuestBookingList';
import GuestInvoiceList from '../List/GuestInvoiceList';
import styles2 from '../../guest.module.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {invoiceByGuest} from '../../../../../../general_redux/invoice/actions';

function AddGuests(props){
    const initValue=props.value;
    const dispatch=useDispatch();
    const [currentTab,setCurrentTab]=useState("Guest Details");
    const tabs=["Guest Details","Bookings","Invoices"];
    const user= useSelector(({user})=>user && user.user);
    const invoiceGuest= useSelector(({invoice})=>invoice && invoice.invoiceByGuest);
    const dateDisplay = useSelector(({generalSetting})=>generalSetting && generalSetting.setting && generalSetting.setting.dateDisplay);

    useEffect(()=>{
        const id=initValue?initValue.id:null;
        if(id){
            dispatch(invoiceByGuest(id));
        }
    },[])

    const handleSubmit=(data)=>{
        const payload={
            id:(props.value)?props.value.id :undefined,
            name: data.name,
            emailId:data.emailId,
            phoneNo:data.phoneNo.split(" ")?.length ===1 ? "" : data.phoneNo,
            guestType: data.guestType,
            secondaryPhoneNo: data.secondaryPhoneNo.split(" ")?.length ===1 ? "" : data.secondaryPhoneNo,
            postalCode: data.postalCode,
            secondaryEmailId: data.secondaryEmailId,
            country: data.country?.label || "",
            address: data.address,
            street: data.street,
            state:data.state,
            nationality:data.nationality,
            language:data.language,
            company:data.company,
            notes:data.notes,
            dob: data.dob ? moment(data.dob).format("YYYY-MM-DD") : ''
        }
        props.onSubmit(payload);
    }

    const phoneRegExp = /^[0-9-+\s()]*$/;

    const FormikEnhancer = withFormik({
        validationSchema:yup.object({
            name: yup.string().required('Guest name is a required field'),
            company: yup.string(),
            emailId:yup.string().email('Invalid email').required('Email is a required field'),
            secondaryEmailId :yup.string().email('Invalid email'),
            phoneNo:yup.string().matches(phoneRegExp, 'Only numbers, space, parenthesis, + and - are accepted.'),
            secondaryPhoneNo:yup.string().matches(phoneRegExp, 'Only numbers, space, parenthesis, + and - are accepted.'),
            country: yup.object(),
            address: yup.string(),
            postalCode: yup.string(),
            street: yup.string(),
            state: yup.string(),
            nationality:yup.string(),
            language:yup.string(),
            notes:yup.string(),
            dob: yup.string().nullable(),
        }),
        mapPropsToValues: () => ({
            name: initValue?.name || '',
            company: initValue?.company || '',
            emailId: initValue?.emailId || '',
            secondaryEmailId: initValue?.secondaryEmailId || '',
            phoneNo: initValue?.phoneNo || '',
            guestType: initValue?.guestType || 'guest',
            secondaryPhoneNo: initValue?.secondaryPhoneNo || '',
            postalCode: initValue?.postalCode || '',
            state: initValue?.state || '',
            nationality: initValue?.nationality || '',
            language: initValue?.language || '',
            country: initValue?.country && {value: initValue.country, label: initValue.country} || '',
            address: initValue?.address || '',
            street: initValue?.street || '',
            dob: (initValue?.dob && initValue?.dob !== "Invalid da") ? new Date(moment(initValue.dob)) : "",
            notes: initValue?.notes || '',
            dateDisplay:dateDisplay,
        }),
        validateOnBlur:false,
        validateOnChange:false,
        handleSubmit :  (values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
          },
        displayName: 'AddGuestForm',
      })(AddGuestForm);

    return(
      <>
          <div className={styles2.upperRow}>
              <a className={`${styles2.tabBox} ${styles2.tabButton}`} onClick={props.onHide}>
                  <span >
                      <ArrowBackIosIcon className={styles2.arrowIcon} />{"Back"}
                  </span>
              </a>
              {tabs.length!==0 && tabs.map((tab,index)=>(
                  <div key={index}>
                      {((tab==="Bookings" || tab==="Invoices") && !initValue) ?(
                         null
                      ):(
                          <a className={(currentTab===tab)?`${styles2.tabBox} ${styles2.active}`:`${styles2.tabBox}`} key={index} onClick={()=>setCurrentTab(tab)}>
                              {tab}
                          </a>
                      )}
                  </div>
              ))}
          </div>
          <div style={{margin:'0em 1em', padding: '1rem'}}>
              {currentTab==="Guest Details" && (
                  <FormikEnhancer/>
              )}
              {initValue && (
                  <>
                      {currentTab==="Bookings" && (
                          <GuestBookingList id={initValue? initValue.id:"1"} guestName={initValue?initValue.name:false}/>
                      )}
                      {currentTab==="Invoices" && (
                          <GuestInvoiceList invoiceGuest={invoiceGuest} guestDetail={initValue} guestName={initValue?initValue.name:false}/>
                      )}
                  </>
              )}
          </div>
      </>
    )
}
export default AddGuests;