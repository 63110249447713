import moment from 'moment';
import { toast } from 'react-toastify';

import {
  ADD_BOOKING,
  EDIT_BOOKING,
  EDIT_BOOKING_COLOR,
  LOAD_BOOKING,
  BOOKING_FLAG,
  CLEAR_BOOKING,
  SAVE_SPECIAL_BOOKING_DATA,
  CLEAR_SPECIAL_BOOKING_DATA,
  DELETE_BOOKING,
  GET_BOOKING_BY_GUEST_ID,
  DELETE_BLOCK_DATE,
  CHARGE_CALCULATION,
  RESET_CHARGES,
  ADD_DISCOUNT,
  FETCH_DISCOUNT,
  EDIT_DISCOUNT,
  EDIT_DISCOUNT_BY_BOOKING_FORM,
  FETCH_DISCOUNT_BY_BOOKING,
  FETCH_BOOKING_BY_ID,
  GET_FEES_TAXES,
  CLEAR_FEESTAXES
} from './types';
import {actionForState} from "../../common/functions/utils";
import { getAllByPlaceholderText } from '@testing-library/react';
toast.configure();

export const INITIAL_STATE = {
  allBooking: [],
  bookingListByGuest: [],
  bookingFlag: null,
  bookingDiscount:[],
  bookingFeesTaxes:{},
  bookingById:[],
  currentCharges:null,
  tempSpecialBookingData: null,
  action: {
    loading: false,
    success: undefined,
  },
  actionForBooking: {
    loading: false,
    success: false,
    error: false,
  },
  actionForAddBooking: {
    loading: false,
    success: false,
    error: false,
  },
  actionEditBooking: {
    loading: false,
    success: false,
    error: false,
  },
  actionEditBookingColor: {
    loading: false,
    success: false,
    error: false,
  },
  actionDeleteBooking: {
    loading: false,
    success: false,
    error: false,
  },
  actionForGetBookingListById: {
    loading: false,
    success: false,
    error: false,
  },
  actionDeleteBlockDate: {
    loading: false,
    success: false,
    error: false,
  },
  actionForCharges:{
    loading: false,
    success: false,
    error: false,
  },
  actionForFetchBooking:{
    loading:false,
    success:false,
    error:false,
  },
  actionForFetchDiscount:{
    loading: false,
    success: false,
    error: false,
  },
};
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_BOOKING.pending:
      return {
        ...state,
        actionForAddBooking: actionForState(state.action, 'pending'),
      };
    case ADD_BOOKING.success:
      if(action.payload.booking[0].bookingType=== "booking" || action.payload.booking[0].bookingType==="weekly" || action.payload.booking[0].bookingType==="monthly"){
        toast.success('Booking has been added successfully!');
      }else{
        toast.success('Dates blocked successfully.');
      }
      return {
        ...state,
        allBooking: state.allBooking.concat(
          action.payload.booking.map((booking) => ({
            ...booking,
            id: booking.id,
            type: booking.bookingType,
            group: booking.rentalId,
            color: booking.color,
            status: false,
            start_time: moment(`${booking.arrive} 12:00`, "YYYY-MM-DD HH:mm"),
            end_time: moment(`${booking.depart} 12:00`, "YYYY-MM-DD HH:mm"),
          }))
        ),
        bookingFlag: action.payload.booking[0].bookingType === 'blockdates' ? null : action.payload.booking[0].id,
        actionForAddBooking: actionForState(state.action, 'success'),
      };
    case ADD_BOOKING.error:
      toast.error(action.payload.message);
      return {
        ...state,
        actionForAddBooking: actionForState(state.action, 'error', action.payload.message),
      };
    case LOAD_BOOKING.pending:
      return {
        ...state,
        actionForBooking: actionForState(state.action, 'pending'),
      };
    case LOAD_BOOKING.success:
      let dataItem=[];
      (action.payload.booking && action.payload.booking.length!==0) && action.payload.booking.forEach(element => {
        element.status !== "Cancelled" && dataItem.push({...element,
          start_time:moment(`${element.arrive} 12:00`,"YYYY-MM-DD HH:mm"),
          end_time:moment(`${element.depart} 12:00`,"YYYY-MM-DD HH:mm"),
          group:element.rentalId,
          status:false,
          color:element.color,
          type:element.bookingType})
      });
      return {
        ...state,
        actionForBooking: actionForState(state.action, 'success'),
        allBooking: dataItem,
      };
    case LOAD_BOOKING.error:
      toast.error(action.payload.message);
      return {
        ...state,
        actionForBooking: actionForState(state.action, 'error', action.payload.message),
      };
    case EDIT_BOOKING.pending:
      return {
        ...state,
        actionEditBooking: actionForState(state.action, 'pending'),
      };
    case EDIT_BOOKING.success:

      let bookingsArrayData = [...state.allBooking];
      bookingsArrayData.forEach((oldBooking, index) => {
        const updateBooking = action.payload.booking.find(newBooking => newBooking.id === oldBooking.id);
        if (updateBooking) {
          bookingsArrayData[index] = {
            ...updateBooking,
            start_time: moment(`${updateBooking.arrive} 12:00`),
            end_time: moment(`${updateBooking.depart} 12:00`),
            group: updateBooking.rentalId,
            type: updateBooking.bookingType,
            status: false,
          };
        }
      })

      toast.success('Booking has been edited successfully!');
      return {
        ...state,
        allBooking: bookingsArrayData,
        actionEditBooking: actionForState(state.action, 'success'),
      };
    case EDIT_BOOKING.error:
      toast.error(action.payload.message);
      return {
        ...state,
        actionEditBooking: actionForState(state.action, 'error', action.payload.message),
      };
    case EDIT_BOOKING_COLOR.pending:
      return {
        ...state,
        actionEditBookingColor: actionForState(state.action, 'pending'),
      };
    case EDIT_BOOKING_COLOR.success:
      const allBookingListClone = state.allBooking;
      const bookingIndex = state.allBooking.findIndex(({ id }) => Number(id) === action.payload.data.id);
      if (bookingIndex !== -1) {
        const bookingClone = allBookingListClone[bookingIndex];
        allBookingListClone[bookingIndex] = {
          ...bookingClone,
          color: action.payload.data.color
        };
      }
      toast.success('Booking color edited successfully!');
      return {
        ...state,
        allBooking: allBookingListClone,
        actionEditBookingColor: actionForState(state.action, 'success'),
      };
    case EDIT_BOOKING_COLOR.error:
      toast.error(action.payload.message);
      return {
        ...state,
        actionEditBookingColor: actionForState(state.action, 'error', action.payload.message),
      };
    case BOOKING_FLAG:
      return {
        ...state,
        bookingFlag: null,
      };
    case CLEAR_BOOKING:
      return {
        ...state,
        bookingDiscount: INITIAL_STATE.bookingDiscount,
        bookingFeesTaxes: INITIAL_STATE.bookingFeesTaxes,
        bookingById: INITIAL_STATE.bookingById,
        currentCharges: INITIAL_STATE.currentCharges,
        actionForAddBooking: INITIAL_STATE.actionForAddBooking,
        actionEditBooking: INITIAL_STATE.actionEditBooking,
      };
      
    case SAVE_SPECIAL_BOOKING_DATA:
      return {
        ...state,
        tempSpecialBookingData: action.payload,
      };
      
    case CLEAR_SPECIAL_BOOKING_DATA:
      return {
        ...state,
        tempSpecialBookingData: INITIAL_STATE.tempSpecialBookingData,
      };
    
    case DELETE_BOOKING.pending:
      return {
        ...state,
        actionDeleteBooking: actionForState(state.action, 'pending'),
      };
    case DELETE_BOOKING.success:
      toast.success('The booking was successfully deleted.');

      let filterBookings = state.allBooking.filter((booking) => {
        if (![booking.id, booking.parentBookingId].includes(action.payload.id)) {
          return booking;
        };
      });

      return {
        ...state,
        allBooking: filterBookings,
        actionDeleteBooking: actionForState(state.action, 'success'),
      };
    case DELETE_BOOKING.error:
      toast.error(action.payload.message);
      return {
        ...state,
        actionDeleteBooking: actionForState(state.action, 'error', action.payload.message),
      };

    case DELETE_BLOCK_DATE.pending:
      return {
        ...state,
        actionDeleteBlockDate: actionForState(state.action, 'pending'),
      };
    case DELETE_BLOCK_DATE.success:
      toast.success('Dates have been unblocked successfully!');
      return {
        ...state,
        allBooking: state.allBooking.filter((item) => item.id !== parseInt(action.payload.id)),
        actionDeleteBlockDate: actionForState(state.action, 'success'),
      };
    case DELETE_BLOCK_DATE.error:
      toast.error(action.payload.message);
      return {
        ...state,
        actionDeleteBlockDate: actionForState(state.action, 'error', action.payload.message),
      };

    case GET_BOOKING_BY_GUEST_ID.pending:
        return {
          ...state,
          actionForGetBookingListById: actionForState(state.action, 'pending'),
        };
    case GET_BOOKING_BY_GUEST_ID.success:
        return {
          ...state,
          bookingListByGuest: action.payload.booking,
          actionForGetBookingListById: actionForState(state.action, 'success'),
        };
    case GET_BOOKING_BY_GUEST_ID.error:
      toast.error(action.payload.message);
        return {
          ...state,
          actionForGetBookingListById: actionForState(state.action, 'error', action.payload.message),
        };
    case CHARGE_CALCULATION.pending:
      return{
        ...state,
        actionForCharges: actionForState(state.action,'pending')
      }
    case CHARGE_CALCULATION.success:
      return{
        ...state,
        currentCharges:action.payload.charges,
        actionForCharges: actionForState(state.action,'success')
      }
    case CHARGE_CALCULATION.error:
      toast.error(action.payload.message);
      return{
        ...state,
        actionForCharges: actionForState(state.action,'error',action.payload.message)
      }
    case ADD_DISCOUNT.pending:
      return{
        ...state,
      }
    case ADD_DISCOUNT.success:
      toast.success("Discount successfully added!")
      return{
        ...state,
        bookingDiscount:state.bookingDiscount ,
      }
    case ADD_DISCOUNT.error:
      toast.error(action.payload.message)
      return{
        ...state,
      }
    case GET_FEES_TAXES.pending:
      return {
        ...state,
      }
    case GET_FEES_TAXES.success:
      return {
        ...state,
        bookingFeesTaxes: {fee:action.payload?.fee || [], tax: action.payload?.tax || []},
      }
    case GET_FEES_TAXES.error:
      toast.error(action.payload.message);
      return {
        ...state,
      }
    case CLEAR_FEESTAXES:
      return {
        ...state,
        bookingFeesTaxes: {}
      };

    case EDIT_DISCOUNT.pending:
      return{
        ...state,
      }
    case EDIT_DISCOUNT.success:
      if(action.payload.data.bookingPrice !== Number(action.payload.data.totalPrice)){
        toast.success("Prices edited successfully!")
      } else {
        toast.success("Discounts edited successfully!")
      }
      const BookingDataR = state.allBooking;
      const indxB = state.allBooking.findIndex(({ id }) => Number(id) === action.payload.data.bookingId);
      if (indxB !== -1) {
        BookingDataR[indxB] = {...BookingDataR[indxB],
          price:action.payload.data.bookingPrice
        };
      }
      return{
        ...state,
        bookingDiscount:action.payload.data,
        allBooking: BookingDataR
      }
    case EDIT_DISCOUNT.error:
      toast.error(action.payload.message)
      return{
        ...state,
      }
      
    case EDIT_DISCOUNT_BY_BOOKING_FORM.pending:
      return {
        ...state,
      }
    case EDIT_DISCOUNT_BY_BOOKING_FORM.success:
      const BookingData = state.allBooking;
      const indexBooking = state.allBooking.findIndex(({ id }) => Number(id) === action.payload.data.bookingId);
      if (indexBooking !== -1) {
        BookingData[indexBooking] = {
          ...BookingData[indexBooking],
          price: action.payload.data.bookingPrice
        };
      };

      return {
        ...state,
        bookingDiscount: action.payload.data,
        allBooking: BookingData
      }
    case EDIT_DISCOUNT_BY_BOOKING_FORM.error:
      toast.error(action.payload.message)
      return {
        ...state,
      }

    case FETCH_DISCOUNT_BY_BOOKING.pending:
      return{
        ...state,
        actionForFetchDiscount: actionForState(state.action,'pending'),
      }
    case FETCH_DISCOUNT_BY_BOOKING.success:
      return{
        ...state,
        bookingDiscount: action.payload.items?action.payload.items[0]:state.bookingDiscount,
        actionForFetchDiscount: actionForState(state.action,'success'),
      }
    case FETCH_DISCOUNT_BY_BOOKING.error:
      toast.error(action.payload.message)
      return{
        ...state,
        actionForFetchDiscount: actionForState(state.action, 'error', action.payload.message)
      }
    case FETCH_BOOKING_BY_ID.pending:
      return{
        ...state,
        actionForFetchBooking: actionForState(state.action,'pending'),
      }
    case FETCH_BOOKING_BY_ID.success:
      const BookingArray = state.allBooking;
      const updateIndx = state.allBooking.findIndex(({ id }) => Number(id) === action.payload.booking.id);
      if (updateIndx !== -1) {
        BookingArray[updateIndx] = {...action.payload.booking,
          start_time:moment(`${action.payload.booking.arrive} 12:00`, "YYYY-MM-DD HH:mm"),
          end_time:moment(`${action.payload.booking.depart} 12:00`, "YYYY-MM-DD HH:mm"),
          group:action.payload.booking.rentalId,
          status:false,
          type:action.payload.booking.bookingType,
        };
      }
      return{
        ...state,
        allBooking: BookingArray,
        bookingById:action.payload.booking,
        actionForFetchBooking: actionForState(state.action,'success'),
      }
    case FETCH_BOOKING_BY_ID.error:
      toast.error(action.payload.message);
      return{
        ...state,
        actionForFetchBooking: actionForState(state.action,'error',action.payload.message)
      }
    case RESET_CHARGES:
      return{
        ...state,
        currentCharges: null,
        bookingDiscount: INITIAL_STATE.bookingDiscount
      }
    default:
      return state;
  }
}
