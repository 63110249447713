import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from "./ConfirmMessage.module.css";


function ConfirmMessage(props){
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDelete = () => {
    props.onConfirmAct();
    setTimeout(() => {
      setConfirmDelete(false);
    }, 500);
  };

  const closeModal = () => {
    props.onHide();
    setTimeout(() => {
      setConfirmDelete(false);
    }, 500);
  };

  return (
    <Modal
      show={props.show}
      onHide={closeModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className={styles.modalHeader}
        >
          {props.confirmHeader}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <p>{props.confirmBody}</p>
      </Modal.Body>
      <div className={styles.modalFooter}>
        {confirmDelete ? (
          <>
            <button
              onClick={handleDelete}
              className={`mr-3 ${styles.allBtn} ${styles.deleteBtn} ${styles.deleteConfirmBtn}`}
            >
              Confirm Delete
              <span>
                <i className={`fa fa-check-circle ${styles.deleteIcon}`} />
              </span>
            </button>
            <button
              onClick={closeModal}
              className={`${styles.allBtn} ${styles.cancelBtn} ${styles.cancelConfirmBtn}`}
            >
              Cancel
              <span>
                <i className={`fa fa-undo ${styles.cancelIcon}`}/>
              </span>
            </button>
          </>
        ) : (
          <>
            <button onClick={() => setConfirmDelete(true)} className={`${styles.allBtn} ${styles.deleteBtn}`}>
              Delete
            </button>
            <button onClick={closeModal} className={`${styles.allBtn} ${styles.cancelBtn}`}>
              Cancel
            </button>
          </>
        )}
      </div>
    </Modal>
  );
}

export default ConfirmMessage; 