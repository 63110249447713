import React, { useState } from 'react';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import { useResizeDetector } from "react-resize-detector";
import ReactTooltip from "react-tooltip";

import { Menu, Close } from '@material-ui/icons';

import styles from './topbar.module.css';
import logo from '../../../../icons/Logo_B_1000.png';
import address from '../../../../icons/address-book-regular.svg';
import help from '../../../../icons/question-circle-solid.svg';
import person from '../../../../icons/person-lines.svg';
import calendar from '../../../../icons/calendar-alt-regular.svg';
import settingIcon from '../../../../icons/cog-solid.svg';
import rentalB from '../../../../icons/building-solid.svg';
import rates from '../../../../icons/comment-dollar-solid.svg';
import hotel from '../../../../icons/inquiries-icon.svg';
import invoice from '../../../../icons/file-invoice-dollar-solid.svg';
import channel from '../../../../icons/channel management.svg';

import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../../../../general_redux/user/actions';
import {changeInternalRoute} from '../../../../general_redux/actions';


function TopBar() {
  const [showMenu, setShowMenu] = useState(false);
  
  const dispatch = useDispatch();
  
  const settings = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);
  const user = useSelector(({ user }) => user && user.user);
  
  const {width, ref} = useResizeDetector();

  const links = [
    { lable: 'Calendar',        img: calendar,       to: '/view/calendar',       path: '/calendar',      icon: 'fa fa-calendar',          display: true },
    { lable: 'Guests',          img: address,        to: '/view/guest',          path: '/guest',         icon: 'fa fa-address-book',      display: true },
    { lable: 'Reservations',    img: hotel,          to: '/view/inquiry',        path: '/inquiry',       icon: 'fa fa-list-alt',          display: true },
    { lable: 'Invoices',        img: invoice,        to: '/view/invoice',        path: '/invoice',       icon: 'fa fa-file',              display: true },
    { lable: 'Rentals',         img: rentalB,        to: '/view/rentals',        path: '/rentals',       icon: 'fa fa-home' },
    { lable: 'Rates',           img: rates,          to: '/view/rates',          path: '/rates',         icon: 'fa fa-tags' },
    { lable: 'Settings',        img: settingIcon,    to: '/view/settings',       path: '/settings',      icon: 'fa fa-cog' },
    { lable: 'Channels',        img: channel,        to: '/view/channel',        path: '/channel',       icon: 'fas fa-project-diagram',                         hidden: settings && settings.accountType === 'free' },
    { lable: 'User Settings',   img: settingIcon,    to: '/view/userSetting',    path: '/userSetting',   icon: 'fa fa-cog',               display: true },
    { lable: 'Help',            img: help,           to: '/view/help',           path: '/help',          icon: 'fa fa-help',              display: true },
    { lable: 'Log out',         img: '',             to: '/login',               path: '',               icon: '',                        display: true,         logOut: true },
  ];

  const logOut = () => {
    dispatch(logoutUser());
  };

  const toggleMenu = () => {
    let collapse = document.getElementById('navbar-collapse');
    if (showMenu) {
      collapse.classList.remove('show');
    } else {
      collapse.classList.add('show');
    }
    setShowMenu(!showMenu);
  };

  return (
    <>
      <Navbar ref={ref} collapseOnSelect expand="lg" variant="light" className={styles.topBarPanel}>
        <Navbar.Brand className={width <= 985 ? 'm-0 p-1 w-100' : 'm-0 p-1'}>
          <div className={`${styles.header_sm_menu}`}>
            <a href="/view">
              <img src={logo} className={styles.logoHeader} /> 
            </a>
            <div onClick={toggleMenu} className={styles.toggle}>
              { showMenu ?
                <Close className={styles.menuIcon}/>
                :
                <Menu className={styles.menuIcon}/>
              }
            </div>
          </div>
        </Navbar.Brand>
        <Navbar.Collapse id="navbar-collapse">
          <Nav className={`mr-auto ${styles.topLinkContainer}`}>
            <div className={styles.spacer}>
            </div>
            <>
              { links &&
                links.map((item, i) =>
                !item?.hidden ? (
                    <NavLink
                        to={item.to}
                        className={item?.display ? `${styles.topLink} ${styles.topLinkHide}` : styles.topLink}
                        key={`topMenu_${i}`}
                        activeClassName={styles.isActive}
                        onClick={ item?.logOut ? logOut : toggleMenu }
                    >
                      <div className={styles.linkcontainer}>
                        {(
                            <div className={styles.iconStyle}>
                              <i className={`${item.icon} ${styles.icon}`}/>
                            </div>
                        )}
                        <span className={`${styles.topLinkText} ${item?.logOut ? styles.topLinkTextLogOut : ''}`}>{item.lable}</span>
                      </div>
                    </NavLink>
                ):(
                    <div
                        className={`${item?.display ? styles.topLinkHide : styles.topLink} ${styles.disabledIcon}`}
                        key={`topMenu_${i}`}
                    >
                      <div className={styles.linkcontainer}>
                        {(
                            <>
                              <div className={styles.iconStyle} data-for="channelPlusIcon" data-tip>
                                <i className={`${item.icon} ${styles.icon} ${styles.disabledIcon}`} />
                              </div>
                              <ReactTooltip place="bottom" type="dark" id="channelPlusIcon" effect="solid" className={styles.toolTip}>
                                <span>
                                  Only available for Plus Plan users.
                                </span>
                              </ReactTooltip>
                            </>
                        )}
                        <span className={styles.topLinkText} style={{fontWeight:'bold',color:"#adacac"}}>{item.lable}</span>
                      </div>
                    </div>
                )
              )}
            </>
          </Nav>
          <Nav className={styles.navbar2}>
            <a href={"https://www.beehaz-resources.com/"} target={"_blank"} rel="noopener noreferrer">
              <img src={help} className={styles.imgStyle} alt="helpImage"/>
            </a>
            <img src={person} className={styles.imgStyle} alt="personImage"/>
            <div className={styles.dropdownBox}>
              <NavDropdown title={user.name} id="collapsible-nav-dropdown" className={styles.dropdownStyle}>
                <NavDropdown.Item id="settings" className={styles.dropDownBox2}>
                  <div className={styles.dropdownItemStyle}>
                    <Link
                        to="/view/userSetting"
                        className={styles.dropdownItemStyle}
                        onClick={(e)=>dispatch(changeInternalRoute('/userSetting'))}
                    >
                      User Settings
                    </Link>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Item
                    id="logout"
                    onClick={logOut}
                    className={styles.dropDownBox2}
                >
                  <div className={styles.dropdownItemStyle}>Logout</div>
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
export default TopBar;
