import React from 'react';
import { Modal } from 'react-bootstrap';
import GroupForm from './GroupForm';
import * as yup from 'yup';

import styles from '../../rentals.module.css';

function AddGroup(props) {
  const {value, onHide, onEdit} = props;

  const schemaGroup = yup.object({
    color: yup.string().required(),
    groupName: yup.string().required('Group name is a required field'),
  });

  const handleSubmit = (data) => {
    const payload = {
      color: data.color,
      groupName: data.groupName,
    };
    
    if (value) {
      payload['id'] = data.id;
      onEdit(payload);
    } else {
      onHide(payload);
    }
  };

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>
          { value?.id ? 
              'Edit group'
          :
              'Add group'
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.deleteModalBody}>
        <GroupForm schema={schemaGroup} handleSubmit={handleSubmit} initValue={value ? value : false} />
      </Modal.Body>
    </Modal>
  );
}
export default AddGroup;
