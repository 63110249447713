import React, { useEffect, useState } from 'react';
import Table from '../../../../../components/Table';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { deleteProperty, loadProperty } from '../../../../../general_redux/rentals/actions';
import Loading from '../../../../../components/loading';
import ConfirmMessage from '../../../../../components/Confirmation';

const ViewProperty = (props) => {
  const properties = useSelector(({ rentals }) => rentals && rentals.properties);
  const loading = useSelector(({ rentals }) => rentals && rentals.actionForProperty);
  const [deleteData, setDeleteData] = useState(null);
  const [confirmDel, setConfirmDel] = useState(false);

  const dispatch = useDispatch();

  const cols = [
    { lable: 'Name', key: 'name' },
    { lable: 'Colour', key: 'color' },
    { lable: 'Address', key: 'street'},
    { lable: 'Actions', key: '' },
  ];
  
  const deletePropertyData = (data) => {
    setDeleteData(data.id);
    setConfirmDel(true);
  };
  const ConfirmedDeleteProperty = () => {
    if (deleteData) {
      dispatch(deleteProperty(deleteData));
      setDeleteData(null);
    }
  };

  return (
    <div>
      <Loading loadingStatus={loading.loading} />
      <ConfirmMessage
        show={confirmDel}
        onHide={() => {
          setConfirmDel(false);
        }}
        confirmHeader={`Delete property`}
        confirmBody={`Deleting this property will delete all rentals, bookings, and
                      information linked to it. It is advisable that you transfer the
                      rentals to another property before proceeding. Are you sure you
                      want to proceed?`
                    }
        onConfirmAct={() => {
          ConfirmedDeleteProperty();
          setConfirmDel(false);
        }}
      />
      <Container fluid>
        <Row className="justify-content-left">
          <Col xs={12} md={8} lg={8} xl={6} className={`p-0 pt-2`}>
            <Table
              isViewProperty={true}
              cols={cols}
              rows={properties}
              onEdit={props.editProperty}
              onDelete={deletePropertyData}
              startKey={cols[0].key}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ViewProperty;