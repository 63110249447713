import React,{useEffect} from 'react';
import styles from './payment.module.css';
import {useDispatch} from "react-redux";

function CustomerSuccess(props){
    const dispatch=useDispatch();
    useEffect(()=>{
        props.history.replace('/view/calendar');
    },[])
    return(
        <>
            <div className={`container`}>
                <div className={`copy-container ${styles.center}`}>
                    <p>Payment success redirecting to invoices...</p>
                </div>
            </div>
        </>
    )
}

export default CustomerSuccess;