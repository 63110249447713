// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InvoiceHistoryItem_invoiceHistoryItem__1sG16 {\n    margin: 10px 0;\n    padding: 12px 6px 0 12px;\n    border-radius: 10px;\n    border: 2px solid rgb(190, 190, 190);\n}\n\n.InvoiceHistoryItem_invoiceHistoryButtons__2n5Rz {\n    grid-gap: 12px;\n    gap: 12px;\n}\n\n.InvoiceHistoryItem_invoiceHistoryItemText__Rcfyp {\n    width: 100%;\n    height: 155px;\n    overflow: auto;\n}\n\n.InvoiceHistoryItem_icon__65fxT {\n    font-size: 16px;\n    cursor: pointer;\n}", ""]);
// Exports
exports.locals = {
	"invoiceHistoryItem": "InvoiceHistoryItem_invoiceHistoryItem__1sG16",
	"invoiceHistoryButtons": "InvoiceHistoryItem_invoiceHistoryButtons__2n5Rz",
	"invoiceHistoryItemText": "InvoiceHistoryItem_invoiceHistoryItemText__Rcfyp",
	"icon": "InvoiceHistoryItem_icon__65fxT"
};
module.exports = exports;
