import React from 'react';
import { Modal } from 'react-bootstrap';

import styles from '../../rentals.module.css';


const NoPropertyModal = (props) => {
    
    const {
        show,
        onHide,
        openAddPropertyModal,
    } = props;

    return(
        <Modal
            show={show}
            onHide={onHide}
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter' className={styles.modalTitle}>
                    No property found
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p className='mb-3'>
                        A rental must belong to a property. Please create a property before adding a
                        rental.
                    </p>
                    
                    <p className='mb-3'>
                        Think about the property as the physical location (for instance, the building or
                        plot of land) and the rental as the rental object within the property (a room in the
                        case of hotels or an apartment or house in the case of vacation rentals, for
                        instance).
                    </p>
                    
                    <p className='mb-4 mb-md-3'>
                        After you add a property, you can add a rental belonging to that property.
                    </p>
                </div>
                <div className='mb-2 d-flex justify-content-center'>
                    <button
                        type='button'
                        className={styles.modalButton}
                        onClick={() => {
                            onHide();
                            openAddPropertyModal();
                        }}
                    >
                        Add property
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default NoPropertyModal;