import React,{useEffect} from 'react';
import styles from './payment.module.css';

function CustomerFailure(props){
    useEffect(()=>{
        props.history.replace('/view/calendar');
    },[])
    return(
        <>
            <div className={`container`}>
                <div className={`copy-container ${styles.center}`}>
                    <p>Payment Failure redirecting to main page..</p>
                </div>
            </div>
        </>
    )
}

export default CustomerFailure;