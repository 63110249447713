import React from 'react';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import { Modal } from 'react-bootstrap';
import RateForm from './RateForm'
import * as yup from 'yup';
import {setMultipleRate} from '../../../../../../../general_redux/rates/actions';
import styles from "../../../../Rentals/rentals.module.css";

import { toast } from "react-toastify";
toast.configure();

function AddRate({show, onHide}) {
  const dispatch=useDispatch();

  const checkBoxWarning = () => {
    toast.warning("Please select the checkbox of the field you wish to edit/add. It is mandatory to select at least one.");
    return false;
  };

  const schemaRate = yup.object({
    multiRentals: yup.array().of(yup.number()).test("multiRentals", "Rental selection is required", value => value && value.length > 0),
    dateFrom: yup.date().required("Date from is a required field"),
    dateTo: yup.date().required("Date until is a required field"),
    rate: yup.number().test("rate", "Must be a positive number", function(value) { return ((this.parent.checkRate && value >= 0) || (!this.parent.checkRate)) ? true : false }).typeError("Must be a number value"),
    minStay: yup.number().test("minStay", "Must be an integer greater than or equal to 1", function(value) { return ((this.parent.checkMinStay && value >= 1) || (!this.parent.checkMinStay)) ? true : false }).integer('Must be an integer number').typeError("Must be a number value"),
    weekDays: yup.array().test("weekDays", "At least 1 week day must be selected", value => value && value.length > 0),
    checkRate: yup.boolean().test("checkRate", "",  function(value) { return !this.parent.checkMinStay && !value ? false : true }),
    checkMinStay: yup.boolean().test("checkMinStay", "",  function(value) { return !this.parent.checkRate && !value ? false : true }),
    flag: yup.string().test("flag", "",  function() { return !this.parent.checkRate && !this.parent.checkMinStay ? checkBoxWarning() : true }),
  });

  const checkWeekDays=(weekDay, weekDayList)=>{
    return weekDayList.some(day => day === weekDay);
  };
  
  const getFlag = (data) => {
    return (data.checkRate && data.checkMinStay) && 'rate&&minStay'
    || data.checkRate && 'rate'
    || data.checkMinStay && 'minStay'
    || undefined;
  };

  const handleSubmit = (data) => {
    const dateTo = moment(data.dateTo).format('YYYY-MM-DD');
    const dateFrom = moment(data.dateFrom).format('YYYY-MM-DD');

    let appDate=[];
    let payload=[];

    let diff = moment(dateTo).diff(moment(dateFrom),'days');

    for (let i = 0; i <= diff ; i++) {
      const tmpDate = moment(dateFrom).add(i,'days');
      if(checkWeekDays(tmpDate.format('ddd'), data.weekDays)){
        appDate.push(tmpDate.format('YYYY-MM-DD'));
      }
    };

    data.multiRentals.forEach((rentalId)=>{
      appDate.forEach((dayEntry)=>{
        payload.push({
          rentalId: rentalId,
          changeDate: dayEntry,
          dailyRate: data?.rate ? Number(data.rate) : 0,
          minimumStayRequirement: data?.minStay ? Number(data.minStay) : 1,
          flag: getFlag(data),
        })
      })
    });

    if (payload.length) {
      // console.log(payload);
      dispatch(setMultipleRate(payload));
      onHide();
    } else {
      toast.warning('No changes could be detected. Check the dates or days of the week before proceeding.');
    };
  };

  return (
    <Modal 
      show = {show}
      onHide = {onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>Set rate</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.deleteModalBody}>
        <RateForm
          schema = {schemaRate}
          handleSubmit = {handleSubmit}
          onHide = {onHide}
        />
      </Modal.Body>
    </Modal>
  );
}

export default AddRate;
