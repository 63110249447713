import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import styles from '../../../Rates/rates.module.css';
import Select from 'react-select';

function ChannelMapping(props){
    
    const options = [
        {label: 'Agoda', value: '189'},
        {label: 'Airbnb', value: '244'},
        {label: 'Booking.com', value: '19'},
        {label: 'VRBO (HomeAway)', value: '253'},
    ];

    const [selected, setSelected] = useState({ label: 'Select Channel', value: '' });

    useEffect(()=>{
        if(!props.show){
            setSelected({label: 'Select Channel', value: ''});
        }
    },[props.show])


    return(
        <>
            <Modal
                show={props.show}
                size='md'
                onHide={props.onHide}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter' className={styles.modalTitle}>Channel mapping</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div>
                        <p>
                            some explanatory message here...
                        </p>
                        <Form>
                            <Form.Row>
                                <Form.Label className={`col-md-7 col-lg-4 col-form-label ${styles.form_label}`}>Select Channel*: </Form.Label>
                                <div className={'col-md-5 col-lg-6'}>
                                    <Select
                                        options={options}
                                        value={selected}
                                        onChange={(val)=>setSelected(val)}
                                        className={styles.form_label}
                                    />
                                </div>
                            </Form.Row>
                            <div className='p-2 mt-4 d-flex justify-content-center'>
                                <button
                                    type='submit'
                                    className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`}
                                    onClick={()=>props.onSelection(selected)}
                                    disabled={(selected && selected.value==='')}
                                >Continue</button>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChannelMapping;