import React, { useState, useEffect, useRef } from "react";

import CheckboxList from './CheckboxList';
import styles from './SearchableSelect.module.css';


function SearchableSelect(props) {

  const {
    items,
    selectedItems,
    setSelectedItems,
  } = props;

  const inputRef = useRef(null);
  const sectionRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleClickOutside = (event) => {
    if (inputRef.current && inputRef.current.contains(event.target)) {
      setDropdownVisible(true);
    } else if (sectionRef.current && sectionRef.current.contains(event.target)) {
      setDropdownVisible(true);
    } else {
      setDropdownVisible(false);
      setIsFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredItems = items
    .filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => a.label.localeCompare(b.label));

  const inputStyle = { cursor: isHovered && !isFocused ? "pointer" : "text" };
  
  const mainStyle = {
    ...props?.style,
    position: "relative",
  };


  return (
    <main style={mainStyle} className={props?.className}>
      <div className="d-flex">
        <input
          ref={inputRef}
          type="text"
          value={isDropdownVisible ? searchTerm : props?.placeholder}
          placeholder={props?.placeholder}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={(e) => {
            setIsFocused(true);
            setDropdownVisible(true);
            setSearchTerm("");
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={inputStyle}
          className={styles.input}
        />
        {isDropdownVisible ? (
          <i className={`fa fa-caret-up ${styles.icon}`} />
        ) : (
          <i className={`fa fa-caret-down ${styles.icon}`} />
        )}
      </div>
      {filteredItems.length > 0 && isDropdownVisible && (
        <section
          ref={sectionRef}
          className={styles.section}
        >
          <CheckboxList
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            items={filteredItems}
          />
        </section>
      )}
    </main>
  );
};

export default SearchableSelect;
