import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Popover } from "@material-ui/core";
import { Formik } from 'formik';


import styles from './DeleteConfirm.module.css';


function DeleteConfirm (props) {

    return (
        <div>
            <Popover
                className={styles.modalContainer}
                id={props.id}
                open={props.open}
                onClose={props.onClose}
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        borderRadius: 0
                    }
                }}
            >
            
            <Formik>
                <section className={styles.modalBody}>
                    <div className={styles.containerText}>
                        Warning: This is a channel booking. Deleting this reservation might not delete it from the originating channel.
                        Consider deleting from the source.
                    </div>
                    <div className={styles.containerButton}>
                        <button
                            type="submit"
                            onClick={props.onConfirmAct}
                            className={`mr-3 ${styles.deleteBtn}`}
                        >
                            Confirm Delete
                            <span>
                                <i className={`fa fa-check-circle ${styles.confirmIcon}`} />
                            </span>
                        </button>
                        <button
                            onClick={props.onClose}
                            className={`${styles.cancelBtn}`}
                        >
                            Cancel
                            <span>
                                <i className={`fa fa-undo ${styles.confirmIcon}`}/>
                            </span>
                        </button>
                    </div>
                </section>
            </Formik>
            
            <Box className={styles.bottomArrow}/>

            </Popover>
        </div>
    );
};

export default DeleteConfirm;