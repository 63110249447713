import React from 'react';
import {Modal} from 'react-bootstrap';
import * as yup from 'yup';
import { withFormik } from 'formik';
import { useSelector } from "react-redux";

import PropertyMultipleForm from "./PropertyMultipleForm";
import styles from '../../rentals.module.css';


function EditMultipleProperties(props){

    const user = useSelector(({user})=> user && user.user);
    
    const handleSubmit = (data) => {

        const payloadMapper = {
            id: data.id,
            street: data.street,
            city: data.city,
            postalCode: data.postalCode,
            country: data.country,
            currency: data.currency,
            latitude: data.latitude,
            longitude: data.longitude,
            timezone: data.timezone,
            licenseNumber: data.licenseNumber,
            checkInTimeAt: data.checkInTimeAt,
            checkOutTimeAt: data.checkOutTimeAt,
            checkInTime:data.checkInTime,
            checkOutTime:data.checkOutTime,
            contactName: data.contactName,
            phoneNumber: data.phoneNumber,
            email: data.email,
            color: data.color,
        };

        // setting 'check' equal to true/false for fields that do not have a checkBox.
        const addressAdded = data.checkCountry || data.checkCity;
        data['checkLatitude'] = addressAdded;
        data['checkLongitude'] = addressAdded;
        data['checkId'] = true;
        //

        const payload = {};

        Object.keys(payloadMapper).forEach((field)=> {

            const fieldValue = payloadMapper[field];
            const check = `check${field.charAt(0).toUpperCase()}${field.slice(1)}`;

            if (fieldValue !== undefined && data[check]) {
                if (['checkInTime', 'checkOutTime'].includes(field)) {
                    const renamedField = `check${field.charAt(5).toLowerCase()}${field.slice(6)}`;
                    payload[renamedField] = fieldValue;
                } else {
                    payload[field] = fieldValue;
                };
            };

        });
        
        // console.log(payload,'Edit Multiple Properties');
        props.onEdit(payload);

    };

    const initialValues = {
        street: "",
        city: "",
        postalCode: "",
        currency: user.currency,
        country: user.country,
        latitude: "",
        longitude: "",
        timezone: user.timezone || "",
        checkInTimeAt: "from",
        checkOutTimeAt: "until",
        checkInTime: "15:00",
        checkOutTime: "11:00",
        licenseNumber: "",
        contactName: "",
        phoneNumber: "",
        email: "",
        color: "#dcdcdc",
    };

    const FormikEnhancer = withFormik({
        validationSchema:yup.object({
            id: yup.array().of(yup.number()).test('minLength', 'Property selection is required', value => value && value.length > 0),
            street: yup.string().max(50, 'Must be less than 50 characters'),
            city: yup.string().max(50, 'Must be less than 50 characters'),
            postalCode: yup.string().max(20, 'Must be less than 20 characters'),
            licenseNumber: yup.string().max(250, 'Must be less than 250 characters'),
            checkInTime: yup.string().test('checkInTime', 'Check-in Time is required', function(value) {
                return this.parent.checkCheckInTime ? value : true;
            }),
            checkOutTime: yup.string().test('checkOutTime', 'Check-out Time is required', function(value) {
                return this.parent.checkCheckOutTime ? value : true;
            }),
            contactName: yup.string().max(50, 'Must be less than 50 characters'),
            phoneNumber: yup.string().max(50, 'Must be less than 50 characters'),
            email: yup.string().email('Incorrect email format').max(100, 'Must be less than 100 characters'),
        }),
        validateOnBlur:false,
        validateOnChange:false,
        mapPropsToValues: () => (initialValues),
        handleSubmit: (values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
        },
        displayName: 'PropertyMultipleForm',
    })(PropertyMultipleForm);

    return(
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>
                    Edit multiple properties
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.deleteModalBody}>
                <FormikEnhancer />
            </Modal.Body>
        </Modal>
    )
}
export default EditMultipleProperties;