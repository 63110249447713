import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { setRateAdjustment } from '../../../../../../general_redux/rates/actions';

import ChannelAdjustment from './ChannelAdjustment';
import styles from '../../../Rentals/rentals.module.css';

function BulkChannelAdjustment(props) {
  const dispatch = useDispatch();

  const handleSubmit = (data) => {
    const payload = {
      percentage: Number(data.percentage),
    };
    dispatch(setRateAdjustment(payload));
    props.onHide();
  };
  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>Set rate adjustment </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.deleteModalBody}>
        <ChannelAdjustment
          handleSubmit={handleSubmit}
          onHide={props.onHide}
        />
      </Modal.Body>
    </Modal>
  );
}
export default BulkChannelAdjustment;
