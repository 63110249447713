// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".buttonstyles_modalButton__QunPR{\n    background-color: #439a86 ;\n    color:#fff;\n    border-radius: 6px ;\n    padding:7px 12px;\n    margin-top:4px;\n    align-items: center;\n    border: #2e6f61;\n    width: 240px;\n}\n@media(max-width: 700px) {\n    .buttonstyles_modalButton__QunPR{\n        width: 190px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"modalButton": "buttonstyles_modalButton__QunPR"
};
module.exports = exports;
