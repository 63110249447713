const GROUP = 'group/';
const RENTAL = 'rental/';
const BOOKING = 'booking/';
const INQUIRY = 'inquiry/';
const GUEST = 'guest/';
const CUSTOMER = 'customer/';
const RATE = 'rate/';
const FEE = 'fee/';
const TAX='tax/';
const SUBSCRIBERS = 'subscribers/';
const INVOICE='invoice/';
const PAYMENT='payment/';
const GET_ICAL='get_feed_map/'
const PROPERTY = 'property/'
const LOGIN = `${CUSTOMER}login`;
const OAUTH_LOGIN = `${CUSTOMER}oauth/login`;
const OAUTH_LOGIN_CALLBACK = `${CUSTOMER}login_callback`;
const REGISTER = `${CUSTOMER}register`;
const ADD_USER_SETTINGS = `${CUSTOMER}customerSettings`;
const LOGINBYAUTH = `${CUSTOMER}login`;
const GROUPADD = `${GROUP}`;
const GROUPLIST = `${GROUP}`;
const GROUPDELETE = `${GROUP}`;
const RENTALDELETE = `${RENTAL}`;
const GUEST_ADD = `${GUEST}`;
const EDIT_GROUP = `${GROUP}`;
const RENTAL_LIST = `${RENTAL}`;
const RENTAL_ADD = `${RENTAL}`;
const EDIT_RENTAL = `${RENTAL}`;
const ADD_BOOKING = `${BOOKING}`;
const EDIT_BOOKING = `${BOOKING}`;
const EDIT_BOOKING_COLOR = `${BOOKING}color`;
const CHARGES_CALC=`${BOOKING}charges`;
const GNRL_SETTINGS = `${CUSTOMER}generalSettings`;
const CUSTOMER_SETTINGS = `${CUSTOMER}customerInfo`;
const ADD_GUEST_BY_BOOKING = `${GUEST}addGuestByBookingId`;
const GET_GUEST_BY_BOOKING = `${GUEST}getGuestByBookingId`;
const INVOICE_PATH=`${INVOICE}`;
const GUEST_INVOICE=`${INVOICE}getInvoiceByGuestId/`;
const DEFAULT_BULK = `${RATE}multiple`;
const SPECIAL_RATE=`${RATE}changeRate`;
const DISCOUNT = `${BOOKING}discount`;
const FEES_TAXES =`${BOOKING}feeTaxes`
const CONTACT = `contact/`;
const CHANGEDRATE_TIMELINE=`${RATE}changeRateGetDate`;
const FETCH_CAL=`calander/cal`;
const PAYMENT_SESSION=`${PAYMENT}create-checkout-session`;
const PAYMENT_CONNECT_CREATE=`${PAYMENT}create_user`;
const PAYMENT_SUBSCRIPTION = `${PAYMENT}start_subscription`;
const CUSTOMER_PAYMENT=`${PAYMENT}do_checkout`;
const WORLD_API = `world`;
const RESET_NEWUSER = `${CUSTOMER}newuser`;
const MARK_SENT = `${INVOICE}mark_sent`;
const INVOICE_FILTER = `${INVOICE}filter`;
const SEND_INVOICE_NW = `${INVOICE}send_invoice`;
const SCHEDULE_INVOICE = `${INVOICE}schedule_invoice`;
const CANCEL_INVOICE = `${INVOICE}cancel_schedule_invoice`;
const WEEKLE_FIXED_RATE = `weekly-fixed-rate/`;
const WEEKLE_FIXED_FEE = `weekly-fixed-fee/`;
const WEEKLE_FIXED_TAX = `weekly-fixed-tax/`;
const MONTHLY_FIXED_RATE = `monthly-fixed-rate/`;
const MONTHLY_FIXED_FEE = `monthly-fixed-fee/`;
const MONTHLY_FIXED_TAX = `monthly-fixed-tax/`;
const GET_COORDINATES = `${PROPERTY}coordinates`;
const ICAL_CHANNEL = `ical-channel/`;
const SET_RATE_ADJUSTMENT = `channel-rates/`;
const GET_RATE_ADJUSTMENT = `channel-rates/`;
const CONNECT_PROPERTY_TO_SU = `${PROPERTY}connect-property-to-ota`;
const CHANNEL_MAPPING = `${PROPERTY}channel-mapping`;


const METHOD = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
};

const APIURL = {
  LOGIN,
  REGISTER,
  CUSTOMER,
  OAUTH_LOGIN,
  OAUTH_LOGIN_CALLBACK,
  LOGINBYAUTH,
  GROUP,
  RENTAL,
  BOOKING,
  GUEST,
  TAX,
  FEE,
  RATE,
  INQUIRY,
  PAYMENT,
  DISCOUNT,
  FEES_TAXES,
  CONTACT,
  GROUPADD,
  GROUPLIST,
  GROUPDELETE,
  METHOD,
  GUEST_ADD,
  RENTAL_LIST,
  RENTAL_ADD,
  RENTALDELETE,
  EDIT_RENTAL,
  ADD_BOOKING,
  EDIT_GROUP,
  EDIT_BOOKING,
  EDIT_BOOKING_COLOR,
  WORLD_API,
  GNRL_SETTINGS,
  CUSTOMER_SETTINGS,
  ADD_GUEST_BY_BOOKING,
  ADD_USER_SETTINGS,
  GET_GUEST_BY_BOOKING,
  SUBSCRIBERS,
  DEFAULT_BULK,
  CHARGES_CALC,
  INVOICE_PATH,
  GUEST_INVOICE,
  SPECIAL_RATE,
  FETCH_CAL,
  CHANGEDRATE_TIMELINE,
  PAYMENT_SESSION,
  PAYMENT_CONNECT_CREATE,
  CUSTOMER_PAYMENT,
  GET_ICAL,
  RESET_NEWUSER,
  MARK_SENT,
  INVOICE_FILTER,
  SEND_INVOICE_NW,
  SCHEDULE_INVOICE,
  PAYMENT_SUBSCRIPTION,
  CANCEL_INVOICE,
  WEEKLE_FIXED_RATE,
  WEEKLE_FIXED_FEE,
  WEEKLE_FIXED_TAX,
  MONTHLY_FIXED_RATE,
  MONTHLY_FIXED_FEE,
  MONTHLY_FIXED_TAX,
  PROPERTY,
  GET_COORDINATES,
  ICAL_CHANNEL,
  SET_RATE_ADJUSTMENT,
  GET_RATE_ADJUSTMENT,
  CONNECT_PROPERTY_TO_SU,
  CHANNEL_MAPPING,
};

export default APIURL;
