import React,{useEffect} from 'react';
import styles from './payment.module.css';

function FailurePayment(props){
    useEffect(()=>{
        props.history.replace('/view/userSetting');
    },[])
    return(
        <>
            <div className={`container`}>
                <div className={`copy-container ${styles.center}`}>
                    <p>Payment failure redirecting to main page...</p>
                </div>
            </div>
        </>
    )
}

export default FailurePayment;