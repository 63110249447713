import {
    LOAD_INQUIRIES,
    DELETE_INQUIRIES, DOWNLOAD_HISTORIC_DATA,
} from './types' ;
import {BACKEND_API} from "../../config/middleware";
import API from "../../config/api-url";

export const LoadInquiries = (data) => ({
  type: BACKEND_API,
  payload: Object.assign(
      {
          type:LOAD_INQUIRIES,
          method: 'post',
          url: API.INQUIRY,
          data
      },
  )
});

export const DeleteInquiry=(data)=>({
    type:BACKEND_API,
    payload: Object.assign(
        {
            type:DELETE_INQUIRIES,
            method:'delete',
            url:`${API.INQUIRY}`,
            data
        },
    )
})

export const downloadHistoricData=(data)=>({
    type:BACKEND_API,
    payload: Object.assign(
        {
            type:DOWNLOAD_HISTORIC_DATA,
            method:'post',
            url:`${API.INQUIRY}downloadExcel`,
            data
        }
    )
})
