import React, { useState } from "react";
import Table from "../../../../../components/Table/TableViewRentals";
import { Container, Row, Col } from "react-bootstrap";
import styles from "../rentals.module.css";
import { useSelector, useDispatch } from "react-redux";
import ConfirmDelete from "./ConfirmDelete/ConfirmDelete";
import ConfirmMessage from "../../../../../components/Confirmation";
import { deleteRental } from "../../../../../general_redux/rentals/actions";
import Loading from "../../../../../components/loading";

function ViewRentals(props) {
  const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
  const groups = useSelector(({ rentals }) => rentals && rentals.groups);
  const property = useSelector(({ rentals }) => rentals && rentals.properties);
  const loading = useSelector(
    ({ rentals }) => rentals && rentals.actionForRental
  );

  const [confirmDel, setConfirmDel] = useState(false);
  const [isParentRental, setIsParentRental] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const dispatch = useDispatch();
  const cols = [
    { lable: "Name", key: "name" },
    { lable: "Property", key: "propertyName" },
    { lable: "Group", key: "groupName" },
    { lable: "Max. guests", key: "maxOccup" },
    { lable: "Actions", key: "" },
  ];

  const deleteRentalData = (deleteChildrenRentals = false) => {
    const payload = {
      id: deleteData,
      deleteChildrenRentals: deleteChildrenRentals,
    };
    dispatch(deleteRental(payload));
    setConfirmDel(false);
    setDeleteData(null);
  };

  const rentalToTable = (value) => {
    return value || "Not found.";
  };

  function getGroupNameById(groups, groupId) {
    const group = groups.find((grp) => Number(grp.id) === Number(groupId));
    return group?.groupName === "Default"
      ? "-"
      : group?.groupName || "Not found.";
  }

  function getPropertyNameById(properties, propertyId) {
    const property = properties.find((prop) => prop.id === propertyId);
    return property?.name || "Not found.";
  }

  function getUnique(data, key) {
    return [...new Map(data.map((item) => [item[key], item])).values()];
  }

  const filledRentals = rentals.map((rental) => ({
    ...rental,
    name: rentalToTable(rental.name),
    propertyName: getPropertyNameById(property, rental.propertyId),
    groupName: getGroupNameById(groups, rental.groupId),
    maxOccup: rentalToTable(rental.maxOccup),
  }));

  const getChildren = (rentalId) => {
    const hasChild = rentals.find(
      (rental) => rental.parentRentalId === rentalId
    );
    return hasChild ? true : false;
  };

  return (
    <div>
      <Loading loadingStatus={loading.loadiyng} />
      <Container fluid>
        <Row className="justify-content-left">
          <Col className={`p-0 pt-2 ${styles.outerTable}`}>
            <Table
              isViewRentals={true}
              rows={getUnique(filledRentals, "id")}
              cols={cols}
              onEdit={props.editRental}
              onDelete={(data) => {
                setConfirmDel(true);
                setDeleteData(data.id);
                const hasChildren = getChildren(data.id);
                if (hasChildren) {
                  setIsParentRental(true);
                } else {
                  setIsParentRental(false);
                }
              }}
              startKey={cols[0].key}
            />
          </Col>
        </Row>
      </Container>
      <ConfirmMessage
        show={confirmDel && !isParentRental}
        onHide={() => {
          setConfirmDel(false);
          setDeleteData(null);
        }}
        confirmHeader={`Delete rental`}
        confirmBody={`Deleting this rental will delete all bookings and information linked to it. This action cannot be undone. Are you sure you wish to proceed?`}
        onConfirmAct={() => deleteRentalData()}
      />
      <ConfirmDelete
        show={confirmDel && isParentRental}
        onHide={() => {
          setConfirmDel(false);
          setDeleteData(null);
        }}
        title={`Delete rental`}
        firstMessage={`Deleting this rental will delete all bookings and information linked to it. This action cannot be undone. Are you sure you wish to proceed?`}
        secondMessage={`This rental is a parent rental. Please choose if you would like to delete the  parent rental only or you would like to delete it and it’s children as well. If you  only delete this rental, the children will become regular rentals (with no  parent).`}
        onConfirmAct={(e) => deleteRentalData(e)}
      />
    </div>
  );
}

export default ViewRentals;
