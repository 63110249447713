// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CustomDate_form_entry__2PXDf{\n    border-radius: 5px !important;\n    border: 1px solid #d1ceda;\n    width: 100% !important;\n    padding-left: 5px;\n    height: 35px;\n    font-size: 13px !important;\n}\n.CustomDate_form_entry_error__MMWCe{\n    border: 1px solid red;\n    border-radius: 5px !important;\n    width: 100% !important;\n    padding-left: 5px;\n    height: 35px;\n    font-size: 13px !important;\n}\n/* .today{\n    background: #439a86;\n} */\n.CustomDate_css-nxo287-MuiInputBase-input-MuiOutlinedInput-input__XYKhb{\n    padding: 5px !important;\n}", ""]);
// Exports
exports.locals = {
	"form_entry": "CustomDate_form_entry__2PXDf",
	"form_entry_error": "CustomDate_form_entry_error__MMWCe",
	"css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": "CustomDate_css-nxo287-MuiInputBase-input-MuiOutlinedInput-input__XYKhb"
};
module.exports = exports;
