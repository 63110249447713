// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ConfirmMessage_modalHeader__2Z4-J {\n  font-size: 1.4em !important;\n}\n\n.ConfirmMessage_modalBody__1YyUy {\n  font-size: 1em;\n}\n\n.ConfirmMessage_modalFooter__3Ym1S {\n  margin: 0.7em 0em;\n  align-items: center;\n  text-align: center;\n  padding: 0.5em;\n}\n\n.ConfirmMessage_actionIcon__3dgod {\n  font-size: 13px !important;\n  margin-left: 7px;\n}\n\n.ConfirmMessage_cancelIcon__3QEZ_ {\n  font-size: 12px !important;;\n  margin-left: 7px;\n}\n\n.ConfirmMessage_allBtn__Gl2Ld {\n  color: white;\n  padding: 0.5em 1.5em;\n  border: 0px;\n  font-size: 1em;\n  margin-right: 1em;\n  border-radius: 5px;\n}\n\n.ConfirmMessage_cancelBtn__WAjUB {\n  background-color: #177e66;\n}\n\n.ConfirmMessage_cancelBtn__WAjUB:hover {\n  background-color:#61aa99;\n}\n\n.ConfirmMessage_cancelConfirmBtn__325YK {\n  padding: 0.5em 0.8em;\n}\n\n.ConfirmMessage_actionBtn__eTXpm {\n  background-color: #dc3545 ;\n}\n\n.ConfirmMessage_actionBtn__eTXpm:hover {\n  background-color: #cd0027;\n}\n\n.ConfirmMessage_actionConfirmBtn__AyKWU {\n  padding: 0.5em 0.8em;\n}", ""]);
// Exports
exports.locals = {
	"modalHeader": "ConfirmMessage_modalHeader__2Z4-J",
	"modalBody": "ConfirmMessage_modalBody__1YyUy",
	"modalFooter": "ConfirmMessage_modalFooter__3Ym1S",
	"actionIcon": "ConfirmMessage_actionIcon__3dgod",
	"cancelIcon": "ConfirmMessage_cancelIcon__3QEZ_",
	"allBtn": "ConfirmMessage_allBtn__Gl2Ld",
	"cancelBtn": "ConfirmMessage_cancelBtn__WAjUB",
	"cancelConfirmBtn": "ConfirmMessage_cancelConfirmBtn__325YK",
	"actionBtn": "ConfirmMessage_actionBtn__eTXpm",
	"actionConfirmBtn": "ConfirmMessage_actionConfirmBtn__AyKWU"
};
module.exports = exports;
