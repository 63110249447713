// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/*body {*/\n/*    padding-top: 1em;*/\n/*}*/\n.cookieConsent_cookieDisclaimer__1VvI_ {\n    background: black;\n    color: #FFF;\n    opacity: 0.9;\n    width: 100%;\n    bottom: 0;\n    left: 0;\n    z-index: 10000;\n    /*height: 150px;*/\n    position: fixed;\n    height: auto;\n    padding-bottom: 20px;\n}\n.cookieConsent_cookieDisclaimer__1VvI_ .cookieConsent_container__317_u {\n    text-align: center;\n    padding-top: 20px;\n    padding-bottom: 20px;\n}\n\n.cookieConsent_cookieDisclaimer__1VvI_ .cookieConsent_cookieClose__124XD{\n    float: right;\n    padding: 10px;\n    cursor: pointer;\n}\n\n.cookieConsent_declineCookie__3K9cK{\n    background-color: darkgrey;\n    color:#ffffff;\n    border:0px transparent;\n    padding: 0.6em 1em;\n    font-size: 0.9em;\n}\n.cookieConsent_acceptCookie__3K1nW{\n    background-color: #439a86;\n    color:#ffffff;\n    border:0px transparent;\n    padding: 0.6em 1em;\n    font-size: 0.9em;\n}\n.cookieConsent_btnContainer__2G01r{\n    display: flex;\n    justify-content: space-between;\n}\n@media screen and (max-width: 990px) {\n    .cookieConsent_btnContainer__2G01r{\n        display: flex;\n        justify-content: flex-start;\n    }\n    .cookieConsent_declineCookie__3K9cK{\n        margin-right: 6px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"cookieDisclaimer": "cookieConsent_cookieDisclaimer__1VvI_",
	"container": "cookieConsent_container__317_u",
	"cookieClose": "cookieConsent_cookieClose__124XD",
	"declineCookie": "cookieConsent_declineCookie__3K9cK",
	"acceptCookie": "cookieConsent_acceptCookie__3K1nW",
	"btnContainer": "cookieConsent_btnContainer__2G01r"
};
module.exports = exports;
