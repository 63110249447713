import React from 'react';
import { Popover, Box } from '@material-ui/core';
import styles from './ConfirmDelete.module.css';

const ConfirmDelete = (props) => {

    const {
        popoverId,
        open,
        anchorEl,
        onClose,
        onConfirmAct,
        title,
    } = props;

    return (
        <Popover
            id={popoverId}
            open={open}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "center",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "center",
                horizontal: "right",
            }}
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: 0,
                    display: "flex",
                },
            }}
        >
            <div className={styles.modalContainer}>
                <div className={styles.modalTitle}>
                    {title}
                </div>
                <div className="text-center m-1">
                    <button
                        onClick={onConfirmAct}
                        className={styles.deleteBtn}
                    >
                        Delete
                    </button>
                    <button
                        onClick={onClose}
                        className={styles.cancelBtn}
                    >
                        Cancel
                    </button>
                </div>
            </div>
            <Box
                className={styles.rightArrow}
            />
        </Popover>
    );
};

export default ConfirmDelete;
