const asyncActionType = type => ({
    pending: `${type}_PENDING`,
    success: `${type}_SUCCESS`,
    error: `${type}_ERROR`
});

export const GET_INVOICES=asyncActionType('GET_INVOICES');
export const ADD_INVOICE = asyncActionType('ADD_INVOICE');
export const DELETE_INVOICE=asyncActionType('DELETE_INVOICE');
export const EDIT_INVOICE_BY_BOOKING = asyncActionType('EDIT_INVOICE_BY_BOOKING');
export const EDIT_INVOICE_BY_GUEST = asyncActionType('EDIT_INVOICE_BY_GUEST');
export const EDIT_INVOICE_LIST = asyncActionType('EDIT_INVOICE_LIST');
export const INVOICE_BY_BOOKING = asyncActionType('INVOICE_BY_BOOKING');
export const INVOICE_BY_GUEST= asyncActionType('INVOICE_BY_GUEST');
export const CLEAR_INVOICE='CLEAR_INVOICE';
export const DELETE_MULTIPLE_INVOICE=asyncActionType('DELETE_MULTIPLE_INVOICE');
export const GET_FILTER_INVOICES=asyncActionType('GET_FILTER_INVOICES');
export const MARK_INVOICE = asyncActionType('MARK_INVOICE');
export const SEND_INVOICE = asyncActionType('SEND_INVOICE');
export const GET_SEND_INVOICE = asyncActionType('GET_SEND_INVOICE');
export const DELETE_SEND_INVOICE = asyncActionType('DELETE_SEND_INVOICE');
export const SCHEDULE_INVOICE = asyncActionType('SCHEDULE_INVOICE');
export const GET_SCHEDULED_INVOICE= asyncActionType('GET_SCHEDULED_INVOICE');
export const CANCEL_SCHEDULED_INVOICE = asyncActionType('CANCEL_SCHEDULED_INVOICE');
export const RESET_INVOICE_ACTIONS = 'RESET_INVOICE_ACTIONS';