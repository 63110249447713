// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".table_icon__3LcOk {\n  cursor: pointer;\n}\n.table_iconText__23BWK {\n  cursor: pointer;\n}\n.table_iconText__23BWK:hover {\n  text-decoration: underline;\n}\n\n.table_mainTable__1VMBF tbody tr td:first-child span{\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  width: 180px;\n  display: block;\n}\n\n@media only screen and (max-width:575px){\n  .table_mainTable__1VMBF tbody tr td span , .table_mainTable__1VMBF thead tr th{\n    font-size: 12px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"icon": "table_icon__3LcOk",
	"iconText": "table_iconText__23BWK",
	"mainTable": "table_mainTable__1VMBF"
};
module.exports = exports;
