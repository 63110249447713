import React,{useEffect} from 'react';
import {authRelogin, oauthLoginUserCallback} from '../../general_redux/user/actions';
import {useDispatch,useSelector} from 'react-redux';
import styles from "../Verify/verify.module.css";
import {Link} from "react-router-dom";

function OauthLogin(props) {
    let code = new URLSearchParams(props.location.search).get("code");
    let state = new URLSearchParams(props.location.search).get("state");
    let authFailed = useSelector(({user}) => user && user.authFail);
    const dispatch = useDispatch();
    useEffect(()=>{
        console.log("Oauth compoennet rendered:",code,state);
        dispatch(oauthLoginUserCallback(code, state));
    },[])

    const handleReLogin=(e)=>{
        e.preventDefault();
        dispatch(authRelogin());
        props.history.push("/login");
    }

    return (
        <div className={`container`}>
            <div className={`copy-container ${styles.center}`}>
                {!authFailed ?(
                    <p>Loading....</p>
                ):(
                    <p>
                        Authentication error please click
                        <Link
                            onClick={(e)=>handleReLogin(e)}
                        >
                            {` here`}</Link> to login again!
                    </p>
                )}
                <span className={`handle`}/>
            </div>
        </div>
    )
}
export default OauthLogin;