import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Form, Field } from 'formik';
import { Row, Col } from 'react-bootstrap';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";
import moment from 'moment';

import { chargeCalculation } from "../../../../../../../general_redux/calendar/actions";
import { zeroPad } from '../../../../../../../common/functions/utils';
import CustomDate from "../../../../../../../components/CustomDate";
import info_icon from '../../../../../../../icons/info_icon_squared.svg';
import styles from '../editBooking.module.css';


const BookingDetails = (props) => {

  const {
    ota,
    getChannelOptions,
    certainTypesOfReservationsWarning,
    errors,
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = props;

  const dispatch = useDispatch();

  const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
  const bookingData = useSelector(({ calendar }) => calendar && calendar.bookingById);
  const guestDetails = useSelector(({ guests }) => guests && guests?.guestByBooking?.[0]);
  const bookingDiscount = useSelector(({ calendar }) => calendar && calendar.bookingDiscount);
  const currentCharges = useSelector(({ calendar }) => calendar && calendar.currentCharges);
  const ratesList = useSelector(({rates}) => rates && rates.rateSettings);
  const channels = useSelector(({ icalChannels }) => icalChannels && getChannelOptions(icalChannels.channels));
  const { numberOf, allowBookingFor } = useSelector(({ generalSetting }) => generalSetting && generalSetting.setting);

  const colorDictionary = useSelector(({ generalSetting }) => {
    const colorsSetting = generalSetting && generalSetting.setting.colors;
    const map = [];
    for (const key in colorsSetting) {
      if (key.startsWith('color')) {
        map.push(colorsSetting[key]);
      };
    };
    return map;
  });

  const [disableSubmit, setDisableSubmit] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(0);

  const findRentalDetail = (rentalId) => {
    const findRental = rentals.find((rental) => Number(rental.id) === Number(rentalId));
    return findRental || { id: '', maxOccup: 1, maxChild: 0, checkinTime: '', checkoutTime: '' };
  };

  const findRateDetail = (rentalId) => {
    const findRate = ratesList.find((rate) => Number(rate.rentalId) === Number(rentalId));
    return findRate || { minimumStayRequirement: 10, maximumStayRequirement: 1 };
  };

  const guestName = guestDetails ? guestDetails.name : bookingData?.guestName;
  const paymentOpt = ['Payment Pending', 'Partially Paid', 'Paid'];
  const rentalSelector = rentals.map((row) => ({ id: row.id, name: row.name }));

  useEffect(() => {
    
    const rateDetail = findRateDetail(values.rentalId);
    setFieldValue('minStay', rateDetail.minimumStayRequirement);
    setFieldValue('maxStay', rateDetail.maximumStayRequirement);

  }, [])

  useEffect(() => {

    if (values.arrive && values.depart && values.rentalId && values.totalGuests && (values.adult > 0) && bookingDiscount.chargeAmount !== undefined && bookingDiscount.discountAmount !== undefined) {

      if (shouldFetch > 0) {

        dispatch(chargeCalculation({
          arrive: moment(values.arrive).format().slice(0, 10),
          depart: moment(values.depart).format().slice(0, 10),
          noOfAdults: Number(values.adult),
          noOfChildren: Number(values.children),
          rentalId: Number(values.rentalId),
          extraCharge: Number(bookingDiscount.chargeAmount),
          discount: Number(bookingDiscount.discountAmount),
          bookingType: bookingData.bookingType,
        }))

        setFieldValue('nights', moment(values.depart).diff(moment(values.arrive), 'days'));
        setDisableSubmit(true);

      };

      setShouldFetch(value => value + 1);

    };

  }, [values.arrive, values.depart, values.rentalId, values.totalGuests])

  useEffect(() => {

    if (currentCharges) {

      if(currentCharges && !currentCharges.maxStay){
        setFieldValue('maxStay', currentCharges.maxStayConstrain);
      };
  
      if(currentCharges && !currentCharges.minStay){
        setFieldValue('minStay', currentCharges.minStayConstrain);
      };
      
      setFieldValue('finalPrice', currentCharges.total);
      setDisableSubmit(false);
    };

  }, [currentCharges])

  const payloads = {
    bookingPayload: {
      id: bookingData.id,
      bookingType: bookingData.bookingType,
      title: values.title,
      rentalId: Number(values.rentalId),
      noOfGuests: Number(values.totalGuests),
      noOfAdults: Number(values.adult),
      noOfChildren: Number(values.children),
      noOfBabies: Number(values.babies),
      arrive: moment(values.arrive).utc(true).format("YYYY-MM-DD"),
      depart: moment(values.depart).utc(true).format("YYYY-MM-DD"),
      arriveUTC : moment(values.arrive).utc(true).format("YYYY-MM-DD HH:mm:ss"),
      departUTC : moment(values.depart).utc(true).format("YYYY-MM-DD HH:mm:ss"),
      checkInTime: values.checkInTime,
      checkOutTime: values.checkOutTime,
      nights: values.nights,
      status: values.status,
      source: values.source,
      tax: Number(Number(currentCharges?.taxes || bookingDiscount.tax).toFixed(2)),
      fees: Number(Number(currentCharges?.fees || bookingDiscount.fee).toFixed(2)),
      extraGuestFee: Number(Number(currentCharges?.extra_guest_fees || bookingDiscount.extraGuestFee).toFixed(2)),
      baseRate: Number(Number(currentCharges?.daily_rate || bookingDiscount.baseRate).toFixed(2)),
      discountName: bookingDiscount.discountName,
      discountAmount: Number(Number(bookingDiscount.discountAmount).toFixed(2)),
      chargeName: bookingDiscount.chargeName,
      chargeAmount: Number(Number(bookingDiscount.chargeAmount).toFixed(2)),
      price: Number(Number(values.finalPrice).toFixed(2)),
      paymentStatus: values.paymentStatus,
      miniNotes: values.miniNotes,
      notes: values.notes,
      color: values.color,
    },
    guestPayload: {
      id: guestDetails?.id ?? '',
      guestType: 'guest',
      name: guestDetails?.name ?? '',
      emailId: guestDetails?.emailId ?? '',
      secondaryEmailId: guestDetails?.secondaryEmailId  ?? '',
      phoneNo: guestDetails?.phoneNo ?? '',
      secondaryPhoneNo: guestDetails?.secondaryPhoneNo ?? '',
      street: guestDetails?.street ?? '',
      address: guestDetails?.address ?? '',
      state: guestDetails?.state ?? '',
      country: guestDetails?.country ?? '',
      postalCode: guestDetails?.postalCode ?? '',
      company: guestDetails?.company ?? '',
      dob: guestDetails?.dob ?? '',
      language: guestDetails?.language ?? '',
      nationality: guestDetails?.nationality ?? '',
      notes: values.moreInfo ?? '',
    },
    discountPayload: {
      bookingId: bookingData.id,
      rentalId: Number(values.rentalId),
      discountName: bookingDiscount.discountName,
      discountAmount: Number(Number(bookingDiscount.discountAmount).toFixed(2)),
      chargeName: bookingDiscount.chargeName,
      chargeAmount: Number(Number(bookingDiscount.chargeAmount).toFixed(2)),
      tax: Number(Number(currentCharges?.taxes || bookingDiscount.tax).toFixed(2)),
      fees: Number(Number(currentCharges?.fees || bookingDiscount.fee).toFixed(2)),
      extraGuestFee: Number(Number(currentCharges?.extra_guest_fees || bookingDiscount.extraGuestFee).toFixed(2)),
      baseRate: Number(Number(currentCharges?.daily_rate || bookingDiscount.baseRate).toFixed(2)),
      totalPrice: Number(currentCharges?.total || bookingDiscount?.totalPrice),
      bookingPrice: Number(currentCharges?.total || values.finalPrice),
      nights: Number(values.nights),
      noOfGuests: Number(values.adult) + Number(values.children),
    }
  };


  return (
    <Form onSubmit={(e) => {
        setFieldValue('payloads', payloads);
        handleSubmit(e);
      }}>
      <div className="col-12">
        {['weekly', 'monthly'].includes(bookingData.bookingType) && (
          <div className="mb-3 d-flex align-content-center">
            <div className={styles.warningIconContainer}>
              <img src={info_icon} alt="Info Icon Squared" className={styles.warningIconOne} />
            </div>
            <div>
              {`This is a fixed-term reservation. ${bookingData.bookingType === 'weekly' ? 'Weekly' : 'Monthly'} pricing is being used.`}
              <InfoOutlinedIcon
                fontSize="small"
                color="inherit"
                data-tip
                data-for="fixedTerm"
                className={styles.toolTipIcon}
              />
              <ReactTooltip place="bottom" type="dark" id="fixedTerm" effect="solid" className={styles.toolTip}>
                <span>
                  <p>
                    Fixed-term reservations use special rate settings, which you might have set up differently than your nightly rates. If you make any changes to this reservation, the fixed nightly rate will be used to calculate the extra nights.
                  </p>
                  <p>
                    If you plan on changing the length of stay substantially in this fixed-term booking, consider the effect on your pricing. You can correct unintended effects by overwriting the price under the payment tab or making a standard nightly reservation instead.
                  </p>
                </span>
              </ReactTooltip>
            </div>
          </div>
        )}
      </div>
      <div className={styles.mainContainer}>
        <div className={`col-lg-6 col-md-12 px-xs-1`}>
          <div className="pb-2">
            {bookingData.bookingType === 'channelBooking' ?
              <>
                <div className={styles.subHeader}>{`${(ota && guestName) ? `${ota} ${guestName}` : `${ota} Guest`}: Reserved`}</div>
                <div className={styles.defaultFont}>{guestDetails?.emailId}</div>
                <div className={styles.defaultFont}>{bookingData?.reservationLink && `Reservation link: ${bookingData.reservationLink}`}</div>
              </>
              :
              <>
                <div className={styles.subHeader}>{guestDetails?.name}</div>
                <div className={styles.defaultFont}>{guestDetails?.emailId}</div>
                <div className={styles.defaultFont}>{guestDetails?.phoneNo}</div>
              </>
            }
          </div>
        </div>
        <div className={`col-lg-6 col-md-12`}>
          <div className="mb-3">
            <div className={`${styles.smallHeader} font-weight-normal`}>More info
              <InfoOutlinedIcon
                fontSize="small"
                color="inherit"
                data-tip
                data-for="moreInfo"
                className={styles.toolTipIcon}
              />
              <ReactTooltip place="bottom" type="dark" id="moreInfo" effect="solid" className={styles.toolTip}>
                <span>
                  Write more information specific to your guests here. These can be retrieved later if the guest makes another reservation.
                </span>
              </ReactTooltip>
            </div>
            <Field
              type={'text'}
              name={'moreInfo'}
              onChange={handleChange}
              value={values.moreInfo}
              className={`form-control ${styles.form_entry} w-100`}
            />
          </div>
        </div>
      </div>
      <hr className="m-0 mb-3" />
      <div className={styles.mainContainer}>
        <div className={`col-lg-6 col-md-12 px-xs-1`}>
          <div className="pb-2">
            <div className={styles.subHeader}> Booking </div>
            <div className={styles.defaultFont}>{`Booking Number: ${new Date().getUTCFullYear()}${zeroPad(bookingData.id, 4)}`}</div>
          </div>
          <Row className={`${styles.form_wrapper} m-0`} key={'selectRental'}>
            <div className={styles.form_title}>{'Select rental: '}</div>
            <div className={styles.field_width}>
              <Field
                as={'select'}
                name={'rentalId'}
                value={values.rentalId}
                onChange={(e) => {
                  const currentRental = findRentalDetail(e.target.value);
                  const rateDetail = findRateDetail(e.target.value);
                  setFieldValue('rentalId', currentRental.id);
                  setFieldValue('checkInTime', currentRental.checkinTime);
                  setFieldValue('checkOutTime', currentRental.checkoutTime);
                  setFieldValue('maxOccup', currentRental.maxOccup);
                  setFieldValue('maxChild', currentRental.maxChild);
                  setFieldValue('minStay', rateDetail.minimumStayRequirement);
                  setFieldValue('maxStay', rateDetail.maximumStayRequirement);
                  certainTypesOfReservationsWarning();
                }}
                className={errors.rentalId ? styles.form_entry_err : styles.form_entry}
              >
                {rentalSelector.map((opt) => (
                  <option key={opt.id} value={opt.id} >
                    {opt.name}
                  </option>
                ))}
              </Field>
              <div className={errors.rentalId ? styles.form_error : styles.form_error_hidden}>{errors.rentalId}</div>
            </div>
          </Row>
          <Row className={`${styles.form_wrapper} m-0`} key={'totalGuests'}>
            <div className={styles.form_title}>{'Number of guests: '}</div>
            <div className={styles.field_width}>
              <Field
                type={'text'}
                name={'totalGuests'}
                value={values.totalGuests}
                disabled={true}
                className={errors.totalGuests ? styles.form_entry_err : styles.form_entry}
              />
              <div className={errors.totalGuests ? styles.form_error : styles.form_error_hidden}>
                {errors.totalGuests}
              </div>
            </div>
          </Row>
          <Row className={`${styles.form_wrapper} m-0`} key={'adult'}>
            <div className={`${styles.form_title}`}>{'Adults: '}</div>
            <div className={styles.field_width}>
              <Field
                type={'text'}
                name={'adult'}
                value={values.adult}
                onChange={(e) => {
                  const adultValue = e.target.value;
                  setFieldValue('adult', adultValue);
                  setFieldValue('totalGuests', Number(adultValue) + Number(values.children));
                }}
                className={errors.adult ? styles.form_entry_err : styles.form_entry}
              />
              <div className={errors.adult ? styles.form_error : styles.form_error_hidden}>
                {errors.adult}
              </div>
            </div>
          </Row>
          <Row className={`${styles.form_wrapper} m-0`} key={'children'}>
            <div className={`${styles.form_title}`}>{'Children: '}</div>
            <div className={styles.field_width}>
              <Field
                type={'text'}
                name={'children'}
                value={values.children}
                onChange={(e) => {
                  const childrenValue = e.target.value;
                  setFieldValue('children', childrenValue);
                  setFieldValue('totalGuests', Number(childrenValue) + Number(values.adult));
                }}
                className={errors.children ? styles.form_entry_err : styles.form_entry}
              />
              <div className={errors.children ? styles.form_error : styles.form_error_hidden}>
                {errors.children}
              </div>
            </div>
          </Row>
          <Row className={`${styles.form_wrapper} m-0`} key={'babies'}>
            <div className={`${styles.form_title}`}>{'Babies: '}
              <InfoOutlinedIcon
                fontSize="small"
                color="inherit"
                data-tip
                data-for="babies"
                className={styles.toolTipIcon}
              />
              <ReactTooltip place="bottom" type="dark" id="babies" effect="solid" className={styles.toolTip}>
                <span>
                  Babies are not counted in the number of total guests (which consists of the total number of adults + the total number of children). This means they do not have any effect on the price calculation (while the number of adults and children influence the price when you have per person fees in your pricing).
                </span>
              </ReactTooltip>
            </div>
            <div className={styles.field_width}>
              <Field
                type={'text'}
                name={'babies'}
                value={values.babies}
                onChange={handleChange}
                className={errors.babies ? styles.form_entry_err : styles.form_entry}
              />
              <div className={errors.babies ? styles.form_error : styles.form_error_hidden}>
                {errors.babies}
              </div>
            </div>
          </Row>
          <Row className={`${styles.form_wrapper} m-0`} key={'arrive'}>
            <Col className="p-sm-0 pl-0 col-7 d-sm-flex align-items-center">
              <div className={`${styles.form_title_half} ${styles.checkTitleWidth}`}>Check-in: </div>
              <div className={styles.calendarWidth}>
                <CustomDate
                  id={'editBookingArrive'}
                  name={'arrive'}
                  value={values.arrive}
                  hasError={errors.arrive}
                  // onBlur={certainTypesOfReservationsWarning}
                  handleChange={(date) => {
                    setFieldValue('arrive', date);
                  }}
                  max={values.depart ? values.depart : new Date(`${moment().add(numberOf, allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                />
                <div className={errors.arrive ? styles.form_error : styles.form_error_hidden}>
                  {errors.arrive}
                </div>
              </div>
            </Col>
            <Col className={`p-0 col-5 mr-0 d-sm-flex align-items-center justify-content-md-end justify-content-lg-center ${styles.checkWrap}`}>
              <div className={`${styles.form_title_half} ${styles.atWidth}`}>from: </div>
              <Field
                type={'time'}
                name={'checkInTime'}
                value={values.checkInTime}
                onChange={handleChange}
                onBlur={certainTypesOfReservationsWarning}
                className={errors.checkInTime ? `${styles.form_entry_half_err} ${styles.atEntryWidth}` : `${styles.form_entry_half} ${styles.atEntryWidth}`}
              />
            </Col>
          </Row>
          <Row className={`${styles.form_wrapper} m-0`} key={'depart'}>
            <Col className="p-sm-0 pl-0 col-7 d-sm-flex align-items-center">
              <div className={`${styles.form_title_half} ${styles.checkTitleWidth}`}>Check-out: </div>
              <div className={styles.calendarWidth}>
                <CustomDate
                  id={'editBookingDepart'}
                  name={'depart'}
                  value={values.depart}
                  hasError={errors.depart}
                  // onBlur={certainTypesOfReservationsWarning}
                  handleChange={(date) => {
                    certainTypesOfReservationsWarning();
                    setFieldValue('depart', date);
                  }}
                  max={new Date(`${moment().add(numberOf, allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                  min={values.arrive}
                />
                <div className={errors.depart ? styles.form_error : styles.form_error_hidden}>
                  {errors.depart}
                </div>
              </div>
            </Col>
            <Col className={`p-0 col-5 mr-0 d-sm-flex align-items-center justify-content-md-end justify-content-lg-center ${styles.checkWrap}`}>
              <div className={`${styles.form_title_half} ${styles.atWidth}`}>until: </div>
              <Field
                type={'time'}
                name={'checkOutTime'}
                value={values.checkOutTime}
                onChange={handleChange}
                onBlur={certainTypesOfReservationsWarning}
                className={errors.checkOutTime ? `${styles.form_entry_half_err} ${styles.atEntryWidth}` : `${styles.form_entry_half} ${styles.atEntryWidth}`}
              />
            </Col>
          </Row>
          <Row className={`${styles.form_wrapper} m-0`} key={'nights'}>
            <div className={styles.form_title}>Total nights: </div>
            <div className={styles.field_width}>
              <Field
                type={'text'}
                name={'nights'}
                value={values.nights}
                disabled={true}
                className={errors.nights ? styles.form_entry_err : styles.form_entry}
              />
              <div className={errors.nights ? styles.form_error : styles.form_error_hidden}>
                {errors.nights}
              </div>
            </div>
          </Row>
          <Row className={`${styles.form_wrapper} m-0`} key={'source'}>
            <div className={styles.form_title}>{'Source: '}</div>
            <Field
              as={'select'}
              name={'source'}
              value={values.source}
              onChange={handleChange}
              className={errors.source ? styles.form_entry_err : styles.form_entry}
            >
              {channels.map((opt, index) => (
                <option key={index} value={opt.value} >
                  {opt.label}
                </option>
              ))}
            </Field>
          </Row>
          <hr className="my-1" />
          <div className="mb-2">
            <div className={styles.subHeader}>Payment
              <InfoOutlinedIcon
                fontSize="small"
                color="inherit"
                data-tip
                data-for="paymentIcon"
                className={styles.toolTipIcon}
              />
              <ReactTooltip place="bottom" type="dark" id="paymentIcon" effect="solid" className={styles.toolTip}>
                <span>
                  The total includes rates, extra guest fees, fees, and taxes. Any discount or charge you have set
                  are also considered here. You can change this total under the Payments tab.
                </span>
              </ReactTooltip>
            </div>
          </div>
          <Row className={`${styles.form_wrapper} d-flex justify-content-between m-0`} key={'finalPrice'}>
            <div className="d-flex align-items-center ">
              <div className={`${styles.form_title_half} ${styles.paymentTitleWidth}`}>Total: </div>
              <div className={styles.totalEntryWidth}>
                {bookingData.bookingType === 'channelBooking' ?
                  '-'
                  :
                  <Field
                    type={'text'}
                    name={'finalPrice'}
                    className={
                      errors.finalPrice
                        ? `${styles.form_entry_half_err} ${styles.totalEntryWidth}`
                        : `${styles.form_entry_half} ${styles.totalEntryWidth}`
                    }
                    value={Number(values.finalPrice).toFixed(2)}
                    disabled={true}
                  />
                }
                <div className={errors.finalPrice ? styles.form_error : styles.form_error_hidden}>
                  {errors.finalPrice}
                </div>
              </div>
            </div>
            <div className={`d-flex align-items-center mt-3 mt-md-0 ${styles.paymentStatus}`}>
              <div className={`${styles.form_title_half} ${styles.paymentTitleWidth}`}>Status: </div>
              <Field
                as={'select'}
                name={'paymentStatus'}
                value={values.paymentStatus}
                onChange={handleChange}
                className={
                  errors.paymentStatus
                    ? styles.form_entry_err
                    : `${styles.form_entry_half} ${styles.statusEntryWidth}`
                }
              >
                {paymentOpt.map((opt) => (
                  <option value={opt} key={opt}>
                    {opt}
                  </option>
                ))}
              </Field>
            </div>
          </Row>
        </div>
        <div className={`col-lg-6 col-md-12`}>
          <hr className="my-1 d-lg-none" />
          <div>
            <div className="mt-sm-3 mt-md-0">
              <div className={`${styles.subHeader} font-weight-normal`}>Mini notes
                <InfoOutlinedIcon
                  fontSize="small"
                  color="inherit"
                  data-tip
                  data-for="miniNotes"
                  className={styles.toolTipIcon}
                />
                <ReactTooltip place="bottom" type="dark" id="miniNotes" effect="solid" className={styles.toolTip}>
                  <span>
                    Write a short note to be displayed in the booking information summary box that appears when you click on a reservation in the calendar.
                  </span>
                </ReactTooltip>
              </div>
            </div>
            <Field
              type={"text"}
              name="miniNotes"
              value={values.miniNotes}
              onChange={handleChange}
              className={`form-control ${styles.form_entry} w-100`}
              style={{ marginBottom: '-5px' }}
            />
          </div><br />
          <div>
            <div className="mt-sm-3 mt-md-0">
              <div className={styles.subHeader}>Notes
                <InfoOutlinedIcon
                  fontSize="small"
                  color="inherit"
                  data-tip
                  data-for="notesIcon"
                  className={styles.toolTipIcon}
                />
                <ReactTooltip place="bottom" type="dark" id="notesIcon" effect="solid" className={styles.toolTip}>
                  <span>
                    Write longer notes specific to this booking here.
                  </span>
                </ReactTooltip>
              </div>
            </div>
            <textarea
              className={`form-control ${styles.text_area} `}
              rows="18"
              name="notes"
              value={values.notes}
              onChange={handleChange}
            />
          </div>
          <Row className={`d-block d-sm-flex ${styles.colorSection}`} key={'colour'}>
            <div className={`${styles.subHeader} ${styles.titleColor}`}>Colour</div>
            <div className={styles.containerColor}>
              {colorDictionary.map((item, index) => (
                <div
                  key={index}
                  onClick={() => setFieldValue('color', item)}
                  className={`${styles.containerBox} ${values.color === item ? 'active1' : ''}`}
                  style={{ background: `${item}` }}
                />
              ))}
              <Field
                type={'color'}
                name={'color'}
                value={values.color}
                onChange={handleChange}
                className={styles.form_entry_color}
              />
            </div>
          </Row>
        </div>
      </div>
      <div className={`d-flex justify-content-center ${styles.button_group}`}>
        <button
          type="submit"
          className={styles.settings_save}
          disabled={disableSubmit}
        >
          Save changes
        </button>
      </div>
    </Form>
  );
};

export default BookingDetails;
