import React, { useEffect, useState, useMemo, useRef } from 'react';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';
import styles from './ratesCalendar.module.css';
import {useDispatch,useSelector} from "react-redux";
import {Table} from 'react-bootstrap';
import ConfirmChange from "./ConfirmRateChange/ConfirmChange";
import SlideSelect from '../../../../../../components/Slide_select/Slide_select';
import {fetchRatesCal,setSpecialRate,clearCalendarAction} from '../../../../../../general_redux/rates/actions';
import AddRate from './AddRate/AddRate';
import Loading from '../../../../../../components/loading';
import tick from '../../../../../../icons/tick-yellow.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import { customSort } from '../../../../../../common/functions/utils';


function RatesCalendar(){

    const formRef = useRef(null);

    const dispatch = useDispatch();

    const rentals = useSelector(({rentals})=>rentals && rentals.rentals);
    const rateList = useSelector(({rates})=>rates && rates.rateSettings);
    const rateCalendar = useSelector(({rates})=>rates && rates.rateCalendar);
    const actionForMultipleRateSet=useSelector(({rates})=>rates && rates.actionForMultipleRateSet);
    const actionForRateCal=useSelector(({rates})=>rates && rates.actionForRateCal);
    const actionForSpecialRate=useSelector(({rates})=>rates && rates.actionForSpecialRate);

    const [showConfirm,setShowConfirm]=useState(false);
    const [parentKey, setParentKey] = useState(null);

    const [currentValue,setCurrentValue]=useState({
        value:0,
        date:'2021-06-04',
        rental:'Rental 1',
        dailyRate:0,
    });

    const Months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    
    const Years = (() => {
        const currentYear = new Date().getFullYear();
        const startYear = Number(currentYear) - 3;
        const endYear = Number(currentYear) + 3;
    
        return Array.from({ length: endYear - startYear + 1 }, (_, index) => String(startYear + index));
    })();
    
    const d=new Date();

    const findCurrency = (id)=>{
        const tmp= rentals.filter((rental)=>rental.id===Number(id))
        if(tmp.length!==0){
            return getSymbolFromCurrency(tmp[0].currency);
        }else{
            return "";
        }
    }
    const rateListOrdered = useMemo(() => {
        const rateListName = [];
        if(rentals && rentals.length!==0 && rateList && rateList.length!=0){
            rateList.map((r)=> {
                const rental = rentals.find(o => o.id === r.rentalId);
                if (rental) {
                    rateListName.push({...r, rentalName: rental.name, parentRentalId: rental.parentRentalId});
                };
            })
        }
        return customSort(rateListName, 'asc', 'rentalName', 'rentalId');
    }, [rateList])

    //selector
    const [currentDate,setCurrentDate] = useState(1);
    const [currentMonth,setCurrentMonth]=useState(d.getMonth())
    const [currentYear,setCurrentYear]=useState(Years.findIndex((val)=>val===String(d.getFullYear())));

    useEffect(()=>{
        if(actionForMultipleRateSet.success){
            const begin=`${Years[currentYear]}-${currentMonth+1}-${currentDate}`;
            const payload={
                startDate:begin,
                endDate:moment(begin,'YYYY-MM-DD').add(1,'month').format('YYYY-MM-DD'),
            }
            dispatch(fetchRatesCal(payload))
        }
    },[actionForMultipleRateSet])

    const currentTimeline = useMemo(()=>{
        if(actionForSpecialRate.success){}
        let tmp=[];
        const yr=Number(Years[currentYear]);
        tmp.push(moment(`${currentDate}-${currentMonth+1}-${yr}`,"DD-M-YYYY").format("DD MMM YYYY ddd"));
        const totDays=moment(`${currentDate}-${currentMonth+1}-${yr}`, "DD-M-YYYY").daysInMonth();
        for(let i=1;i<totDays;i++){
            const newDate= moment(`${currentDate}-${currentMonth+1}-${yr}`,"DD-M-YYYY").add(i,'days');
            tmp.push(newDate.format("DD MMM YYYY ddd"));
        }
        const begin=`${Years[currentYear]}-${currentMonth+1}-${currentDate}`;
        const payload={
            startDate:begin,
            endDate:moment(begin,'YYYY-MM-DD').add(1,'month').format('YYYY-MM-DD'),
        }
        dispatch(fetchRatesCal(payload));
        return tmp;
    },[currentMonth,currentYear])

    const onBlur=(e,date,rate,type)=>{
        if(type==="rate"){
            const minStayFind=rateCalendar[`${rate.rentalId}`]?
                (rateCalendar[`${rate.rentalId}`][`${moment(date,'DD MMM YYYY ddd').format('YYYY-MM-DD')}`]?
                    rateCalendar[`${rate.rentalId}`][`${moment(date,'DD MMM YYYY ddd').format('YYYY-MM-DD')}`]['minimumStayRequirement']
                    :rate.minimumStayRequirement):rate.minimumStayRequirement;
            const tmp=[{
                dailyRate:Number(Number(e.target.value).toFixed(2)),
                changeDate:moment(date,'DD MMM YYYY ddd').format('YYYY-MM-DD'),
                rentalId:rate.rentalId,
                minimumStayRequirement:minStayFind,
                flag:'rate'
            }];
            if(JSON.stringify(currentValue) !== JSON.stringify(tmp) ){
                setCurrentValue(tmp);
                setShowConfirm(true);
            }
        }else{
            const rateFind=rateCalendar[`${rate.rentalId}`]?
                (rateCalendar[`${rate.rentalId}`][`${moment(date,'DD MMM YYYY ddd').format('YYYY-MM-DD')}`]?
                    rateCalendar[`${rate.rentalId}`][`${moment(date,'DD MMM YYYY ddd').format('YYYY-MM-DD')}`]['rate']
                    :rate.dailyRate):rate.dailyRate;
            const tmp=[{
                dailyRate:rateFind,
                changeDate:moment(date,'DD MMM YYYY ddd').format('YYYY-MM-DD'),
                rentalId:rate.rentalId,
                minimumStayRequirement:Number(e.target.value),
                flag:'minStay'
            }];
            if(JSON.stringify(currentValue) !== JSON.stringify(tmp) ){
                setCurrentValue(tmp);
                setShowConfirm(true);
            }
        }
    }

    const handleReset = () => {
        formRef.current.reset();
    }

    const handleHide=()=>{
        setShowConfirm(false);
        handleReset();
    }

    const toggleChannelRates = (key, parentKey) => {
        if (parentKey === key) {
            setParentKey(null);
        } else {
            setParentKey(key);
        }
    };


    const handleConfirm=()=>{
        dispatch(setSpecialRate(currentValue));
        setShowConfirm(false);
    }

    const handleDiscard=()=>{
        const begin=`${Years[currentYear]}-${currentMonth+1}-${currentDate}`;
        const payload={
            startDate:begin,
            endDate:moment(begin,'YYYY-MM-DD').add(1,'month').format('YYYY-MM-DD'),
        }
        dispatch(fetchRatesCal(payload))
        handleHide();
    }

    //cleaning function
    useEffect(()=>{
        return()=>{
            dispatch(clearCalendarAction());
        }
    },[])

    const [addRateModal,setAddRateModal] = useState(false);
    return(
        <>
            <Loading loadingStatus={actionForRateCal.loading || actionForSpecialRate.loading}/>
            <AddRate
                show={addRateModal}
                onHide={() => setAddRateModal(false)}
            />
            <ConfirmChange
                show={showConfirm}
                onHide={handleHide}
                confirmHeader={`Sync updates`}
                confirmBody={`Sync changes to the calendar?`}
                value={currentValue}
                onConfirm={handleConfirm}
                onDiscard={handleHide}
                sticky-sectiononDiscard={handleDiscard}
            />

            <div className={`mt-sm-1 mb-sm-3 d-block d-sm-flex ${styles.calendarSetup}`}>
                <button
                    className={`${styles.allBtn}`}
                    onClick={() => setAddRateModal(true)}
                >
                    Set Rates
                </button>
                <div className='my-2 m-sm-0 d-flex'>
                    <SlideSelect list={Years} currentSelect={currentYear} onSelectData={(val)=>setCurrentYear(val)}/>
                    <SlideSelect list={Months} currentSelect={currentMonth} onSelectData={(val)=>setCurrentMonth(val)}/>
                </div>
            </div>
            {!actionForRateCal.loading && rateCalendar && rateListOrdered && rateListOrdered.length!==0 ?
            <form ref={formRef} action="">
            <Table responsive className={styles.table}>
                <thead className={styles.tableHead}>
                <tr className={styles.firstRow}>
                    <th className={styles.headRental}><p>Rentals</p></th>
                    {currentTimeline.length!==0 && currentTimeline.map((day)=>{
                        const locStr= day.split(" ");
                        return(
                            <th className={styles.dateDisplay} key={day}>
                                <div className={styles.dayHead}>{locStr[3]}</div>
                                <div className={styles.dateHead}>{locStr[0]}</div>
                                <div className={styles.dayHead}>{locStr[1]}</div>
                            </th>
                        )
                    })}
                </tr>
                </thead>
                <tbody >
                {!actionForRateCal.loading && rateCalendar && rateListOrdered && rateListOrdered.length!==0 && rateListOrdered.map((rate)=>(
                    <tr className={styles.secondRow} key={rate.rentalId}>

                        <td className={`${styles.secondData} ${parentKey === rate.rentalId ? `${styles.secondExpandMore}` : 'expandLess'}`}>
                            <div className={styles.rowWrapper}>
                                {rate.parentRentalId && 
                                    <SubdirectoryArrowRightIcon
                                        fontSize="small"
                                        style={{ color: "#9e9e9e" }}
                                    />
                                }
                                <span className={styles.rentalName}>{rate.rentalName}</span>
                                <span className={styles.flexDisp}>
                                    <p className={styles.nightlyRate}>
                                        Nightly Rate
                                    </p>
                                    <label className={styles.currencyStyles}>{findCurrency(rate.rentalId)}</label>
                                </span>
                                <section className={styles.sectionBtnArrowMore}>
                                    <p className={styles.minStay}>Min Stay(Nights)</p>
                                    { parentKey !== rate.rentalId
                                       ? <ExpandMoreIcon className={styles.btnArrowMore} onClick={() => toggleChannelRates(rate.rentalId, parentKey)}/>
                                       : <></>
                                    }
                                </section>
                            </div>
                            <section className={styles.sectionBtnArrowLess} style={{ display: parentKey === rate.rentalId  ? 'flex' : 'none' }}>
                                <p className={styles.channelRates}>Channel Rate</p>
                                <ExpandLessIcon className={styles.btnArrowLess} onClick={() => toggleChannelRates(rate.rentalId, parentKey)}/>
                            </section>
                            
                        </td>


                        {rateCalendar && currentTimeline.length!==0 && currentTimeline.map((day)=>(
                            <td key={day} className={styles.inputCell}>
                                <div className={styles.bookingBox}>
                                    {((rateCalendar[rate.rentalId]?
                                        rateCalendar[rate.rentalId][`${moment(day,'DD MMM YYYY ddd').format('YYYY-MM-DD')}`]?
                                            rateCalendar[rate.rentalId][`${moment(day,'DD MMM YYYY ddd').format('YYYY-MM-DD')}`]["booking"]:false:false) &&(
                                        <img src={tick} alt="booked" style={{height:"1.5em",width:"1.5em", color: "#dfdfdf"}}/>
                                    ))}
                                </div>
                                <div>
                                    {rateCalendar && (
                                        <input
                                            className={styles.rateInput}
                                            defaultValue={Number(
                                                rateCalendar[rate.rentalId] &&
                                                rateCalendar[rate.rentalId][`${moment(day,'DD MMM YYYY ddd').format('YYYY-MM-DD')}`] ?
                                                rateCalendar[rate.rentalId][`${moment(day,'DD MMM YYYY ddd').format('YYYY-MM-DD')}`]['rate']
                                                : rate.dailyRate
                                            ).toFixed(2)}
                                            onBlur={(e)=>onBlur(e,day,rate,"rate")}
                                        />
                                    )}
                                </div>
                                <div>
                                    {rateCalendar && (
                                        <input
                                            className={styles.rateInput}
                                            defaultValue={
                                                rateCalendar[rate.rentalId] &&
                                                rateCalendar[rate.rentalId][`${moment(day,'DD MMM YYYY ddd').format('YYYY-MM-DD')}`] ?
                                                rateCalendar[rate.rentalId][`${moment(day,'DD MMM YYYY ddd').format('YYYY-MM-DD')}`]['minimumStayRequirement']
                                                : rate.minimumStayRequirement
                                            }
                                            onBlur={(e)=>onBlur(e,day,rate,"minStay")}
                                        />
                                    )}
                                </div>
                                <div style={{ display: parentKey === rate.rentalId ? 'block' : 'none' }} >
                                    {rateCalendar && (
                                        <div className={styles.channelRateInput}>
                                            {
                                                Number(
                                                    rateCalendar[rate.rentalId] &&
                                                    rateCalendar[rate.rentalId][`${moment(day,'DD MMM YYYY ddd').format('YYYY-MM-DD')}`] ?
                                                    rateCalendar[rate.rentalId][`${moment(day,'DD MMM YYYY ddd').format('YYYY-MM-DD')}`]['channelRates']
                                                    : rate.channelRates
                                                ).toFixed(2)
                                            }
                                        </div>

                                    )}
                                </div>
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </Table>
            </form>
            :
            <h5>No data to display</h5>}
        </>
    )
}

export default RatesCalendar;