// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".confirmChange_customDialg__16rhh{\n    top:20%;\n    right:0\n}\n.confirmChange_deleteModalBody__YES9A {\n    font-size: 1em;\n}\n.confirmChange_modalHeader__1Dn5r{\n    font-size: 1.4em !important;\n}\n.confirmChange_modalFooter__3RBhN {\n    margin: 0.7em 0em;\n    align-items: center;\n    text-align: center;\n    padding: 0.5em;\n}\n.confirmChange_savBtn__135zI {\n    background-color: #439a86;\n    color: white;\n    padding: 0.5em 0.8em;\n    border: 0px;\n    font-size: 1em;\n    margin-right: 1em;\n    border-radius: 5px;\n}\n.confirmChange_delBtn__2E8x0{\n    background-color: #dc3545  !important;\n    color: white !important;\n    padding: 0.5em 0.8em !important;\n    border: 0px !important;\n    font-size: 1em !important;\n    border-radius: 5px !important;\n}\n.confirmChange_savBtn__135zI:hover {\n    background-color: #177e66;\n}\n.confirmChange_delBtn__2E8x0:hover {\n    background-color: #cd0027 !important;\n}", ""]);
// Exports
exports.locals = {
	"customDialg": "confirmChange_customDialg__16rhh",
	"deleteModalBody": "confirmChange_deleteModalBody__YES9A",
	"modalHeader": "confirmChange_modalHeader__1Dn5r",
	"modalFooter": "confirmChange_modalFooter__3RBhN",
	"savBtn": "confirmChange_savBtn__135zI",
	"delBtn": "confirmChange_delBtn__2E8x0"
};
module.exports = exports;
