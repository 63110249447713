// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sidebar_sidebar__2r7Od {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  height: calc(100vh - 69px) !important;\n  z-index: 100;\n  padding: 48px 0 0;\n  background-color: white !important;\n}\n.sidebar_sidebar_wrapper__2tBIR {\n  height: calc(100vh - 57px) !important;\n  width: 86px !important;\n  background-color: #fff !important;\n  border-right: 2px solid rgb(52, 52, 54);\n  z-index: 5;\n  position: relative;\n  top: -2px;\n}\n\n.sidebar_sidebar_heading__xC2iL {\n  padding: 0.875rem 1.25rem;\n  font-size: 0.8rem;\n}\n\n.sidebar_tawkChatContainer__bJEmU {\n  height: 100px;\n  width: 100%;\n}\n\n.sidebar_page_content_wrapper__2e360 {\n  min-width: 0;\n  width: 100%;\n  height: calc(100vh - 69px) !important;\n  overflow-y: auto;\n  padding-left: 0px !important;\n  padding-right: 0px !important;\n}\n\n.sidebar_sidebar_img__3pTAY {\n  height: 4em;\n  width: 4em;\n  padding: 0.5em 1.1em 0 1.1em;\n  align-items: center;\n}\n\n.sidebar_sidebar_link__3LyyD {\n  color: #555;\n  align-items: center;\n  font-size: 12px;\n  font-weight: 600;\n}\n\n.sidebar_linkMenu__3AEYk {\n  background-color: #fff !important;\n  text-decoration: none !important;\n  margin-bottom: 5px;\n  padding-top: 5px;\n}\n\n.sidebar_linkMenu__3AEYk:hover div,\n.sidebar_linkMenu__3AEYk:hover i {\n  color: #f5b60e !important;\n}\n.sidebar_isActive__19nHl i {\n  background-color: #dcdcdc !important;\n  color: rgb(52, 52, 54)!important;\n}\n\n.sidebar_isActive__19nHl {\n  background-color: #dcdcdc !important;\n}\n\n.sidebar_isActive__19nHl div {\n  background-color: #dcdcdc !important;\n  color: rgb(52, 52, 54) !important;\n}\n\n.sidebar_icons__1gc90 {\n  font-size: 18px !important;\n  color: #555;\n  margin: 3px;\n}\n\n@media (max-width: 600px) {\n  .sidebar_sidebar_wrapper__2tBIR {\n    display: none;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"sidebar": "sidebar_sidebar__2r7Od",
	"sidebar_wrapper": "sidebar_sidebar_wrapper__2tBIR",
	"sidebar_heading": "sidebar_sidebar_heading__xC2iL",
	"tawkChatContainer": "sidebar_tawkChatContainer__bJEmU",
	"page_content_wrapper": "sidebar_page_content_wrapper__2e360",
	"sidebar_img": "sidebar_sidebar_img__3pTAY",
	"sidebar_link": "sidebar_sidebar_link__3LyyD",
	"linkMenu": "sidebar_linkMenu__3AEYk",
	"isActive": "sidebar_isActive__19nHl",
	"icons": "sidebar_icons__1gc90"
};
module.exports = exports;
