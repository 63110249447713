import { toast } from 'react-toastify';
import {
  LOGIN_USER,
  OAUTH_LOGIN_USER,
  USER_TOKEN_VERIFY_SUCCESS,
  LOGOUT_USER,
  CLEAR_REGISTER_ACTION,
  REGISTER_USER,
  RESET_WELCOME,
  USER_TOKEN_ADD,
  USER_TOKEN_VERIFY_FAIL,
  ADD_USER_SETTINGS,
  GET_USER_INFO,
  DELETE_USER,
  RELOGIN_REQ,
  CONFIRM_MAIL_UPDATE,
  GET_PACKAGES,
  START_PAYMENT_SESSION,
  CREATE_USER_CONNECT,
  MAKE_CUSTOMER_PAYMENT,
  START_SUBSCRIPTION,
} from './types';
import { actionForState,setCookie } from '../../common/functions/utils';
toast.configure();

const INITIAL_STATE = {
  user: null,
  isNewUser: false,
  authToken: '',
  authFail:false,
  paymentPackages:null,
  paymentSession:[],
  connect:[],
  action: {
    loading: false,
    success: undefined,
  },
  loginAction: {
    success: false,
    loading: false,
    error: false,
  },
  oauthLoginAction: {
    success: false,
    redirect: false,
    error: false
  },
  registerAction: {
    success: false,
    loading: false,
    error: false,
  },
  useSettingLoad:{
    success: false,
    loading: false,
    error: false,
  },
  useSettingUpdate:{
    success: false,
    loading: false,
    error: false, 
  },
  deleteUserAction:{
    success: false,
    loading: false,
    error: false,
  },
  confirmMailAction:{
    success:false,
    loading:false,
    error:false,
  },
  paymentSessionAction:{
    success:false,
    loading:false,
    error:false,
  },
  actionForStripeConnect:{
    success:false,
    loading:false,
    error:false,
  },
  paymentCustomerAction:{
    success:false,
    loading:false,
    error:false,
  }
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case OAUTH_LOGIN_USER.redirect:
      window.location.href = action.payload.data.uri;
      return state;
    case OAUTH_LOGIN_USER.success:
      // redirection is necessary here
      window.location.href = window.location.origin + "/verify/" + action.payload.token;
      return state;
    case OAUTH_LOGIN_USER.error:
      toast.error(" This email address is not registered in our system. Please check the spelling or create an account")
      return{
        ...state,
        authFail:true,
      }
    case LOGIN_USER.pending:
      return {
        ...state,
        loginAction: actionForState(state.action, 'pending'),
      };
    case LOGIN_USER.success:
      toast.success('Login successful, please check your email!');
      return {
        ...state,
        loginAction: actionForState(state.action, 'success'),
      };
    case LOGIN_USER.error:
      toast.error(action.payload.message);
      return {
        ...state,
        loginAction: actionForState(state.action, 'error', action.payload.message),
      };
    case USER_TOKEN_VERIFY_SUCCESS:
      setCookie('BeehazToken', action.payload.token, 25);
      return {
        ...state,
        user: action.payload.user,
      };
    case USER_TOKEN_VERIFY_FAIL:
      return {
        ...state,
        user: null,
        authToken: null,
        authFail:true,
      };
    case RELOGIN_REQ:
      return{
        ...state,
        authFail: false,
      }
    case USER_TOKEN_ADD:
      return {
        ...state,
        authToken: action.payload
      };
    case REGISTER_USER.pending:
      return {
        ...state,
        registerAction: actionForState(state.action, 'pending'),
      };
    case REGISTER_USER.success:
      toast.success('Account created successfully! Please check your email to log in.');
      return {
        ...state,
        isNewUser: action.payload.newUser,
        user: action.payload.email,
        registerAction: actionForState(state.action, 'success'),
      };
    case REGISTER_USER.error:
      toast.error(action.payload.message);
      return {
        ...state,
        registerAction: actionForState(state.action, 'error', action.payload.message),
      };
    case CLEAR_REGISTER_ACTION:
      return {
        ...state,
        registerAction: INITIAL_STATE.registerAction,
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        isNewUser: false,
      };
    case RESET_WELCOME.pending:
      return {
        ...state,
      };
    case RESET_WELCOME.success:
      return{
        ...state,
        user: {
          ...state.user,
          isNewUser:action.payload.data.isNewUser
        },
      }
    case RESET_WELCOME.error:
      toast.error(action.payload.message);
      return{
        ...state,
      }
    case ADD_USER_SETTINGS.pending:
      return {
        ...state,
        useSettingUpdate: actionForState(state.action, 'pending'),
      };
    case ADD_USER_SETTINGS.success:
      toast.success('Successfully updated your settings!');
      if(action.payload.data?.customer.emailId !== state.user.emailId){
        toast.warning('Email change: please check your email to confirm the change.')
      }
      return {
        ...state,
        useSettingUpdate: actionForState(state.action, 'success'),
        user: {
          ...action.payload.data?.customer,
          colors: action.payload.data?.colors_setting,
        },
      };
    case ADD_USER_SETTINGS.error:
      toast.error(action.payload.message);
      return {
        ...state,
        useSettingUpdate: actionForState(state.action, 'error', action.payload.message),
      };

    case GET_USER_INFO.pending:
      return{
        ...state,
        useSettingLoad: actionForState(state.action,'pending'),
      } 
    case GET_USER_INFO.success:
      return {
        ...state,
        useSettingLoad: actionForState(state.action, 'success'),
        user: {
          ...action.payload.customer,
          colors: action.payload.colors_setting,
        },
      };
    case GET_USER_INFO.error:
      toast.error(action.payload.message)
      return{
        ...state,
        useSettingLoad: actionForState(state.action,'error',action.payload.message) 
      }
    case DELETE_USER.pending:
      return {
        ...state,
        deleteUserAction: actionForState(state.action,'pending')
      }
    case DELETE_USER.success:
      toast.success("Your account has been deleted successfully. We are sorry to see you go and wish you well!")
      return {
        ...state,
        user: null,
        isNewUser: false,
        deleteUserAction: actionForState(state.action,'success')
      }
    case DELETE_USER.error:
      toast.error('Could not delete the account please try again!')
      return{
        ...state,
        deleteUserAction: actionForState(state.action,'error')
      }
    case CONFIRM_MAIL_UPDATE.pending:
      return{
        ...state,
        confirmMailAction: actionForState(state.action,'pending')
      }
    case CONFIRM_MAIL_UPDATE.success:
      return{
        ...state,
        confirmMailAction: actionForState(state.action,'success')
      }
    case CONFIRM_MAIL_UPDATE.error:
      toast.error(action.payload.message);
      return{
        ...state,
        confirmMailAction: actionForState(state.action,'error')
      }
    case GET_PACKAGES.pending:
      return{
        ...state,
      }
    case GET_PACKAGES.success:
      return{
        ...state,
        paymentPackages: action.payload.packages
      }
    case GET_PACKAGES.error:
      toast.error(action.payload.message)
      return{
        ...state,
      }
    case START_PAYMENT_SESSION.pending:
      return{
        ...state,
        paymentSessionAction: actionForState(state.action,'pending'),
      }
    case START_PAYMENT_SESSION.success:
      window.location.href = action.payload.url;
      return{
        ...state,
        paymentSessionAction: actionForState(state.action,'success'),
        paymentSession: action.payload
      }
    case START_PAYMENT_SESSION.error:
      toast.error("Some error occurred please try again later!")
      return{
        ...state,
        paymentSessionAction: actionForState(state.action,'error'),
      }
    case CREATE_USER_CONNECT.pending:
      return{
        ...state,
        actionForStripeConnect: actionForState(state.action,'pending')
      }
    case CREATE_USER_CONNECT.success:
      window.location.href = action.payload.url;
      return{
        ...state,
        connect:action.payload,
        actionForStripeConnect: actionForState(state.action,'success')
      }
    case CREATE_USER_CONNECT.error:
      toast.error(action.payload.message)
      return{
        ...state,
        actionForStripeConnect: actionForState(state.action,'error',action.payload.message)
      }
    case MAKE_CUSTOMER_PAYMENT.pending:
      return{
        ...state,
        paymentCustomerAction: actionForState(state.action,'pending')
      }
    case MAKE_CUSTOMER_PAYMENT.success:
      window.location.href = action.payload.url 
      return{
        ...state,
        paymentCustomerAction: actionForState(state.action,'success')
      }
    case MAKE_CUSTOMER_PAYMENT.error:
      toast.error(action.payload.message)
      return{
        ...state,
        paymentCustomerAction: actionForState(state.action,'error',action.payload.message)
      }
    case START_SUBSCRIPTION.pending:
      return{
        ...state,
        paymentSessionAction: actionForState(state.action,'pending'),
      }
    case START_SUBSCRIPTION.success:
      window.location.href = action.payload.url;
      return{
        ...state,
        paymentSessionAction: actionForState(state.action,'success'),
        paymentSession: action.payload
      }
    case START_SUBSCRIPTION.error:
      toast.error("Some error occurred please try again later!")
      return{
        ...state,
        paymentSessionAction: actionForState(state.action,'error'),
      }  
    default:
      return state;
  }
}
