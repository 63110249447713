import React, { useEffect, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'formik';
import PhoneInput from 'react-phone-input-2';
import TimezoneSelect from 'react-timezone-select';
import countryList from "react-select-country-list";

import { getCoordinates, clearCoordinates } from '../../../../../../general_redux/rentals/actions';
import MultiSelect from "../../../Rates/components/BulkDefaultChanges/MultiSelect";
import { currencies } from "../../../../../../config/data/currencies";
import { findCountry } from '../../../../../../common/functions/utils';
import ModalButton from "../../../../../../components/ModalButton";
import styles from '../../rentals.module.css';
 
 
function PropertyMultipleForm(props){

    const dispatch = useDispatch();

    const {
        values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        initialValues,
    } = props;

    const [color, setColor] = useState('#dcdcdc');
    const [primaryPhone, setPrimaryPhone] = useState(values.phoneNumber);
    const [selectedProperties, setSelectedProperties] = useState([]);
    
    const coordinates = useSelector(({ rentals }) => rentals && rentals.coordinates);
    const properties = useSelector(({ rentals }) => rentals && rentals.properties);
    const user = useSelector(({ user }) => user && user.user);
    const colorDictionary = useSelector(({ generalSetting }) => {
        const colorsSetting = generalSetting && generalSetting.setting.colors;
        const map = [];
        for (const key in colorsSetting) {
            if (key.startsWith('color')) {
                map.push(colorsSetting[key]);
            }
        };
        return map;
    });
    
    const code = values?.country ? findCountry(values?.country) : findCountry(user.country);
    const propertyOptions = properties.map((row) => ({ value: row.id, label: row.name }));
    const countryOptions = countryList().getData();
    const currencyOptions = currencies;

    useEffect(() => {

        if (values.checkCity || values.checkCountry) {
            
            const payload = {
                city: values.city,
                country: values.country,
            };
            dispatch(getCoordinates(payload));

        };

        return () => {
            dispatch(clearCoordinates());
        };

    }, [values.updateCoordinates]);
    
    useEffect(() => {

        setFieldValue('latitude', (coordinates?.latitude || ''));
        setFieldValue('longitude', (coordinates?.longitude || ''));

    }, [coordinates]);

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Select properties*: </Form.Label>
                <Col style={{ marginRight: '35px' }}>
                        <MultiSelect
                            options={propertyOptions}
                            value={selectedProperties}
                            name="id"
                            onChange={(selectedOptions) => {
                                const selectedIds = selectedOptions.map(option => option.value);
                                setFieldValue('id', selectedIds);
                                setSelectedProperties(selectedOptions);
                            }}
                            onBlur={handleBlur}
                            fieldHasError={errors.id}
                            placeholder={"---Select Property---"}
                            selectAll="All properties"
                        />
                        <div className={`${styles.errorMessage}`} >{errors.id}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Street and no: </Form.Label>
                <Col>
                <div className="d-flex align-items-center">
                    <Form.Control
                        type="text"
                        name="street"
                        value={values.street}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.street}
                        disabled={!values.checkStreet}
                        style={{fontSize:'inherit'}}
                    />
                    <div className="ml-3">
                        <Form.Check
                            type="checkbox"
                            id="checkStreet"
                            name="checkStreet"
                            checked={values.checkStreet}
                            onChange={() => {
                                setFieldValue('street', (values.street != undefined ? values.street : ''));
                                setFieldValue('checkStreet', !values.checkStreet);
                                if (values.checkStreet == true) {
                                    setFieldValue('street', '');
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.street}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">City: </Form.Label>
                <Col>
                <div className="d-flex align-items-center">
                    <Form.Control
                        type="text"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={() => {
                            setFieldValue('updateCoordinates', !values.updateCoordinates);
                        }}
                        isInvalid={errors.city}
                        disabled={!values.checkCity}
                        style={{fontSize:'inherit'}}
                    />
                    <div className="ml-3">
                        <Form.Check
                            type="checkbox"
                            id="checkCity"
                            name="checkCity"
                            checked={values.checkCity}
                            onBlur={() => {
                                setFieldValue('updateCoordinates', !values.updateCoordinates);
                            }}
                            onChange={() => {
                                setFieldValue('city', (values.city != undefined ? values.city : ''));
                                setFieldValue('checkCity', !values.checkCity);
                                if (values.checkCity == true) {
                                    setFieldValue('city', '');
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.city}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Postal code: </Form.Label>
                <Col>
                <div className="d-flex align-items-center">
                    <Form.Control
                        type="text"
                        name="postalCode"
                        value={values.postalCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.postalCode}
                        disabled={!values.checkPostalCode}
                        style={{fontSize:'inherit'}}
                    />
                    <div className="ml-3">
                        <Form.Check
                            type="checkbox"
                            id="checkPostalCode"
                            name="checkPostalCode"
                            checked={values.checkPostalCode}
                            onChange={() => {
                                setFieldValue('postalCode', (values.postalCode != undefined ? values.postalCode : ''));
                                setFieldValue('checkPostalCode', !values.checkPostalCode);
                                if (values.checkPostalCode == true) {
                                    setFieldValue('postalCode', '');
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.postalCode}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Select country: </Form.Label>
                <Col>
                <div className="d-flex align-items-center">
                    <Form.Control
                        as="select"
                        type="text"
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                        onBlur={() => {
                            setFieldValue('updateCoordinates', !values.updateCoordinates);
                        }}
                        isInvalid={errors.country}
                        disabled={!values.checkCountry}
                        style={{ fontSize: 'inherit' }}
                    >
                        { countryOptions.map( option => (
                                <option key={option.label} value={option.label} selected={values.country === option.label}>
                                    {option.label}
                                </option>
                        ))}
                    </Form.Control>
                    <div className="ml-3">
                        <Form.Check
                            type="checkbox"
                            id="checkCountry"
                            name="checkCountry"
                            checked={values.checkCountry}
                            onBlur={() => {
                                setFieldValue('updateCoordinates', !values.updateCoordinates);
                            }}
                            onChange={() => {
                                setFieldValue('country', (values.country || initialValues.country));
                                setFieldValue('checkCountry', !values.checkCountry);
                                if (values.checkCountry == true) {
                                    setFieldValue('country', '');
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.country}</div>
                </Col>
            </Form.Row>
            { values.latitude && values.longitude && (
                <Form.Row className='mb-sm-2'>
                    <Form.Label className="m-0 p-0 col-sm-4 col-form-label"></Form.Label>
                    <Col className='ml-2 p-0'>
                        <p style={{color:'#888888', marginBottom: '6px'}}>
                            Latitude: {values.latitude}<br />
                            Longitude: {values.longitude}
                        </p>
                    </Col>
                </Form.Row>
            )}
            <Form.Row className="mt-0 pb-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Time zone: </Form.Label>
                <Col>
                <div className="d-flex align-items-center">
                    <div className={`w-100 ${!values.checkTimezone ? styles.isDisabled : ''}`}>
                        <TimezoneSelect
                            value={values.timezone}
                            onChange={(data) => setFieldValue('timezone', data?.value)}
                        />
                    </div>
                    <div className="ml-3">
                        <Form.Check
                            type="checkbox"
                            id="checkTimezone"
                            name="checkTimezone"
                            checked={values.checkTimezone}
                            onChange={() => {
                                setFieldValue('timezone', (values.timezone != undefined ? values.timezone : ''));
                                setFieldValue('checkTimezone', !values.checkTimezone);
                                if (values.checkTimezone == true) {
                                    setFieldValue('timezone', '');
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.timezone}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 py-sm-0 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Licence/registration number: </Form.Label>
                <Col>
                <div className="d-flex align-items-center">
                    <Form.Control
                        type="text"
                        name="licenseNumber"
                        value={values.licenseNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.licenseNumber}
                        disabled={!values.checkLicenseNumber}
                        style={{fontSize:'inherit'}}
                    />
                    <div className="ml-3">
                        <Form.Check
                            type="checkbox"
                            id="checkLicenseNumber"
                            name="checkLicenseNumber"
                            checked={values.checkLicenseNumber}
                            onChange={() => {
                                setFieldValue('licenseNumber', (values.licenseNumber != undefined ? values.licenseNumber : ''));
                                setFieldValue('checkLicenseNumber', !values.checkLicenseNumber);
                                if (values.checkLicenseNumber == true) {
                                    setFieldValue('licenseNumber', '');
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.licenseNumber}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Check-in time: </Form.Label>
                <Col>
                    <div className="d-flex align-items-center">
                        <Col className={`m-0 p-0 d-sm-flex`}>
                            <div className="m-0 p-0 mb-2 mb-sm-0 pr-sm-1 col-sm-5">
                                <Form.Control
                                    as="select"
                                    name="checkInTimeAt"
                                    value={values.checkInTimeAt}
                                    onChange={handleChange}
                                    isInvalid={errors.checkInTimeAt}
                                    disabled={!values.checkCheckInTimeAt}
                                    style={{fontSize:'inherit'}}
                                >
                                    <option value="at" key="at" selected={values.checkInTimeAt === "at"} >at &gt;&gt;</option>
                                    <option value="from" key="from" selected={values.checkInTimeAt === "from"} >from &gt;&gt;</option>
                                </Form.Control>
                                <div className="d-none d-sm-block">{errors.checkInTime ? <br /> : <></>}</div>
                            </div>
                            <div className="m-0 p-0 mb-2 mb-sm-0 pl-sm-1 col-sm-7">
                                <Form.Control
                                    type="time"
                                    name="checkInTime"
                                    value={values.checkInTime}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.checkInTime}
                                    disabled={!values.checkCheckInTime}
                                    style={{fontSize:'inherit'}}
                                />
                                <Form.Control.Feedback type="invalid" >{errors.checkInTime}</Form.Control.Feedback>
                            </div>
                        </Col>
                        <div className={`ml-3 ${styles.checkBox}`}>
                            <Form.Check
                                type="checkbox"
                                id="checkCheckInTime"
                                name="checkCheckInTime"
                                checked={values.checkCheckInTime}
                                onChange={() => {
                                    setFieldValue('checkInTime', (values.checkInTime != undefined ? values.checkInTime : ''));
                                    setFieldValue('checkCheckInTime', !values.checkCheckInTime);
                                    setFieldValue('checkCheckInTimeAt', !values.checkCheckInTime);
                                    if (values.checkCheckInTime == true) {
                                        setFieldValue('checkInTime', '');
                                    }
                                }}
                            />
                            <div>{errors.checkInTime ? <br /> : <></>}</div>
                        </div>
                    </div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 mb-sm-2 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Check-out time: </Form.Label>
                <Col>
                    <div className="d-flex align-items-center">
                        <Col className="m-0 p-0 d-sm-flex">
                            <div className="m-0 p-0 mb-2 mb-sm-0 pr-sm-1 col-sm-5">
                                <Form.Control
                                    as="select"
                                    name="checkOutTimeAt"
                                    value={values.checkOutTimeAt}
                                    onChange={handleChange}
                                    isInvalid={errors.checkOutTimeAt}
                                    disabled={!values.checkCheckOutTimeAt}
                                    style={{fontSize:'inherit'}}
                                >
                                    <option value="at" key="at" selected={values.checkOutTimeAt === "at"} >at &gt;&gt;</option>
                                    <option value="until" key="until" selected={values.checkOutTimeAt === "until"} >until &gt;&gt;</option>
                                </Form.Control>
                                <div className="d-none d-sm-block">{errors.checkOutTime ? <br /> : <></>}</div>
                            </div>
                            <div className="m-0 p-0 mb-2 mb-sm-0 pl-sm-1 col-sm-7">
                                <Form.Control
                                    type="time"
                                    name="checkOutTime"
                                    value={values.checkOutTime}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={errors.checkOutTime}
                                    disabled={!values.checkCheckOutTime}
                                    style={{fontSize:'inherit'}}
                                />
                                <Form.Control.Feedback type="invalid" >{errors.checkOutTime}</Form.Control.Feedback>
                            </div>
                        </Col>
                        <div className={`ml-3 ${styles.checkBox}`}>
                            <Form.Check
                                type="checkbox"
                                id="checkCheckOutTime"
                                name="checkCheckOutTime"
                                checked={values.checkCheckOutTime}
                                onChange={() => {
                                    setFieldValue('checkOutTime', (values.checkOutTime != undefined ? values.checkOutTime : ''));
                                    setFieldValue('checkCheckOutTime', !values.checkCheckOutTime);
                                    setFieldValue('checkCheckOutTimeAt', !values.checkCheckOutTime);
                                    if (values.checkCheckOutTime == true) {
                                        setFieldValue('checkOutTime', '');
                                    }
                                }}
                            />
                            <div>{errors.checkOutTime ? <br /> : <></>}</div>
                        </div>
                    </div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Currency: </Form.Label>
                <Col>
                <div className="d-flex align-items-center">
                    <Form.Control
                        as="select"
                        type="text"
                        name="currency"
                        value={values.currency}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.currency}
                        disabled={!values.checkCurrency}
                        style={{ fontSize: 'inherit' }}
                    >
                        { currencyOptions.map( option => (
                                <option key={option.label} value={option.label} selected={values.currency === option.label}>
                                    {option.label}
                                </option>
                        ))}
                    </Form.Control>
                    <div className="ml-3">
                        <Form.Check
                            type="checkbox"
                            id="checkCurrency"
                            name="checkCurrency"
                            checked={values.checkCurrency}
                            onChange={() => {
                                setFieldValue('currency', (values.currency != undefined ? values.currency : ''));
                                setFieldValue('checkCurrency', !values.checkCurrency);
                                if (values.checkCurrency == true) {
                                    setFieldValue('currency', '');
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.currency}</div>
                </Col>
            </Form.Row>
            <hr/>
                <label>Contact information</label>

                <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Name: </Form.Label>
                <Col>
                <div className="d-flex align-items-center">
                    <Form.Control
                        type="text"
                        name="contactName"
                        value={values.contactName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.contactName}
                        disabled={!values.checkContactName}
                        style={{fontSize:'inherit'}}
                    />
                    <div className="ml-3">
                        <Form.Check
                            type="checkbox"
                            id="checkContactName"
                            name="checkContactName"
                            checked={values.checkContactName}
                            onChange={() => {
                                setFieldValue('contactName', (values.contactName != undefined ? values.contactName : ''));
                                setFieldValue('checkContactName', !values.checkContactName);
                                if (values.checkContactName == true) {
                                    setFieldValue('contactName', '');
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.contactName}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">Phone number: </Form.Label>
                <Col>
                <div className="d-flex align-items-center">
                    <PhoneInput
                        country={code}
                        value={primaryPhone}
                        onChange={(value, data) => {
                            const rawVal = value.slice(data.dialCode.length);
                            const phone = rawVal ? `+${data.dialCode} ${rawVal}` : "";
                            setFieldValue("phoneNumber", phone);
                            setPrimaryPhone(phone || data.dialCode);
                        }}
                        inputStyle={{width: '100%'}}
                        countryCodeEditable={false}
                        enableSearch={true}
                        disableSearchIcon={true}
                        disabled={!values.checkPhoneNumber}
                    />
                    <div className="ml-3">
                        <Form.Check
                            type="checkbox"
                            id="checkPhoneNumber"
                            name="checkPhoneNumber"
                            checked={values.checkPhoneNumber}
                            onChange={() => {
                                setFieldValue('phoneNumber', (values.phoneNumber != undefined ? values.phoneNumber : ''));
                                setFieldValue('checkPhoneNumber', !values.checkPhoneNumber);
                                if (values.checkPhoneNumber == true) {
                                    setFieldValue('phoneNumber', '');
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.phoneNumber}</div>
                </Col>
            </Form.Row>
            <Form.Row className="py-1 align-items-center">
                <Form.Label className="col-sm-4 col-form-label">E-mail: </Form.Label>
                <Col>
                <div className="d-flex align-items-center">
                    <Form.Control
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.email}
                        disabled={!values.checkEmail}
                        style={{fontSize:'inherit'}}
                    />
                    <div className="ml-3">
                        <Form.Check
                            type="checkbox"
                            id="checkEmail"
                            name="checkEmail"
                            checked={values.checkEmail}
                            onChange={() => {
                                setFieldValue('email', (values.email != undefined ? values.email : ''));
                                setFieldValue('checkEmail', !values.checkEmail);
                                if (values.checkEmail == true) {
                                    setFieldValue('email', '');
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.email}</div>
                </Col>
            </Form.Row>
            <hr/>
            <Form.Row>
              <Form.Label className="col-2 col-sm-4 col-form-label">Colour:</Form.Label>
              <Col>
                <div className="d-flex align-items-center">
                    <div className="w-100">
                        <Col className={styles.containerColor}>
                            {colorDictionary.map((item) => (
                                <div
                                className={`${styles.containerBox} ${color === item ? styles.active : ``}`}
                                style={{ background: `${item}` }}
                                onClick={() => {
                                    setColor(item);
                                    values.color = item;
                                }}
                                key={item}
                                />
                            ))}
                        </Col>
                    </div>
                    <div className="ml-3">
                        <Form.Check
                            type="checkbox"
                            id="checkColor"
                            name="checkColor"
                            checked={values.checkColor}
                            onChange={() => {
                                setFieldValue('color', (values.color != undefined ? values.color : ''));
                                setFieldValue('checkColor', !values.checkColor);
                                if (values.checkColor == true) {
                                    setFieldValue('color', '#dcdcdc');
                                }
                            }}
                        />
                    </div>
                </div>
                <Col className="p-0 mt-2 ml-2 d-flex align-items-center">
                    <span className={styles.color_picker_text}>Colour picker: </span>
                    <Field
                        type={'color'}
                        name={'color'}
                        value={values.color}
                        onChange={handleChange}
                        onClick={() => setColor(null)}
                        className={styles.form_entry_color}
                        required
                    />
                </Col>
              </Col>
            </Form.Row>
            <ModalButton btnText={"Save changes"} />
        </Form>
    )}

export default PropertyMultipleForm;