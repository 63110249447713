import React from 'react';
import {Modal} from 'react-bootstrap';
import * as yup from 'yup';

import styles from '../../../../Rentals/rentals.module.css';
import TaxForm from './TaxForm';

import { toast } from "react-toastify";
toast.configure();


function AddTax(props){

  const taxData = props.viewForEdit;

  const checkBoxWarning = () => {
    toast.warning("Does this tax apply to adults, children or both? It is mandatory to select at least one.");
    return false;
  };

  const validationSchema = yup.object({
    rentalIds: taxData ? yup.array() : yup.array().of(yup.number()).test('minLength', 'Rental selection is a required field', value => value && value.length > 0),
    name: yup.string().required("Name of tax is a required field"),
    taxType: yup.string().required("Tax type is a required field"),
    amount: yup.number().required("Amount is a required field").min(0, 'Must be a positive number').typeError('Must be a value of type number'),
    modality: yup.string(),
    taxApplyAdults: yup.boolean(),
    taxApplyChildren: yup.boolean().test("taxApplyChildren", "",  function(value) {
      return ["per person", "pppn"].includes(this.parent.modality)
      && !this.parent.taxApplyAdults
      && !value ? checkBoxWarning() : true
    }),
  });

  const initialValues = {
    id: taxData?.id || null,
    rentalId: taxData?.rentalId || null,
    name: taxData?.name || '',
    amount: taxData?.amount ? Number(taxData?.amount).toFixed(2) : '',
    taxType: taxData?.taxType || 'flat rate',
    modality: taxData?.modality || 'per stay',
    taxApplyAdults: taxData?.taxApplyAdults || false,
    taxApplyChildren: taxData?.taxApplyChildren || false,
  };

  const handleSubmit=(data)=>{

    const payloadMapper = {
      name: data.name,
      taxType: data.taxType,
      amount: Number(data.amount),
      modality: data.modality,
      taxApplyAdults: Boolean(Number(data.taxApplyAdults)),
      taxApplyChildren: Boolean(Number(data.taxApplyChildren)),
    };

    if(taxData){
      const payload = {
        ...payloadMapper,
        id: data.id,
        rentalId: data.rentalId,
      };
      props.onEdit(payload);
    }else{
      const payload = data.rentalIds.map((rentalId) => ({
        ...payloadMapper,
        rentalId: rentalId,
      }));
      props.onEdit(payload);
    };
  };

    return(
        <Modal
          show={props.show}
          onHide={props.onHide}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>
            { taxData ? 'Edit tax' : 'Add tax' }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.deleteModalBody}>
        <TaxForm
         validationSchema={validationSchema}
         handleSubmit={handleSubmit}
         initValue={initialValues}
         currency={props.currency}
         onHide={props.onHide}
        />
      </Modal.Body>
    </Modal>
    )
}
export default AddTax;