import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { withFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { toast } from "react-toastify";

import Loading from "../../../../../../components/loading";
import BookingDetails from './components/BookingDetails';
import GuestDetails from './components/GuestDetails';
import Payment from './components/Payment';
import Invoice from './components/Invoice';
import { editGuest } from "../../../../../../general_redux/guest/actions";
import { clearInvoice } from '../../../../../../general_redux/invoice/actions';
import { getDateObjFromString } from '../../../../../../common/functions/utils';
import { getGuestByBooking, clearGuest } from '../../../../../../general_redux/guest/actions';
import {
  editBooking,
  fetchBookingById,
  resetCharges,
  fetchDiscountByBooking,
  editDiscountByBookingForm,
  clearBooking,
} from '../../../../../../general_redux/calendar/actions';

import styles from './editBooking.module.css';


const EditBooking = ({ bookingId, setViewEditBooking }) => {
  
  const dispatch = useDispatch();
  
  const user = useSelector(({ user }) => user && user.user);
  const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
  const bookingData = useSelector(({ calendar }) => calendar && calendar.bookingById);
  const bookingDiscount = useSelector(({ calendar }) => calendar && calendar.bookingDiscount);
  const guestDetails = useSelector(({ guests }) => guests && guests?.guestByBooking?.[0]);
  const actionForFetchDiscount = useSelector(({ calendar }) => calendar && calendar.actionForFetchDiscount);
  const actionForFetchBooking = useSelector(({ calendar }) => calendar && calendar.actionForFetchBooking);

  const [currentTab, setCurrentTab] = useState('Booking Details');
  const [paymentStatus] = useState(bookingData?.paymentStatus || 'Payment Pending');
  
  useEffect(() => {
    dispatch(getGuestByBooking(bookingId));
    dispatch(fetchDiscountByBooking(bookingId));
    dispatch(fetchBookingById(bookingId));
  }, []);

  useEffect(()=>{
    return()=>{
      dispatch(clearGuest());
      dispatch(resetCharges());
      dispatch(clearInvoice());
      dispatch(clearBooking());
    }
  },[])

  const tabs = ['Booking Details', 'Guest Details', 'Payment', 'Invoices'];
  const guestName = guestDetails ? guestDetails.name : bookingData?.guestName;

  const ota = (() => {
    const source = bookingData?.source;
    const OTAs = ['AIRBNB','BEEHAZ','AGODA','BOKKINGCOM','VRBO','OTHERS'];
    const ota = source && OTAs.find(item => item === source.toUpperCase());

    if (!ota) {
        return '';
    };
    if (ota === 'BOKKINGCOM') { 
        return 'Booking.com';
    };
    if (ota === 'VRBO') { 
        return 'VRBO';
    };
    return ota.charAt(0).toUpperCase() + ota.slice(1).toLowerCase();

  })();

  const findRentalDetail = (rentalId) => {
    const findRental = rentals.find((rental) => Number(rental.id) === Number(rentalId));
    return findRental || { id: '', maxOccup: 7, maxChild: 7, checkinTime: '', checkoutTime: '' };
  };

  const rentalDetail = findRentalDetail(bookingData?.rentalId);

  const getChannelName = (channel) => {
    if (channel.ota === 'Other')
        return {label: `${channel.name} (iCal)`, value: channel.name}
    if (channel.ota === 'VRBO')
        return {label: 'VRBO (HomeAway) (iCal)', value: channel.ota}
    else
        return {label: `${channel.ota} (iCal)`, value: channel.ota}
  };

  const getChannelOptions = (channels) => {
    const channelOptions = channels.map((item) => {
      const ChannelNameObject = getChannelName(item.channel);
      return {label: ChannelNameObject.label, value: ChannelNameObject.value};
    });
    return [{label: 'Beehaz', value: 'Beehaz'}, ...channelOptions];
  };

  const certainTypesOfReservationsWarning = () => {

    if (bookingData.bookingType === 'channelBooking') {
      toast.warn(`
        This is a channel reservation. Changes are not recommended.
        Making changes in Beehaz shall not modify the reservation at the source and may lead to calendar errors.
        Change this reservation on the channel from which it derives.
      `)
    };

    if (bookingData.bookingType === "monthly" || bookingData.bookingType === "weekly") {
      toast.warn(`
        You are modifying a fixed-term reservation.
        Note the prices will be recalculated using this special rates and not your usual rates.
      `)
    };

  };

  const handleSubmit = (data) => {

    const { bookingPayload, guestPayload, discountPayload } = data.payloads;

    dispatch(editBooking(bookingPayload)).then(() => {
      
      dispatch(editDiscountByBookingForm(discountPayload, bookingDiscount.id));
      
      if (guestPayload.notes !== guestDetails?.notes) {
        dispatch(editGuest(guestPayload));
      };
    
    })

    setViewEditBooking(false);

    // console.log(bookingPayload);
    // console.log(guestPayload);
    // console.log(discountPayload);

  };

  const initialValues = {
    // booking
    bookingType: bookingData?.bookingType || 'booking',
    title: `${(bookingData?.bookingType !== 'channelBooking' && guestName) || ((ota && guestName) ? `${ota} ${guestName}` : `${ota} Guest`)} | ${paymentStatus} | Direct Booking`,
    rentalId: bookingData?.rentalId || '',
    totalGuests: (bookingData?.noOfAdults && bookingData?.noOfChildren) ? (bookingData.noOfAdults + bookingData.noOfChildren) : 1,
    adult: bookingData?.noOfAdults || 1,
    children: bookingData?.noOfChildren || 0,
    babies: bookingData?.noOfBabies || 0,
    arrive: bookingData?.arrive ? getDateObjFromString(bookingData.arrive) : new Date(),
    depart: bookingData?.depart ? getDateObjFromString(bookingData.depart) : new Date(),
    checkInTime: bookingData?.checkInTime || user.checkInTime,
    checkOutTime: bookingData?.checkOutTime || user.checkOutTime,
    nights: bookingData?.nights || 0,
    status: bookingData?.status ||"Booked",
    source: bookingData?.source || 'Beehaz',
    discount: 0,
    extraCharge: 0,
    price: 0,
    finalPrice: bookingData?.price || 0.0,
    paymentStatus: bookingData?.paymentStatus || 'Payment Pending',
    miniNotes: bookingData?.miniNotes || '',
    notes: `${bookingData?.notes ? bookingData.notes : ''}`,
    color: bookingData?.color || '',

    // guest
    moreInfo: guestDetails?.notes || '',

    // rental
    maxOccup: rentalDetail.maxOccup,
    maxChild: rentalDetail.maxChild,
  };

  const validationSchema = yup.object().shape({
    rentalId: yup.number().required('Rental selection is required').integer('Must be an integer').typeError('Rental selection is required'),
    totalGuests: yup.number().required('At least 1 guest is required').min(1, 'Must be a number greater than or equal to 1').integer('Must be an integer').typeError('Must be a value of type number')
      .test('totalGuests', function (value) {
        const maxOccup = this.parent.maxOccup;
        return value <= maxOccup ? true : this.createError({
          path: this.path,
          message: `Maximum ${maxOccup} guests are allowed`,
        });
    }),
    adult: yup.number().required('At least 1 adult is required').min(1, 'At least 1 adult is required').integer('Must be an integer').typeError('Must be a value of type number'),
    children: yup.number().min(0, 'Must be a positive number').integer('Must be an integer').typeError('Must be a value of type number')
      .test('children', function (value) {
        const maxChild = this.parent.maxChild;
        return value <= maxChild ? true : this.createError({
          path: this.path,
          message: `Maximum ${maxChild} children are allowed`,
        });
    }),
    babies: yup.number().min(0, 'Must be a positive number').integer('Must be an integer').typeError('Must be a value of type number'),
    arrive: yup.date().required('Arrival date is required').typeError('Must be a value of type date').max(`${moment().add(2,'years').format('YYYY-MM-DD')}`, 'Booking not allowed after 2 years'),
    depart: yup.date().required('Departure date is required').typeError('Must be a value of type date').max(`${moment().add(2,'years').format('YYYY-MM-DD')}`, 'Booking not allowed after 2 years'),
    checkInTime: yup.string().required('Check-In is required'),
    checkOutTime: yup.string().required('Check-Out is required'),
    nights: yup.number().min(0, 'Must be a positive number').integer('Must be an integer').typeError('Must be a value of type number')
      .test('nights', function (value) {
        let message;
        const minStay = this.parent.minStay;
        const maxStay = this.parent.maxStay;
        if (value < minStay) {
          message = `Minimum stay for this rental is ${minStay} day${minStay > 1 ? 's' : ''}`;
        } else if (value > maxStay && maxStay > 0) {
          message = `Maximum stay for this rental is ${maxStay} day${maxStay > 1 ? 's' : ''}`;
        };
        return !message ? true : this.createError({
          path: this.path,
          message: message,
        });
    }),
    source: yup.string(),
    paymentStatus: yup.string(),
    finalPrice: yup.number().min(0, 'Must be a positive number').typeError('Must be a value of type number'),
    miniNotes: yup.string().max(50, 'Maximum 50 characters are allowed'),
    notes: yup.string().max(150, 'Maximum 150 characters are allowed'),
    color: yup.string(),
  });

  const FormikEnhancer = withFormik({
    displayName: 'BookingDetails',
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    mapPropsToValues: () => (initialValues),
    handleSubmit: (values, { setSubmitting }) => {
      handleSubmit(values);
      setSubmitting(false);
    },
  })(BookingDetails);


  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className={styles.modalTitle}>Edit booking</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`px-sm-2 px-0 ${styles.modal_body}`}>
        <div className={styles.upperRow}>
          {tabs.map((tab, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              className={currentTab === tab ? `${styles.tabBox} ${styles.active}` : `${styles.tabBox}`}
              key={index}
              onClick={() => setCurrentTab(tab)}
            >
              {tab}
            </a>
          ))}
        </div>
        <div>
          {currentTab === 'Booking Details' && (
            <div className="mt-3">
              <Loading
                loadingStatus={
                  actionForFetchBooking.loading ||
                  actionForFetchDiscount.loading
                }
              />
              <FormikEnhancer
                ota={ota}
                getChannelOptions={getChannelOptions}
                certainTypesOfReservationsWarning={certainTypesOfReservationsWarning}
              />
            </div>
          )}
          {currentTab === 'Guest Details' &&
            <GuestDetails
                bookingId={bookingId}
            />
          }
          {currentTab === 'Payment' && <Payment bookingId={bookingId} />}
          {currentTab === 'Invoices' && <Invoice bookingId={bookingId}/>}
        </div>
      </Modal.Body>
    </>
  );
};

export default EditBooking;
