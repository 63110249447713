import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";

import { channelMapping, addChannelsMock } from '../../../../general_redux/ota/actions';
import Loading from '../../../../components/loading';
import ChannelMapping from './Components/ChannelMapping/ChannelMapping';
import Properties from './Components/Tables/PropertiesTable/PropertiesTable';
import OTAsTable from './Components/Tables/OTAsTable/OTAsTable';
import styles from './channel.module.css';
import SuWidget from './Components/SuWidget/SuWiget';
import { SectionAboveTheTable } from './Components/Tables/components';


function SuChannelManagement() {

    const dispatch = useDispatch();

    const [addChannelView, setAddChannelView] = useState(false);
    const [showWidget, setShowWidget] = useState(false);
    const [propertyData, setPropertyData] = useState(null);
    const [currentView, setCurrentView] = useState("properties-table");

    const channels = useSelector(({ ota }) => ota && ota.channels);
    const channelMappingData = useSelector(({ ota }) => ota && ota.channelMappingData);
    const actionForChannelMapping = useSelector(({ ota }) => ota && ota.actionForChannelMapping);
    const properties = useSelector(({ rentals }) => rentals && rentals.properties);
    const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);

    const views = {
        propertiesTable: "properties-table",
        rentalsTable: "rentals-table",
        OTAsTable: "OTAs-table",
        channelSelection: "channel-selection",
    };

    const handleConnectToChannel = (data) => {
        const payload = {
            'propertyId': propertyData.id,
            'channelCode': data.value,
        };
        dispatch(channelMapping(payload)).then(() => setShowWidget(true));
    };

    const onSelection = (data) => {
        handleConnectToChannel(data);
        setAddChannelView(false);
    };
    
    // const onConnectToChannel = (data) => {
    //     setPropertyData(data);
    //     setAddChannelView(true);
    // };

    const onShowOTAsTable = () => {
        setCurrentView(views.OTAsTable);
    };

    const onShowPropertiesTable = () => {
        setCurrentView(views.propertiesTable);
    };

    const onShowRentalsTable = () => {
        setCurrentView(views.rentalsTable);
    };

    const onShowWidget = () => {
        setCurrentView(views.channelSelection);
        setShowWidget(true);
    };

    useEffect(() => {

        const loadWidget = () => {
            if (!process.env.REACT_APP_SU_ID) {
                console.error('REACT_APP_SU_ID is not defined in the environment.');
            };
            const tmpScriptObj = {
                elementId: 'su-channel-widget',
                type: channelMappingData.type,
                appId: process.env.REACT_APP_SU_ID,
                propertyId: channelMappingData.propertyId,
                token: channelMappingData.token,
                channelCode: channelMappingData.channelCode,
                language: 'en',
                themeColor: channelMappingData.themeColor,
            };
            // eslint-disable-next-line no-undef
            loadScript(tmpScriptObj);
            // console.log(tmpScriptObj);
        };

        if (showWidget) {
            loadWidget();
        };

    }, [showWidget]);

    //region: generate custom mock

    let channelsMock = [];

    properties.forEach(property => {
        const rentalIds = [];
        rentals.forEach(rental => {
            if (rental.propertyId === property?.id) {
                rentalIds.push(rental.id);
            };
        });

        channelsMock = [
            ...channelsMock,
            {
                id: `1-${property?.id}`,
                name: 'Airbnb',
                isActive: true,
                propertyId: property?.id,
                rentalIds: rentalIds,
            },
            {
                id: `2-${property?.id}`,
                name: 'Agoda',
                isActive: true,
                propertyId: property?.id,
                rentalIds: rentalIds.slice(0, -1), // make the last item have 'no mapped' status
            },
            {
                id: `3-${property?.id}`,
                name: 'Booking.com',
                isActive: false,
                propertyId: property?.id,
                rentalIds: [],
            }
        ];

    });

    useEffect(() => {
        dispatch(addChannelsMock(channelsMock));
    }, [])

    //endregion: generate custom mock

    return (
        <>
            <Loading loadingStatus={actionForChannelMapping.loading} />
            <ChannelMapping
                show={addChannelView}
                onHide={() => setAddChannelView(false)}
                onSelection={(data) => onSelection(data)}
            />
            {currentView == 'OTAs-table' &&
                <>
                    {channels.length ?
                        <>
                            <SectionAboveTheTable
                                title='Channel Connection'
                                backButton={true}
                                onShowPropertiesTable={onShowPropertiesTable}
                                firstText={
                                    <p className="d-flex align-items-center mt-4">
                                        <span>Connections</span>
                                        <span className={styles.propertyColor} style={{ backgroundColor: `${propertyData?.color || '#dcdcdc'}` }} />
                                        {propertyData?.name}
                                    </p>
                                }
                                secondText={
                                    <p>
                                        The following OTAs
                                        <span className={styles.toolTipIconContainer} style={{ padding: 0, marginLeft: "2px" }}>
                                            <InfoOutlinedIcon
                                                fontSize="small"
                                                data-tip
                                                data-for="ota-table"
                                                className={styles.toolTipIconOTA}
                                            />
                                            <ReactTooltip type="dark" place="bottom" effect="solid" id="ota-table" className={styles.toolTip}>
                                                <span>
                                                    OTAs (Online travel agencies) are third-party websites that
                                                    facilitate online booking of accommodations. Examples: Airbnb,
                                                    VRBO, and Booking.com
                                                </span>
                                            </ReactTooltip>
                                        </span> are connected to this property.
                                    </p>
                                }
                            />
                            <div className='m-0 p-0 ml-4'>
                                <OTAsTable
                                    propertyData={propertyData}
                                    setPropertyData={setPropertyData}
                                    onShowRentalsTable={onShowRentalsTable}
                                />
                            </div>
                        </>
                        :
                        <SectionAboveTheTable
                            title='Channel Connection'
                            backButton={true}
                            onShowPropertiesTable={onShowPropertiesTable}
                            firstText={
                                <p className="d-flex align-items-center mt-4">
                                    <span>Connections</span>
                                    <span className={styles.propertyColor} style={{ backgroundColor: `${propertyData?.color || '#dcdcdc'}` }} />
                                    {propertyData?.name}
                                </p>
                            }
                            secondText={
                                <p>No OTAs
                                    <span className={styles.toolTipIconContainer} style={{ padding: 0, marginLeft: "2px" }}>
                                        <InfoOutlinedIcon
                                            fontSize="small"
                                            data-tip
                                            data-for="ota-table"
                                            className={styles.toolTipIconOTA}
                                        />
                                        <ReactTooltip type="dark" place="bottom" effect="solid" id="ota-table" className={styles.toolTip}>
                                            <span>
                                                OTAs (Online travel agencies) are third-party websites that
                                                facilitate online booking of accommodations. Examples: Airbnb,
                                                VRBO, and Booking.com
                                            </span>
                                        </ReactTooltip>
                                    </span> are connected to this property.
                                </p>
                            }
                            thirdText={<p>Please click on the map icon next to the property you want to connect in the property table to view your connected channels on this page.</p>}
                        />
                    }
                </>
            }
            {currentView == 'properties-table' &&
                <>
                    {rentals.length ?
                        <>
                            <SectionAboveTheTable
                                title='Connect to Channels'
                                backButton={false}
                                firstText={<p className='mt-2'>Select the properties whose rentals you want to connect to channels by toggling "Connect".</p>}
                                secondText={<p>Then, click the "Map" icon to link each property to the desired channel. Make sure your nightly rates are not set to 0 for the rentals you wish to connect to OTAs!'</p>}
                            />
                            <div className='m-0 p-0 ml-4'>
                                <Properties
                                    propertyData={propertyData}
                                    setPropertyData={setPropertyData}
                                    onShowWidget={onShowWidget}
                                    onShowOTAsTable={onShowOTAsTable}
                                />
                            </div>
                        </>
                        :
                        <SectionAboveTheTable
                            title='Channel Connection'
                            backButton={false}
                            firstText={<p className='mt-2'>You do not have any rentals to connect to channels.</p>}
                            secondText={<p>Please go to the rental module and create a rental first.</p>}
                        />
                    }
                </>
            }
            {currentView == 'channel-selection' &&
                <>
                    <SectionAboveTheTable
                        title='Channel Connection'
                        backButton={true}
                        onShowPropertiesTable={onShowPropertiesTable}
                        firstText={
                            <p className="d-flex align-items-center mt-4">
                                <span>Map</span>
                                <span className={styles.propertyColor} style={{ backgroundColor: `${propertyData?.color || '#dcdcdc'}` }} />
                                {propertyData?.name}
                            </p>
                        }
                        secondText={
                            <p>Check out the guides to learn how mapping works by clicking <a href="https://beehaz.com/tutorials" target="_blank" rel="noopener noreferrer">here</a>.</p>
                        }
                    />
                    <SuWidget />
                </>
            }
        </>
    )
}

export default SuChannelManagement;
