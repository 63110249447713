import React, { useEffect, useState } from  'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    rateList,
    getFeeList,
    fetchTax,
    getWeeklyFixedRate,
    getWeeklyFixedFee,
    getWeeklyFixedTax,
    getMonthlyFixedRate,
    getMonthlyFixedFee,
    getMonthlyFixedTax,
    getRateAdjustment
} from '../../../../general_redux/rates/actions';

import RatesCalendar from "./components/RateCalendar/RatesCalendar";
import RatesList from './components/RatesList/RatesList';
import Taxes from './components/Taxes/Taxes';
import Fees from './components/Fees/Fees';
import Special from './components/Special/Special';
import Loading from '../../../../components/loading';

import styles2 from "../Guest/guest.module.css";


const Rates = () => {

    const dispatch = useDispatch();

    const rateLoadStatus = useSelector(({rates}) => rates && rates.actionForRateList);

    const [currentTab, setCurrentTab] = useState("Rates");

    const tabs = [
        "Rates",
        "Defaults",
        "Fees",
        "Taxes",
        "Fixed"
    ];

    useEffect(() => {
        dispatch(rateList());
        dispatch(getFeeList());
        dispatch(fetchTax());
        dispatch(getWeeklyFixedRate());
        dispatch(getWeeklyFixedFee());
        dispatch(getWeeklyFixedTax());
        dispatch(getMonthlyFixedRate());
        dispatch(getMonthlyFixedFee());
        dispatch(getMonthlyFixedTax());
        dispatch(getRateAdjustment());
    }, [])

    return(
        <div style={{margin:'0em 1em'}}>
            <Loading loadingStatus={rateLoadStatus.loading}/>
            <div className={styles2.upperRow}>
                {tabs.map((tab,index)=>(
                    <a className={(currentTab===tab)?`${styles2.tabBox} ${styles2.active}`:`${styles2.tabBox}`}
                       key={index} onClick={() => setCurrentTab(tab)}>
                        {tab}
                    </a>
                ))}
            </div>
            <div >
                {currentTab === "Rates" && (
                    <RatesCalendar/>
                )}
                {currentTab === "Defaults" && (
                    <RatesList/>
                )}
                {currentTab === "Fees" && (
                    <Fees/>
                )}
                {currentTab === "Taxes" &&(
                    <Taxes/>
                )}
                {currentTab === "Fixed" &&(
                    <Special/>
                )}
            </div>
        </div>
    )
};

export default Rates;