import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import Table from '../Table';
import Loading from '../../../../../../../components/loading';
import { TTextLabel, ViewMappedRentalCount } from '../components';


const OTAsTable = (props) => {

  // NOTE: the commented code is a draft of what would be the implementation of the rental table where it would be possible to
  // activate/deactivate rentals. This way it would not be necessary to go to each OTA to perform this action. In this table,
  // the mapping of each rental will also be implemented when we create the Beehaz UI for mapping.

  const {
    propertyData,
    setPropertyData,
    onShowRentalsTable,
  } = props;

  const rentals = useSelector(({ rentals }) => rentals && rentals.rentals).filter(rental => rental.propertyId === propertyData.id);
  const channels = useSelector(({ ota }) => ota && ota.channels).filter(channel => channel.propertyId === propertyData.id);
  const actionForGetChannels = useSelector(({ ota }) => ota && ota.actionForGetChannels);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  // const handleViewButton = (data) => {
  //   setPropertyData(data);
  //   onShowRentalsTable();
  // };

  const cols = [
    { label: 'OTA', key: 'name', component: 'TTextLabel' },
    { label: 'Status', key: 'isActive', component: 'TTextLabel' },
    { label: 'Rentals', key: '', component: 'ViewMappedRentalCount' },
    // { label: 'View', key: '', component: 'TViewButton' },
  ];

  return (
    <div>
      <Loading loadingStatus={actionForGetChannels.loading} />
      <Container fluid>
        <Row className='justify-content-left'>
          <Col xs={12} md={8} lg={8} xl={6} className={`m-0 p-0`}>
            <Table
              cols={cols}
              rows={channels}
              order={order}
              setOrder={setOrder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              tableName={'ota'}
              dropdownListData={rentals}
              TTextLabel={TTextLabel}
              ViewMappedRentalCount={ViewMappedRentalCount}
            // TViewButton={TViewButton}
            // handleViewButton={handleViewButton}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OTAsTable;