import React, { useState, useEffect } from 'react';
import Loading from '../../components/loading';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { onLoad } from '../../general_redux/actions';
import styles from './homeDesign.module.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import comma from '../../icons/comma.svg';
import { Link } from 'react-router-dom';
import deck from '../../icons/Beehaz_01_main.png';
import desk from '../../icons/Beehaz_02_front_desk.png';
import beehaz3 from '../../icons/Beehaz_03_fixed term.png';
import beehaz1 from '../../icons/Beehaz_02_front_desk.png';
import beehaz4 from '../../icons/Beehaz_05_ical.png';
import beehaz5 from '../../icons/Beehaz_06_guests.png';
import PrevArrow from '../../icons/prev-arrow.svg';
import {Carousel} from 'react-responsive-carousel';

function Home() {
    const dispatch = useDispatch();

    const [selectedItem, setSelectedItems] = useState(0);

    useEffect(() => {
        dispatch(onLoad());
        window.scrollTo(0, 0);
    }, []);

    const actionForAddSubscriber = useSelector(({ subscribers }) => subscribers && subscribers.actionForAddSubscriber);

    return (
        <>
            <Loading  loadingStatus={actionForAddSubscriber.loading}/>
            <div className={styles.mainLayout}>
                <Header/>
                <div className={`${styles.container}`}>
                    <div className={`${styles.section1}`}>
                        <div className={`${styles.section1Left} `}>
                            <h1>The Rental Management Software</h1>
                            <label className={styles.cusontsize}>that is simple to use and fits your budget</label>
                            <button className={`${styles.mainBut} mt-4`}>Watch Video</button>
                        </div>
                        <div className={`${styles.section1Right}  `}>
                            <div className={`${styles.section1Img} mb-5 mt-2 mx-auto`}>
                                <img
                                    className={`mx-auto mt-1 mb-4`}
                                    src={deck}
                                    alt={"Beehaz Rental Management Software"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.section2} ${styles.paddinone}`}>
                        <label className={styles.section2Text}>
                            <img src={comma} className={styles.leftSvgComma}/>
                            The must-have software if you manage rental offerings of both short- and long-term stays
                            <img src={comma} className={styles.rightSvgComma}/>
                        </label>
                    </div>
                    <div className={styles.sectionMainBottom}>
                        <p>Our goal was to create a rental management software that is intuitive, simple, but has everything you need at an affordable price. It takes less than 5 minutes to set up your account and take bookings.</p>
                        <p>Built for short- and long-term rental managers who would like to simplify their daily operations, keep track of payment and guest information, and work from anywhere.</p>
                        <p>If you are new to hosting or looking for a rental management software solution that better fits your needs and budget, you have come to the right place!</p>
                    </div>
                    </div>

             <div className={`${styles.sliderBgcolor}`}>

                    <div  className={`${styles.sliderSlide} ${styles.container}`}>
                    <Carousel 
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                            <button
                                type="button"
                                className={`control-arrow ${styles.slideArrow}`}
                                onClick={onClickHandler}
                                title={label}
                                style={{left: '-150px' }}
                            >
                                <img alt="Prev" src={PrevArrow} />
                            </button>
                            )
                        }
                        renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                                <button 
                                    type="button" 
                                    className={`control-arrow ${styles.slideArrow}`}
                                    onClick={onClickHandler} 
                                    title={label} 
                                    style={{ right: '-150px' }}
                                    >
                                        <img alt="Next" style={{transform: 'scaleX(-1)'}} src={PrevArrow} />
                                </button>
                            )
                        }
                        onChange={(val)=>setSelectedItems(val)} 
                        // renderArrowPrev = {(val)=>setSelectedItems(val)}
                        statusFormatter={(current, total) => <div className={styles.slideIndex}>{`${current} of ${total}`}</div>}
                        showThumbs={false}
                    >
                        <div style={{padding:"1em"}}>
                            <div><h2 className={styles.rightAlignText}>Front-desk operations</h2></div>
                            <div>
                                 <img src={beehaz1} />
                            </div>
                            <div>
                              <p className={`${styles.sliderTextone}`}>
                                Take/edit bookings, block dates  & color-code reservations<br/>
                                Check availability from an infinite-scroll calendar<br/>
                                Send emails & WhatsApp messages with one click
                              </p>
                            </div>
                        </div>
                        <div>
                            <div><h2 className={styles.rightAlignText}>Monthly Bookings</h2></div>
                            <div>
                                <img src={beehaz3} />
                            </div>
                            <div>
                                <p className={`${styles.sliderTextone}`}>
                                    Easy input of fixed price stays (monthly and weekly bookings)<br/>
                                    Book and manage long-time stays efficiently<br/>
                                    Perfect to track payments of fixed-term stays
                                </p>
                            </div>
                        </div>
                        <div>
                            <div><h2 className={styles.rightAlignText}>Custom Invoices</h2></div>
                            <div>
                                <img src={beehaz3} />
                            </div>
                            <div>``
                                <p className={`${styles.sliderTextone}`}>
                                    Create, download and send invoices instantly<br/>
                                    Schedule invoices for a future date<br/>
                                    Add a payment button to accept credit card payments
                                </p>
                            </div>
                        </div>
                        <div>
                            <div><h2 className={styles.rightAlignText}>Synchronize your Calendar</h2></div>
                            <div>
                                <img src={beehaz4} />
                            </div>
                            <div>
                                <p className={`${styles.sliderTextone}`}>
                                    Check availability from an infinite scroll calendar<br/>
                                    Use iCal connection to synchronize availability across channels<br/>
                                    Sync with Google Calendar, VRBO, Airbnb, and more
                                </p>
                            </div>
                        </div>
                        <div>
                            <div><h2 className={styles.rightAlignText}>Guest Management</h2></div>
                            <div>
                                <img src={beehaz5} />
                            </div>
                            <div>
                                <p className={`${styles.sliderTextone}`}>
                                    Save important guest information<br/>
                                    Keep track of a guest's past bookings and invoices<br/>
                                    Speed up the reservation process when a guest re-books
                                </p>
                            </div>
                        </div>
                    </Carousel>  
                    </div>
            </div>
                   <div  className={`${styles.container}`}>
 <div className={`row justify-content-center align-items-center`}>
                        <div className={`col-md-6 order-md-1 order-2 text-md-left text-center pt-md-0 pt-4 mt-2`}>
                            <h2>Work from anywhere</h2><br/>
                            <p>Hosts are always on the go. You should be able to perform the same tasks on your smartphone that you can complete from your computer.</p>
                            <p>Manage your calendar and guests from any device. All our software features are available to you independently of your screen size.</p>
                            <p>If you need to call or send a quick WhatsApp message to your guest, a click will be all you need!</p>
                        </div>
                        <div className={`col-lg-5 col-md-6 order-md-2 order-1 `}>
                            <div className={`${styles.section1Img} mx-auto mt-3`} style={{maxHeight: 'unset'}}>
                                <img
                                    src={desk}
                                    className={`w-100`}
                                    style={{borderRadius:'21px'}}
                                    alt={"desk.jpg"}
                                />
                                <div className={styles.imgLayout}/>
                            </div>
                        </div>
                    </div>
                   </div>
                   
                    <div className={`${styles.backgroundBGgray}  my-5`}>
                        <div className={`${styles.container}`}>
                        <div className={`row justify-content-center align-items-center`}>
                        <div className={`col-lg-5 col-md-6`}>
                            <div className={`${styles.section1Img} mx-auto`} style={{maxHeight: 'unset'}}>
                                <img
                                    src={"https://static.wixstatic.com/media/844e46_e2f3705d3a5749e694bf76452b2d3914~mv2.png/v1/fill/w_412,h_515,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/bggdg.png"}
                                    style={{borderRadius:'21px'}}
                                    alt={"desk.jpg"}
                                />
                                <div className={styles.imgLayout}/>
                            </div>
                        </div>
                        <div className={`col-md-6 text-md-left text-center pt-md-0 pt-4 mt-2`}>
                            <h2 className={styles.rightAlignText}>We want to grow with you</h2>
                            <p>Beehaz is a cloud solution for hosts designed by hosts. Entrepreneurs who themselves test and use Beehaz in their daily operations.</p>
                            <p>We will keep improving, and we look forward to perfecting our interface and adding the features you desire. </p>
                            <p>Coming up next: website builder, payment gateway, guest app, & API connection!</p>
                            <p>Help us tailor the software you want to have. If you have ideas for features that are not yet on our list share your thoughts!</p>
                        </div>
                    </div>
                        </div>
                    </div>
                    
                    <div className={`${styles.container}`}>
<div className={`row justify-content-center align-items-center`}>
                        <div className={` col-md-6 order-md-1 order-2 text-md-left text-center pt-md-0 pt-4 mt-2`}>
                            <h2>More for less</h2><br/>
                            <p>Beehaz is a great software for property managers who rent accommodations for both short- and long-term stays.</p>
                            <p>You can set daily, weekly, and monthly rates, and make multiple reservations using the fixed-term booking tool!</p>
                            <p>Beehaz can also help you avoid double bookings by synchronizing your calendar across multiple platforms, such as Airbnb and VRBO.</p>
                            <p>You can use Beehaz for free (forever) or start our Plus Plan trial today - no credit card required!</p>
                        </div>
                        <div className={`col-lg-5 col-md-6 order-md-2 order-1`}>
                            <div className={`${styles.section1Img} mx-auto`} style={{maxHeight: 'unset'}}>
                                <img
                                    src={"https://static.wixstatic.com/media/844e46_d4d8084305414707be62bfa345e0ad1c~mv2.png/v1/fill/w_412,h_515,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Beehaz%20Website%20Part%20B%20(3)ff.png"}
                                    className={`w-100`}
                                    style={{borderRadius:'21px'}}
                                    alt={"desk.jpg"}
                                />
                                <div className={styles.imgLayout}/>
                            </div>
                        </div>
                    </div>
                    </div >
                    

                    <div className={styles.section5}>
                        <div className={`${styles.container}`}>
                            <h1 className={styles.textCenter}>Don't just take our word for it: try it!</h1>
                            <h2 className={styles.textCenter}>Try for free. Cancel any time. No credit card required.</h2>
                            <div className={styles.videoDiv}>
                                <video
                                    width="100%"
                                    controls
                                    loop
                                    muted
                                    preload={"auto"}
                                    alt={"Easy to use rental management software"}
                                >
                                    <source
                                        src="https://video.wixstatic.com/video/e30525_66592b5af6384254af3f3be3696cdc41/1080p/mp4/file.mp4"
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        </div>
                    </div>
                    <div className={styles.sectionMainBottom}>
                        <div className={`${styles.container}`}>
                            <div className={`${styles.section2} ${styles.paddinone}`} >
                            <label className={styles.section2Text}>
                                <img src={comma} className={styles.leftSvgComma}/>
                                Beehaz's mission is to simplify and make rental management affordable. The software enables users to spend less time managing daily operations and more time to spend on growing their business.
                                <img src={comma} className={styles.rightSvgComma}/>
                            </label>
                        </div>
                        <div className={`d-flex justify-content-center `}>

                       
                        <Link className={`${styles.mainBut} mt-4 d-inlineblock`} to={'/register'}>Check out our plans</Link> 
                        </div>
                        </div>
                       
                    </div>
                    <Footer/>
                
            </div>
        </>
    );
}
export default Home;