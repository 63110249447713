// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".general_para__vNeV7{\n    font-size: 15px;\n    line-height: 25px;\n}\n.general_headerTitle__1MflE{\n    font-size: 32px !important;\n    font-weight: 800 !important;\n    line-height: 1.125em !important;\n}\n.general_container__hIqcM{\n    padding-left: 15%;\n    padding-right:15%;\n    padding-top: 2.5em;\n}\n.general_headings__1g7Ax{\n    font-size: 1.1rem;\n    font-weight: 600 !important;\n    font-family: inherit !important;\n}\n\n@media (max-width:1200px){\n    .general_container__hIqcM{\n        padding-left: 10%;\n        padding-right: 10%;\n    }\n}\n@media (max-width:520px){\n    .general_container__hIqcM{\n        padding-right: 5%;\n        padding-left: 5%;\n    }\n    .general_headerTitle__1MflE{\n        font-size: 22px !important;\n    }\n    .general_headings__1g7Ax{\n        font-size: 1rem;\n    }\n    .general_para__vNeV7{\n        font-size: 13px;\n    }\n}", ""]);
// Exports
exports.locals = {
	"para": "general_para__vNeV7",
	"headerTitle": "general_headerTitle__1MflE",
	"container": "general_container__hIqcM",
	"headings": "general_headings__1g7Ax"
};
module.exports = exports;
