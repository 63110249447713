import { actionForState } from "../../common/functions/utils";

import {
    ADD_ICAL_CHANNEL,
    EDIT_ICAL_CHANNEL,
    GET_ICAL_CHANNEL_LIST,
    DELETE_ICAL_CHANNEL,
    CHANGE_ICAL_CHANNEL_NAME
} from './types';

import { toast } from "react-toastify";
toast.configure();

export const INITIAL_STATE = {
    channels: [],
    actionForAddChannel: {
        loading: false,
        success: false,
        error: false,
    },
    actionForEditChannel: {
        loading: false,
        success: false,
        error: false,
    },
    actionForGetChannels: {
        loading: false,
        success: false,
        error: false,
    },
    actionForDeleteChannel: {
        loading: false,
        success: false,
        error: false,
    },
    actionForChangeChannelName: {
        loading: false,
        success: false,
        error: false,
    }
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case ADD_ICAL_CHANNEL.pending:
            return {
                ...state,
                actionForAddChannel: actionForState(state.action, 'pending'),
            }
        case ADD_ICAL_CHANNEL.success:
            toast.success("iCal channel has been successfully added.")
            return {
                ...state,
                channels: [...state.channels, action.payload],
                actionForAddChannel: actionForState(state.action, 'success'),
            }
        case ADD_ICAL_CHANNEL.error:
            toast.error(action.payload.message)
            return {
                ...state,
                actionForAddChannel: actionForState(state.action, 'error', action.payload.message),
            }

        case EDIT_ICAL_CHANNEL.pending:
            return {
                ...state,
                actionForEditChannel: actionForState(state.action, 'pending'),
            }
        case EDIT_ICAL_CHANNEL.success:
            toast.success("iCal channel has been successfully edited.")
            const index = state.channels.findIndex((channel) => channel.channel.id === action.payload.channel.id);
            const channelsUpdated = [...state.channels];
            channelsUpdated[index] = action.payload;
            return {
                ...state,
                channels: channelsUpdated,
                actionForEditChannel: actionForState(state.action, 'success'),
            }
        case EDIT_ICAL_CHANNEL.error:
            toast.error(action.payload.message)
            return {
                ...state,
                actionForEditChannel: actionForState(state.action, 'error', action.payload.message),
            }

        case GET_ICAL_CHANNEL_LIST.pending:
            return {
                ...state,
                actionForGetChannels: actionForState(state.action, 'pending'),
            }
        case GET_ICAL_CHANNEL_LIST.success:
            return {
                ...state,
                channels: action.payload.channels,
                actionForGetChannels: actionForState(state.action, 'success'),
            }
        case GET_ICAL_CHANNEL_LIST.error:
            toast.error(action.payload.message);
            return {
                ...state,
                actionForGetChannels: actionForState(state.action, 'error', action.payload.message),
            }

        case DELETE_ICAL_CHANNEL.pending:
            return {
                ...state,
                actionForDeleteChannel: actionForState(state.action, 'pending'),
            }
        case DELETE_ICAL_CHANNEL.success:
            toast.success("iCal channel has been successfully deleted.");
            return {
                ...state,
                channels: state.channels.filter(channel => channel.channel.id !== Number(action.payload.id)),
                actionForDeleteChannel: actionForState(state.action, 'success'),
            }
        case DELETE_ICAL_CHANNEL.error:
            toast.error(action.payload.message)
            return {
                ...state,
                actionForDeleteChannel: actionForState(state.action, 'error', action.payload.message),
            }

        case CHANGE_ICAL_CHANNEL_NAME.pending:
            return {
                ...state,
                actionForChangeChannelName: actionForState(state.action, 'pending'),
            }
        case CHANGE_ICAL_CHANNEL_NAME.success:
            toast.success("iCal channel name has been successfully edited.")
            const _index = state.channels.findIndex((channel) => channel.channel.id === action.payload.data.id);
            const targetChannel = state.channels[_index];
            const targetChannelUpdated = {...targetChannel, channel: action.payload.data};
            const _channelsUpdated = [...state.channels];
            _channelsUpdated[_index] = targetChannelUpdated;
            return {
                ...state,
                channels: _channelsUpdated,
                actionForChangeChannelName: actionForState(state.action, 'success'),
            }
        case CHANGE_ICAL_CHANNEL_NAME.error:
            toast.error(action.payload.message)
            return {
                ...state,
                actionForChangeChannelName: actionForState(state.action, 'error', action.payload.message),
            }
        
        default:
            return state;
    }
}