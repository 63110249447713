import {
    ADD_NEW_RATE,
    GET_RATE_LIST,
    GET_FEE_LIST,
    ADD_FEE,
    DELETE_FEE,
    EDIT_FEE,
    EDIT_RATES,
    ADD_NEW_TAX,
    DELETE_TAX,
    EDIT_TAX_LIST,
    EDIT_BULK_DEFAULT,
    CLEAR_ACTIONS,
    FETCH_RATE_CALENDAR,
    SET_SPECIAL_RATE,
    CHANGERATE_TIMELINE,
    SET_SPECIAL_RATE_MULTIPLE,
    FETCH_TAXES,
    SET_EXTRA_GUEST_FEE,
    CLEAR_CALENDAR_ACTION,
    ADD_NEW_WEEKLY_FIXED_RATE,
    ADD_NEW_WEEKLY_FIXED_FEE,
    ADD_NEW_WEEKLY_FIXED_TAX,
    ADD_NEW_MONTHLY_FIXED_RATE,
    ADD_NEW_MONTHLY_FIXED_FEE,
    ADD_NEW_MONTHLY_FIXED_TAX,
    SET_RATE_ADJUSTMENT,
    GET_RATE_ADJUSTMENT,
    GET_WEEKLY_FIXED_RATE,
    GET_WEEKLY_FIXED_FEE,
    GET_WEEKLY_FIXED_TAX,
    GET_MONTHLY_FIXED_RATE,
    GET_MONTHLY_FIXED_FEE,
    GET_MONTHLY_FIXED_TAX
  } from './types';
import {BACKEND_API} from "../../config/middleware";
import API from "../../config/api-url";

export const addRate = data => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:ADD_NEW_RATE,
            method: 'post',
            url: API.RATE,
            data
        },
    )
});

export const rateList = () => ({
    type: BACKEND_API,
        payload: Object.assign(
        {
            type:GET_RATE_LIST,
            method: 'get',
            url: API.RATE,
        },
    )
})

export const editRates=(data)=>({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:EDIT_RATES,
            method: 'put',
            url: `/api/rates/{id}`,
            data
          
        },
    )
})


export const editBulkDefault=(data)=>({
    type:BACKEND_API,
    payload:Object.assign(
        {
            type:EDIT_BULK_DEFAULT,
            method:'put',
            url:API.DEFAULT_BULK,
            data
        }
    )
})

export const addFee = data => ({
    type: BACKEND_API,
        payload: Object.assign(
        {
            type:ADD_FEE,
            method: 'post',
            url: API.FEE,
            data: data
        },
    )
});

export const editFee = data => ({
    type: BACKEND_API,
        payload: Object.assign(
        {
            type: EDIT_FEE,
            method: 'put',
            url: API.FEE,
            data: data
        },
    )
});

export const getFeeList = () => ({
    type: BACKEND_API,
        payload: Object.assign(
        {
            type: GET_FEE_LIST,
            method: 'get',
            url: API.FEE
        },
    )
});

export const deleteFee = id => ({
    type: BACKEND_API,
        payload: Object.assign(
        {
            type: DELETE_FEE,
            method: 'delete',
            url: `${API.FEE}${id}`,
        },
    )
})

export const addExtraGuestFee=(data)=>({
    type:BACKEND_API,
    payload: Object.assign(
        {
            type:SET_EXTRA_GUEST_FEE,
            method:'post',
            url:`${API.FEE}extraGuestFees`,
            data
        }
    )
})

export const clearAction = ()=>({
    type:CLEAR_ACTIONS,
})

export const fetchTax=()=>({
    type:BACKEND_API,
    payload:Object.assign({
        type:FETCH_TAXES,
        method:'get',
        url:`${API.TAX}`
    })
})

export const addTax =data=>({
    type:BACKEND_API,
    payload:Object.assign({
        type:ADD_NEW_TAX,
        method:'post',
        url:`${API.TAX}`,
        data
    })
})

export const deleteTax=id=>({
    type: BACKEND_API,
    payload: Object.assign({
        type:DELETE_TAX,
        method:'delete',
        url:`${API.TAX}${id}`,
    })
})
export const editTax=data=>({
    type: BACKEND_API,
    payload: Object.assign({
        type:EDIT_TAX_LIST,
        method:'put',
        url:`${API.TAX}`,
        data
    })
})

export const setSpecialRate=data=>({
    type:BACKEND_API,
    payload: Object.assign({
        type:SET_SPECIAL_RATE,
        method:'post',
        url:`${API.SPECIAL_RATE}`,
        data
    })
})

export const setMultipleRate=data=>({
    type:BACKEND_API,
    payload: Object.assign({
        type:SET_SPECIAL_RATE_MULTIPLE,
        method:'post',
        url:`${API.SPECIAL_RATE}`,
        data
    })
})

export const changedRatesInTimeline=data=>({
    type:BACKEND_API,
    payload: Object.assign({
        type:CHANGERATE_TIMELINE,
        method:'post',
        url:`${API.CHANGEDRATE_TIMELINE}`,
        data
    })
})

export const fetchRatesCal=data=>({
    type:BACKEND_API,
    payload:Object.assign({
        type:FETCH_RATE_CALENDAR,
        method:'post',
        url:`${API.FETCH_CAL}`,
        data
    })
})

export const clearCalendarAction = ()=>({
    type:CLEAR_CALENDAR_ACTION,
})

export const addWeeklyFixedRate = data => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:ADD_NEW_WEEKLY_FIXED_RATE,
            method: 'post',
            url: API.WEEKLE_FIXED_RATE,
            data
        },
    )
});

export const addWeeklyFixedFee = data => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:ADD_NEW_WEEKLY_FIXED_FEE,
            method: 'post',
            url: API.WEEKLE_FIXED_FEE,
            data
        },
    )
});

export const addWeeklyFixedTax = data => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:ADD_NEW_WEEKLY_FIXED_TAX,
            method: 'post',
            url: API.WEEKLE_FIXED_TAX,
            data
        },
    )
});

export const addMonthlyFixedRate = data => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:ADD_NEW_MONTHLY_FIXED_RATE,
            method: 'post',
            url: API.MONTHLY_FIXED_RATE,
            data
        },
    )
});

export const addMonthlyFixedFee = data => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:ADD_NEW_MONTHLY_FIXED_FEE,
            method: 'post',
            url: API.MONTHLY_FIXED_FEE,
            data
        },
    )
});

export const addMonthlyFixedTax = data => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:ADD_NEW_MONTHLY_FIXED_TAX,
            method: 'post',
            url: API.MONTHLY_FIXED_TAX,
            data
        },
    )
});

export const setRateAdjustment=(data)=>({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type: SET_RATE_ADJUSTMENT,
            method: 'put',
            url: API.SET_RATE_ADJUSTMENT,
            data
          
        },
    )
});

export const getRateAdjustment = () => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:GET_RATE_ADJUSTMENT,
            method: 'get',
            url: API.GET_RATE_ADJUSTMENT,
        },
    )
});


/* M02-12 , M02-13, M02-14*/
export const getWeeklyFixedRate = () => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:GET_WEEKLY_FIXED_RATE,
            method: 'get',
            url: API.WEEKLE_FIXED_RATE,
        },
    )
});

export const getWeeklyFixedFee = () => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:GET_WEEKLY_FIXED_FEE,
            method: 'get',
            url: API.WEEKLE_FIXED_FEE,
        },
    )
});

export const getWeeklyFixedTax = () => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:GET_WEEKLY_FIXED_TAX,
            method: 'get',
            url: API.WEEKLE_FIXED_TAX,
        },
    )
});

export const getMonthlyFixedRate = () => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:GET_MONTHLY_FIXED_RATE,
            method: 'get',
            url: API.MONTHLY_FIXED_RATE,
        },
    )
});

export const getMonthlyFixedFee = () => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:GET_MONTHLY_FIXED_FEE,
            method: 'get',
            url: API.MONTHLY_FIXED_FEE,
        },
    )
});

export const getMonthlyFixedTax = () => ({
    type: BACKEND_API,
    payload: Object.assign(
        {
            type:GET_MONTHLY_FIXED_TAX,
            method: 'get',
            url: API.MONTHLY_FIXED_TAX,
        },
    )
});
