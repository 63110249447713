import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import BookingForm from './BookingForm';
import styles from './addnewbooking.module.css';

function AddNewBooking(props) {
  
  const gList= useSelector(({guests})=>guests && guests.guestList);
  const rentalList = useSelector(({rentals})=>rentals && rentals.rentals);
  const userId= useSelector(({user})=>user && user.user.id);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className={styles.modalTitle}>Add new booking</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BookingForm
            handleSave={(data,guestData,check) => props.handleSave(data,guestData,check)}
            rentals={rentalList}
            guestList={gList}
            userId={userId}
            newBookingFromEmptyNight={props.newBookingFromEmptyNight}
        />
      </Modal.Body>
    </>
  );
}

export default AddNewBooking;
