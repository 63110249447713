// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".slide_select_borderBox__3OS4l {\n  display: block;\n  width: -moz-fit-content;\n  width: fit-content;\n  border: 1px solid lightgray;\n  padding: 0.2em;\n  background-color: #fff;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 0.8rem;\n  margin-right: 1rem;\n}\n.slide_select_mainContainer__cTspR {\n  display: flex;\n  flex-direction: row;\n  padding-top: 3px;\n}\n.slide_select_scroll_menu_arrow__UvMku {\n  cursor: pointer;\n  padding: 0em 0.5em;\n  height: -moz-fit-content;\n  height: fit-content;\n  font-size: 0.8rem;\n  align-self: center;\n  color: #333;\n}\n.slide_select_middleSelection__14P9L {\n  width: -moz-fit-content;\n  width: fit-content;\n  text-align: center;\n  padding: 0em 0.3em;\n  min-width: 3.5rem;\n  color: #333;\n}\n@media (max-width: 990px){\n  .slide_select_middleSelection__14P9L{\n    font-size: 13px;\n  }\n}\n@media (max-width: 575px){\n  .slide_select_middleSelection__14P9L{\n    font-size: 12px;\n  }\n  .slide_select_borderBox__3OS4l {\n    margin-right: 0.5rem;\n  }\n}", ""]);
// Exports
exports.locals = {
	"borderBox": "slide_select_borderBox__3OS4l",
	"mainContainer": "slide_select_mainContainer__cTspR",
	"scroll_menu_arrow": "slide_select_scroll_menu_arrow__UvMku",
	"middleSelection": "slide_select_middleSelection__14P9L"
};
module.exports = exports;
