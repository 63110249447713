import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Form, Spinner } from 'react-bootstrap';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";
import { ErrorMessage } from 'formik';
import { currencies } from '../../config/data/currencies';
import styles from './register.module.css';


const RegisterForm = (props) => {

    const {
        values,
        errors,
        handleChange,
        setFieldValue,
        handleSubmit
    } = props;

    const registerStatus = useSelector(({ user }) => user && user.registerAction);
    const options = useMemo(() => countryList().getData(), []);
  
    const customTheme = {
        control: (provided) => ({
        ...provided,
        border: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        }),
        menu: (provided) => ({
        ...provided,
        border: '1px solid lightgrey',
        boxShadow: 1,
        }),
    };

    return (
        <Form onSubmit={handleSubmit}>
            <div className={`input-group ${styles.input_group}`}>
                <Form.Label className={`pl-2 col-form-label ${styles.input_group_text}`}>
                    First name: * 
                </Form.Label>
                <input
                onChange={handleChange}
                type="text"
                name='firstName'
                className={`form-control ${styles.form_control}`}
                aria-label="firstName"
                aria-describedby="firstName"
                value={values.firstName}
                />
            </div>
            <div className={`d-flex ${!errors.firstName && 'mb-3'}`}>
                <span className={styles.error_message}></span>
                <ErrorMessage name='firstName' component="div" className="text-danger"/>
            </div>

            <div className={`input-group ${styles.input_group}`}>
                <Form.Label className={`pl-2 col-form-label ${styles.input_group_text}`}>
                    Last name: * 
                </Form.Label>
                <input
                onChange={handleChange}
                type="text"
                name='lastName'
                className={`form-control ${styles.form_control}`}
                aria-label="lastName"
                aria-describedby="lastName"
                value={values.lastName}
                />
            </div>
            <div className={`d-flex ${!errors.lastName && 'mb-3'}`}>
                <span className={styles.error_message}></span>
                <ErrorMessage name='lastName' component="div" className="text-danger"/>
            </div>

            <div className={`input-group ${styles.input_group}`}>
                <Form.Label className={`pl-2 col-form-label ${styles.input_group_text}`}>
                    Email address: * 
                </Form.Label>
                <input
                onChange={handleChange}
                type="email"
                name='emailId'
                className={`form-control ${styles.form_control}`}
                aria-label="emailId"
                aria-describedby="emailId"
                value={values.emailId}
                />
            </div>
            <div className={`d-flex ${!errors.emailId && 'mb-3'}`}>
                <span className={styles.error_message}></span>
                <ErrorMessage name='emailId' component="div" className="text-danger"/>
            </div>

            <div className={`input-group mb-3 ${styles.input_group}`}>
                <Form.Label className={`pl-2 col-form-label ${styles.input_group_text}`}>
                    Company name: 
                </Form.Label>
                <input
                onChange={handleChange}
                type="text"
                name="companyName"
                className={`form-control ${styles.form_control}`}
                aria-label="companyName"
                aria-describedby="companyName"
                value={values.company}
                />
            </div>

            <div className={`input-group ${styles.input_group}`}>
                <Form.Label className={`pl-2 col-form-label ${styles.input_group_text}`}>
                    Select country: *
                    <InfoOutlinedIcon
                        fontSize="small"
                        color="inherit"
                        data-tip
                        data-for="country"
                        className={styles.toolTipIcon}
                    />
                    <ReactTooltip place="bottom" type="dark" id="country" effect="solid" className={styles.toolTip}>
                        <span>
                            If your rentals are in different countries, you can set different location for different rentals later.
                        </span>
                    </ReactTooltip>
                </Form.Label>
                <div className={`form-control ${styles.form_control} ${styles.selector}`}>
                <Select
                    type="text"
                    options={options}
                    value={values.country}
                    onChange={value => setFieldValue('country', value)}
                    styles={customTheme}
                />
                </div>
            </div>
            <div className={`d-flex ${!errors.country && 'mb-3'}`}>
                <span className={styles.error_message}></span>
                <ErrorMessage name='country' component="div" className="text-danger"/>
            </div>

            <div className={`input-group ${styles.input_group}`}>
                <Form.Label className={`pl-2 col-form-label ${styles.input_group_text}`}>
                    Currency: * 
                    <InfoOutlinedIcon
                        fontSize="small"
                        color="inherit"
                        data-tip
                        data-for="currency"
                        className={styles.toolTipIcon}
                    />
                    <ReactTooltip place="bottom" type="dark" id="currency" effect="solid" className={styles.toolTip}>
                        <span>
                            If your rentals use different currencies, you can set currency per location later.
                        </span>
                    </ReactTooltip>
                </Form.Label>
                <div className={`form-control ${styles.form_control} ${styles.selector}`}>
                <Select
                    type="text"
                    options={currencies}
                    value={values.currency}
                    onChange={value => setFieldValue('currency', value)}
                    styles={customTheme}
                />
                </div>
            </div>
            <div className={`d-flex ${!errors.currency && 'mb-3'}`}>
                <span className={styles.error_message}></span>
                <ErrorMessage name='currency' component="div" className="text-danger"/>
            </div>

            <div className="d-flex justify-content-center pt-3">
                <button type="submit" className={styles.register_submit} disabled={registerStatus.loading}>
                { 
                    registerStatus.loading ? 
                    <>
                        <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        style={{marginRight:'1em', height:'1.3em', width:'1.3em'}}
                        />
                        <span>Create account</span>
                    </>
                    :
                    <span>Create account</span>
                }
                </button>
                { registerStatus.success  && <Redirect to="/login" /> }
            </div>
        </Form>
    )
}

export default RegisterForm;