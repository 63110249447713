// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".payment_center__135Tm {\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%,-50%);\n    position: absolute;\n}", ""]);
// Exports
exports.locals = {
	"center": "payment_center__135Tm"
};
module.exports = exports;
