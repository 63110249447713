import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { addProperty, channelMapping } from '../../../../../../../general_redux/ota/actions';
import { loadProperty } from '../../../../../../../general_redux/rentals/actions';

import Table from '../Table';
import { TTextLabel, TConnectButton, TMapButton, TViewButton } from '../components';
import ConfirmMessage from '../../ConfirmMessage/ConfirmMessage';
import Loading from '../../../../../../../components/loading';
import { customClassification } from '../../../../../../../common/functions/utils';


const Properties = (props) => {

  const dispatch = useDispatch();

  const {
    propertyData,
    setPropertyData,
    onShowWidget,
    onShowOTAsTable,
  } = props;

  const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
  const properties = useSelector(({ rentals }) => rentals && rentals.properties);
  const actionForProperty = useSelector(({ rentals }) => rentals && rentals.actionForProperty);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [changeStatusModalConfirm, setChangeStatusModalConfirm] = useState(false);
  const [confirmHeaderText, setConfirmHeaderText] = useState(false);
  const [confirmBodyText, setConfirmBodyText] = useState(false);

  useEffect(() => {

    setConfirmHeaderText(propertyData?.connectedWithSU ?
      'Disconnect property'
      :
      'Connect property'
    );

    setConfirmBodyText(propertyData?.connectedWithSU ?
      'Are you sure you want to this disconnect property?'
      :
      'Are you sure you want to this connect property?'
    );

  }, [propertyData, properties]);

  const getPropertyData = (propertyId) => properties.find(property => property.id == propertyId);

  const handleMapButton = (data) => {
    if (data.connectedWithSU) {
      const payload = { 'propertyId': data.id };
      dispatch(channelMapping(payload)).then(() => onShowWidget());
      setPropertyData(getPropertyData(payload.propertyId));
    } else {
      toast.warning('Please connect the property before mapping.');
    };
  };
  
  const handleChangeStatus = (data) => {
    setPropertyData(data);
    setChangeStatusModalConfirm(true);
  };

  const handleChangeStatusConfirm = () => {
    if (propertyData) {
      const payload = { "propertyId": propertyData.id };
      dispatch(addProperty(payload)).then(() => {
        dispatch(loadProperty());
      });
      setPropertyData(null);
    };
    setChangeStatusModalConfirm(false);
  };

  const handleViewButton = (data) => {
    setPropertyData(data);
    onShowOTAsTable();
  };

  const cols = [
    { label: 'Property', key: 'name', component: 'TTextLabel' },
    { label: 'Status', key: 'connectedWithSU', component: 'TTextLabel' },
    { label: 'Connect', key: '', component: 'TConnectButton' },
    { label: 'Map', key: '', component: 'TMapButton' },
    { label: 'View', key: '', component: 'TViewButton' },
  ];

  return (
    <div>
      <Loading loadingStatus={actionForProperty.loading} />
      <ConfirmMessage
        show={changeStatusModalConfirm}
        onHide={() => setChangeStatusModalConfirm(false)}
        confirmHeader={confirmHeaderText}
        confirmBody={confirmBodyText}
        onConfirmAct={handleChangeStatusConfirm}
        status={propertyData?.connectedWithSU}
      />
      <Container fluid>
        <Row className='justify-content-left'>
          <Col xs={12} md={8} lg={8} xl={6} className={`m-0 p-0`}>
            <Table
              cols={cols}
              rows={customClassification(properties, order, orderBy)}
              order={order}
              setOrder={setOrder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              tableName={'property'}
              dropdownListData={rentals}
              handleConnectButton={handleChangeStatus}
              handleMapButton={handleMapButton}
              handleViewButton={handleViewButton}
              TTextLabel={TTextLabel}
              TConnectButton={TConnectButton}
              TMapButton={TMapButton}
              TViewButton={TViewButton}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Properties;