import { actionForState } from "../../common/functions/utils";

import {
    SU_ADD_PROPERTY,
    SU_DELETE_PROPERTY,
    CHANNEL_MAPPING,
} from './types';

import { toast } from "react-toastify";
toast.configure();


export const INITIAL_STATE = {
    channelMappingData: {},
    channels: [],
    properties: [],
    actionForGetChannels: {
        loading: false,
        success: false,
        error: false,
    },
    actionForChannelMapping: {
        loading: false,
        success: false,
        error: false,
    },
    actionForAddProperty: {
        loading: false,
        success: false,
        error: false,
    },
    actionForDeleteProperty: {
        loading: false,
        success: false,
        error: false,
    },
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case "ADD_CHANNELS_MOCK_SUCCESS":
            const channelsMock = action.payload;
            return {
                ...state,
                channels: channelsMock,
                actionForGetChannels: actionForState(state.action, 'success'),
            }
        case CHANNEL_MAPPING.pending:
            return {
                ...state,
                actionForChannelMapping: actionForState(state.action, 'pending'),
            }
        case CHANNEL_MAPPING.success:
            const channelMappingData = action.payload.data;
            return {
                ...state,
                channelMappingData: channelMappingData,
                actionForChannelMapping: actionForState(state.action, 'success'),
            }
        case CHANNEL_MAPPING.error:
            toast.error(action.payload.message)
            return {
                ...state,
                actionForChannelMapping: actionForState(state.action, 'error', action.payload.message),
            }
        
        case SU_ADD_PROPERTY.pending:
            return {
                ...state,
                actionForAddProperty: actionForState(state.action, 'pending'),
            }
        case SU_ADD_PROPERTY.success:
            toast.success(action.payload.message);
            return {
                ...state,
                actionForAddProperty: actionForState(state.action, 'success'),
            }
        case SU_ADD_PROPERTY.error:
            toast.error(action.payload.message)
            return {
                ...state,
                actionForAddProperty: actionForState(state.action, 'error', action.payload.message),
            }

        case SU_DELETE_PROPERTY.pending:
            return {
                ...state,
                actionForDeleteProperty: actionForState(state.action, 'pending'),
            }
        case SU_DELETE_PROPERTY.success:
            toast.success("Property has been successfully deleted.");
            return {
                ...state,
                actionForDeleteProperty: actionForState(state.action, 'success'),
            }
        case SU_DELETE_PROPERTY.error:
            toast.error(action.payload.message)
            return {
                ...state,
                actionForDeleteProperty: actionForState(state.action, 'error', action.payload.message),
            }
        
        default:
            return state;
    }
}