import React from 'react';
import {useSelector} from "react-redux";
import styles from './CustomDate.module.css'; 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CustomDate(props){
    const dateDisplay=useSelector(({generalSetting})=>generalSetting && generalSetting.setting && generalSetting.setting.dateDisplay);
  
    return(
            <DatePicker
                wrapperClassName="w-100"
                selected={props.value}
                onChange={(date) => {
                    props.handleChange(date);
                }}
                showYearDropdown
                dateFormat={dateDisplay}
                dateFormatCalendar="MMMM"
                yearDropdownItemNumber={150}
                scrollableYearDropdown
                readOnly={props.readOnly}
                className={props.hasError ? (props?.customClass || styles.form_entry_error) : (props?.customClass || styles.form_entry)}
                maxDate={props.max}
                minDate={props.min}
            />
    );
};

export default CustomDate;