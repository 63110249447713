import React, { useEffect, useRef, useState } from "react";
import Loading from '../../../../../../components/loading';


const SuWidget = () => {

    const divRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!divRef.current) return;

        const observer = new MutationObserver(() => {
            if (divRef.current?.children.length > 0) {
                setIsLoading(false);
            } else {
                setIsLoading(true);
            }
        });

        observer.observe(divRef.current, { childList: true, subtree: true });

        return () => observer.disconnect();
    }, []);

    return (
        <>
            <Loading loadingStatus={isLoading} />
            <div id='su-channel-widget' ref={divRef} />
        </>
    );

};

export default SuWidget;