import React from 'react';
import { Modal } from 'react-bootstrap';
import * as yup from 'yup';
import { withFormik } from 'formik';
import {useSelector, useDispatch} from "react-redux";
import countryList from "react-select-country-list";
import { findCountry } from '../../../../../../common/functions/utils';
import {addGuests} from "../../../../../../general_redux/guest/actions";
import AddGuestForm from "../../../Guest/components/AddGuests/AddGuestForm";
import styles from './addspecialbooking.module.css';

function AddGuest(props) {
    const dispatch = useDispatch();
    const user= useSelector(({user})=>user && user.user);
    const dateDisplay = useSelector(({generalSetting})=>generalSetting && generalSetting.setting && generalSetting.setting.dateDisplay);

    const findArray = (val)=>{
        const ar=countryList().getData().filter((row)=>row.label===val);
        if(ar.length!==0){
            return ar[0];
        }
    }

    const handleSubmit=(data)=>{
        const payload={
            name: data.name,
            emailId:data.emailId,
            phoneNo:data.phoneNo.split(" ")?.length ===1 ? "" : data.phoneNo,
            guestType: data.guestType,
            secondaryPhoneNo: data.secondaryPhoneNo.split(" ")?.length ===1 ? "" : data.secondaryPhoneNo,
            postalCode: data.postalCode,
            secondaryEmailId: data.secondaryEmailId ,
            country: (data.country)?data.country.label:"",
            address: data.addressDetail,
            street: data.street,
            state:data.state,
            nationality:data.nationality,
            language:data.language,
            company:data.company,
            notes:data.notes,
            dob:data.dob,
        }
        dispatch(addGuests(payload));
        props.handleClose();
    }

    const phoneRegExp = /^[0-9-+\s()]*$/;

    const FormikEnhancer = withFormik({
        validationSchema:yup.object({
            name: yup.string().required('Guest name is a required field'),
            company: yup.string().nullable(),
            emailId:yup.string().email('Invalid email').required('Email is a required field'),
            secondaryEmailId :yup.string().email('Invalid email'),
            phoneNo:yup.string().matches(phoneRegExp, 'Only numbers, space, parenthesis, + and - are accepted.'),
            secondaryPhoneNo: yup.string().matches(phoneRegExp, 'Only numbers, space, parenthesis, + and - are accepted.'),
            country:yup.array().nullable(),
            address: yup.string(),
            postalCode: yup.string(),
            addressDetail:yup.string(),
            street: yup.string().nullable(),
            state: yup.string(),
            nationality:yup.string(),
            language:yup.string(),
            notes:yup.string(),
            dob: yup.string(),
            code:yup.string(),
        }),
        mapPropsToValues: (props)=>({
            name: "",
            company: "",
            emailId: "",
            secondaryEmailId: "",
            phoneNo: "",
            secondaryPhoneNo: "",
            postalCode: "",
            guestType: "guest",
            secondaryPhoneNo: "",
            addressDetail: "",
            country: findArray(user.country),
            address: "",
            nationality: "",
            street: "",
            state: "",
            language: "",
            notes: "",
            dob: "",
            code: findCountry(user.country),
            dateDisplay:dateDisplay,
        }),
        validateOnBlur:false,
        validateOnChange:false,
        handleSubmit :  (values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
        },
        displayName: 'AddGuestForm',
    })(AddGuestForm);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title className={styles.modalTitle}>Add Guest</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormikEnhancer customFieldClass={"col-12"} customLabelClass={"col-12"}/>
            </Modal.Body>
        </>
    )
}

export default AddGuest;