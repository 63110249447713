import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import * as yup from 'yup';
import styles from './register.module.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import logo from '../../icons/logo.png';
import ContactUs from "../Home/Containers/ContactUs";
import { registerUser, clearRegisterAction } from '../../general_redux/user/actions';
import RegisterForm from './RegisterForm'

const Register = () => {
  const [viewContactUs, setViewContactUs] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = (data) => {
    const payload = {
      name: `${data.firstName} ${data.lastName}`,
      emailId: data.emailId,
      companyName: data.companyName,
      country: data.country.label,
      currency: data.currency.label,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    // console.log(payload);
    dispatch(registerUser(payload))
    .then(() => {
      dispatch(clearRegisterAction());
    });
  };
  
  const initialValues = {
    firstName: '',
    lastName: '',
    emailId: '',
    companyName: '',
    country: '',
    currency: {value:"USD", label:"USD"},
  };
  
  const validationSchema = yup.object({
    firstName: yup.string().required('First name is a required field'),
    lastName: yup.string().required('Last name is a required field'),
    emailId: yup.string().required('Email is a required field'),
    companyName: yup.string(),
    country: yup.object().required('Country is a required field'),
    currency: yup.object().required('Currency is a required field'),
  });
  
  const FormikEnhancer = withFormik({
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    mapPropsToValues: () => (initialValues),
    handleSubmit: (values, { setSubmitting }) => {
      handleSubmit(values);
      setSubmitting(false);
    },
    displayName: 'RegisterForm',
  })(RegisterForm);

  return (
      <>
        <Header/>
        <ContactUs
            show={viewContactUs}
            onHide={() => {
              setViewContactUs(false);
            }}
        />
        <div className="d-flex justify-content-center">
          <div className={`${styles.mainContainer} ${styles.register}`}>
            <div className={`${styles.register_wrapper} shadow`}>
              <div className="d-flex justify-content-center">
                <Link to="/">
                  <img src={logo} className={styles.register_image} />
                </Link>
              </div>

              <div className={`d-flex justify-content-center flex-column pb-3`}>
                <h4 className="text-center font-weight-bold">Account Set Up</h4>
              </div>
              
              <div className="d-flex justify-content-center pt-4">
                <FormikEnhancer />
              </div>
              <div className="d-flex justify-content-center">
                <Link to="/login" className={styles.back_to_home}>
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer setViewContactUs={() => setViewContactUs(true)}/>
      </>
  );
}

export default Register;
