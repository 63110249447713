import React from 'react';
import { useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';
import * as yup from 'yup';
import { withFormik } from 'formik';
import RentalForm from "./RentalForm";
import styles from '../../rentals.module.css';
import rentalType from "../../../../../../config/data/rentalType";



const AddEditRental = (props) => {
    const properties = useSelector(({ rentals }) => rentals && rentals.properties);
    const groups = useSelector(({rentals}) => rentals && rentals.groups);
    
    const {
        show,
        modalType,
        value,
        onEdit,
        onHide
    } = props;

    const findDefaultValue = (array, type) => {
        const defaultValue = type === "property" ? array[0] : array.find(item => item.groupName === "Default");
        if(defaultValue){
            return defaultValue.id;
        } else {
            return "";
        }
    }
    
    const handleSubmit = (data) => {

        const payload = {
            name: data.name,
            groupId: Number(data.groupId),
            propertyId: Number(data.propertyId),
            addressInformation: data.addressInformation,
            parentRentalId: data.parentRentalId ? Number(data.parentRentalId) : null,
            rentalType: data.rentalType,
            maxOccup: Number(data.maxOccup),
            maxChild: Number(data.maxChild),
            wifiName: data.wifiName,
            wifiPassword: data.wifiPassword,
            securityCode: data.securityCode,
            notes: data.notes,
        }

        if (modalType === 'edit') {
            payload['id'] = data.id[0]
            // console.log(payload, 'Edit Rental');
            onEdit(payload);
        } else {
            payload['numberOfUnits'] = Number(data.numberOfUnits);
            // console.log(payload, 'Add Rentals');
            onHide(payload);
        };
    }

    const initialValues = {
        numberOfUnits: modalType === 'edit' ? undefined : '1',
        name: value?.name ? value.name : '',
        id: value?.id ? [value.id] : [],
        groupId: value?.groupId ? value.groupId : findDefaultValue(groups, "group"),
        propertyId: value?.propertyId ? value.propertyId : findDefaultValue(properties, "property"),
        addressInformation: value?.addressInformation ? value.addressInformation : '',
        parentRentalId: value?.parentRentalId ? value.parentRentalId : null,
        rentalType: value?.rentalType ? value.rentalType : rentalType[0].value,
        maxOccup: value?.maxOccup ? value.maxOccup : 1,
        maxChild: value?.maxChild ? value.maxChild : 0,
        wifiName: value?.wifiName ? value.wifiName : '',
        wifiPassword: value?.wifiPassword ? value.wifiPassword : '',
        securityCode: value?.securityCode ? value.securityCode : '',
        notes: value?.notes ? value.notes : '',
    }

    const FormikEnhancer = withFormik({
        validationSchema:yup.object({
            name: yup.string().required('Name is a required field').max(50, 'Must be 50 characters or less'),
            numberOfUnits: yup.number(),
            id: modalType === 'add' ? yup.array().of(yup.number()) : yup.array().of(yup.number()).test('minLength', 'Rental selection is required', value => value && value.length > 0),
            groupId: yup.number(),
            propertyId: yup.number().required('Property is a required field'),
            addressInformation: yup.string(),
            parentRentalId: yup.mixed(),
            rentalType: yup.string(),
            maxOccup: yup.number().integer('Must be an integer number').min(1, 'Must be a number greater than or equal to 1').typeError('Must be a value of type number'),
            maxChild: yup.number().integer('Must be an integer number').min(0, 'Must be a number greater than or equal to 0').typeError('Must be a value of type number').test('maxChildRequired',
                'Must be a number less than total number of occupants', function (value) {
                return (value < this.parent.maxOccup);
            }),
            wifiName: yup.string(),
            wifiPassword: yup.string(),
            securityCode: yup.string(),
            notes: yup.string().max(250, 'Must be 250 characters or less'),
        }),
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: () => (initialValues),
        handleSubmit:  (values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);
        },
        displayName: 'RentalForm',
      })(RentalForm);

    return(
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>
                    { modalType === 'add' ? 
                        'Add rental'
                    :
                        'Edit rental'
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.deleteModalBody}>
                <FormikEnhancer modalType={modalType} />
            </Modal.Body>
        </Modal>
    )
}
export default AddEditRental;