import React, { useState,useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Modal } from 'react-bootstrap';
import {Container, Row, Col} from 'react-bootstrap';
import * as yup from "yup";
import moment from "moment";
import {Box, Popover} from "@material-ui/core";
import Table from '../../../../../../components/Table';
import InvoiceSend from "../../../../../../components/InvoiceSend";
import CreateMode from "../../../Calendar/components/EditBooking/components/CreateMode/CreateMode";
import Loading from "../../../../../../components/loading";
import {addInvoice, deleteInvoice, editInvoiceByGuest} from "../../../../../../general_redux/invoice/actions";
import styles from "../../../Calendar/components/EditBooking/editBooking.module.css";
import styles2 from "../../../Calendar/components/EditBooking/components/invoiceDetail.module.css";
import { getDateObjFromString } from '../../../../../../common/functions/utils';

function GuestInvoiceList(props) {

    const dispatch=useDispatch();
    
    const guestName=props.guestName;
    const guestDetails=props.guestDetail;

    const [selectedInvoice, setSelectedInvoice]=useState(null);
    const bookingId = props.bookingId ? props.bookingId : selectedInvoice ? selectedInvoice.bookingId : "";

    const bookingData = useSelector(({calendar}) => calendar && calendar.allBooking.filter((row) => row.id === bookingId)[0]);
    const settings = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);
    const actionForEditInvoice = useSelector(({ invoice }) => invoice && invoice.actionForEditInvoice);
    const actionForDeleteInvoice = useSelector(({ invoice }) => invoice && invoice.actionForDeleteInvoice);

    const initialValueForInvoiceItem = {
        id: 1,
        title: (bookingData) ? `${bookingData.rentalName}, ${moment(bookingData.arrive).format('Mo MMM YYYY')} - ${moment(bookingData.depart).format('Mo MMM YYYY')}, ${bookingData.noOfAdults + bookingData.noOfChildren} Guest(s)`: `Booking`,
        fee: Number(bookingData?.price ? Number(bookingData.price) : 0),
    };

    const [viewSendInvoice, setViewSendInvoice] = useState(false);
    const [createMode,setCreateMode]=useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentDelete,setCurrentDelete]=useState(null);
    const [invoiceItems, setInvoiceItems] = useState(selectedInvoice?.invoiceItem || [initialValueForInvoiceItem]);
    const [feeItems, setFeeItems] = useState(selectedInvoice?.feeItems || []);
    const [taxItems, setTaxItems] = useState(selectedInvoice?.taxItems || []);

    const handleDiscardChanges = () => {
        setCreateMode(false);
        setSelectedInvoice(null);
    }

    const updateValidationSchema = (invoiceItems, feeItems, taxItems) => {
        setInvoiceItems(invoiceItems);
        setFeeItems(feeItems);
        setTaxItems(taxItems);
    };

    useEffect(() => {
        if (actionForEditInvoice.success) {
            handleDiscardChanges();
        }
    }, [actionForEditInvoice.success]);

    const cols = [
        {lable: 'Created', key: 'invoiceDate'},
        {lable: 'Invoice No.', key: 'invoiceNumber'},
        {lable: 'Total', key: 'total'},
        {lable: 'Sent', key: 'markSent'},
        {lable: 'Payment Status', key: 'paymentStatus'},
        {lable: 'Actions', key: ''},
    ]

    const initialValues = {
        issueDate: selectedInvoice?.invoiceDate ? getDateObjFromString(selectedInvoice?.invoiceDate) : new Date(),
        dueDate: selectedInvoice?.dueDate ? getDateObjFromString(selectedInvoice?.dueDate) : new Date(),
        status: selectedInvoice?.paymentStatus || "Not Paid",
        r_name: selectedInvoice?.recipientDetail.name || "",
        r_comp: selectedInvoice?.recipientDetail.company || "",
        r_address1: selectedInvoice?.recipientDetail.address1 || "",
        r_address2: selectedInvoice?.recipientDetail.address2 || "",
        r_address3: selectedInvoice?.recipientDetail.address3 || "",
        s_name: selectedInvoice?.senderDetail.name || "",
        s_address1: selectedInvoice?.senderDetail.address1 || `${settings?.address1}`,
        s_address2: selectedInvoice?.senderDetail.address2 || `${settings?.address2}`,
        s_address3: selectedInvoice?.senderDetail.address3 || `${settings?.address3}`,
        s_country: selectedInvoice?.senderDetail.country || `${settings?.country}`,
        invoiceText: selectedInvoice?.invoiceText || "",
        invoiceFooter: selectedInvoice?.invoiceFooter || "",
        total: selectedInvoice?.total ? Number(selectedInvoice?.total) : 0,
        invoiceNr: selectedInvoice?.invoiceNumber ? Number(selectedInvoice?.invoiceNumber) : 0,
        bookingNumber: selectedInvoice?.bookingNumber ? Number(selectedInvoice?.bookingNumber) : 0,
        stripeButton: selectedInvoice?.stripeButton || false,
        markBookingAsPaid: selectedInvoice?.markBookingAsPaid || false,
        whenPaidMarkBookingAsPaid: selectedInvoice?.whenPaidMarkBookingAsPaid || false,
        tableData: selectedInvoice?.invoiceItem || [initialValueForInvoiceItem],
        tableData2: selectedInvoice?.feeItems || [],
        tableData3: selectedInvoice?.taxItems || [],
        ...(selectedInvoice?.invoiceItem || [initialValueForInvoiceItem]).reduce((acc, curr, index) => {
            acc[`invoice_table-1_description_${index + 1}`] = curr.title;
            acc[`invoice_table-1_amount_${index + 1}`] = Number(curr.fee);
            return acc;
        }, {}),
        ...(selectedInvoice?.feeItems || []).reduce((acc, curr, index) => {
            acc[`invoice_table-2_description_${index + 1}`] = curr.title;
            acc[`invoice_table-2_amount_${index + 1}`] = Number(curr.fee);
            return acc;
        }, {}),
        ...(selectedInvoice?.taxItems || []).reduce((acc, curr, index) => {
            acc[`invoice_table-3_description_${index + 1}`] = curr.title;
            acc[`invoice_table-3_amount_${index + 1}`] = Number(curr.tax);
            return acc;
        }, {}),
    };

    const validationSchema =  yup.object().shape({
        issueDate: yup.date().required('Issue Date is a required field').typeError('Must be a value of type date'),
        dueDate: yup.date().required('Due date is a required field').typeError('Must be a value of type date'),
        status: yup.string().required('Status of payment is a required field'),
        r_name: yup.string().required("Recipient's name is a required field"),
        r_comp: yup.string(),
        r_address1: yup.string(),
        r_address2: yup.string(),
        r_address3: yup.string(),
        s_name: yup.string().required("Sender name is a required field"),
        s_address1: yup.string(),
        s_address2: yup.string(),
        s_address3: yup.string(),
        s_country: yup.string(),
        invoiceText: yup.string(),
        invoiceFooter: yup.string(),
        total: yup.number().typeError('Must be a number greater than 0'),
        invoiceNr: yup.number().required('Invoice Nr is a required field').typeError('Must be a number greater than 0'),
        bookingNumber: yup.number().typeError('Must be a number greater than 0'),
        stripeButton: yup.bool().typeError('Must be a boolean value'),
        markBookingAsPaid: yup.bool().typeError('Must be a boolean value'),
        whenPaidMarkBookingAsPaid: yup.bool().typeError('Must be a boolean value'),
        ...invoiceItems.reduce((acc, curr, index) => {
            acc[`invoice_table-1_description_${index + 1}`] = yup.string().required('Description is a required field').max(150, 'Must be 150 characters or less');
            acc[`invoice_table-1_amount_${index + 1}`] = yup.number().required('Amount is a required field').typeError('Must be a value of type number');
            return acc;
        }, {}),
        ...feeItems.reduce((acc, curr, index) => {
            acc[`invoice_table-2_description_${index + 1}`] = yup.string().required('Description is a required field').max(150, 'Must be 150 characters or less');
            acc[`invoice_table-2_amount_${index + 1}`] = yup.number().required('Amount is a required field').positive('Must be a number greater than 0').typeError('Must be a value of type number');
            return acc;
        }, {}),
        ...taxItems.reduce((acc, curr, index) => {
            acc[`invoice_table-3_description_${index + 1}`] = yup.string().required('Description is a required field').max(150, 'Must be 150 characters or less');
            acc[`invoice_table-3_amount_${index + 1}`] = yup.number().required('Amount is a required field').positive('Must be a number greater than 0').typeError('Must be a value of type number');
            return acc;
        }, {}),
    });

    const handleDownload=(invoiceD)=>{
        window.open(invoiceD.downloadLink,"_parent")
    }

    const handleSendInvoice = (data) => {
        setSelectedInvoice(data);
        setViewSendInvoice(true);
    }

    const handleSendInvoiceEdit = (data) => {
        setViewSendInvoice(false);
        handleEdit(data);
    }
    
    const handleDelete=()=>{
        if(currentDelete && currentDelete.id){
            dispatch(deleteInvoice(currentDelete.id))
        }
        setAnchorEl(null);
        setCurrentDelete(null);
    }

    const handleEdit=(data)=>{
        setSelectedInvoice(data);
        setCreateMode(true);
    }

    const handleSubmit = (data, totalCost, tableData, tableData2, tableData3)=>{
        const payload={
            id: selectedInvoice.id,
            guestId:guestDetails.id,
            bookingId:selectedInvoice.bookingId,
            dueDate: moment(data.dueDate).utc(false).format('YYYY-MM-DD'),
            dueDateUtc: moment(data.dueDate).utc(true).format('YYYY-MM-DD HH:mm:ss'),
            invoiceDate: moment(data.issueDate).utc(false).format('YYYY-MM-DD'),
            invoiceDateUtc: moment(data.issueDate).utc(true).format('YYYY-MM-DD HH:mm:ss'),
            invoiceNumber: Number(data.invoiceNr || selectedInvoice.invoiceNumber),
            invoiceItem: tableData.map((item) => ({title: item.title, fee: Number(item.fee)})),
            feeItems: tableData2.map((item) => ({title: item.title, fee: Number(item.fee)})),
            taxItems: tableData3.map((item) => ({title: item.title, tax: Number(item.tax)})),
            currency:selectedInvoice.currency,
            total: Number(totalCost),   
            markSent:selectedInvoice.markSent || false,
            downloadLink:selectedInvoice?.downloadLink || "",
            recipientDetail:{
                name:data.r_name,
                company:data.r_comp,
                address1:data.r_address1,
                address2:data.r_address2,
                address3: data.r_address3,
                email:guestDetails.emailId,
            },
            senderDetail:{
                name:data.s_name,
                address1:data.s_address1,
                address2:data.s_address2,
                address3: data.s_address3,
                country: data.s_country
            },
            invoiceText:data.invoiceText,
            invoiceFooter:data.invoiceFooter,
            paymentStatus:data.status,
            bookingNumber: Number(data.bookingNumber) || 0,
            stripeButton: data.stripeButton,
            markBookingAsPaid: data.markBookingAsPaid,
            whenPaidMarkBookingAsPaid: data.whenPaidMarkBookingAsPaid,
        }
        if(!selectedInvoice){
            dispatch(addInvoice(payload));
        }else{
            dispatch(editInvoiceByGuest(payload))
        }
    }

    const onHide=()=>{
        setCreateMode(false);
        setSelectedInvoice(null);
    }

    const handleConfirmDialog = (e,row) => {
        setCurrentDelete(row);
        setAnchorEl( anchorEl ? null : e.currentTarget);
    }

    const invoiceDeleteDialog = () => {
        return (
            <Popover
                id={"popover-1"}
                open={Boolean(anchorEl)}
                onClose={handleConfirmDialog}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        borderRadius: 0,
                        display: "flex",
                    },
                }}
            >
                <div className={styles2.contentWrap}>
                    <div className={styles2.deleteTitle}>
                        Are you sure you want to delete this invoice?
                    </div>
                    <div className="text-center m-1">
                        <button
                            onClick={() => handleDelete()}
                            className={styles2.delConfirmBtn}
                        >
                            Delete
                        </button>
                        <button
                            onClick={handleConfirmDialog}
                            className={styles2.cancelBtn}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
                <Box
                    className={styles2.rightArrow}
                />
            </Popover>
        );
    };

    return (
        <div>
            <Loading loadingStatus={actionForEditInvoice.loading || actionForDeleteInvoice.loading} />
            <Modal
                show={createMode}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className={styles.modalTitle}>Edit Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body className={`px-sm-2 p-0 ${styles.modal_body}`}>
                    <div className="p-3">
                        <CreateMode
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            onReset={handleDiscardChanges}
                            bookingId={props.bookingId ? props.bookingId : selectedInvoice ? selectedInvoice.bookingId : ""}
                            updateValidationSchema={updateValidationSchema}
                        />
                    </div>
                </Modal.Body>
            </Modal>
            {guestName && (
                <div className={styles.guestName}>{`Guest: ${guestName}`}</div>
            )}
            <Container fluid>
                <Row >
                    <Col xs={12} md={8} className={`pt-3`}>
                        <Table
                            rows={props.invoiceGuest}
                            cols={cols}
                            startKey={cols[0].key}
                            onDownload={handleDownload}
                            onDelete={(val) => handleDelete(val)}
                            onEdit={(data)=>handleEdit(data)}
                            confirmDeleteDialog={invoiceDeleteDialog}
                            handleConfirmDialog={handleConfirmDialog}
                            onSendGuest={(data) => handleSendInvoice(data)}
                        />
                    </Col>
                </Row>
            </Container>
            <InvoiceSend
                show = {viewSendInvoice}
                onHide = {() => setViewSendInvoice(false)}
                invoiceData = {selectedInvoice}
                onEdit={(data) => handleSendInvoiceEdit(data)}
            />
        </div>
    )
}
export default GuestInvoiceList;