// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loading_loader__2Wmei{\n    position: 'absolute';\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    align-items: 'center';\n    justify-content: 'center';\n    background-color: transparent;\n}\n\n.loading_modalContent__1c_HM {\n    border:none !important;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: transparent !important;\n}\n", ""]);
// Exports
exports.locals = {
	"loader": "loading_loader__2Wmei",
	"modalContent": "loading_modalContent__1c_HM"
};
module.exports = exports;
