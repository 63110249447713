// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SearchableSelect_input__134BU {\n    background: #fff;\n    font-size: 0.8rem;\n    font-family: Montserrat;\n    width: 100%;\n    height: 38px;\n    margin: 0;\n    margin-bottom: 8px;\n    padding: 0 25px 0 8px;\n    border-radius: 5px;\n    border-left: 1px solid #ccc;\n    border-top: 1px solid #ccc;\n    border-bottom: 1px solid #ccc;\n    border-right: none;\n}\n\n.SearchableSelect_icon__131ho {\n    font-size: 16px !important;\n    padding-top: 10px;\n    padding-right: 8px;\n    margin-left: -18px;\n    height: 38px;\n    border-radius: 5px;\n    border-right: 1px solid #ccc;\n}\n\n.SearchableSelect_section__2wPLD {\n    background: #fff;\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n    border-radius: 5px;\n    border: 1px solid #ccc;\n    width: 100%;\n    max-height: 400px;\n    overflow-y: auto;\n    margin-top: 4px;\n    z-index: 1000;\n}  ", ""]);
// Exports
exports.locals = {
	"input": "SearchableSelect_input__134BU",
	"icon": "SearchableSelect_icon__131ho",
	"section": "SearchableSelect_section__2wPLD"
};
module.exports = exports;
