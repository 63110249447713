const asyncActionType = type => ({
    pending: `${type}_PENDING`,
    success: `${type}_SUCCESS`,
    error: `${type}_ERROR`
});

export const ADD_NEW_RATE = asyncActionType('ADD_NEW_RATE');
export const GET_RATE_LIST = asyncActionType('GET_RATE_LIST');
export const EDIT_RATES= asyncActionType('EDIT_RATES');
export const EDIT_BULK_DEFAULT= asyncActionType('EDIT_BULK_DEFAULT');

export const ADD_FEE = asyncActionType('ADD_FEE');
export const EDIT_FEE = asyncActionType('EDIT_FEE');
export const DELETE_FEE = asyncActionType('DELETE_FEE');
export const GET_FEE_LIST = asyncActionType('GET_FEE_LIST');
export const SET_SPECIAL_RATE = asyncActionType('SET_SPECIAL_RATE');
export const SET_SPECIAL_RATE_MULTIPLE=asyncActionType('SET_SPECIAL_RATE_MULTIPLE');
export const SET_EXTRA_GUEST_FEE = asyncActionType('SET_EXTRA_GUEST_FEE');
export const SET_RATE_ADJUSTMENT = asyncActionType('SET_RATE_ADJUSTMENT');
export const GET_RATE_ADJUSTMENT = asyncActionType('GET_RATE_ADJUSTMENT');

export const CHANGERATE_TIMELINE=asyncActionType('CHANGERATE_TIMELINE');
export const FETCH_RATE_CALENDAR=asyncActionType('FETCH_RATE_CALENDAR');

export const CLEAR_ACTIONS='CLEAR_ACTIONS';
export const CLEAR_CALENDAR_ACTION='CLEAR_CALENDAR_ACTION';
export const FETCH_TAXES=asyncActionType('FETCH_TAXES');
export const ADD_NEW_TAX = asyncActionType('ADD_NEW_TAX');
export const DELETE_TAX = asyncActionType('DELETE_TAX');
export const EDIT_TAX_LIST = asyncActionType('EDIT_TAX_LIST');

export const ADD_NEW_WEEKLY_FIXED_RATE = asyncActionType('ADD_NEW_WEEKLY_FIXED_RATE');
export const ADD_NEW_WEEKLY_FIXED_FEE = asyncActionType('ADD_NEW_WEEKLY_FIXED_FEE');
export const ADD_NEW_WEEKLY_FIXED_TAX = asyncActionType('ADD_NEW_WEEKLY_FIXED_TAX');

export const ADD_NEW_MONTHLY_FIXED_RATE = asyncActionType('ADD_NEW_MONTHLY_FIXED_RATE');
export const ADD_NEW_MONTHLY_FIXED_FEE = asyncActionType('ADD_NEW_MONTHLY_FIXED_FEE');
export const ADD_NEW_MONTHLY_FIXED_TAX = asyncActionType('ADD_NEW_MONTHLY_FIXED_TAX');

export const GET_WEEKLY_FIXED_RATE = asyncActionType('GET_WEEKLY_FIXED_RATE');
export const GET_WEEKLY_FIXED_FEE = asyncActionType('GET_WEEKLY_FIXED_FEE');
export const GET_WEEKLY_FIXED_TAX = asyncActionType('GET_WEEKLY_FIXED_TAX');

export const GET_MONTHLY_FIXED_RATE = asyncActionType('GET_MONTHLY_FIXED_RATE');
export const GET_MONTHLY_FIXED_FEE = asyncActionType('GET_MONTHLY_FIXED_FEE');
export const GET_MONTHLY_FIXED_TAX = asyncActionType('GET_MONTHLY_FIXED_TAX');

