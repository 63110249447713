// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".viewBlockDate_mainContainer__2jmAi{\n    padding:0.4em;\n}\n.viewBlockDate_sideText__1Mtbt{\n    color:grey;\n    padding-bottom: 0.5em;\n}\n.viewBlockDate_mainText__1jOH-{\n    font-weight: 400;\n    padding-bottom: 0.5em;\n}\n.viewBlockDate_icons__3L2X0 {\n    font-size: 14px !important;\n    margin-left: 3px !important;\n}", ""]);
// Exports
exports.locals = {
	"mainContainer": "viewBlockDate_mainContainer__2jmAi",
	"sideText": "viewBlockDate_sideText__1Mtbt",
	"mainText": "viewBlockDate_mainText__1jOH-",
	"icons": "viewBlockDate_icons__3L2X0"
};
module.exports = exports;
