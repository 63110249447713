import React from 'react';
import {Button,Modal} from 'react-bootstrap';
import styles from '../../../UserSetting/usersetting.module.css';

function WelcomeModal(props){

    const handleGettingStarted = () => {
        window.open('https://www.beehaz-resources.com/getting-started');
        props.onHide()
    }

    return(
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Welcome to Bee Haz!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles.deleteModalBody}>
                <p>
                    We have added the number of rentals you need to your account. Click
                    on the Rentals menu to add, edit and remove rentals. It is important to
                    edit your rentals and rates before adding a reservation to the calendar
                </p>
                <p>
                    The best way to get started is by watching our introductory video: click
                    the button bellow. If you prefer exploring on your own, just close this
                    message box.
                </p>
                <p>
                    Whenever you need to access our help page and guides, click on the
                    question mark icon in the top menu bar!
                </p>
            </Modal.Body>
            <div className={styles.modalFooter}>
                <button onClick={handleGettingStarted} className={styles.savBtn}>Getting started</button>
            </div>
        </Modal>
    )
}
export default WelcomeModal;