// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".generalStyles_weekcontainer__2y6du{\n    width: 100%;\n    height: auto;\n    padding:0.5em;\n    font-size: 0.9em;\n    display: flex;\n    flex-direction: row;\n}\n.generalStyles_weekDays__1S9gN{\n    background: #bfbfbf ;\n    display: block;\n    border: 1px solid #eeeeee;\n    padding:0.4em 0.3em;\n    margin-right: 0.4em;\n    text-align: center;\n    color:white;\n    border-radius: 0.9em;\n    min-width: 3em;\n    cursor:pointer;\n    \n}\n.generalStyles_weekDays_active__2_eVE{\n    background: grey ;\n    display: block;\n    border: 1px solid #eeeeee;\n    padding:0.4em 0.3em;\n    margin-right: 0.4em;\n    text-align: center;\n    color:white;\n    border-radius: 0.9em;\n    min-width: 3em;\n    cursor:pointer;\n}", ""]);
// Exports
exports.locals = {
	"weekcontainer": "generalStyles_weekcontainer__2y6du",
	"weekDays": "generalStyles_weekDays__1S9gN",
	"weekDays_active": "generalStyles_weekDays_active__2_eVE"
};
module.exports = exports;
