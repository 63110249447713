import React, {useEffect, useState} from 'react';
import getSymbolFromCurrency from 'currency-symbol-map'
import { Form,InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import styles from '../../../rates.module.css';
import {toast} from "react-toastify";
import MultiSelect from "../../BulkDefaultChanges/MultiSelect";
import {useSelector} from "react-redux";

function ExtraGuest({validationSchema, handleSubmit, initValue, currency}){
    const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
    const [count,setCount]=useState(0);
    const [selectedRentals, setSelectedRentals] = useState([]);
    const selectedCurr = initValue?.currency || currency;

    const sameCurrency=(row)=>{
      const currencyVal = rentals && rentals.length !== 0 && rentals[0].currency;
      return row.currency === currencyVal;
    };

    const getOptions = () => {
        const options = rentals.map((row) => ({ value: row.id, label: row.name }));
        return options;
    };

    function diffCurrencyWarning() {
        if (!rentals.every(sameCurrency)) {
            setCount(count+1);
        }
    };

    useEffect( () => {
        count === 1 && toast.warn(`Different currencies used in different rentals. Check the changes.`
            ,{
                style:{
                    color:'#484848'
                }
            });
    }, [count]);

    const resetForm = (handleReset) => {
        handleReset();
        setSelectedRentals([]);
    };

    return (
      <>
        <Formik
          validationSchema={validationSchema}
          initialValues={initValue}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ values, errors, handleReset, handleChange, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Row className="mb-3 mb-md-4 mt-md-2">
                <Form.Label className={`col-md-4 col-form-label`}>
                  Select rental:*
                </Form.Label>
                <div className="col-md-6">
                  <MultiSelect
                      options={getOptions(rentals)}
                      value={selectedRentals}
                      name="rentalIds"
                      onChange={(selectedOptions) => {
                          const selectedIds = selectedOptions.map(option => option.value);
                          setFieldValue('rentalIds', selectedIds);
                          setSelectedRentals(selectedOptions);
                      }}
                      onBlur={diffCurrencyWarning}
                      fieldHasError={errors.rentalIds}
                      placeholder={"---Select Rental---"}
                  />
                  <div
                    style={{
                      fontSize: "0.8em",
                      color: "#dc3545",
                      padding: "0em",
                    }}
                  >
                    {errors.rentalIds}
                  </div>
                </div>
              </Form.Row>
              <Form.Row className="mb-2">
                <Form.Label className="pt-md-0 d-flex align-content-center col-md-4 col-form-label">
                  Amount per guest, per night*:
                </Form.Label>
                <div className="col-md-6">
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      name="amount"
                      onChange={handleChange}
                      value={values.amount}
                      className={styles.form_entry}
                      isInvalid={!!errors.amount}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text
                        id="basic-addon2"
                        className={styles.form_entry}
                      >
                        {getSymbolFromCurrency(`${selectedCurr}`)}
                      </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                      {errors.amount}
                    </Form.Control.Feedback>
                  </InputGroup>
                </div>
              </Form.Row>
              <Form.Row className="mb-2">
                <Form.Label className="col-md-4 col-form-label">
                  For each guest after*:
                </Form.Label>
                <div className="mb-2 col-md-6">
                  <Form.Control
                    type="text"
                    name="guestLimit"
                    onChange={handleChange}
                    value={values.guestLimit}
                    className={styles.form_entry}
                    isInvalid={!!errors.guestLimit}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.guestLimit}
                  </Form.Control.Feedback>
                </div>
              </Form.Row>
              <Form.Row className="mb-3">
                <Form.Label className="col-md-4 col-form-label">
                  Does the extra guest fee apply to children?
                </Form.Label>
                <div className="d-flex align-content-center col-md-6">
                  <Form.Check
                    inline
                    name="extraChildFee"
                    label="Yes"
                    onChange={handleChange}
                    value={1}
                    type="radio"
                    checked={Boolean(Number(values.extraChildFee))===true}
                    className="ml-3 mr-2"
                  />
                  <Form.Check
                    inline
                    name="extraChildFee"
                    label="No"
                    onChange={handleChange}
                    value={0}
                    type="radio"
                    checked={Boolean(Number(values.extraChildFee))===false}
                    className="ml-5"
                  />
                </div>
              </Form.Row>
              { !Boolean(Number(values.extraChildFee)) &&
              <>
              <Form.Row className="mb-3">
                <Form.Label className="pt-md-0 d-flex align-content-center col-md-4 col-form-label">
                  Amount per child, per night:
                </Form.Label>
                <div className="col-md-6">
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="usdPerChild"
                      onChange={handleChange}
                      value={values.usdPerChild}
                      className={styles.form_entry}
                      isInvalid={!!errors.usdPerChild}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text
                        id="basic-addon2"
                        className={styles.form_entry}
                      >
                        {getSymbolFromCurrency(`${selectedCurr}`)}
                      </InputGroup.Text>
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">
                      {errors.usdPerChild}
                    </Form.Control.Feedback>
                  </InputGroup>
                </div>
              </Form.Row>
              </>
              }
              <div className="w-100 mt-4 d-flex justify-content-center">
                <div className="col-sm-8 d-flex justify-content-around">
                  <button
                    type="submit"
                    className={`p-1 mx-2 col-5 ${styles.allBtn}`}
                  >
                    Save
                  </button>
                  <button
                    type="reset"
                    className={`p-1 mx-2 col-5 ${styles.allBtn}`}
                    style={{ backgroundColor: "#6c757d" }}
                    onClick={() => resetForm(handleReset)}
                  >
                    Discard
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
};

export default ExtraGuest;