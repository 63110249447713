const rentalType = [
    { value: 'Apartment', label: 'Apartment' },
    { value: 'Studio', label: 'Studio' },
    { value: 'Bed in Dormitory', label: 'Bed in dormitory' },
    { value: 'Dormitory room', label: 'Dormitory room' },
    { value: 'Bungalow', label: 'Bungalow' },
    { value: 'Chalet', label: 'Chalet' },
    { value: 'Holiday home', label: 'Holiday home' },
    { value: 'Villa', label: 'Villa' },
    { value: 'Mobile home', label: 'Mobile home' },
    { value: 'Tent', label: 'Tent' },
    { value: 'Powered/Unpowered Site', label: 'Powered/Unpowered Site' },
];

export default rentalType;