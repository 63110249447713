import React from "react";
import { SubdirectoryArrowRight, Visibility } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Button } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import Toggle from "react-toggle";
import moment from "moment";

import { customSort } from "../../../../../../../common/functions/utils";
import styles from "../table.module.css";


const DropdownComponent = (props) => {

    const {
        tableData,
        tableName,
        dropdownListData,
        order,
        orderBy,
        displayMethod,
    } = props;

    const getUnique = (data, key) => {
        return [...new Map(data.map((item) => [item[key], item])).values()];
    };

    let propertyId;
    if (tableName === "property") {
        propertyId = tableData.id;
    };
    if (tableName === "ota") {
        propertyId = tableData.propertyId;
    };

    const filteredData = dropdownListData.filter((item) => (item.propertyId == propertyId));
    const dropdownListSortedData = customSort(getUnique(filteredData, "id"), order, orderBy);

    return (
        <div
            className={styles.dropdownContainer}
            style={{ paddingLeft: displayMethod === "name" ? "20px" : 0 }}
        >
            {dropdownListSortedData.length ?
                dropdownListSortedData.map((item, index) => (
                    displayMethod === "name" ?
                        <span key={index} className="d-flex">
                            {item.parentRentalId &&
                                <SubdirectoryArrowRight
                                    fontSize="small"
                                    style={{ color: "#9e9e9e" }}
                                />
                            }
                            <p>{item.name}</p>
                        </span>
                        :
                        <span key={index}>
                            {tableData.rentalIds.includes(item.id) ?
                                <p>mapped</p>
                                :
                                <p style={{ color: "#cb3300" }}>not mapped</p>
                            }
                        </span>
                ))
                :
                (
                    displayMethod === "status" ?
                        <p style={{ paddingLeft: "20px" }}>-</p>
                        :
                        <p>No rentals available</p>
                )
            }
        </div>
    );

};

export const TTextLabel = (props) => {

    return (
        <div className={styles.wrapField}>
            {props.field.key === "name" && (
                <section key={"name"} className={styles.nameContainer}>
                    <div className={styles.sectionBtnArrowMore}>
                        <p>
                            {props.row[props.field.key]}
                        </p>
                        {!props.dropdownState && (
                            <ExpandMoreIcon
                                className={styles.btnArrowMore}
                                onClick={props.toggleDropdownState}
                            />
                        )}
                    </div>
                    {props.dropdownState && (
                        <div className={styles.sectionBtnArrowLess}>
                            <DropdownComponent
                                tableData={props.row}
                                tableName={props.tableName}
                                dropdownListData={props.dropdownListData}
                                order={props.order}
                                orderBy={props.orderBy}
                                displayMethod="name"
                            />
                            <div className={styles.containerArrowLess}>
                                <ExpandLessIcon
                                    className={styles.btnArrowLess}
                                    onClick={props.toggleDropdownState}
                                />
                            </div>
                        </div>
                    )}
                </section>
            )}
            {props.field.key === "connectedWithSU" && (
                <section key={"connectedWithSU"} className={styles.statusContainer}>
                    <p>
                        {props.row[props.field.key] ? "Connected" : "Not connected"}
                    </p>
                </section>
            )}
            {props.field.key === "isActive" && (
                <section key={"isActive"} className={styles.statusContainer}>
                    <div className={styles.circleIconContainer}>
                        <p>
                            {props.row[props.field.key] ? "Active" : "Not active"}
                        </p>
                        <CircleIcon
                            className={styles.circleIcon}
                            style={{ color: props.row[props.field.key] ? "#00cb33" : "#cb3300" }}
                        />
                    </div>
                    {props.dropdownState &&
                        <DropdownComponent
                            tableData={props.row}
                            tableName={props.tableName}
                            dropdownListData={props.dropdownListData}
                            order={props.order}
                            orderBy={props.orderBy}
                            displayMethod="status"
                        />
                    }
                </section>
            )}
            {!["name", "connectedWithSU", "isActive"].includes(props.field.key) && (
                <section>
                    {props.field.isDate ? (
                        moment(props.row[props.field.key]).format(props.setting.dateDisplay.toUpperCase())
                    ) : (
                        props.row[props.field.key] || [0, "0"].includes(props.row[props.field.key]) && "0" || "-"
                    )}
                </section>
            )}
        </div>
    )
};

export const ViewMappedRentalCount = (props) => {

    const totalNumberOfRentals = props.dropdownListData.length;
    const numberOfMappedRentals = props.row.rentalIds.length;

    let color;
    if (numberOfMappedRentals === totalNumberOfRentals) {
        color = "#00cb33";
    } else if (numberOfMappedRentals === 0) {
        color = "#cb3300";
    } else {
        color = "#f5b60e";
    };

    return (
        <div className={`${styles.viewMappedRentalCount}`}>
            <div className={styles.circleIconContainer}>
                <p>
                    {`${numberOfMappedRentals} of ${totalNumberOfRentals}`}
                </p>
                <CircleIcon
                    className={styles.circleIcon}
                    style={{ color: color }}
                />
            </div>
        </div>
    )
};

export const TConnectButton = (props) => {

    return (
        <div className={`${styles.connectButton} ${styles.wrapField}`}>
            <Toggle
                className={`${styles.icon}`}
                checked={props.row["connectedWithSU"]}
                icons={false}
                onChange={() => props.handleConnectButton(props.row)}
            />
        </div>
    )
};

export const TMapButton = (props) => {

    return (
        <div className={`${styles.mapButton} ${styles.wrapField}`}>
            {props.row["connectedWithSU"] ?
                <i
                    className={`${styles.icon} fas fa-project-diagram`}
                    onClick={() => props.handleMapButton(props.row)}
                />
                :
                <div>
                    <InfoOutlinedIcon
                        fontSize="small"
                        data-tip
                        data-for="channelMappingButton"
                        className={styles.toolTipIcon}
                    />
                    <ReactTooltip type="dark" place="bottom" effect="solid" id="channelMappingButton" className={styles.toolTip}
                    >
                        <span>
                            Toggle the ‘connect’ button to enable mapping.
                        </span>
                    </ReactTooltip>
                </div>
            }
        </div>
    )
};

export const TViewButton = (props) => {

    const {
        tableName,
        dropdownListData,
    } = props;

    let propertyId;
    if (tableName === "property") {
        propertyId = props.row.id;
    };
    if (tableName === "ota") {
        propertyId = props.row.propertyId;
    };

    const filteredDataList = dropdownListData.filter(item => item.propertyId === propertyId);

    return (
        <div className={`${styles.viewButton} ${styles.wrapField}`}>
            {props.row["connectedWithSU"] && filteredDataList.length ?
                <Visibility
                    className={`${styles.viewIcon} ${styles.icon}`}
                    onClick={() => props.handleViewButton(props.row)}
                />
                :
                <div className={styles.invisibility}>
                    {"-"}
                </div>
            }
        </div>
    )
};

export const SectionAboveTheTable = (props) => {

    return (
        <>
            <div className={`ml-3 mt-2 ${styles.header}`}>
                {props.title}
            </div>
            <div className={`m-0 p-0 ml-4 ${props.backButton && "mt-2"} mb-4 ${styles.channelHeader}`}>
                {props.backButton && (
                    <Button
                        className={`m-0 mt-1 ${styles.btn} ${styles.backToPropertyTableBtn}`}
                        onClick={props.onShowPropertiesTable}
                    >
                        Back to Property Table
                    </Button>
                )}
                {props.firstText}
                {props.secondText}
                {props.thirdText}
            </div>
        </>
    )
};