import React, { useState,useEffect,useRef } from 'react';
import { Formik, Form } from 'formik';
import * as yup from "yup";
import Toggle from 'react-toggle';
import './toggle-css.css';
import getSymbolFromCurrency from 'currency-symbol-map';
import {useSelector,useDispatch} from 'react-redux';
import { Row } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  chargeCalculation,
  addDiscount,
  editDiscount,
  fetchBookingById,
  fetchDiscountByBooking,
  resetCharges
} from '../../../../../../../general_redux/calendar/actions';
import Loading from "../../../../../../../components/loading";
import styles from '../editBooking.module.css';

const Payment = ({bookingId}) => {
  const dispatch =useDispatch();
  const bookingDiscount=useSelector(({calendar})=>calendar && calendar.bookingDiscount);
  const bookingData= useSelector(({calendar}) => calendar &&
  calendar.allBooking.filter((row)=>row.id===bookingId)[0]);
  const isChannelBooking = bookingData?.bookingType === 'channelBooking';
  const rentalDetail = useSelector(({rentals})=>rentals
      && rentals.rentals.filter((row)=>row.id===bookingData.rentalId)[0]);
  const currentCharges=useSelector(({calendar})=>calendar && calendar.currentCharges);
  const [newTotal, setNewTotal] = useState(bookingDiscount && bookingDiscount.totalPrice || 0.0);
  const [discount,setDiscount]=useState({
    name: bookingDiscount && bookingDiscount.discountName || 'Discount',
    amount: bookingDiscount && bookingDiscount.discountAmount || '',
  });
  const [charges,setCharges]=useState({
    name: bookingDiscount && bookingDiscount.chargeName || 'Extra charge',
    amount: bookingDiscount && bookingDiscount.chargeAmount || '',
  });
  const [editableMode,setEditableMode]=useState(bookingData && bookingDiscount && bookingData.price!==bookingDiscount.totalPrice);
  const [overWrittenTotal,setOverWrite]=useState(bookingData && bookingData.price || 0.0);
  const loadBookingStatus=useSelector(({calendar})=>calendar && calendar.actionForBooking)
  const [fee,setFee]=useState(bookingDiscount && bookingDiscount.fee || 0.0);
  const [tax,setTax]=useState(bookingDiscount && bookingDiscount.tax || 0.0);
  const [extraGuestFee,setExtraGuestFee]=useState(bookingDiscount && bookingDiscount.extraGuestFee || 0.0);
  const [baseRate,setBaseRate]=useState(bookingDiscount && bookingDiscount.baseRate || 0.0);
  const [errorDisc,setErrorDisc]=useState(null);

  useEffect(()=>{
    dispatch(fetchDiscountByBooking(bookingId));
    dispatch(fetchBookingById(bookingId));
  },[])

  useEffect(()=>{
    return()=>{
      dispatch(resetCharges());
    }
  },[])

  useEffect(()=>{

    setNewTotal(baseRate+extraGuestFee-Number(discount.amount)+Number(charges.amount)+fee+tax);

  },[discount.amount,charges.amount])

  useEffect(()=>{

    if(currentCharges && currentCharges.total){
      if(currentCharges.total<0){
        setErrorDisc("Please enter valid discount amount!");
      }else{
        setErrorDisc(null);
      }

      setNewTotal(currentCharges.total);
      setOverWrite(bookingData.price);
      setTax(currentCharges.taxes);
      setFee(currentCharges.fees);
      setBaseRate(currentCharges.daily_rate);
      setExtraGuestFee(currentCharges.extra_guest_fees);

    };

  },[currentCharges])

  useEffect(()=>{
    if(bookingDiscount){
      setDiscount({
        name:bookingDiscount.discountName,
        amount:bookingDiscount.discountAmount,
      })
      setCharges({
        name:bookingDiscount.chargeName,
        amount:bookingDiscount.chargeAmount,
      })
      setFee(bookingDiscount.fee)
      setTax(bookingDiscount.tax)
      setBaseRate(bookingDiscount.baseRate)
      setExtraGuestFee(bookingDiscount.extraGuestFee)
      setNewTotal(bookingDiscount.totalPrice)
    }
  },[bookingDiscount])

  useEffect(()=>{
    if(bookingData && bookingDiscount){
      setEditableMode(bookingData.price!==bookingDiscount.totalPrice)
    }
  },[bookingData,bookingDiscount])
  
  useEffect(()=> {
    setOverWrite(bookingData?.price || 0);
  }, [editableMode])

  const handleSubmit = () => {

    const discountId = bookingDiscount.id;
    const isOWTotal = (editableMode && newTotal !== overWrittenTotal);
    
    const payload = {
        bookingId: bookingId,
        rentalId: bookingData.rentalId,
        discountName: discount?.name || "Discount",
        discountAmount: Number(isOWTotal ? bookingDiscount.discountAmount : discount.amount),
        chargeName: charges?.name || "Extra charge",
        chargeAmount: Number(isOWTotal ? bookingDiscount.chargeAmount : charges.amount),
        tax: tax,
        fees: fee,
        extraGuestFee: extraGuestFee,
        baseRate: baseRate,
        totalPrice: Number(isOWTotal ? bookingDiscount.totalPrice : newTotal),
        bookingPrice: Number(isOWTotal ? overWrittenTotal : newTotal),
        nights: bookingData.nights,
        noOfGuests: bookingData.noOfGuests
    };

    if (isOWTotal) {

      dispatch(editDiscount(payload, discountId));
      // console.log(payload, discountId);

    } else {

      const chargePayload = {
        arrive:bookingData.arrive,
        depart:bookingData.depart,
        noOfAdults: Number(bookingData.noOfAdults),
        noOfChildren: Number(bookingData.noOfChildren),
        rentalId:bookingData.rentalId,
        extraCharge:Number(charges.amount),
        discount:Number(discount.amount),
        bookingType: bookingData.bookingType,
      };
  
      if (!isChannelBooking) {
          dispatch(chargeCalculation(chargePayload)).then(() => {
            dispatch(editDiscount(payload, discountId));
          });
          // console.log(chargePayload, payload, discountId);
      };
    };
    
  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    const newValue = value;

    if (name === 'discount') {
      setDiscount({ ...discount, amount: newValue });
    } else if (name === 'charges') {
      setCharges({ ...charges, amount: newValue });
    } else if (name === 'overWrittenTotal') {
      setOverWrite(value);
    }
  };

  const initialValues = {
    discountName: discount.name,
    discountAmount: discount.amount,
    chargeName: charges.name,
    chargeAmount: charges.amount,
    overWrittenTotal: editableMode ? overWrittenTotal : newTotal,
  };

  const validationSchema = yup.object().shape({
    discountName: yup.string().max(50, 'Must be 50 characters or less'),
    discountAmount: yup.number().min(0, 'Must be a number greater than or equal to 0').typeError('Must be a value of type number'),
    chargeName: yup.string().max(50, 'Must be 50 characters or less'),
    chargeAmount: yup.number().min(0, 'Must be a number greater than or equal to 0').typeError('Must be a value of type number'),
    overWrittenTotal: yup.number().min(0, 'Must be a number greater than or equal to 0').typeError('Must be a value of type number').test('overWrittenTotal',
      (overWrittenTotal === '' ? 'Must be a value other than empty' : 'Must be a different value than the current total'),
      function (value) {

        // Must be a different value than the current total
        if (editableMode && (Number(value) === Number(newTotal))) {
          return false;
        };
        
        // Must be a value other than empty
        if (value != 0 && !value) {
          return false;
        };

        return true;
      }),
  });

  
  return (
    <div className="p-3">
      <Loading loadingStatus={loadBookingStatus.loading}/>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
      {({ values, errors, setFieldValue, handleSubmit, resetForm }) => (
      <Form onSubmit={handleSubmit}>
      <div className="pb-4 d-flex flex-column align-items-center">
        <div className={styles.subHeader}> Total due: {isChannelBooking ? '-' : `${editableMode ? overWrittenTotal : newTotal} ${getSymbolFromCurrency(rentalDetail.currency)}`} </div>
        <div className={styles.defaultFont}>Payment status: {`${bookingData.paymentStatus}`} </div>
      </div>
      <div className="pb-2 d-flex flex-lg-row flex-column">
        <div className={`${styles.divWrapper} col-12 col-lg-6`}>
          <div className="pb-2 d-flex flex-direction-column">
            <div className={styles.subHeader}>Payment calculation </div>
            <div className={styles.toolTipIconContainer}>
              <InfoOutlinedIcon
                fontSize="small"
                color="inherit"
                data-tip
                data-for="paymentCalculation"
                className={styles.toolTipIcon}
              />
              <ReactTooltip
                place="bottom"
                type="dark"
                id="paymentCalculation"
                effect="solid"
                className={styles.toolTip}
              >
                <span>
                  Calculation based on your rates settings for this rental. You can change rates, taxes, fees and
                  discount for This rental by clicking on 'Rates' in the top menu.
                </span>
              </ReactTooltip>
            </div>
          </div>
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>Base rate:</div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{isChannelBooking || editableMode ? '-'  : `${baseRate} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>Extra guest fee:</div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{isChannelBooking || editableMode ? '-'  : `${extraGuestFee} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>Total rent: </div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{isChannelBooking || editableMode ? '-'  : `${baseRate+extraGuestFee} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>Discount:</div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{isChannelBooking || editableMode ? '-'  : `${discount.amount ? -discount.amount : 0} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>Extra charge: </div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{isChannelBooking || editableMode ? '-'  : `${charges.amount || 0} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>Fees:</div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{isChannelBooking || editableMode ? '-'  : `${fee} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>Taxes: </div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{isChannelBooking || editableMode ? '-'  : `${tax} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
          <br/>
          <Row className={styles.form_wrapper}>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_title}`}>
              <b>Total due:</b>
            </div>
            <div className={`${editableMode ? `text-muted`:``} ${styles.payment_data}`}>{isChannelBooking || editableMode ? '-' : `${newTotal} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </Row>
        </div>
        <div className={`col-12 col-lg-6 mt-5 mt-lg-0 ${styles.divWrapper}`}>
          <div className="pb-2 d-flex flex-direction-column">
            <div className={styles.subHeader}>Add discount: </div>
          </div>
          <div className={`d-flex align-items-center ${styles.form_wrapper}`}>
            <div className={`${editableMode?`text-muted`:``} ${styles.payment_title_sm}`}>Name:</div>
              {isChannelBooking || editableMode ?
                '-'
              :
              <input
                type={'text'}
                className={styles.payment_entry_sm}
                value={discount.name}
                onChange={(e) => {
                  const targetValue = {...discount, name: e.target.value};
                  setDiscount(targetValue);
                  setFieldValue('discountName', e.target.value);
                }}
              />
              }
          </div>
          <div className={`${styles.errorMessage}`} >{errors.discountName}</div>

          <div className={`d-flex align-items-center ${styles.form_wrapper}`}>
            <div className={`${editableMode?`text-muted`:``} ${styles.payment_title_sm}`}>Amount:</div>
              {isChannelBooking || editableMode ?
                  '-'
                :
              <>
              <div>

              <input
                    type="text"
                    name="discount"
                    className={styles.payment_entry_amount}
                    value={discount.amount ? discount.amount : ''}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue('discountAmount', e.target.value);
                    }}
                />
                <div className={styles.customError}>
                  {errorDisc?errorDisc:""}
                </div>
              </div>
              <div className={`${editableMode?`text-muted`:``} ${styles.symbolClass} ml-2`}>{`${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
              </>
              }
          </div>
          <div className={`${styles.errorMessage}`} >{errors.discountAmount}</div>

          <br />
          <div className="pb-2 d-flex flex-direction-column">
            <div className={styles.subHeader}>Add extra charge: </div>
          </div>
          <div className={`d-flex align-items-center ${styles.form_wrapper}`}>
            <div className={`${editableMode?`text-muted`:``} ${styles.payment_title_sm}`}>Name:</div>
            {isChannelBooking || editableMode ?
              '-'
            :
            <input
                type={'text'}
                className={styles.payment_entry_sm}
                value={charges.name}
                onChange={(e) => {
                  const targetValue = {...charges, name: e.target.value};
                  setCharges(targetValue);
                  setFieldValue('chargeName', e.target.value);
                }}
                />
              }
          </div>
          <div className={`${styles.errorMessage}`} >{errors.chargeName}</div>

          <div className={`${styles.form_wrapper} d-flex align-items-center`}>
            <div className={`${editableMode?`text-muted`:``} ${styles.payment_title_sm}`}>Amount:</div>
            {isChannelBooking || editableMode ?
                '-'
              :
              <>
              <input
                type="text"
                name="charges"
                className={styles.payment_entry_amount}
                value={charges.amount ? charges.amount : ''}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue('chargeAmount', e.target.value);
                }}
                />
              <div className={`${editableMode?`text-muted`:``} ${styles.symbolClass} ml-2`}>{`${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
            </>
            }
          </div>
          <div className={`${styles.errorMessage}`} >{errors.chargeAmount}</div>

          <br />
          <br />
          <br />
          <div className={`pb-2 d-flex flex-direction-column justify-content-between ${styles.withToggle}`}>
            <div className={`d-flex flex-direction-row`}>
              <div className={styles.subHeader}>Overwrite total due </div>
              <div className={styles.toolTipIconContainer}>
                <InfoOutlinedIcon
                    fontSize="small"
                    color="inherit"
                    data-tip
                    data-for="overWrite"
                    className={styles.toolTipIcon}
                />
                <ReactTooltip place="top" type="dark" id="overWrite" effect="solid" className={styles.toolTip}>
                <span>
                  Overwrite the total due for this reservation. Doing this will erase the system payment
                  calculation and any charges or discounts you set for this booking. This will become the total
                  amount due for this booking.<br/>
                  If you set this amount to 0, the system’s payment calculations will be used again, along with
                  charges or discounts you set above. If you intend not to charge for this booking, set the
                  discount amount equal to the total rent.
                </span>
                </ReactTooltip>
              </div>
            </div>
            <Toggle
                icons={false}
                id={"overWriteToggle"}
                checked={editableMode}
                onChange={() => {
                  setEditableMode(!editableMode);
                  resetForm();
                  setDiscount({ name: values.discountName, amount: values.discountAmount });
                  setCharges({ name: values.chargeName, amount: values.chargeAmount });
                  setOverWrite(values.overWrittenTotal);
                }}
            />
          </div>
          <div className={`d-flex align-items-center ${styles.form_wrapper}`}>
            <div className={styles.payment_title_sm}>
              <b>New total: </b>
            </div>
            <input
              type="text"
              name="overWrittenTotal"
              className={styles.payment_entry_amount}
              value={editableMode ? overWrittenTotal : newTotal}
              onChange={(e) => {
                handleChange(e);
                setFieldValue('overWrittenTotal', e.target.value);
              }}
              disabled={!editableMode}
            />
            <div className={`${styles.symbolClass} ml-2`}>{`${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
          </div>
          <div className={`${styles.errorMessage}`} >{errors.overWrittenTotal}</div>

        </div>
      </div>
      <div className={`d-flex justify-content-center ${styles.button_group}`}>
        <div>
          <button className={styles.settings_save} type="submit" disabled={errorDisc}>
            Save changes
          </button>
        </div>
      </div>
      </Form>
      )}
      </Formik>
    </div>
  );
};

export default Payment;