const asyncActionType = type => ({
    pending: `${type}_PENDING`,
    success: `${type}_SUCCESS`,
    error: `${type}_ERROR`
});

export const ADD_ICAL_CHANNEL = asyncActionType('ADD_ICAL_CHANNEL');
export const EDIT_ICAL_CHANNEL = asyncActionType('EDIT_ICAL_CHANNEL');
export const GET_ICAL_CHANNEL_LIST = asyncActionType('GET_ICAL_CHANNEL_LIST');
export const DELETE_ICAL_CHANNEL = asyncActionType('DELETE_ICAL_CHANNEL');
export const CHANGE_ICAL_CHANNEL_NAME = asyncActionType('CHANGE_ICAL_CHANNEL_NAME');