import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from "./ConfirmDelete.module.css";

function ConfirmDelete(props){
    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className={styles.modalTitle}
          >
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <p>{props.firstMessage}</p>
          <p>{props.secondMessage}</p>
          <div className='w-100'>
            <button
              onClick={() => props.onConfirmAct(false)}
              className={`${styles.allBtn} ${styles.deleteBtn}`}
            >
              Delete only this rental
              <span>
                <i className={`fa fa-check-circle ${styles.deleteIcon}`} />
              </span>
            </button>
            <button
              onClick={() => props.onConfirmAct(true)}
              className={`${styles.allBtn} ${styles.deleteBtn}`}
            >
              Delete rental and children
              <span>
                <i className={`fa fa-check-circle ${styles.deleteIcon}`} />
              </span>
            </button>
            <button
              onClick={props.onHide}
              className={`${styles.allBtn} ${styles.cancelBtn}`}
            >
              Cancel
              <span>
                  <i className={`fa fa-undo ${styles.cancelIcon}`}/>
              </span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
}

export default ConfirmDelete;