// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "body {\n  font-family: 'Montserrat' !important;\n  margin: 0;\n  padding: 0;\n}\n.offline_warning__2i0mC {\n  margin-top: calc(100vh / 4);\n  margin-bottom: 5px;\n  color: red;\n}\n.offline_text__2Cf6z {\n  margin: 5px;\n  font-size: 0.875rem;\n}\n.offline_container__vYx9q {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  padding-top: 50px;\n}\n.offline_beehaz__YAHtX {\n  width: 200px;\n  height: auto;\n  margin-top: 20px;\n  margin-bottom: 30px;\n}\n\n.offline_bottom_container__35Dev {\n  position: absolute;\n  left: 0;\n  bottom: 50px;\n  width: 100%;\n  text-align: center;\n}\n\n.offline_logo___MeUv {\n  width: 80px;\n  height: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"warning": "offline_warning__2i0mC",
	"text": "offline_text__2Cf6z",
	"container": "offline_container__vYx9q",
	"beehaz": "offline_beehaz__YAHtX",
	"bottom_container": "offline_bottom_container__35Dev",
	"logo": "offline_logo___MeUv"
};
module.exports = exports;
