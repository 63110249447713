import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { changeChannelName } from '../../../../../../general_redux/ical_channels/actions';
import { Form } from 'react-bootstrap';
import Loading from '../../../../../../components/loading';
import styles from '../../channel.module.css';

function ChannelForm(props) {
    const dispatch = useDispatch();

    const {
        values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit
    } = props;

    const rentals = useSelector(({rentals})=>rentals && rentals.rentals);
    const channelName = useSelector(({icalChannels}) => {
        const icalChannel = icalChannels && icalChannels.channels.find(item => item.channel.id === values.id)?.channel;
        return icalChannel ? icalChannel.name : '';
    });
    const actionForChangeChannelName = useSelector(({ icalChannels }) => icalChannels && icalChannels.actionForChangeChannelName);

    const paymentStatus = [
        "Payment Pending",
        "Partially Paid",
        "Paid",
    ];

    useEffect(() => {
        setFieldValue('name', channelName);
    }, []);

    const handleReset = () => {
        for (var prop in values) {
            if (prop === 'color') {
                setFieldValue('color', '#3D3D3D');
            } else if (prop === 'paymentStatus') {
                setFieldValue('paymentStatus', 'Paid');
            } else {
                setFieldValue(`${prop}`, '');
            }
        }
    };

    const handleCopy = (e,id) => {
        e.preventDefault();
        const copyElem=document.getElementById(`myLinkCopy-${id}`);
        copyElem.select();
        document.execCommand("copy");
    };

    const handleSubmitSaveName = () => {
        const payload = {
            id: values.id,
            channelName: values.name
        };
        dispatch(changeChannelName(payload))
        .then(() => setFieldValue('name', channelName));
    };

    return (
        <>
            <Loading
                loadingStatus={actionForChangeChannelName.loading}
            />
            <Form onSubmit={handleSubmit}>
                <div className={`d-flex`}>
                    {!values.id &&
                        <Form.Label className='col-form-label'>Channel name:</Form.Label>
                    }
                    <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`${values.id ? 'mr-2' : 'mx-2'} ${styles.form_entry}`}
                    />
                    {values.id &&
                        <button
                            type="button"
                            className={styles.btnSave}
                            onClick={handleSubmitSaveName}
                        >
                            Save name
                        </button>
                    }
                </div>
                <div className={`${styles.errorMessage}`} >{errors.name}</div>
                <br/>
                    <div className={`d-flex justify-content-lg-start`}>
                        <div className={`${styles.formInp1}`}>
                            Set colour of bookings for this channel:
                        </div>
                        <Form.Control
                            type="color"
                            name="color"
                            value={values.color}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`${styles.form_entry_color} ${styles.formInp2}`}
                        />
                    </div>
                <br/>
                <div className={`${styles.formInp1}`}>
                    <div className={styles.formInp1}>
                        <div className={` col-form-label ${styles.markBookingLabel}`}>
                            Mark incoming bookings as:
                        </div>
                        <Form.Control
                            as="select"
                            type="text"
                            name="paymentStatus"
                            value={values.paymentStatus}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`w-100 ${styles.form_entry}`}
                        >
                        { paymentStatus.map((option, index) => (
                            <option key={index} value={option} selected={values.paymentStatus === option}>
                                {option}
                            </option>
                        ))}
                        </Form.Control>
                    </div>
                </div><br/>
                { rentals && rentals.map((rental)=>(
                    <>
                        <div id={rental.id} className={`d-block d-md-flex mb-2 justify-content-lg-start`} key={rental.id}>
                            <div className='d-block d-md-flex'>
                                <div className={`col-form-label ${styles.rentalList}`}>
                                    <div className={styles.rentalName}>{rental.name}</div>
                                </div>
                                <section className='d-flex'>
                                    <Form.Control
                                        className={`w-100 mr-2 mr-md-3 ${styles.form_entry}`}
                                        id={`myLinkCopy-${rental.id}`}
                                        readOnly={true}
                                        value={rental.icalExportLink}
                                    />
                                    <button
                                        className={styles.btnCopy}
                                        onClick={(e)=>handleCopy(e,rental.id)}
                                    >Copy</button>
                                    <icon
                                        className={`fas fa-copy ${styles.hideIcon}`}
                                        style={{color:'#555', padding:'10px 0'}}
                                        onClick={(e)=>handleCopy(e,rental.id)}
                                    />
                                </section>
                            </div>
                            <Form.Control
                                type="text"
                                name={`icalImportLink-${rental.id}`}
                                value={values[`icalImportLink-${rental.id}`]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`mt-2 mt-md-0 ${styles.link_input} ${styles.form_entry}`}
                            />
                            <br/>
                        </div>
                    </>
                ))}
                <div className='mt-4 d-flex justify-content-center justify-content-md-start'>
                    <button
                        type="submit"
                        className={styles.btnSave}
                    >Save</button>
                    <button
                        type="button"
                        onClick={handleReset}
                        style={{background:'rgb(108, 117, 125)'}}
                        className={`${styles.btnSave}`}
                    >Discard</button>
                </div>
            </Form>
        </>
    );
}

export default ChannelForm;
