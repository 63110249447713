import React, {useEffect, useState} from 'react';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import WeekDayPick from '../../../../../../../common/components/weekDayPick';
import MultiSelect from '../../BulkDefaultChanges/MultiSelect';
import styles from '../ratesCalendar.module.css';
import CustomDate from "../../../../../../../components/CustomDate";

import { toast } from "react-toastify";
toast.configure();


function RateForm({schema, handleSubmit, onHide}) {

    const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);

    const [selectedRentals, setSelectedRentals] = useState([]);
    const [buttonText, setButtonText] = useState('Save');
    const [warning, setWarning] = useState(false);
    const [isRest,setIsReset]=useState(false);
    const [count,setCount]=useState(0);

    const currencyVal = rentals && rentals.length!==0 && rentals[0].currency;

    const confirmSubmit = (confirm) => {
        if (confirm) {
            setButtonText('Confirm');
            setWarning(true);
        } else {
            setButtonText('Save');
            setWarning(false);
        }
    }

    const onSubmit = (data) => {

        if (!warning && data.checkRate && Number(data.rate) === 0) {
            confirmSubmit(true);
        } else {
            handleSubmit(data);
            // console.log(data);
            confirmSubmit(false);
        }
    }

    const sameCurrency=(row)=>{
        return row.currency===currencyVal;
    }
    
    function diffCurrencyWarning() {
        if (!rentals.every(sameCurrency)) {
            setCount(count+1);
        }
    }

    const initialValues = {
        multiRentals: [],
        dateFrom: '',
        dateTo: '',
        rate: '',
        minStay: '',
        weekDays: [],
        checkRate: false,
        checkMinStay: false,
        // flag: 'rate&&minStay',
    }

    const resetForm =(handleReset)=>{
        handleReset();
        setIsReset(true);
        confirmSubmit(false);
        setSelectedRentals([]);
        onHide();
    }

    const getOptions = () => {
        const tmp=[];
        rentals.map((row) => (tmp.push({ value: row.id, label: row.name })));
        return tmp;
    }

    useEffect( () => {
        count === 1 && toast.warn(`Different currencies used in different rentals. Check the changes.`
            ,{
                style:{
                    color:'#484848'
                }
            });
    }, [count]);

    return(
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
        >
        {({ 
            values,
            errors,
            handleChange,
            handleSubmit,
            handleReset,
            setFieldValue
        }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row className='py-1'>
                    <p className={styles.subTitleModal}>
                        Select the fields you would like to change by clicking on the checkbox to the right.
                    </p>
                    <Form.Label className={`m-0 col-md-4 ${styles.rateForm__label}`}>
                        Select rental:*
                    </Form.Label>
                    <div className="col-md-8 col-lg-7">
                        <MultiSelect
                          options={getOptions()}
                          value={selectedRentals}
                          name="multiRentals"
                          onChange={(selectedOptions) => {
                            const selectedIds = selectedOptions.map(option => option.value);
                            setFieldValue('multiRentals', selectedIds);
                            setSelectedRentals(selectedOptions);
                          }}
                          onBlur={diffCurrencyWarning}
                          fieldHasError={!!errors.multiRentals}
                          placeholder={"---Select Rental---"}
                        />
                        <div className={`${styles.errorMessage}`}>{errors.multiRentals}</div>
                    </div>
                </Form.Row>
                <Form.Row className='py-1'>
                    <Form.Label className={`m-0 col-md-4  ${styles.rateForm__label}`}>Select date from:*</Form.Label>
                    <div className="col-md-8 col-lg-7 ">
                            <CustomDate
                                id={"dateFrom"}
                                name="dateFrom"
                                handleChange={(date)=>{
                                    setFieldValue('dateFrom', date);
                                }}
                                value={values.dateFrom}
                                hasError={errors.dateFrom}
                                max={values.dateTo}
                            />
                            <div className={`${styles.errorMessage}`}>{errors.dateFrom}</div>
                    </div>
                </Form.Row>
                <Form.Row className='py-1'>
                    <Form.Label className={`m-0 col-md-4  ${styles.rateForm__label}`}>Select date until:*</Form.Label>
                    <div className="col-md-8 col-lg-7 ">
                            <CustomDate
                                id={"dateTo"}
                                name="dateTo"
                                handleChange={(date)=>{
                                    setFieldValue('dateTo', date);
                                }}
                                value={values.dateTo}
                                hasError={errors.dateTo}
                                min={values.dateFrom}
                            />
                            <div className={`${styles.errorMessage}`}>{errors.dateTo}</div>
                    </div>
                </Form.Row>
                <Form.Row className='py-1'>
                  <Form.Label className={`m-0 col-md-4 ${styles.rateForm__label}`}>Set rate:</Form.Label>
                    <section className="col-md-8 col-lg-7">
                        <div className="d-flex align-items-center">
                            <Form.Control
                                className={styles.rateForm__input}
                                type="text"
                                name="rate"
                                value={values.rate}
                                onChange={handleChange}
                                isInvalid={!!errors.rate }
                                disabled={!values.checkRate}
                            />
                            <div className="ml-3">
                                <Form.Check
                                    type="checkbox"
                                    id="checkRate"
                                    name="checkRate"
                                    checked={values.checkRate}
                                    onChange={() => {
                                        setFieldValue("rate", (values.rate != undefined ? values.rate : initialValues.rate));
                                        setFieldValue("checkRate", !values.checkRate);
                                        if (values.checkRate === true) {
                                            setFieldValue("rate", initialValues.rate);
                                        };
                                    }}
                                />
                            </div>
                        </div>
                        <div className={`${styles.errorMessage}`} >{errors.rate}</div>
                    </section>
                </Form.Row>
                <Form.Row className="py-1">
                  <Form.Label className={`m-0 col-md-4 ${styles.rateForm__label}`}>Set min. stay requirement: </Form.Label>
                    <section className="col-md-8 col-lg-7">
                        <div className="d-flex align-items-center">
                            <Form.Control
                                className={styles.rateForm__input}
                                type="text"
                                name="minStay"
                                value={values.minStay}
                                onChange={handleChange}
                                isInvalid={!!errors.minStay}
                                disabled={!values.checkMinStay}
                            />
                            <div className="ml-3">
                                <Form.Check
                                    type="checkbox"
                                    id="checkMinStay"
                                    name="checkMinStay"
                                    checked={values.checkMinStay}
                                    onChange={() => {
                                        setFieldValue("minStay", (values.minStay != undefined ? values.minStay : initialValues.minStay));
                                        setFieldValue("checkMinStay", !values.checkMinStay);
                                        if (values.checkMinStay === true) {
                                            setFieldValue("minStay", initialValues.minStay);
                                        };
                                    }}
                                />
                            </div>
                        </div>
                        <div className={`${styles.errorMessage}`} >{errors.minStay}</div>
                    </section>
                </Form.Row>
                <Form.Row className="py-1">
                    <Form.Label className={`m-0 col-md-4 ${styles.rateForm__label}`}>Apply only to following week days: </Form.Label>
                    <section className="m-0 p-0 col-md-8 col-lg-8 overflow-auto">
                        <WeekDayPick
                            name="weekDays"
                            isReset={isRest}
                            value={values.weekDays}
                            selectedDays={(days) => {
                                const weekDayList = days.filter((item) => item.status === true).map((item) => item.day);
                                setFieldValue("weekDays", weekDayList);
                                setIsReset(false);
                            }}
                        />
                        <div className={`pl-2 ${styles.errorMessage}`}>{errors.weekDays}</div>
                    </section>
                </Form.Row>
                {warning &&
                    <p className={styles.warningMessage}>
                        The rate was set to 0. Are you sure you want to proceed with saving the changes?
                    </p>
                }
                <div className='p-2 d-flex justify-content-center'>
                    <div className='col-md-8 d-flex justify-content-around'>
                        <button  type="submit" className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`}>{buttonText}</button>
                        <button type="reset" className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`} style={{backgroundColor:"#6c757d"}} onClick={()=>resetForm(handleReset)} >Discard</button>
                    </div>
              </div>
          </Form>
        )}
        </Formik>
    )
}

export default RateForm;